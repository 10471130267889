import { Breadcrumb  } from 'react-bootstrap';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PropTypes from 'prop-types';

const BreadCrumb = (props) => {
    return(
        <Breadcrumb>
            {
                props.breadcrumb.map((breadcrumb, index) => {
                    const isFirstOrDifferent = index === 0 || breadcrumb.name !== props.breadcrumb[index - 1].name;

                    return (
                        isFirstOrDifferent && (
                            <Breadcrumb.Item key={breadcrumb.name} onClick={props.handleClickBreadcrumb} href={breadcrumb.url} active={breadcrumb.active}>{index >= 1 && <NavigateNextRoundedIcon />}<div>{breadcrumb.name}</div></Breadcrumb.Item>
                        )
                );
            })}
        </Breadcrumb>
    );
};

export default BreadCrumb;

BreadCrumb.propTypes = {
    breadcrumb: PropTypes.array,
    handleClickBreadcrumb: PropTypes.func
};

BreadCrumb.defaultProps = {
    handleClickBreadcrumb: () => {},
};
