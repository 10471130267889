import { useContext } from 'react';
import { ShareContext } from '../context/share-state';
import path from '../routePaths';
import Localization from '../localization';
import _ from 'lodash';
import { SessionContext } from '../context/session-provider';

const MenuTabNav = () => {
    // Share Context
    const { menuTabKey,
        updateMenuTabKey } = useContext(ShareContext);

    // Session Context
    const { isEndUser,
        isUserAdmin,
        isAssetOwner,
        isCustomerAdmin } = useContext(SessionContext);

    return (
        <div className='menu-tabs-wrapper'>
            <ul className='menu-tabs-nav nav nav-tabs'>
                {
                    (isCustomerAdmin || isAssetOwner || isEndUser) &&
                        <li className='nav-item'>
                            <button type="button" onClick={() => updateMenuTabKey(path.EXPLORE)} className={`nav-link ${menuTabKey === path.EXPLORE && 'active'}`}>{Localization.MainTab.Search}</button>
                        </li>
                }
                {
                    (isAssetOwner || (!isEndUser && !isUserAdmin)) &&
                        <li className='nav-item'>
                            <button type="button" onClick={() => updateMenuTabKey(path.UPLOAD)} className={`nav-link ${menuTabKey === path.UPLOAD && 'active'}`}>{Localization.MainTab.Upload}</button>
                        </li>
                }
                {
                    (isCustomerAdmin || isAssetOwner || isEndUser) &&
                        <li className='nav-item'>
                            <button type="button" onClick={() => updateMenuTabKey(path.COLLECTIONS)}  className={`nav-link ${menuTabKey === path.COLLECTIONS && 'active'}`}>{Localization.MainTab.Collections}</button>
                        </li>
                }
            </ul>
        </div>
    );
}

export default MenuTabNav;