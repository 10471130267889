import { useState } from "react";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentPdf = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPdfPage = () => {
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1);
        }
    };

    const goToNextPdfPage = () => {
        if (pageNumber < numPages) {
          setPageNumber(pageNumber + 1);
        }
    };
    
    return(
        <>
            <Document file={props.fileURL} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>
            <div className="view-asset-document-page-container">
                <div className="view-asset-document-page">
                    <button className="btn btn-primary icon-button" onClick={goToPrevPdfPage} disabled={pageNumber <= 1}>
                        <ChevronLeftRoundedIcon />
                    </button>
                    <span>
                        {pageNumber} of {numPages}
                    </span>
                    <button className="btn btn-primary icon-button" onClick={goToNextPdfPage} disabled={pageNumber >= numPages}>
                        <ChevronRightRoundedIcon />
                    </button>
                </div>
            </div>
        </>
    );
}

export default DocumentPdf;