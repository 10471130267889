import ServiceUtil from '../util/serviceUtil.js';
import ENDPOINTS from '../util/endpoints';

class SearchService {
    static async Search(filter, callback, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.Search, filter, '', tenantUuid, callback);
    }

    static async searchInDocument(docId, query, callback, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', `${ENDPOINTS.Search}/${docId}`, query, '', tenantUuid, callback);
    }
}

export default SearchService;