import { spanishGray, black, iconSize } from '../../shared/utils';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

const VisibilityIcon = ({ visible, className, color, size }) => {
    const colorVal = color || black;
    const visibleVal = typeof visible === 'boolean' ? visible : true;
    const sizeVal = size || iconSize;

    return (
        <>
            {
                visibleVal
                ?
                    <VisibilityRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <VisibilityOffRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default VisibilityIcon;