import { useDropzone } from "react-dropzone";
import CloseIcon from "../../icons/close";
import { IsDesktop, black, convertImageUrl, checkProtocol, isImageUrl } from "../../shared/utils";
import Localization from "../../shared/localization";
import DOMPurify from "dompurify";
import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import { TextField } from "@mui/material";
import LinkIcon from "../../icons/link";
import { useContext, useState } from "react";
import { UploadContext } from "../../shared/context/upload-provider";
import AlertNotification from "../../shared/components/alert-notification";
import { ShareContext } from "../../shared/context/share-state";
import path from "../../shared/routePaths";
import SearchHooks from "../../shared/hooks/search-hooks";

const ImageSearchUpload = (props) => {
  // Share Context
  const { updateMenuTabKey, setIsImageSearch, setImageSearchUrl, setImageSearchLabels } =
    useContext(ShareContext);

  const { uploadImageFileData, getFileLabels, cancelUploadProcess } = useContext(UploadContext);

  const [messageInfo, setMessageInfo] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isImageSearchFocus, setImageSearchFocus] = useState(false);

  const handleCloseClick = () => {
    props.setIsImageSearchModalOpen(false);
    cancelUploadProcess();
  };

  const { setShowSearch, setSearchSubmitted } = SearchHooks();

  const handleSearchImageUrlClick = async () => {
    setIsUploading(true);
    setMessageInfo("");

    try {
      // Check if the URL is valid with either HTTP or HTTPS protocol
      let validImageUrl = imageUrl;
      const isValid = await isImageUrl(validImageUrl);

      if(!isValid) {
        if (!validImageUrl.startsWith('http://') && !validImageUrl.startsWith('https://')) {  
          // Check if the URL starts with http:// or https://\
          // If not, use checkProtocol to determine the correct protocol
          const protocolToUse = await checkProtocol(validImageUrl);
          if (!protocolToUse) {
            throw new Error('Neither HTTP nor HTTPS protocol worked for the URL.');
          }
          validImageUrl = `${protocolToUse}://${validImageUrl}`;
        }
      }

      const result = await convertImageUrl(validImageUrl);
      if (!result.isImage) {
        throw new Error('The URL does not point to a valid image.');
      }
  
      let file = result.file; // Use the converted JPG file if available
      if (!file) {
        // Fetch the original image as a blob if no converted file is available
        const response = await fetch(validImageUrl, { mode: 'no-cors' });
        const blob = await response.blob();
        if (blob.size === 0) {
          throw new Error('Blob is empty');
        } else {
          file = new File([blob], "image.jpg", { type: "image/jpeg" });
                  
          const labels = await getFileLabels(file);
          setImageSearchLabels(labels.map(label => label.Name));
                  
          setImageSearchUrl(validImageUrl);
          setIsUploading(false);
          setShowSearch(true);
          setSearchSubmitted(true);
          setIsImageSearch(true);
          updateMenuTabKey(path.SEARCH);
        }
      } else if(result.isImage) {
                  
        const labels = await getFileLabels(file);
        setImageSearchLabels(labels.map(label => label.Name));
                  
        setImageSearchUrl(validImageUrl);
        setIsUploading(false);
        setShowSearch(true);
        setSearchSubmitted(true);
        setIsImageSearch(true);
        updateMenuTabKey(path.SEARCH);
      } 
    } catch (_) {
      setMessageInfo(Localization.Upload.ImageUploadErrorInvalidUrl);
      setIsUploading(false);
    }
  };

  const onDrop = async (files) => {
    setIsUploading(true);
    setMessageInfo("");
    const messageInfo = await uploadImageFileData(files);
    setMessageInfo(messageInfo);

    if (!messageInfo) {
      setIsUploading(false);
      setShowSearch(true);
      setSearchSubmitted(true);
      setIsImageSearch(true);
      updateMenuTabKey(path.SEARCH);
    } else {
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const contentToRender = IsDesktop
    ? Localization.Upload.ImageDropzone
    : Localization.Upload.MobileImageDropzone;

  const sanitizedContent = DOMPurify.sanitize(contentToRender);

  return (
    <div className="image-search-upload-section">
      <svg className='pointer' width="100" height="100" viewBox="0 70 200 100">
        <path
          d="M100,80
                Q65,150 
                10,150 
                L10,160 
                L190,160 
                L190,150 
                Q135,150 
                100,80"
          fill="white"
          stroke="none"
        />
        <path
          d="M100,80
                Q65,150 
                10,150"
          fill="none"
          stroke="black"
          strokeWidth="4"
        />
        <path
          d="M100,80
                Q135,150 
                190,150"
          fill="none"
          stroke="black"
          strokeWidth="4"
        />
      </svg>
      <div className="image-search-upload-title-container">
        <h3>Search by image</h3>
        <button
          className="btn-close"
          type="button"
          id="button-close"
          onMouseDown={handleCloseClick}
          title="Clear"
        >
          <CloseIcon color={black} />
        </button>
      </div>
      {isUploading ? (
        <div className="image-search-upload-loader-container">
          <div className="loader"></div>
          <div>Uploading...</div>
        </div>
      ) : (
        <>
          {messageInfo && messageInfo.length > 0 && (
            <>
              <div className="image-search-upload-message-container">
                <AlertNotification variant={"error"} content={messageInfo} />
              </div>
            </>
          )}
          <div className="image-search-upload-box-container">
            <div
              className={
                isDragActive
                  ? "upload-dropzone-wrapper upload-dropzone-active"
                  : "upload-dropzone-wrapper"
              }
            >
              <div
                {...getRootProps({ className: "dropzone" })}
                className="upload-dropzone-cont"
              >
                <input
                  className="upload-input"
                  {...getInputProps()}
                  type="file"
                  accept="image/png, image/jpeg"
                  capture="environment"
                />
                <div className="upload-dropzone-content">
                  <UploadRoundedIcon />
                  <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                  <div className="upload-dropzone-info paragraph-2">
                    {Localization.Upload.ImageDropzoneInfo}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="image-search-upload-url-link">
            <div className="image-search-upload-url-top">
              <div className={`image-search-input ${isImageSearchFocus ? 'black-border' : ''} w-100`}>
                <TextField
                  id="image-url-input-box"
                  className="image-url-input-box"
                  placeholder="Paste URL link"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  onFocus={() => setImageSearchFocus(true)}
                  onBlur={() => setImageSearchFocus(false)}
                  InputProps={{
                    startAdornment: <LinkIcon color="#000000" />,
                  }}
                />
              </div>
              <div className="button-group">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={handleSearchImageUrlClick}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="image-search-upload-url-bottom paragraph-2">
              {Localization.Upload.ImageDropzoneInfo2}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageSearchUpload;
