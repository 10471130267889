import UploadProgress from '../upload-progress';
import UploadError from '../upload-error';
import UploadEdit from '../upload-edit';
import UploadTag from '../upload-tag';
import UploadCollection from '../upload-collection';

const UploadOffCanvas = (props) => {
    return (
        <>
            <div className={`upload-offcanvas-wrapper sticky ${props.progressState ? 'progress-state-wrapper' : ''}`} style={{top: props.isTopSet ? '85px' : ''}}>
                <div className={props.progressState ? 'upload-offcanvas-cont progress-state' : 'upload-offcanvas-cont'}>
                    {
                        !props.uploadError && props.activeSetting === 'edit'
                        ?
                            <UploadEdit
                                owners={props.owners}
                                checkedFiles={props.checkedFiles}
                                updateFileAttributes={props.updateFileAttributes}
                                isDesktop={props.isDesktop}
                            />
                        :
                        !props.uploadError && props.activeSetting === 'tag'
                        ?
                            <UploadTag
                                checkedFiles={props.checkedFiles}
                                updateFileAttributes={props.updateFileAttributes}
                                processRef={props.processRef}
                                isDesktop={props.isDesktop}
                            />
                        :
                        !props.uploadError && props.activeSetting === 'collection'
                        ?
                            <UploadCollection
                                checkedFiles={props.checkedFiles}
                                processRef={props.processRef}
                                totalFiles={props.totalFiles}
                            />
                        :
                        <>
                            {
                                !props.uploadError
                                ?
                                    props.progressState &&
                                        <UploadProgress
                                            uploadedFiles={props.uploadedFiles}
                                            errFiles={props.errFiles}
                                            totalFiles={props.totalFiles}
                                            onHandleSetting={props.onHandleSetting}
                                        />
                                :
                                    <UploadError
                                        errFiles={props.errFiles}
                                        handlerErrorProceeds={props.handlerErrorProceeds}
                                    />
                            }
                        </>
                    }

                </div>
            </div>
        </>
    );
}

export default UploadOffCanvas;