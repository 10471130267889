import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CheckSubDomain from './checkSubdomain';
import reportWebVitals from './reportWebVitals';
import { Session } from './components/shared/context/session-provider';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './scss/main.scss';
import register from './components/shared/serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <main className="App">
    <Session>
      <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#000000',
              colorPrimaryBg: '#EDEDED',
              fontFamily: 'Archivo',
              fontSize: 16
            },
          }}>
          <CheckSubDomain/>
          <App />
      </ConfigProvider>
    </Session>
  </main>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Register Service Worker
register();