import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

const GridSkeleton = (props) => {
    const options = {
        baseColor: props.options && props.options.baseColor,
        highlightColor: props.options && props.options.highlightColor
    };

    return(
        <>
            <div className='asset-card-skeleton'>
                <div className='ratio ratio-4x3'>
                    <Skeleton baseColor={options.baseColor} highlightColor={options.highlightColor} />
                </div>
                <Skeleton
                    baseColor={options.baseColor}
                    highlightColor={options.highlightColor}
                    height={props.view === 'grid' ? "auto" : 80}
                    style={{borderBottom: props.view === 'grid' ? 'none' : '1px solid white' }}
                />
            </div>
        </>
    );
};

export default GridSkeleton;