import { useState, useRef, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { downloadBlob } from '../../utils';
import { GlobalContext } from "../../context/global-provider";
import { ShareContext } from "../../context/share-state";
import { removeStoreItem } from '../../../../store';
import { SessionContext } from '../../context/session-provider';
import Modal from 'react-bootstrap/Modal';
import Localization from '../../localization';
import Button from 'react-bootstrap/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import MovieIcon from '@mui/icons-material/Movie';
import Accordion from 'react-bootstrap/Accordion';
import videojs from 'video.js';
import VideoJS from '../../videojs';
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import CollectionHooks from '../../hooks/collection-hooks';
import CustomIcons from "../custom-icons";
import EditAssets from "./edit-asset";
import AssetService from '../../../../services/api/assetsService';
import DeleteAssetModal from "./delete-asset";
import DocumentPdf from "../document-pdf"

const ViewAssets = (props) => {
    const [reloadProps, setReloadProps] = useState();
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [isTagExpanded, setIsTagExpanded] = useState(false);
    const [isAITagExpanded, setIsAITagExpanded] = useState(false);
    const [isCollectionExpanded, setIsCollectionExpanded] = useState(false);
    const [isTagView, setIsTagView] = useState(null);
    const [isAITagView, setIsAITagView] = useState(null);
    const [isCollectionView, setIsCollectionView] = useState(null);
    const [isTagVisible, setIsTagVisible] = useState(false);
    const [isAITagVisible, setIsAITagVisible] = useState(false);
    const [isCollectionVisible, setIsCollectionVisible] = useState(false);
    const [assetsHover, setAssetsHover] = useState(false);
    const [assetsEdit, setAssetsEdit] = useState(false);
    const [saveAssetsEdit, setSaveAssetsEdit] = useState(false);
    const [assetFilename, setAssetFilename] = useState();
    const [assetDescription, setAssetDescription] = useState();
    const [assetCollections, setAssetCollections] = useState();
    const [assetCollectionsContainer, setAssetCollectionsContainer] = useState(160);
    const [assetsTags, setAssetsTags] = useState();
    const [assetsTagsContainer, setAssetsTagsContainer] = useState(160);
    const containerTagRef = useRef(null);
    const containerAITagRef = useRef(null);
    const containerCollectionRef = useRef(null);
    const audioRef = useRef(null);
    const playerRef = useRef(null);
    const { updateCollectionSearch } = CollectionHooks();
    const { updateToastMessage } = useContext(GlobalContext);
    const { tenantUuid, isCustomerAdmin, isAssetOwner, username, userUuid } = useContext(SessionContext);
    const { AllAssets,
        setAllAssets,
        displayItems,
        setDisplayItems,
        displaySearchItems,
        setDisplaySearchItems,
        displayCollectionItems,
        setDisplayCollectionItems,
        updateStorage,
        setOriginalData,
        displayMyAssetItems,
        setDisplayMyAssetItems,
        setMaxStorageSize,
        setIsStorageMax,
        isMobile
     } = useContext(ShareContext);
    const [modalShow, SetModalShow] = useState(false);
    const handleModalClose = () => SetModalShow(false);
    const [deleteFilesData, setDeleteFilesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const maxContainerHeight = 160;
    const videoJsOptions = {
        playsinline: true,
        autoplay: true,
        controls: true,
        responsive: true,
        muted: true,
        fluid: true,
        sources: {
          src: props.fileData.url,
          type: 'video/mp4'
        }
    };
    const containerTagStyle = {
        maxHeight: isTagExpanded ? 'none' : isTagView ? assetsTagsContainer + 'px' : "none",
        overflow: 'hidden',
        visibility: isTagVisible ? 'visible' : 'hidden',
        marginBottom: isTagView ? '16px' : '0px'
    };
    const containerCollectionStyle = {
        maxHeight: isCollectionExpanded ? 'none' : isCollectionView ? assetCollectionsContainer + 'px' : "none",
        overflow: 'hidden',
        visibility: isAITagVisible ? 'visible' : 'hidden',
        marginBottom: isCollectionView ? '16px' : '0px'
    };
    const containerAITagStyle = {
        maxHeight: isAITagExpanded ? 'none' : isAITagView ? maxContainerHeight + 'px' : "none",
        overflow: 'hidden',
        visibility: isCollectionVisible ? 'visible' : 'hidden',
        marginBottom: isAITagView ? '16px' : '0px'
    };

    //----- Function -----//
    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });

        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
    };

    const handleAudioClick = () => {
        if(audioRef.current !== null) {
            if(audioPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
        }
        setAudioPlaying(!audioPlaying)
    };

    const download = () => {
        downloadBlob(props.fileData.url, props.fileData.key, undefined, tenantUuid, userUuid);
        updateToastMessage(`${props.fileData.name} downloaded`);
    };

    const deleteAsset = async () => {
           try {
                setLoading(true);
                const bodyAsset = {
                    "uuid": tenantUuid,
                    "useruuid": userUuid,
                    "assetuuids": [props.fileData.uuid],
                    "assetnames": [props.fileData.key]
                }
                const response = await AssetService.purgeRecord(bodyAsset, tenantUuid);

                if(response.statusCode === 200) {
                    setLoading(false);
                    removeStoreItem(props.fileData.uuid);
                    updateToastMessage(`1 asset deleted`, 'success');
                    handleViewClose();

                    if(displayItems && AllAssets) {
                        const updatedItems = AllAssets.filter(item => item.uuid !== props.fileData.uuid);
                        setAllAssets(updatedItems);
                        const updatedDisplayItems = displayItems.filter(item => item.uuid !== props.fileData.uuid);
                        setDisplayItems(updatedDisplayItems);
                    }

                    if(displaySearchItems) {
                        const updatedItems = displaySearchItems.filter(item => item.uuid !== props.fileData.uuid);
                        setDisplaySearchItems(updatedItems);
                    }

                    if(displayMyAssetItems) {
                        const updatedItems = displayMyAssetItems.filter(item => item.uuid !== props.fileData.uuid);
                        setDisplayMyAssetItems(updatedItems);

                        updateToastMessage("1 asset deleted", 'success');
                    }

                    if(displayCollectionItems) {
                        const updatedItems = displayCollectionItems.filter(item => item.uuid !== props.fileData.uuid);
                        setDisplayCollectionItems(updatedItems);
                    }

                    const getAssetStorage = await AssetService.getAssetStorage(tenantUuid);

                    if(getAssetStorage.statusCode === 200) {
                        updateStorage(getAssetStorage.response.storageSize, getAssetStorage.response.percentage, getAssetStorage.response.remainingSize);
                        setMaxStorageSize(getAssetStorage.response.maxStorageSize);
                    }

                    setIsStorageMax(getAssetStorage.statusCode === 403);
                }
            } catch (error) {
                console.error("Error fetching asset storage:", error);
                updateToastMessage(`${props.fileData.name} Not Deleted`);
            }

    };

    const handleModalDelete = () =>{
        SetModalShow(true);
        const fileKey = props.fileData.key;
        const parts = fileKey.split('.');
        const fileExtension = parts[parts.length - 1];
        setDeleteFilesData(assetFilename +'.'+ fileExtension);
    };

    const resetExpanded = () => {
        setIsTagExpanded(false);
        setIsAITagExpanded(false);
        setIsCollectionExpanded(false);
        setIsTagView(null);
        setIsAITagView(null);
        setIsCollectionView(null);
    };

    const handleViewClose = () => {
        resetExpanded();
        props.handleViewClose();
    };

    const handleTagsSearch = (labelName) => {
        resetExpanded();
        props.handleViewClose();
        props.updateSearchValue(labelName);
    };

    const handleClickViewNext = async () => {
        await props.handleViewNext();
        await resetExpanded();
    };

    const handleClickViewPrev = async () => {
        await props.handleViewPrev();
        await resetExpanded();
    };

    const handleCollectionSearch = (labelName, labelUUID) => {
        resetExpanded();
        props.handleViewClose();
        updateCollectionSearch(labelName, labelUUID);
    };

    const handleUpdatedAsset = (item) =>{
        setAssetFilename(item.name);
        setAssetDescription(item.desc);
        setAssetCollections(item.collections);
        setAssetsTags(item.tags);

        if(displayMyAssetItems) {
            const updatedItems = displayMyAssetItems.map(asset => {
                if(asset.uuid === props.fileData.uuid){
                    asset.basename = item.name;
                }

                return asset;
            });

            setOriginalData(updatedItems);
            setDisplayMyAssetItems(updatedItems);
        }
    };

    const handleSaveEdit = (value) =>{
        if(!assetsEdit){
            setSaveAssetsEdit(value);
            setLoading(value);
        }else{
            setSaveAssetsEdit(true);
            setAssetsEdit(value);
            setLoading(true);
        }
    };

    const handleEditAsset = () =>{
        setAssetsEdit(true);
    };

    useEffect(() => {
        SetModalShow(false);
    }, [AllAssets,
        displayItems,
        displaySearchItems,
        displayCollectionItems]);

    useEffect(() => {
        setAssetFilename(reloadProps && reloadProps.name)
        setAssetDescription(reloadProps && reloadProps.desc)
        setAssetCollections(reloadProps && reloadProps.collections)
        setAssetsTags(reloadProps && reloadProps.tags);
    }, [reloadProps]);

    useEffect(() => {
        if(containerTagRef.current) {
            const containerTagHeight = containerTagRef.current.getBoundingClientRect().height;
            let container = 160;
            const buttons = Array.from(containerTagRef.current.querySelectorAll('button')).slice(0, 15);
            const count = buttons.slice(0, 3).filter(button => button.offsetHeight > 60).length;
            const count2 = buttons.slice(0, 5).filter(button => button.offsetHeight > 60).length;
            const count3 = buttons.slice(5, 10).filter(button => button.offsetHeight > 60).length;
            const count4 = buttons.slice(0, 7).filter(button => button.offsetHeight > 60).length;
            if(count === 3){
                setAssetsTagsContainer(230);
                container = 230;
            }else if(count2 === 2 || count > 1 || count3 === 2){
                setAssetsTagsContainer(210);
                container = 210;
            }else if(count4 > 0){
                setAssetsTagsContainer(190);
                container = 190;
            }else{
                setAssetsTagsContainer(160);
                container = 160;
            }
            setIsTagView(containerTagHeight > container);
            setIsTagExpanded(!containerTagHeight > container);
            setIsTagVisible(true);
        }

        if(containerCollectionRef.current) {
            const containerCollectionHeight = containerCollectionRef.current.getBoundingClientRect().height;
            let container = 160;
            const buttons = Array.from(containerCollectionRef.current.querySelectorAll('button')).slice(0, 15);
            const count = buttons.slice(0, 3).filter(button => button.offsetHeight > 60).length;
            const count2 = buttons.slice(0, 5).filter(button => button.offsetHeight > 60).length;
            const count3 = buttons.slice(5, 10).filter(button => button.offsetHeight > 60).length;
            const count4 = buttons.slice(0, 7).filter(button => button.offsetHeight > 60).length;
            if(count === 3){
                setAssetCollectionsContainer(230);
                container = 230;
            }else if(count2 === 2 || count > 1 || count3 === 2){
                setAssetCollectionsContainer(210);
                container = 210;
            }else if(count4 > 0){
                setAssetCollectionsContainer(190);
                container = 190;
            }else{
                setAssetCollectionsContainer(160);
                container = 160;
            }
            setIsCollectionView(containerCollectionHeight > container);
            setIsCollectionExpanded(!containerCollectionHeight > container);
            setIsAITagVisible(true);
        }

        if(containerAITagRef.current) {
            const containerAITagHeight = containerAITagRef.current.getBoundingClientRect().height;

            setIsAITagView(containerAITagHeight > maxContainerHeight);
            setIsAITagExpanded(!containerAITagHeight > maxContainerHeight);
            setIsCollectionVisible(true);
        }
        setReloadProps(props.fileData);
    }, [props.fetchedFileData, assetCollections]);

    return(
        <>
            <Modal className="modal-full-page view-asset" show={props.viewAssetsShow} onHide={props.handleViewClose}>
                <Modal.Header closeButton={false} className={`${assetsEdit ? 'd-none' : ''}`}>
                    <div className="container">
                        <div>
                            <button className="btn btn-primary" onClick={handleViewClose}>
                                <ArrowBackRoundedIcon />
                                <h3 className={`mb-0 ${isMobile ? 'd-none' : null}`}>{Localization.Search.View.Return}</h3>
                            </button>
                        </div>
                        {
                            props.fetchedFileData &&
                                <div>
                                    <button className={props.prevUUID ? "btn btn-primary" : "btn btn-primary hidden"} onClick={handleClickViewPrev} disabled={props.viewAssetBtnDisable}>
                                        <ChevronLeftRoundedIcon />
                                        <h3 className="mb-0">{Localization.Search.View.Previous}</h3>
                                    </button>

                                    <button className={props.nextUUID ? "btn btn-primary" : "btn btn-primary hidden"} onClick={handleClickViewNext} disabled={props.viewAssetBtnDisable}>
                                        <h3 className="mb-0">{Localization.Search.View.Next}</h3>
                                        <ChevronRightRoundedIcon />
                                    </button>
                                </div>
                        }
                    </div>
                </Modal.Header>
                <Modal.Body className={`container ${assetsEdit ? 'custom-modal' : ''}`}>
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            {
                                props.fileData.type === 'image' ?
                                    <div className='ratio ratio-4x3'>
                                        <div className={assetsHover ? "view-asset-image view-asset-image-visible" : "view-asset-image"} onMouseEnter={() => {}} onMouseLeave={() => {}}>
                                            <img
                                                alt={ props.fileData.name }
                                                src={ props.fileData.url }
                                            />
                                            <div className="view-asset-image-enlarge">
                                                <div className="view-asset-enlarge" onClick={() => props.updateFSLightBox(props.fileData.url, !props.FSLightbox, 'image', props.fileData)} >
                                                    <ZoomOutMapRoundedIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                props.fileData.type === 'video' ?
                                    <div className='ratio ratio-4x3'>
                                        {
                                            props.fileData.mime === 'video/x-msvideo'
                                            ?
                                                <div className="view-asset-icon"><MovieIcon /></div>
                                            :
                                                <div className="view-asset-video">
                                                    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                                                    <div className="view-asset-video-enlarge">
                                                        {/* <div className="view-asset-enlarge" onClick={() => props.updateFSLightBox(props.fileData.url, !props.FSLightbox, 'video', props.fileData)} >
                                                            <ZoomOutMapRoundedIcon />
                                                        </div> */}
                                                    </div>
                                                </div>
                                        }

                                    </div> :
                                props.fileData.type === 'document' ?
                                    <div className='ratio ratio-4x3'>
                                        <div className="view-asset-document">
                                            <DocumentPdf fileURL={props.fileData.url} />
                                        </div>
                                    </div> :
                                props.fileData.type === 'audio' ?
                                    <div className='ratio ratio-4x3'>
                                        <div className="view-asset-icon">
                                            <FontAwesomeIcon icon={faFileAudio} onClick={handleAudioClick}/>
                                            <audio ref={audioRef} loop controls>
                                                <source src={props.fileData.url} type="audio/mpeg" />
                                                Your browser does not support the audio tag.
                                            </audio>
                                        </div>
                                    </div> : <Skeleton height={`100%`}/>

                            }

                        </div>
                        <div className="col-lg-4 col-md-12 position-relative">
                            <div className={`view-asset-form ${assetsEdit ? 'd-none' : ''}`} style={{marginTop: isMobile ?  props.fileData.type === 'document' ? '77px' : null : null}}>
                                {
                                    props.fetchedFileData
                                    ?
                                        <>
                                            <div className={`view-asset-name ${isMobile ? assetDescription ? 'mobile-view-asset-name' : '' : ''}`}>
                                                {props.fileData.name &&
                                                    <h2 className={`mb-0 font-medium ${!assetsEdit ? 'text-break' : 'text-truncate'}`}>{assetFilename}</h2>
                                                }
                                            </div>
                                            {
                                                assetDescription &&
                                                    <div className="view-asset-desc">
                                                        <p className={`mb-0 paragraph-1 ${!assetsEdit ? 'text-break' : 'text-truncate'}`}>{assetDescription}</p>
                                                    </div>
                                            }
                                            {
                                                assetsTags && assetsTags.length > 0 &&
                                                    <div className="view-asset-accordion">
                                                        <Accordion alwaysOpen defaultActiveKey={['0']}>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    {Localization.Search.Edit.Tags}
                                                                    <ExpandMoreRoundedIcon />
                                                                </Accordion.Header>
                                                                <Accordion.Body ref={containerTagRef}>
                                                                    <div style={containerTagStyle}>
                                                                        {
                                                                            assetsTags.map(label => {
                                                                                const labelName = label;
                                                                                return (<Button variant='secondary' key={labelName} onClick={() => handleTagsSearch(labelName)}>{labelName}</Button>)
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                                !isTagExpanded
                                                                                ?
                                                                                    isTagView && <button className="text-button" onClick={() => setIsTagExpanded(!isTagExpanded)}>{Localization.Search.Edit.ViewMore}</button>
                                                                                :
                                                                                    isTagView && <button className="text-button" onClick={() => setIsTagExpanded(!isTagExpanded)}>{Localization.Search.Edit.ViewLess}</button>
                                                                        }
                                                                    </div>
                                                                </Accordion.Body>
                                                            </ Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                            }
                                            {
                                                assetCollections && assetCollections.length > 0 &&
                                                    <div className="view-asset-accordion view-asset-collection">
                                                        <Accordion alwaysOpen defaultActiveKey={['0']}>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    {Localization.Search.Edit.Collections}
                                                                    <ExpandMoreRoundedIcon />
                                                                </Accordion.Header>
                                                                <Accordion.Body ref={containerCollectionRef}>
                                                                    <div style={containerCollectionStyle}>
                                                                        {
                                                                            //collection && collection.length>0 ?
                                                                            Array.isArray(assetCollections) && assetCollections.map(label => {
                                                                                const labelName = label.name;
                                                                                const labelUUID = label.uuid;
                                                                                return (<Button variant='secondary' key={labelName} onClick={() => handleCollectionSearch(labelName, labelUUID)}>{labelName}</Button>)
                                                                            })
                                                                            /*:
                                                                            props.fileData.collections.map(label => {
                                                                                const labelName = label.name;
                                                                                const labelUUID = label.uuid;
                                                                                return (<Button variant='secondary' key={labelName} onClick={() => handleCollectionSearch(labelName, labelUUID)}>{labelName}</Button>)
                                                                            })*/
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            !isCollectionExpanded
                                                                            ?
                                                                                isCollectionView &&<button className="text-button" onClick={() => setIsCollectionExpanded(!isCollectionExpanded)}>{Localization.Search.Edit.ViewMore}</button>
                                                                            :
                                                                                isCollectionView && <button className="text-button" onClick={() => setIsCollectionExpanded(!isCollectionExpanded)}>{Localization.Search.Edit.ViewLess}</button>
                                                                        }
                                                                    </div>
                                                                </Accordion.Body>
                                                            </ Accordion.Item>
                                                        </Accordion>
                                                    </div>
                                            }
                                            {
                                                <div className="view-asset-accordion view-asset-information">
                                                    <Accordion alwaysOpen defaultActiveKey={['0']}>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header>
                                                                    {Localization.Search.Edit.MetaData}
                                                                    <ExpandMoreRoundedIcon />
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <span>{Localization.Search.Edit.AssetDate}</span>
                                                                            <span>{props.fileData.date}</span>
                                                                        </div>
                                                                        {
                                                                            props.fileData.owner &&
                                                                                <div className={`col-6 ${isMobile ? 'ps-4' : null}`}>
                                                                                    <span>{Localization.Search.Edit.AssetUploadedBy}</span>
                                                                                    <span>{props.fileData.owner}</span>
                                                                                </div>
                                                                        }
                                                                        <div className="col-6">
                                                                            <span>{Localization.Search.Edit.AssetType}</span>
                                                                            <span>{props.fileData.type}</span>
                                                                        </div>
                                                                        <div className={`col-6 ${isMobile ? 'ps-4' : null}`}>
                                                                            <span>{Localization.Search.Edit.AssetFormat}</span>
                                                                            <span>{props.fileData.format}</span>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <span>{Localization.Search.Edit.AssetSize}</span>
                                                                            <span>{props.fileData.size}</span>
                                                                        </div>
                                                                        {
                                                                            props.fileData.dimensions &&
                                                                                <div className={`col-6 ${isMobile ? 'ps-4' : null}`}>
                                                                                    <span>{Localization.Search.Edit.AssetDimensions}</span>
                                                                                    <span>{props.fileData.dimensions}</span>
                                                                                </div>
                                                                        }
                                                                        {
                                                                            props.fileData.framerate &&
                                                                                <div className={`col-6 ${isMobile ? 'ps-4' : null}`}>
                                                                                    <span>{Localization.Search.Edit.AssetFrameRate}</span>
                                                                                    <span>{props.fileData.framerate}</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </Accordion.Body>
                                                            </ Accordion.Item>
                                                        </Accordion>
                                                </div>
                                            }
                                        </>
                                    :
                                        <>
                                            <Skeleton style={{marginBottom: '1rem'}} width={`75%`} height={30} />
                                            <Skeleton style={{marginBottom: '1rem'}} width={`100%`} height={maxContainerHeight / 2} />
                                            <Skeleton style={{marginBottom: '1rem'}} width={`100%`} height={maxContainerHeight / 2} />
                                            <Skeleton  width={`100%`} height={maxContainerHeight} />
                                        </>
                                }
                            </div>
                        </div>
                        <div className="view-asset-form">
                            {
                                props.fetchedFileData  &&
                                        <EditAssets
                                                assetUuid={props.fileData.uuid}
                                                isAssetEdit={assetsEdit}
                                                name={props.fileData.name}
                                                desc={props.fileData.desc}
                                                fileKey={props.fileData.key}
                                                collections={props.fileData.collections}
                                                tags={props.fileData.tags}
                                                aiSmartTags={props.fileData.aiSmartTags}
                                                isSaveAssetEdit = {saveAssetsEdit}
                                                handleSaveEdit = {handleSaveEdit}
                                                handleUpdatedAsset = {handleUpdatedAsset}
                                                fileData={props.fileData}
                                                isMobile ={isMobile}
                                                assetsHover={assetsHover}
                                                videoJsOptions={videoJsOptions}
                                                handlePlayerReady={handlePlayerReady}
                                                handleAudioClick={handleAudioClick}
                                                audioRef={audioRef}
                                                updateFSLightBox={props.updateFSLightBox}
                                        />
                            }
                        </div>
                    </div>
                    {
                        props.fetchedFileData &&
                            <div className={`view-asset-bar ${assetsEdit ? 'd-none' : ''}`}>
                                <div>
                                    <Button className='icon-button' size='sm' variant='link' onClick={() => download()}><CustomIcons variant="download" /></Button>
                                </div>
                                {
                                    isCustomerAdmin || (isAssetOwner && props.fileData.owner === username) ? (
                                        <>
                                        <div>
                                            <Button className='icon-button' size='sm' variant='link' onClick={handleEditAsset}><CustomIcons variant="edit" /></Button>
                                        </div>
                                        <div>
                                            <Button className='icon-button' size='sm' variant='link' onClick={handleModalDelete}><CustomIcons variant="trash" /></Button>
                                        </div>
                                        </>
                                    ) : null
                                }
                            </div>
                    }

                </Modal.Body>
                <div className={`view-asset-edit-btn ${!assetsEdit ? 'd-none' : ''}`}>
                                <div>
                                    <Button
                                        className={`save-edit-asset ${loading ? 'w-auto' : ''}`} disabled={loading}
                                        variant='primary' onClick={handleSaveEdit}>Save {loading && <CustomIcons variant="spinner" />}</Button>
                                </div>
                                <div>
                                    <Button
                                        className='cancel-edit-asset'
                                        variant='secondary' onClick={() => setAssetsEdit(false)}>Cancel</Button>
                                </div>
                </div>
            </Modal>
            <DeleteAssetModal
                loading={loading}
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                //modalType={modalType}
                handleModalDelete={deleteAsset}
                deleteFilesData={deleteFilesData}
            />
        </>
    );
}

export default ViewAssets;