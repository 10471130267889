import { candyRed, iconSize } from '../../shared/utils';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

const ErrorIcon = ({ className, color, size }) => {
    const colorVal = color || candyRed;
    const sizeVal = size || iconSize;

    return (
        <ReportProblemRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default ErrorIcon;