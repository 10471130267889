import { black, iconSize } from '../../shared/utils';
import DownloadingIcon from '@mui/icons-material/Downloading';

const DownloadingRoundedIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <DownloadingIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default DownloadingRoundedIcon;