import { useState, useContext, useEffect, useRef } from 'react';
import { ShareContext } from '../context/share-state';
import { SessionContext } from '../context/session-provider';
import { useNavigate } from "react-router-dom";
import { IsDesktop, IsTablet, IsXLG_To_Tablet } from '../utils';
import Container from 'react-bootstrap/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import GenericModal from '../../shared/components/generic-modal';
import path from '../../shared/routePaths';
import CustomIcons from '../components/custom-icons';
import MenuTabNav from '../menu-tab-nav';
import SearchHooks from '../hooks/search-hooks';
import Localization from '../localization';
import Headroom from 'react-headroom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Accordion from 'react-bootstrap/Accordion';
import SolutionManifest from '../../../solution-manifest';
import Notification from '../components/notification';
import { useDropzone } from 'react-dropzone';
import { UploadContext } from '../context/upload-provider';
import { GlobalContext } from '../context/global-provider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ImageSearchUpload from '../../search/image-search-upload';

const Header = ({ logout }) => {
    // Session Context
    const { profileImgUrl,
            initials,
            username,
            isCustomerAdmin,
            isUserAdmin,
            isAssetOwner,
            isEndUser,
            userRoles,
            subscriptionPlan } = useContext(SessionContext);

    // Share Context
    const { updateMenuTabKey,
        menuTabKey,
        mobileNavigationMenu,
        setMobileNavigationMenu,
        isSearch,
        setIsSearch,
        setIsImageSearch,
        setImageSearchLabels,
        isImageSearchHeaderModalOpen,
        setIsImageSearchHeaderModalOpen,
        setIsImageSearchModalOpen,
        cancelUploadProcess } = useContext(ShareContext);

    // Search Hooks
    const { setInputValue,
        searchKeyword,
        setSearchKeyword,
        showSearch,
        setShowSearch,
        setSearchSubmitted } = SearchHooks();
     
    const { uploadImageFileData } = useContext(UploadContext);

    const [value, setValue] = useState(searchKeyword);
    const [showHeaderSearch, setShowHeaderSearch] = useState(showSearch ? showSearch : searchKeyword ? true : false);
    const [modalShow, SetModalShow] = useState(false);
    const [modalType, SetModalType] = useState("");
    const [profileOpen, setProfileOpen] = useState(false);
    const [mobileSubPage, setMobileSubPage] = useState(false);
    const [modalImageLoadingShow, SetModalImageLoadingShow] = useState(false);
    const profileImageRef = useRef(null);
    const profileNameRef = useRef(null);
    const burgerMenu = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const pathPrefix = "/";    
    // Responsive Check
    const isTablet = IsTablet();
    const isDesktop = IsDesktop();
    const isXLG_To_Tablet = IsXLG_To_Tablet();
    const imageSearchBox = useRef();
    
    const onDrop = async (files) => {

        if(isTablet) {
            SetModalImageLoadingShow(true);
            burgerMenu.current.checked = false;
            setMobileNavigationMenu(false);
            setMobileSubPage(false);
            document.querySelector("html").style = ''; // Enable scrolling
        }
        const messageInfo = await uploadImageFileData(files);
        
        if (!messageInfo) {            
            setSearchKeyword(value);
            setInputValue("");
            setShowSearch(true);
            setIsImageSearch(true);
            setSearchSubmitted(true);
            updateMenuTabKey(path.SEARCH);
        } else {
            updateToastMessage(`${messageInfo}`,"error");
        }
    };

    const { updateToastMessage } = useContext(GlobalContext);
    
    const { getInputProps } = useDropzone({ onDrop });
    
    const imageinputRef = useRef();
    const imageMobileinputRef = useRef();

    // Handler Image Loading Modal Close
    const handleImageLoadingModalClose = () => {
        SetModalImageLoadingShow(false);
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const toggleSearch = async () => {
        if (!showHeaderSearch) {
            setValue("");
        }
        setShowHeaderSearch(!showHeaderSearch);
        setIsImageSearchHeaderModalOpen(false);
    };

    const handleKeyDown = (event) => {
        if(value.length > 2)
        {
            if (event.key === 'Enter' && value) {
                setSearchKeyword(value);
                setInputValue("");
                setShowSearch(true);
                setIsImageSearch(false);
                setImageSearchLabels("");
                setSearchSubmitted(true);
                updateMenuTabKey(path.SEARCH);

                if(isTablet) {
                    burgerMenu.current.checked = false;
                    setMobileNavigationMenu(false);
                    setMobileSubPage(false);
                    document.querySelector("html").style = ''; // Enable scrolling
                }
            }
        }
    };

    const handleInputFocus = () => {
        setIsImageSearchHeaderModalOpen(false);
        setIsImageSearchModalOpen(false);
    };

    const handleInputBlur = () => {
        setIsImageSearchHeaderModalOpen(false);
        setIsImageSearchModalOpen(false);
    };

    const handleLogoClick = () => {
        setShowSearch(false);
        setSearchSubmitted(false);
        setSearchKeyword("");
        setInputValue("");        
        setIsImageSearch(false);
        setImageSearchLabels("");
        updateMenuTabKey(path.EXPLORE);

        if(isUserAdmin && userRoles.length === 1) {
            updateMenuTabKey(path.SETTINGS);
            navigate(pathPrefix + path.SETTINGS, { replace: true });
        } else {
            updateMenuTabKey(path.EXPLORE);
            navigate(pathPrefix + path.EXPLORE, { replace: true });
        }

        if(isTablet) {
            burgerMenu.current.checked = false;
            setMobileNavigationMenu(false);
            clearSearchMobile();
        }
        const openRequest = indexedDB.open("image-search", 1);

        openRequest.onupgradeneeded = function(event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("imageFile")) {
                db.createObjectStore("imageFile");
            }
        };

        openRequest.onsuccess = function() {
            const db = openRequest.result;
            const transaction = db.transaction("imageFile", "readwrite"); // Change to readwrite
            const imageFile = transaction.objectStore("imageFile");
            
            // Use the delete method to remove the 'file'
            const deleteRequest = imageFile.delete("file");

            deleteRequest.onerror = function() {
                console.error("Error removing the image file.");
            };
        };
    }

    const handleProfileClick = () => {
        updateMenuTabKey(path.PROFILE);
        navigate(pathPrefix + path.PROFILE, { replace: true });
        setSearchKeyword("");
        setInputValue("");
        setShowSearch(false);
        setSearchSubmitted(false);
    }

    const handleLogout = () => {
        logout();
        navigate(pathPrefix + path.LOGIN, { replace: true });
    };

    const handleModalClose = () => SetModalShow(false);

    const handleModalAccept = () => {
        SetModalShow(false);
        logout();
    };

    const handleSettingsClick = () => {
        updateMenuTabKey(path.SETTINGS);
        navigate(pathPrefix + path.SETTINGS, { replace: true });
        setSearchKeyword("");
        setInputValue("");
        setShowSearch(false);
        setSearchSubmitted(false);
    }

    const openProfileDropdown = () => {
        setProfileOpen(!profileOpen);
    }

    const clearSearchMobile = () => {
        setValue("");
        setSearchKeyword("");
    };

    const showMobileNavigationMenu = () => {
        if(burgerMenu.current.checked) {
            setMobileSubPage(false);
            setMobileNavigationMenu(false);
            document.querySelector("html").style = ''; // Enable scrolling

            if(isSearch) {
                setShowSearch(false);
                setSearchSubmitted(false);
                setIsSearch(false)
                updateMenuTabKey(path.EXPLORE);
            }
        } else {
            setValue("");
            setSearchKeyword("");
            setMobileNavigationMenu(true);
            document.querySelector("html").style.overflowY = 'hidden'; // Disable scrolling
        }
    };

    const toggleSubPage = () => setMobileSubPage(!mobileSubPage);

    const mobileNavigation = (route) => {
        if(burgerMenu.current.checked) {
            if(mobileSubPage) {
                updateMenuTabKey(path.SETTINGS);
                navigate(route, { replace: true });
            } else {
                updateMenuTabKey(route);
            }
            setMobileSubPage(false);
            setMobileNavigationMenu(false);
            document.querySelector("html").style = ''; // Enable scrolling
            burgerMenu.current.checked = false;
        } else {
            setMobileNavigationMenu(true);
            document.querySelector("html").style.overflowY = 'hidden'; // Disable scrolling
        }
    };    

    const handleImageSearchClick = () => {
        if(isTablet) {
            imageMobileinputRef.current.click();
        } else {
            setIsImageSearchHeaderModalOpen(true);
            setIsImageSearchModalOpen(false);
        }
    }

    useEffect(() => {
        setValue(searchKeyword);
        setShowHeaderSearch(searchKeyword ? true : false);
    }, [searchKeyword]);

    useEffect(() => {
        if(showHeaderSearch) {
            if(inputRef.current) {
                inputRef.current.querySelector('input').focus();
            }
        }
    }, [showHeaderSearch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (profileOpen && (!profileImageRef?.current?.contains(event.target) && !profileNameRef?.current?.contains(event.target) && !event.target.classList.contains("MuiSvgIcon-root"))) {
            setProfileOpen(false);
          }
        };

        // Add event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, [profileOpen]);    
    
    const handleClickOutside = (event) => {
        if (imageSearchBox.current && !imageSearchBox.current.contains(event.target)) {
            setIsImageSearchHeaderModalOpen(false);
        }
    };

    useEffect(() => {
        if (isImageSearchHeaderModalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            if (isTablet) {
                document.body.style.overflow = 'hidden';
            }
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            if (isTablet) {
                document.body.style.overflow = '';
            }
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isImageSearchHeaderModalOpen]);

    return (
        <>
            {
                isTablet &&
                    <Headroom pinStart={96}>
                        <header className='header'>
                            <Container>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='mobile-navigation-header'>
                                            {
                                                mobileSubPage
                                                ?
                                                    <IconButton className='subpage-back' onClick={() => toggleSubPage()}>
                                                        <ArrowBackRoundedIcon />
                                                    </IconButton>
                                                :
                                                    <button className='dam-logo' onClick={handleLogoClick}>
                                                        <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo" />
                                                    </button >
                                            }

                                            <input id="burger-menu-toggle" type="checkbox" ref={burgerMenu}/>
                                            <div className="mobile-controls">
                                                {!mobileNavigationMenu && <Notification />}
                                                <label className="burger-menu-container" htmlFor="burger-menu-toggle" onClick={showMobileNavigationMenu}>
                                                    <div className="burger-menu"></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className={mobileNavigationMenu ? mobileSubPage ? "mobile-navigation-menu mobile-sub-menu open" : "mobile-navigation-menu open" : "mobile-navigation-menu"}>
                                            <div className='mobile-navigation-container'>
                                                {
                                                    !mobileSubPage &&
                                                        <div className='search-input-container'>
                                                            <div className="search-input flex-grow-1">
                                                                <TextField
                                                                    id="search-input-box"
                                                                    placeholder="Type here"
                                                                    value={value}
                                                                    onChange={handleChange}
                                                                    InputProps={{
                                                                        startAdornment: <CustomIcons variant='search' color="#000000" />,
                                                                        endAdornment: (
                                                                            <>
                                                                                {!value ? (
                                                                                    <>
                                                                                    <IconButton size="small" onClick={handleImageSearchClick}>
                                                                                        <CustomIcons variant='filtercenterfocus' size="medium" color="#fffffff" />
                                                                                    </IconButton>
                                                                                    <input
                                                                                        className="upload-inputs d-none"
                                                                                        {...getInputProps()}
                                                                                        type="file"
                                                                                        accept=".mov,.gif,.pdf,.mp4,.avi,.jpg,.png,.mp3,.wav,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                        capture="environment"
                                                                                        ref={imageMobileinputRef}
                                                                                    />
                                                                                    </>
                                                                                ) : (
                                                                                    <div onClick={clearSearchMobile}>
                                                                                        <CustomIcons variant='close' size="medium" color="#000000" />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        ),
                                                                    }}
                                                                    className="w-100"
                                                                    onKeyDown={handleKeyDown}
                                                                    ref={inputRef}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className='menu-tabs-wrapper'>
                                                    {
                                                        mobileSubPage
                                                        ?
                                                            <>
                                                                {
                                                                    (isCustomerAdmin) &&
                                                                        <Accordion>
                                                                            <Accordion.Item eventKey="0">
                                                                                <Accordion.Header>
                                                                                    {Localization.MainTab.Navigation.UserManagement.Title}
                                                                                    <CustomIcons variant="arrow" direction="expand" />
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ul className='menu-tabs-nav nav nav-tabs'>
                                                                                        <li className='nav-item'>
                                                                                            <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=user-list`)} className='nav-link'>
                                                                                                {Localization.MainTab.Navigation.UserManagement.SubPage.UserList}
                                                                                            </button>
                                                                                        </li>
                                                                                        <li className='nav-item'>
                                                                                            <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=user-request`)} className='nav-link'>
                                                                                                {Localization.MainTab.Navigation.UserManagement.SubPage.UserRequest}
                                                                                            </button>
                                                                                        </li>
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </ Accordion.Item>
                                                                        </Accordion>
                                                                }
                                                                {
                                                                    (isCustomerAdmin || isAssetOwner) &&
                                                                        <Accordion>
                                                                            <Accordion.Item eventKey="1">
                                                                                <Accordion.Header>
                                                                                    {Localization.MainTab.Navigation.AssetManagement.Title}
                                                                                    <CustomIcons variant="arrow" direction="expand" />
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ul className='menu-tabs-nav nav nav-tabs'>
                                                                                        <li className='nav-item'>
                                                                                            <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=my-assets`)} className='nav-link'>
                                                                                                {Localization.MainTab.Navigation.AssetManagement.SubPage.MyAssets}
                                                                                            </button>
                                                                                        </li>
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </ Accordion.Item>
                                                                        </Accordion>
                                                                }
                                                                {
                                                                    (isCustomerAdmin) &&
                                                                        <Accordion>
                                                                            <Accordion.Item eventKey="2">
                                                                                <Accordion.Header>
                                                                                    {Localization.MainTab.Navigation.PlanManagement.Title}
                                                                                    <CustomIcons variant="arrow" direction="expand" />
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <ul className='menu-tabs-nav nav nav-tabs'>
                                                                                        <li className='nav-item'>
                                                                                            <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=plan`)} className='nav-link'>
                                                                                                {Localization.MainTab.Navigation.PlanManagement.SubPage.Plan}
                                                                                            </button>
                                                                                        </li>
                                                                                        {
                                                                                            subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                                                                                            <>
                                                                                                <li className='nav-item'>
                                                                                                    <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=invoices`)} className='nav-link'>
                                                                                                        {Localization.MainTab.Navigation.PlanManagement.SubPage.Invoices}
                                                                                                    </button>
                                                                                                </li>
                                                                                                <li className='nav-item'>
                                                                                                    <button type="button" onClick={() => mobileNavigation(`${pathPrefix + path.SETTINGS}?tab=audit-trail`)} className='nav-link'>
                                                                                                        {Localization.MainTab.Navigation.PlanManagement.SubPage.AuditTrail}
                                                                                                    </button>
                                                                                                </li>
                                                                                            </>
                                                                                        }
                                                                                    </ul>
                                                                                </Accordion.Body>
                                                                            </ Accordion.Item>
                                                                        </Accordion>
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                <div className='menu-tabs-container'>
                                                                    <ul className='menu-tabs-nav nav nav-tabs'>
                                                                        {
                                                                            (isCustomerAdmin || isAssetOwner || isEndUser) &&
                                                                                <li className='nav-item'>
                                                                                    <button type="button" onClick={() => mobileNavigation(path.EXPLORE)} className='nav-link'>{Localization.MainTab.Search}</button>
                                                                                </li>
                                                                        }
                                                                        {
                                                                            (isAssetOwner || (!isEndUser && !isUserAdmin)) &&
                                                                                <li className='nav-item'>
                                                                                    <button type="button" onClick={() => mobileNavigation(path.UPLOAD)} className='nav-link'>{Localization.MainTab.Upload}</button>
                                                                                </li>
                                                                        }
                                                                        {
                                                                            (isCustomerAdmin || isAssetOwner || isEndUser) &&
                                                                                <li className='nav-item'>
                                                                                    <button type="button" onClick={() => mobileNavigation(path.COLLECTIONS)} className='nav-link'>{Localization.MainTab.Collections}</button>
                                                                                </li>
                                                                        }
                                                                        {
                                                                            (isCustomerAdmin || isUserAdmin || isAssetOwner) &&
                                                                                <li className='nav-item'>
                                                                                    <button type="button" onClick={() => toggleSubPage()}  className="nav-link">
                                                                                        <span>{Localization.MainTab.Settings}</span>
                                                                                        <span>
                                                                                            <IconButton>
                                                                                                <CustomIcons variant='arrow' direction="right" />
                                                                                            </IconButton>
                                                                                        </span>
                                                                                    </button>
                                                                                </li>
                                                                        }
                                                                        <li className='nav-item'>
                                                                            <button type="button" onClick={() => mobileNavigation(path.PROFILE)}  className={`nav-link ${menuTabKey === path.PROFILE && 'active'}`}>{Localization.MainTab.Profile}</button>
                                                                        </li>
                                                                    </ul>
                                                                    <div>
                                                                        <button type="button" className='btn btn-primary' onClick={handleLogout}>Log out</button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </header>
                    </Headroom>
            }
            {
                isDesktop &&
                    <header className='header'>
                        <Container>
                            <div className="row">
                                <div className="col-6 col-md-8 d-flex flex-row">
                                    <button className='dam-logo' onClick={handleLogoClick}>
                                        <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo" />
                                    </button >
                                    <MenuTabNav />
                                </div>
                                <div className="col-6 col-md-4 d-flex justify-content-end align-items-center">
                                    {
                                        (isCustomerAdmin || isAssetOwner || isEndUser) &&
                                            <>
                                                {showHeaderSearch && (
                                                    <div className="search-input flex-grow-1">
                                                        <TextField
                                                            id="search-input-box"
                                                            placeholder="Type here"
                                                            value={value}
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                startAdornment: <CustomIcons variant='search' color="#000000" />,
                                                                endAdornment: (
                                                                    <>
                                                                        {!value && (
                                                                            <>
                                                                                <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    overlay={
                                                                                        <Tooltip className={`custom-tooltip paragraph-2 ${isImageSearchHeaderModalOpen ? 'd-none' : ''}`}>
                                                                                        Search by image
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <IconButton className="search-image-icon" size="small" onClick={handleImageSearchClick}>
                                                                                        <CustomIcons variant='filtercenterfocus' size="medium" color="#fffffff" />
                                                                                    </IconButton>
                                                                                </OverlayTrigger>
                                                                                <input
                                                                                    className="upload-inputs d-none"
                                                                                    {...getInputProps()}
                                                                                    type="file"
                                                                                    accept=".jpg,.png,.jpeg"
                                                                                    capture="environment"
                                                                                    ref={imageinputRef}
                                                                                />
                                                                            </>
                                                                        )}
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={<Tooltip className="custom-tooltip paragraph-2">Close search</Tooltip>}
                                                                        >
                                                                            <IconButton size="small" onClick={toggleSearch}>
                                                                                <CustomIcons variant='close' size="medium" color="#fffffff" />
                                                                            </IconButton>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                ),
                                                            }}
                                                            className="w-100"
                                                            onKeyDown={handleKeyDown}
                                                            onFocus={handleInputFocus}
                                                            onBlur={handleInputBlur}
                                                            ref={inputRef}
                                                        />
                                                    </div>
                                                )}
                                                {!showHeaderSearch && (
                                                    <button className='icon-button' onClick={toggleSearch}>
                                                        <CustomIcons variant='search' className='header-icon' style={{ display: showHeaderSearch ? 'none' : 'flex' }}/>
                                                    </button>
                                                )}
                                                {isImageSearchHeaderModalOpen && <div className="image-search-container" ref={imageSearchBox}><ImageSearchUpload setIsImageSearchModalOpen={setIsImageSearchHeaderModalOpen} /></div>}
                                            </>
                                    }
                                    {
                                        isXLG_To_Tablet
                                        ?
                                        <>
                                            {
                                                !showHeaderSearch &&
                                                    <>
                                                        {
                                                            (isCustomerAdmin || isUserAdmin || isAssetOwner) &&
                                                                <button className='icon-button' onClick={handleSettingsClick}>
                                                                    <CustomIcons variant='settings' className='header-icon' />
                                                                </button>
                                                        }
                                                        <Notification />
                                                        <div style={{ marginLeft: '3rem' }}>
                                                            {initials ? (
                                                                    <div className='header-name-initials-section' onClick={openProfileDropdown} >
                                                                        <div className="header-initials-container" ref={profileImageRef} style={{ backgroundImage: profileImgUrl ? `url(${profileImgUrl})` : 'none', backgroundSize: 'cover' }}>
                                                                            {!profileImgUrl && (<span>{initials}</span>)}
                                                                        </div>
                                                                        <div className="header-fullname-container">
                                                                            {/*<p>{username}</p>*/}
                                                                            <span ref={profileNameRef}>
                                                                                { profileOpen ? <CustomIcons variant='arrow' direction="up" /> : <CustomIcons variant='arrow' direction="down" /> }
                                                                            </span>
                                                                            <div className={profileOpen ? 'fullname-container-dropdown open' : 'fullname-container-dropdown'}>
                                                                                <p onClick={handleProfileClick}>Profile</p>
                                                                                <p onClick={handleLogout}>Log out</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div onClick={handleProfileClick}>
                                                                        <CustomIcons variant='user' className='header-icon' />
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                (isCustomerAdmin || isUserAdmin || isAssetOwner) &&
                                                    <button className='icon-button' onClick={handleSettingsClick}>
                                                        <CustomIcons variant='settings' className='header-icon' />
                                                    </button>
                                            }
                                            <Notification />
                                            <div style={{ marginLeft: '3rem' }}>
                                                {initials ? (
                                                        <div className='header-name-initials-section' onClick={openProfileDropdown} >
                                                            <div className="header-initials-container" ref={profileImageRef} style={{ backgroundImage: profileImgUrl ? `url(${profileImgUrl})` : 'none', backgroundSize: 'cover' }}>
                                                                {!profileImgUrl && (<span>{initials}</span>)}
                                                            </div>
                                                            <div className="header-fullname-container">
                                                                <p ref={profileNameRef}>{username}</p>
                                                                <span>
                                                                { profileOpen ? <CustomIcons variant='arrow' direction="up" /> : <CustomIcons variant='arrow' direction="down" /> }
                                                                </span>
                                                                <div className={profileOpen ? 'fullname-container-dropdown open' : 'fullname-container-dropdown'}>
                                                                    <p onClick={handleProfileClick}>Profile</p>
                                                                    <p onClick={handleLogout}>Log out</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div onClick={handleProfileClick}>
                                                            <CustomIcons variant='user' className='header-icon' />
                                                        </div>
                                                    )}
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </Container>
                    </header>
            }
            <GenericModal
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                handleModalAccept={handleModalAccept}
                modalType={modalType}
            />
            <GenericModal
                modalCloseButton={true}
                className="image-search-loading-modal"
                modalShow={modalImageLoadingShow}
                handleModalClose={handleImageLoadingModalClose}
                modalType="imageSearchLoading"
            />
        </>
    );
};

export default Header;