import { useContext, useEffect } from 'react';
import { ShareContext } from '../context/share-state';
import { ApiProvider } from '../context/api-state';
import { SessionContext } from '../context/session-provider';
import { useParams, useLocation } from "react-router-dom";
import { getStoreItem, createStore } from '../../../store';
import path from '../routePaths';
import CollectionHooks from '../hooks/collection-hooks';
import ExploreView from '../../search/explore-view';
import SearchView from '../../search/search-view';
import UploadView from '../../upload/upload-view';
import UploadGuide from '../../upload/upload-guide';
import ProfileView from '../../profile/profile-view';
import SettingsView from '../../settings/settings-view';
import CollectionView from '../../collection/collection-view';
import CollectionSearch from '../../collection/collection-search';
import AssetService from '../../../services/api/assetsService';
import _ from 'lodash';

const MenuTabContent = (props) => {
    // Share Context
    const { menuTabKey,
            breadcrumb,
            updateMenuTabKey,
            setCollectionUuid,
            setBreadcrumb,
            updateStorage,
            setMaxStorageSize,
            setIsStorageMax,
            setIsLoading } = useContext(ShareContext);

    // Session Context
    const { tenantUuid,
        isCustomerAdmin,
        isUserAdmin,
        isAssetOwner,
        isEndUser }= useContext(SessionContext);

    const { subpath } = useParams();
    const { handleClickBreadcrumb } = CollectionHooks();
    const location = useLocation();
    const pathPrefix = "/";
    const startsWithCollections = location.pathname.startsWith(pathPrefix + path.COLLECTIONS);

    const getCollectionPromise = async (uuid) => {
        try {
          const storeItem = await getStoreItem('all-collections', createStore('get-collections', 'all-collections'));

          if (storeItem) {
            const collectionName = storeItem.find((item) => item.uuid === uuid);
            return collectionName ? collectionName.name : null;
          } else {
            const res = await AssetService.getCollectionTenantUUID(tenantUuid);
            const collectionName = res.find((item) => item.uuid === uuid);
            return collectionName ? collectionName.name : null;
          }
        } catch (error) {
          console.error('Error:', error);
          return null;
        }
    };

    useEffect(() => {
        const funcGetAssetStorage = async () => {
            setIsLoading(true);
            const getAssetStorage = await AssetService.getAssetStorage(tenantUuid);

            if(getAssetStorage.statusCode === 200) {
                setIsLoading(false);
                updateStorage(getAssetStorage.response.storageSize, getAssetStorage.response.percentage, getAssetStorage.response.remainingSize);
            }

            setMaxStorageSize(getAssetStorage.response.maxStorageSize);
            setIsStorageMax(getAssetStorage.statusCode === 403);
        };

        if(startsWithCollections && subpath) {
            const assignCollection = async () => {
                const collectionName = await getCollectionPromise(subpath);

                if(collectionName) {
                    updateMenuTabKey(path.COLLECTIONS + pathPrefix + subpath);
                    setCollectionUuid(subpath);
                    if(collectionName) {
                        setBreadcrumb(prevBreadcrumb => ({
                            title: collectionName,
                            breadcrumb: [
                              ...prevBreadcrumb.breadcrumb,
                              {
                                name: collectionName,
                                url: "#",
                                active: true
                              }
                            ]
                        }));
                    }
                } else {
                    updateMenuTabKey(path.COLLECTIONS);
                }
            };

            assignCollection();
        };

        funcGetAssetStorage();
    }, []);

    return (
        <div className='menu-tabs-wrapper'>
            <ApiProvider tenantUuid={tenantUuid} >
                <div className='tab-content'>
                    {
                        (isCustomerAdmin || isAssetOwner || isEndUser) &&
                            <>
                                {
                                    menuTabKey === path.EXPLORE &&
                                        <ExploreView
                                            searchShow={true}
                                            menuTabKey={menuTabKey}
                                            FSLightbox={props.FSLightbox}
                                            updateFSLightBox={props.updateFSLightBox}
                                        />
                                }
                                {
                                    menuTabKey === path.SEARCH &&
                                        <SearchView
                                            searchShow={true}
                                            menuTabKey={menuTabKey}
                                            FSLightbox={props.FSLightbox}
                                            updateFSLightBox={props.updateFSLightBox}
                                        />
                                }
                                {
                                    menuTabKey === path.COLLECTIONS &&
                                        <CollectionView
                                            menuTabKey={menuTabKey}
                                        />
                                }
                                {
                                    menuTabKey === path.COLLECTIONS + pathPrefix + subpath &&
                                        <CollectionSearch
                                            menuTabKey={menuTabKey}
                                            breadcrumb={breadcrumb}
                                            handleClickBreadcrumb={handleClickBreadcrumb}
                                            collectionUuid={subpath}/>
                                }
                            </>
                    }
                    {
                        (isAssetOwner || (!isEndUser && !isUserAdmin)) &&
                            <>
                                {
                                    menuTabKey === path.UPLOAD &&
                                        <UploadView
                                            FSLightbox={props.FSLightbox}
                                            updateFSLightBox={props.updateFSLightBox}
                                        />
                                }
                                {
                                    menuTabKey === path.UPLOADGUIDE &&
                                        <UploadGuide/>
                                }
                            </>
                    }
                    {
                        menuTabKey === path.PROFILE &&
                            <ProfileView />
                    }
                    {
                        (isCustomerAdmin || isUserAdmin || isAssetOwner) &&
                            <>
                                {
                                    menuTabKey === path.SETTINGS &&
                                        <SettingsView />
                                }
                            </>
                    }
                </div>
            </ApiProvider>
        </div>
    )
}

export default MenuTabContent;