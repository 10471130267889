import { spanishGray, iconSize } from '../../shared/utils';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';

const BookmarkIcon = ({ filled, className, color, size }) => {
    const colorVal = color || spanishGray;
    const filledVal = typeof filled === 'boolean' ? filled : true;
    const sizeVal = size || iconSize;

    return (
        <>
            {
                filledVal
                ?
                    <BookmarkRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <BookmarkBorderRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default BookmarkIcon;