import React, { useState, useContext } from "react";
import { AccountContext } from "../../components/shared/context/account-provider";
import { ShareContext, ShareStateProvider } from '../../components/shared/context/share-state';
import { GlobalContext, GlobalProvider } from '../../components/shared/context/global-provider';
import { UploadProvider } from '../../components/shared/context/upload-provider';
import Header from "../../components/shared/header";
import MenuTabContent from "../../components/shared/menu-tab-content";
import BackToTop from "../../components/shared/components/back-to-top";
import ToastMessage from "../../components/shared/components/toast-message";
import FsLightbox from "fslightbox-react";
import path from "../../components/shared/routePaths";
import DocumentPdf from "../../components/shared/components/document-pdf"

const Landing = React.memo((props) => {
    const { logout } = useContext(AccountContext);
    const [FSLightbox, setFSLightbox] = useState(false);
    const [FSLightboxSourceType, setFSLightboxSourceType] = useState('');
    const [FSLightboxSource, setFSLightboxSource] = useState(['', '']);

    // Update FS LightBox State
    const updateFSLightBox = (source, toggler, type, file) => {
        const defaultSource = [<div></div>, <div></div>, <div></div>];
        let fsSource;

        if(type === 'image') {
            fsSource = [<div style={{ width: "100%", height: "100%" }}><img src={source} alt={file && file.name}/></div>, <div></div>, <div></div>];
        } else if(type === 'video') {
            fsSource = [<div></div>, <div style={{ width: "100%", height: "100%" }}><video playsInline loop controls><source src={source} />Your browser does not support the video tag.</video></div>, <div></div>];
        } else if(type === 'audio') {
            fsSource = [<div></div>, <div style={{ width: "100%", height: "100%" }}><audio loop controls><source src={source} type="audio/mpeg"/>Your browser does not support the audio tag.</audio></div>, <div></div>];
        } else if(type === 'custom') {
            const fileURL = URL.createObjectURL(file.object);
            fsSource = [<div></div>, <div></div>, file ? <div style={{ width: "100%", height: "100%" }}>
            <DocumentPdf fileURL={fileURL} />
            </div> : <div></div>];
        }

        setFSLightboxSourceType(type);
        setFSLightboxSource(fsSource ? fsSource : defaultSource);
        setFSLightbox(toggler);
    }

    const ShareStateComponents = () => {
        const { menuTabKey } = useContext(ShareContext);

        return (
            <div className={ menuTabKey === path.UPLOAD ? "upload-view-container" : "" }>
                <Header
                    logout={ () => { logout(); props.updateAuthenticatedState(false)} }
                />
                <MenuTabContent
                    FSLightbox={FSLightbox}
                    updateFSLightBox={updateFSLightBox}
                    updateLoading={props.updateLoading}
                />
            </div>
        );
    };

    const GlobalComponents = () => {
        const { showBackToTop, toastHeader, hideToastMessage, showToast, toastVariant } = useContext(GlobalContext);
        const { menuTabKey } = useContext(ShareContext);

        return (
            <>
                {
                    menuTabKey !== path.UPLOAD &&
                        <BackToTop showBackToTop={showBackToTop}/>
                }
                <ToastMessage header={toastHeader} showToast={showToast} hideToastMessage={hideToastMessage} variant={toastVariant}/>
            </>
        )
    };

    return (
        <>
            <GlobalProvider>
                <ShareStateProvider path={props.path}>
                    <UploadProvider>
                        <ShareStateComponents />
                    </UploadProvider>
                    <GlobalComponents />
                </ShareStateProvider>
            </GlobalProvider>
            <FsLightbox
                toggler={FSLightbox}
                sources={FSLightboxSource}
                sourceIndex={FSLightboxSourceType === 'image' ? 0 : FSLightboxSourceType === 'video' ? 1 : FSLightboxSourceType === 'audio' ? 1 : FSLightboxSourceType === 'custom' ? 2 : null}
                types={[null, 'video', 'audio', null]}
                disableSlideshowLoop={true}
                loadOnlyCurrentSource={true}
                disableSlideSwiping={true}
            />
        </>
    );
});

export default Landing;