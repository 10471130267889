import { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { DatePicker, InputNumber, } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import _ from 'lodash';
import CustomIcons from '../../shared/components/custom-icons';
const { RangePicker } = DatePicker;

const AssetFilter = (props) => {
    // styling
    const [inputFocusIndex, setFocusIndex] = useState(-1);
    //Share Context
    const { pdFromOnChange,
        pdToOnChange,
        pd,
        setPd,
        minFileSize,
        setMinFileSize,
        maxFileSize,
        setMaxFileSize,
        setIsFilter,
        isMobile } = useContext(ShareContext);

    if (!props.show) {
        return <></>
    }

    const onChangePublishedDate = (value) => {
        setPd(value);
        if(!value) {
            pdFromOnChange(null);
            pdToOnChange(null);
            return;
        }
        pdFromOnChange(value[0].valueOf());
        pdToOnChange(value[1].valueOf());
    }

    const apply = () => {
        setIsFilter(true);
        props.applyFilters({}, props.collectionUuid, props.searchResult, props.imgSrc);
    }

    const clearAll = () => {
        if(!props.clear) {
            return;
        }
        props.clear(props.collectionUuid, props.searchResult);

    }
    return (
    <>
        <div className='d-flex flex-row-reverse asset-filter-top'>
            <div>
                <Button
                    className='apply-filter-button'
                    variant='primary' onClick={apply}
                >
                    <span>Apply</span>{!isMobile && <span>&nbsp;Filters</span>}
                </Button>
            </div>
            <div className='clear-all-button'>
                <Button
                    className='clear-all-button'
                    variant='secondary' onClick={clearAll}>Clear All</Button>
            </div>
        </div>
        <div className="row asset-filter-container">
            <div className="col-3">
                <div className="rangepicker-container">
                    <p className='filter-title'>Date Uploaded</p>
                    <label>Date Uploaded</label>
                    <RangePicker
                        value={pd}
                        size='8'
                        format="DD-MM-YYYY"
                        placeholder={['DD/MM/YY', 'DD/MM/YY']}
                        className='date-component'
                        onChange={onChangePublishedDate}
                        clearIcon={<CustomIcons className='rangepicker-close-icon' variant="cancel" />}
                    />
                </div>
            </div>
            {/* <div className="col">
                <div className="rangepicker-container">
                        <p className='filter-title'>Modified Date</p>
                        <label>Modified Date</label>
                        <RangePicker
                            value={md}
                            size='8'
                            format="DD-MM-YYYY"
                            placeholder={['DD/MM/YY', 'DD/MM/YY']}
                            className='w-100 date-component'
                            onChange={onChangeModifiedDate}
                        />
                </div>
            </div> */}
            <div className="col-4">
                <p className='filter-title'>File Size</p>
                <div className="file-size-input-fields">
                    <div style={inputFocusIndex === 0 ? {borderColor: 'black'} : {}} className='size-component min-size-component'>
                        <label style={{ color: 'black' }}>Minimum</label>
                        <div className={`row ${maxFileSize ? 'black-font' : 'grey-font'}`}>
                            <InputNumber controls={false}
                                value={minFileSize} onChange={(value) => setMinFileSize(value)}
                                onFocus={() => setFocusIndex(0)}
                                onBlur={() => setFocusIndex(-1)}
                                className='col' min={0} bordered={false}
                                placeholder="0"
                            />
                        </div>
                    </div>
                    <div style={inputFocusIndex === 1 ? {borderColor: 'black'} : {}} className='size-component max-size-component'>
                        <label style={{ color: 'black' }}>Maximum</label>
                        <div className={`row ${maxFileSize ? 'black-font' : 'grey-font'}`}>
                            <InputNumber
                                defaultValue={0}
                                controls={false}
                                value={maxFileSize} onChange={(value) => setMaxFileSize(value)}
                                onFocus={() => setFocusIndex(1)}
                                onBlur={() => setFocusIndex(-1)}
                                className='col' min={0} bordered={false}
                                placeholder="0"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default AssetFilter;