import { useContext } from 'react';
import { ShareContext } from '../../context/share-state';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import MobileModal from '../mobile-modal';

const MobileType = (props) => {
    const {
        type,
        changeType,
        handleMobileModalTypeClose,
        getAssetCount,
        isImageSearch
    } = useContext(ShareContext);

    return(
        <MobileModal title="File type" onClose={handleMobileModalTypeClose}>
            <div className="mobile-modal-file-type">
                <Navbar expand="lg" className="justify-content-between p-0">
                    <Container fluid className='g-0'>
                        <Nav>
                            <Nav.Item className={type === ''? 'selected': ''}>
                                <Nav.Link onClick={(e) => { changeType(e, '', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                    <img src="/images/icons/all_assets.svg" alt="all assets icon" />
                                    <h3>All Assets</h3>
                                    <Badge><p>{getAssetCount('', props.collectionUuid)}</p></Badge>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={type === 'image'? 'selected': ''}>
                                <Nav.Link onClick={(e) => {changeType(e, 'image', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                <img src="/images/icons/images.svg" alt="images icon" />
                                    <h3>Images</h3>
                                    <Badge><p>{getAssetCount('image', props.collectionUuid)}</p></Badge>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={type === 'video'? 'selected': ''}>
                                <Nav.Link onClick={(e) => {changeType(e, 'video', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                <img src="/images/icons/videos.svg" alt="videos icon" />
                                    <h3>Videos</h3>
                                    <Badge><p>{getAssetCount('video', props.collectionUuid)}</p></Badge>
                                </Nav.Link>
                            </Nav.Item>
                            {!isImageSearch && (
                                <>
                                    <Nav.Item className={type === 'document'? 'selected': ''}>
                                        <Nav.Link onClick={(e) => {changeType(e, 'document', props.collectionUuid, props.searchResult); handleMobileModalTypeClose(); }}>
                                        <img src="/images/icons/documents.svg" alt="documents icon" />
                                            <h3>Documents</h3>
                                            <Badge><p>{getAssetCount('document', props.collectionUuid)}</p></Badge>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className={type === 'audio'? 'selected': ''}>
                                        <Nav.Link onClick={(e) => {changeType(e, 'audio', props.collectionUuid); handleMobileModalTypeClose(); }}>
                                        <img src="/images/icons/audio.svg" alt="audio icon" />
                                            <h3>Audio</h3>
                                            <Badge><p>{getAssetCount('audio', props.collectionUuid)}</p></Badge>
                                        </Nav.Link>
                                    </Nav.Item>
                                </>
                            )}
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        </MobileModal>
    );
};

export default MobileType;
