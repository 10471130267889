import { spanishGray, iconSize } from '../../shared/utils';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';

const ZoomIcon = ({ variant, className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;
    const variantVal = variant || 'expand';

    return (
        <>
            {
                variantVal === 'out'
                ?
                    <ZoomOutRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                variantVal === 'in'
                ?
                    <ZoomInRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                variantVal === 'expand'
                ?
                    <ZoomOutMapRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <ZoomOutMapRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default ZoomIcon;