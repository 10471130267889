const path = {
    LOGIN: 'login',
    REGISTRATION: 'registration',
    EXPLORE: 'explore',
    SEARCH: 'search',
    UPLOAD: 'upload',
    COLLECTIONS: 'collections',
    UPLOADGUIDE: 'upload-guide',
    USER: 'user',
    PROFILE: 'profile',
    SETTINGS: 'settings',
    CHANGEPASSWORD: "change-password",
    FORGOTPASSWORD: "forget-password",
    VERIFYTENANTEMAIL: "verify-tenant-email",
    USERVERIFYEMAIL: "user-verify-email",
    WELCOME: "welcome",
    ERROR: "error",
};

export default path;