import { useState, useEffect, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IsMobile, IsTablet } from '../../shared/utils';
import { Table } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import CustomIcons from '../../shared/components/custom-icons';
import Skeleton from 'react-loading-skeleton';
import MobileModal from '../../shared/components/mobile-modal';
import ArrowIcon from "../../icons/arrow";

const Invoices = (props) => {
    // Pagination
    const [paginationStart, setPaginationStart] = useState(null);
    const [paginationEnd, setPaginationEnd] = useState(null);
    const [paginationTotal, setPaginationTotal] = useState(null);

    // Page Data
    const [currentPage, setCurrentPage] = useState(1);
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    // Share Context
    const { tablePageSize
    } = useContext(ShareContext)

    const [isMobileModalOpenFilter, setIsMobileModalOpenFilter] = useState(false);
    const [isSortNewToOld, setIsSortNewToOld] = useState(true);
    const [isSortOldToNew, setIsSortOldToNew] = useState(false);
    const [isSortHighToLow, setIsHighToLow] = useState(false);
    const [isSortLowToHigh, setIsLowToHigh] = useState(false);
    const [isStatus, setIsStatus] = useState(false);

    const tableColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, record) => {
                if(isTablet){
                    return(
                    <div className='ant-table-custom-cell mobile-cell'>
                        <div>
                            <span>{record.description} - {record.amount}</span>
                        </div>
                        <div className='invoices-addition-info'>
                            <div className={`${record.status === 'paid' ? 'invoices-paid' : 'invoices-pending'}`}>
                                <span className='micro text-capitalize'>{record.status}</span>
                            </div>
                            <span className='paragraph-2'>{record.invoice_number}</span>
                            <span className='paragraph-2'>{record.issue_date}</span>
                        </div>
                    </div>
                    );
                }else{
                    return(
                        <div className='ant-table-custom-cell'>
                            <span>{record.description}</span>
                        </div>
                    );
                }
            }
        },
        {
          title: 'Invoice number',
          dataIndex: 'invoice_number',
          sorter: (a, b) => {
            if (a.invoice_number === null && b.invoice_number === null) {
                return 0;
            } else if (a.invoice_number === null) {
                return 1;
            } else if (b.invoice_number === null) {
                return -1;
            } else {
                return a.invoice_number.localeCompare(b.invoice_number);
            }
        },
          sortDirections: ['ascend', 'descend', 'ascend'],
          ...(isTablet && isSortNewToOld || isSortOldToNew ? { sortOrder: isSortOldToNew ? 'ascend' : isSortNewToOld ? 'descend' : 'ascend'} : {}),
          className: isTablet ? 'd-none' : null,
          render: (_, record) => (
            <div className='ant-table-custom-cell'>
                <span>{record.invoice_number}</span>
            </div>
          )
        },
        {
            title: 'Issue date',
            dataIndex: 'issue_date',
            sorter: (a, b) => new Date(a.issue_date) - new Date(b.issue_date),
            sortDirections: ['ascend', 'descend', 'ascend'],
            className: isTablet ? 'd-none' : null,
            render: (_, record) => (
                <div className='ant-table-custom-cell'>
                    <span>{record.issue_date}</span>
                </div>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: (a, b) => parseFloat(a.amount.replace(/[^\d.-]/g, '')) - parseFloat(b.amount.replace(/[^\d.-]/g, '')),
            className: isTablet ? 'd-none' : null,
            ...(isTablet && isSortLowToHigh || isSortHighToLow ? { sortOrder: isSortLowToHigh ? 'ascend' : isSortHighToLow ? 'descend' : 'ascend'} : {}),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, record) => (
                <div className='ant-table-custom-cell'>
                    <span>{record.amount}</span>
                </div>
            )
        },
        {
            title: 'Payment status',
            dataIndex: 'amount',
            className: isTablet ? 'd-none' : null,
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (_, record) => (
                <div className={`${record.status === 'paid' ? 'invoices-paid' : 'invoices-pending'}`}>
                    <span className='paragraph-1 text-capitalize'>{record.status}</span>
                </div>
            )
        },
        {
          title: 'Actions',
          key: 'actions',
          className: isTablet ? 'mobile-invoices-btn' : null,
          render: (text, record) => (
            <>
            {
                record.invoice_pdf &&
                    <div className='invoices-list-action-btns'>
                        <a
                            href={record.invoice_pdf}
                            target="_blank"
                            className='icon-button'
                        >
                            <CustomIcons variant="download" />
                        </a>
                    </div>
            }
            </>
          )
        },
    ];

    const InvoiceTableSkeleton = () => {
        return(
            <div className="invoices-list-table-skeleton">
                {isTablet ?
                   <>
                    <Skeleton width="100px" height="20px" style={{marginTop: '40px'}}/>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Skeleton width="100%" height="46px"/>
                        </div>
                    </div>
                   </>
                :
                <>
                <Skeleton width="196px" height="24px"/>
                   <div className={`${isTablet ? 'd-none' : null}`}>
                    <div>
                        Description
                    </div>
                    <div>
                        Invoice number
                    </div>
                    <div>
                        Issue date
                    </div>
                    <div>
                        Amount
                    </div>
                    <div>
                        Payment status
                    </div>
                    <div>
                        Actions
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                <div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                    <div>
                        <Skeleton width="100%" height="24px"/>
                    </div>
                </div>
                </>
            }
            </div>
        );
    };

    const handlePagination = (page, tablePageSize) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const listStripeInvoices = async () => {
            const totalItems = props.invoices.length;
            const startItem = (currentPage - 1) * tablePageSize + 1;
            const endItem = Math.min(currentPage * tablePageSize, totalItems);

            setPaginationTotal(totalItems);
            setPaginationStart(startItem);
            setPaginationEnd(endItem);
        };

        listStripeInvoices();
    }, [props.invoices]);


    const handleMobileModalClose = () => {
        setIsMobileModalOpenFilter(false);
    }
    const handleNewToOld = () => {
        setIsMobileModalOpenFilter(false);
        setIsSortNewToOld(true);
        setIsSortOldToNew(false);
        setIsHighToLow(false);
        setIsLowToHigh(false);
        setIsStatus(false);
    }
    const handleOldToNew = () => {
        setIsMobileModalOpenFilter(false);
        setIsSortNewToOld(false);
        setIsSortOldToNew(true);
        setIsHighToLow(false);
        setIsLowToHigh(false);
        setIsStatus(false);
    }
    const handleHighToLow = () => {
        setIsMobileModalOpenFilter(false);
        setIsSortNewToOld(false);
        setIsSortOldToNew(false);
        setIsHighToLow(true);
        setIsLowToHigh(false);
        setIsStatus(false);
    }
    const handleLowToHigh = () => {
        setIsMobileModalOpenFilter(false);
        setIsSortNewToOld(false);
        setIsSortOldToNew(false);
        setIsHighToLow(false);
        setIsLowToHigh(true);
        setIsStatus(false);
    }
    const handleStatus = () => {
        setIsMobileModalOpenFilter(false);
        setIsSortNewToOld(false);
        setIsSortOldToNew(false);
        setIsHighToLow(false);
        setIsLowToHigh(false);
        setIsStatus(true);
    }

    return(
    <>
        <div className='invoices-list'>
            <h2 className={`${isTablet ? 'd-none' : null }`}>Invoices</h2>
            {isTablet ? (
                <>
                    <div className='mobile-setting-bar'>
                        <div className='mobile-setting-filter'>
                            <Button
                                onClick={() => { setIsMobileModalOpenFilter(true);}}
                                variant="default" className={"icon-button icon-button-text icon-button-left"}>
                                <h3 className='filters-h3'>
                                    {isSortNewToOld ?
                                     'New to Old'
                                    : isSortOldToNew ?
                                      'Old to New'
                                    : isSortHighToLow ?
                                      'Amount: High to Low'
                                    : isSortLowToHigh ?
                                      'Amount: Low to High'
                                    : isStatus ?
                                        'Status'
                                    :'Filters'
                                    }
                                    </h3>
                                <span className='d-flex align-items-center'>
                                {isMobileModalOpenFilter ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                                </span>
                            </Button>
                        </div>
                    </div>
                        {isMobileModalOpenFilter && (
                                    <MobileModal title={'Sort By'} onClose={handleMobileModalClose}>
                                        <div className="mobile-modal-more-vertical">
                                            <Button size='sm' className={`btn-row-action ${isSortNewToOld ? 'selected' : null}`} onClick={() => { handleNewToOld()}} variant='link'>
                                                <h3>New to Old</h3>
                                            </Button>
                                            <Button className={`btn-row-action icon-button ${isSortOldToNew ? 'selected' : null}`} onClick={() => {handleOldToNew()}} size='sm' variant='link'>
                                                    <>
                                                        <h3>Old to New</h3>
                                                    </>
                                            </Button>
                                            <Button className={`btn-row-action icon-button ${isSortHighToLow ? 'selected' : null}`} onClick={() =>handleHighToLow()} size='sm' variant='link'>
                                                    <>
                                                        <h3>Amount: High to Low</h3>
                                                    </>
                                            </Button>
                                            <Button className={`btn-row-action icon-button ${isSortLowToHigh ? 'selected' : null}`} onClick={() => handleLowToHigh()} size='sm' variant='link'>
                                                    <>
                                                        <h3>Amount: Low to High</h3>
                                                    </>
                                            </Button>
                                            <Button className={`btn-row-action icon-button ${isStatus ? 'selected' : null}`} onClick={() => handleStatus()} size='sm' variant='link'>
                                                    <>
                                                        <h3>Status</h3>
                                                    </>
                                            </Button>
                                        </div>
                                    </MobileModal>
                        )
                    }
                    </>
                    ): null
            }
            {
                props.invoices.length > 0
                ?
                    <div className='invoices-list-table'>
                        <div className='invoices-list-pagination'>
                        Showing <span>{paginationStart}</span>-<span>{paginationEnd}</span> of <span>{paginationTotal}</span> {paginationTotal === 1 ? 'invoice' : 'invoices'}
                        </div>
                        <Table
                            className='custom-ant-table'
                            showQuickJumper
                            pagination={props.invoices.length > tablePageSize ? {
                                showSizeChanger: false,
                                showQuickJumper: true,
                                tablePageSize: tablePageSize,
                                position: ['none', "bottomCenter"],
                                onChange: handlePagination
                            } : false}
                            columns={tableColumns}
                            dataSource={props.invoices.map((item) => ({
                                ...item
                            }))}
                            rowKey={(record) => record.invoice_id}
                        />
                    </div>
                :
                    <InvoiceTableSkeleton />
            }
        </div>
    </>);
};

export default Invoices;
