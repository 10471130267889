import { spanishGray, iconSize } from '../../shared/utils';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';

const StopIcon = ({ circle, className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;
    const circleVal = size || circle;

    return (
        <>
            {
                circleVal
                ?
                    <StopCircleRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <StopRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default StopIcon;