import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import path from "../../components/shared/routePaths";
import Localization from "../../components/shared/localization";
import Button from 'react-bootstrap/Button';

const Welcome = () => {
    const navigate = useNavigate();
    const pathPrefix = "/";

    const handleLogoClick = () => {
        navigate(pathPrefix + path.LOGIN, { replace: true });
    }

    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-3 d-flex flex-row">
                            <button className="dam-logo" onClick={handleLogoClick}>
                                <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo"/>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <div className="welcome-elko">
                <div className="row">
                    <div className="col-12">
                        <img  src="/images/welcome.svg" alt="Welcome SVG"/>
                        <h2 className="h2">{Localization.Welcome.Title}</h2>
                        <p className="paragraph-1">{Localization.Welcome.Desc}</p>
                        <Button onClick={handleLogoClick}>Go to my workspace</Button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Welcome;