import { useContext } from 'react';
import { ShareContext } from '../../context/share-state';
import MobileModal from '../mobile-modal';
import AssetFilter from '../../../search/asset-filter';

const MobileFilter = (props) =>{
    const {
        displayFilters,
        applyFilters,
        clearFilters,
        sortMobile,
        handleMobileModalFilterClose,
        setSortByMobile
    } = useContext(ShareContext);

    return(
        <MobileModal title="Filters" onClose={props.handleMobileModalFilterClose ? props.handleMobileModalFilterClose : handleMobileModalFilterClose} withBtn={true}>
            {props.hasSort &&
                <div className="mobile-modal-sort">
                    <div className="sort-title">
                        <p className="paragraph-1">Sort By</p>
                    </div>
                    <div className="sort-data">
                        <input
                            type="radio"
                            id="new-to-old"
                            name="sort"
                            value={1}
                            checked={sortMobile === 1}
                            onChange={() => setSortByMobile(1)}
                        />
                        <label htmlFor="new-to-old" className="paragraph-1">New to Old</label>
                    </div>
                    <div className="sort-data">
                        <input
                            type="radio"
                            id="old-to-new"
                            name="sort"
                            value={2}
                            checked={sortMobile === 2}
                            onChange={() => setSortByMobile(2)}
                        />
                        <label htmlFor="old-to-new" className="paragraph-1">Old to New</label>
                    </div>
                </div>
            }
            <div className="mobile-modal-filter" style={{marginTop: props.hasSort ? '0' : '0.75rem' }}>
                <AssetFilter clear={props.clearFilters ? props.clearFilters : clearFilters} applyFilters={props.applyFilters ? props.applyFilters : applyFilters} show={displayFilters} collectionUuid={props.collectionUuid} searchResult={props.searchResult}/>
            </div>
        </MobileModal>
    );
};

export default MobileFilter;