import { spanishGray, iconSize } from '../../shared/utils';

const DocumentIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fontSize: sizeVal }}
        >
            <g clipPath="url(#clip0_3955_48136)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.99967 3.99992C1.63301 3.99992 1.33301 4.29992 1.33301 4.66659V13.3333C1.33301 14.0666 1.93301 14.6666 2.66634 14.6666H11.333C11.6997 14.6666 11.9997 14.3666 11.9997 13.9999C11.9997 13.6333 11.6997 13.3333 11.333 13.3333H3.33301C2.96634 13.3333 2.66634 13.0333 2.66634 12.6666V4.66659C2.66634 4.29992 2.36634 3.99992 1.99967 3.99992ZM13.333 1.33325H5.33301C4.59967 1.33325 3.99967 1.93325 3.99967 2.66659V10.6666C3.99967 11.3999 4.59967 11.9999 5.33301 11.9999H13.333C14.0663 11.9999 14.6663 11.3999 14.6663 10.6666V2.66659C14.6663 1.93325 14.0663 1.33325 13.333 1.33325ZM11.9997 7.33325H6.66634C6.29967 7.33325 5.99967 7.03325 5.99967 6.66659C5.99967 6.29992 6.29967 5.99992 6.66634 5.99992H11.9997C12.3663 5.99992 12.6663 6.29992 12.6663 6.66659C12.6663 7.03325 12.3663 7.33325 11.9997 7.33325ZM9.33301 9.99992H6.66634C6.29967 9.99992 5.99967 9.69992 5.99967 9.33325C5.99967 8.96659 6.29967 8.66659 6.66634 8.66659H9.33301C9.69967 8.66659 9.99967 8.96659 9.99967 9.33325C9.99967 9.69992 9.69967 9.99992 9.33301 9.99992ZM11.9997 4.66659H6.66634C6.29967 4.66659 5.99967 4.36659 5.99967 3.99992C5.99967 3.63325 6.29967 3.33325 6.66634 3.33325H11.9997C12.3663 3.33325 12.6663 3.63325 12.6663 3.99992C12.6663 4.36659 12.3663 4.66659 11.9997 4.66659Z"
                fill={colorVal}
            />
            </g>
            <defs>
            <clipPath id="clip0_3955_48136">
                <rect width="16" height="16" fill={colorVal} />
            </clipPath>
            </defs>
        </svg>
    )
};

export default DocumentIcon;