import { spanishGray, iconSize } from '../../shared/utils';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';

const FilterIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <FilterListRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default FilterIcon;