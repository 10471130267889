import CustomIcons from '../custom-icons';
import DocumentIcon from '../../../icons/document';
import SolutionManifest from '../../../../solution-manifest';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ShareContext } from '../../context/share-state';
import { useContext } from 'react';
import path from '../../routePaths';

const NotificationItem = ({name, age, variant, seen, oldRole, newRole, editor, names=[], handleCloseNotification}) => {
  // Share Context
  const { updateMenuTabKey } = useContext(ShareContext);

  const navigate = useNavigate();

  const formatText = str => str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

  const handleReviewClick = () => {
    handleCloseNotification();
    updateMenuTabKey(path.SETTINGS);
    navigate('/settings?tab=user-request');
  };

  const handleRegisterClick = () => {
    handleCloseNotification();
    navigate(SolutionManifest.Plan.Url);
  };

  return (
    <>
      {
        variant === 'join request'?
          <div className={`notification-item join ${seen == false? 'unseen': ''}`}>
              <span className='icon'>
                  <div className='icon-bg'>
                    <CustomIcons className="icon-img" variant='userCircle' color="rgba(2, 118, 225, 1)"/>
                  </div>
              </span>
              <span className="details">
                  <p>
                  {
                    names.length >= 3 ? (
                      <><strong>{names[0]}</strong>, <strong>{names[1]}</strong> and {names.length - 2} {(names.length - 2) > 1? "others" : "other"} requested to join your workspace.</>
                    ) : names.length === 2 ? (
                      <><strong>{names[0]}</strong> and <strong>{names[1]}</strong> requested to join your workspace.</>
                    ) : names.length === 1 ? (
                      <><strong>{names[0]}</strong> requested to join your workspace.</>
                    ) : null
                  }
                  </p>
                  <span className="notification-age">
                  {age}
                  </span>
                  <a href="#" className='notif-btn' onClick={handleReviewClick}>
                      Review
                  </a>
              </span>
          </div>
        :
        variant === 'trial ending'?
          <div className={`notification-item trial ${seen == false? 'unseen': ''}`}>
              <span className='icon'>
                  <div className='icon-bg'>
                    <DocumentIcon className="icon-img" color="rgba(104, 59, 226, 1)" />
                  </div>
              </span>
              <span className="details">
                  <p>
                    Your free trial is ending soon. Register a paid plan today.
                  </p>
                  <span className="notification-age">
                    {age}
                  </span>
                  <a href="#" className='notif-btn' onClick={handleRegisterClick}>
                  Register
                  </a>
              </span>
          </div>
        :
        variant === 'reset 2fa'?
          <div className={`notification-item reset2fa ${seen == false? 'unseen': ''}`}>
              <span className='icon'>
                  <div className='icon-bg'>
                    <CustomIcons className="icon-img" variant='accountCog' color="rgba(255, 78, 137, 1)"/>
                  </div>
              </span>
              <span className="details">
                  <p>
                    <strong>{editor}</strong> reset your 2FA credentials. You will be prompted to set up 2FA again on your next log in.
                  </p>
                  <span className="notification-age">
                    {age}
                  </span>
              </span>
          </div>
        :
        variant === 'role change'?
          <div className={`notification-item reset2fa ${seen == false? 'unseen': ''}`}>
              <span className='icon'>
                  <div className='icon-bg'>
                    <CustomIcons className="icon-img" variant='accountCog' color="rgba(255, 78, 137, 1)"/>
                  </div>
              </span>
              <span className="details">
                  <p>
                    <strong>{editor}</strong> edited your role from <strong>{formatText(oldRole)}</strong> to <strong>{formatText(newRole)}</strong>.
                  </p>
                  <span className="notification-age">
                    {age}
                  </span>
              </span>
          </div>
        :
        variant === 'skeleton'?
          <div className={`notification-item reset2fa ${seen == false? 'unseen': ''}`}>
              <span className='icon'>
                  <div className='icon-bg'>
                    <Skeleton width="60px" height="60px"/>
                  </div>
              </span>
              <span className="details">
                <Skeleton width="100%" height="30px"/>
                <Skeleton width="100%" height="30px"/>
                <Skeleton width="20%" height="20px"/>
              </span>
          </div>
        :
        null
      }
    </>
  )
}

export default NotificationItem
