import { useState, useContext, useEffect } from "react";
import { SessionContext } from "../../shared/context/session-provider";
import { GlobalContext } from "../../shared/context/global-provider";
import { ShareContext } from "../../shared/context/share-state";
import SearchResultList from '../search-result';
import ViewAssets from '../../shared/components/view-asset';
import AssetService from '../../../services/api/assetsService';
import GenericModal from '../../shared/components/generic-modal';
import ViewAssetsHooks from '../../shared/hooks/view-assets-hooks';
import SearchHooks from '../../shared/hooks/search-hooks';
import path from "../../shared/routePaths";

const SearchView = (props) => {
    const [data, setData] = useState(null);
    const [owners, setOwners] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalShow, SetModalShow] = useState(false);
    const [modalType, SetModalType] = useState("");
    const [deleteFilesData, setDeleteFilesData] = useState({});

    const { updateSearchValue,
        selectedOption,
        searchKeyword,
        searchSubmitted } = SearchHooks();

    const {
        progress,
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        nextUUID,
        prevUUID,
        viewAssetsShow,
        viewAssetBtnDisable,
    } = ViewAssetsHooks(props);

    const { tenantUuid,
        userUuid } = useContext(SessionContext);

    // Share Context
    const { menuTabKey, updateMenuTabKey } = useContext(ShareContext);

    const pathPrefix = '/';
    const { updateToastMessage } = useContext(GlobalContext);

    //----- Function -----//
    // Update Asset Data State
    const updateAssetData = (assetData) => {
        setData(assetData);
    }

    const handleModalDelete = async () => {
        const bodyAsset = {
            "uuid": tenantUuid,
            "useruuid": userUuid,
            "assetuuids": [deleteFilesData.uuid],
            "assetnames": [deleteFilesData.name]
        }

        await SetModalShow(false);
        await AssetService.purgeRecord(bodyAsset, tenantUuid).then(() => {
            const filterData = data.filter(data => data.uuid !== deleteFilesData.uuid);
            setData(filterData);
            // Set Toast Message
            updateToastMessage(`${deleteFilesData.name} deleted`);
        })
          .catch((e) =>
            console.error(`fail to remove media ${deleteFilesData.uuid}`));
    }

    // Handler Modal Close
    const handleModalClose = () => SetModalShow(false);

    // Remove file Modal Trigger
    const handleRemoveFileModal = (fileName, fileUUID) => {
            const newDeleteFilesData = {
                name: fileName.substring(fileName.lastIndexOf("/") + 1),
                uuid: fileUUID
            };

            setDeleteFilesData(newDeleteFilesData);
            SetModalType('delete');
            SetModalShow(true);
    }

    useEffect(() => {
        if(!searchSubmitted) {
            updateMenuTabKey(path.EXPLORE);
        }
    }, [menuTabKey]);

    return (
        <>
            { searchSubmitted &&
                <SearchResultList progress={progress} data={data} handleViewAssetOpen={handleViewAssetOpen} handleRemoveFileModal={handleRemoveFileModal} updateAssetData={updateAssetData} selectedOption={selectedOption} selectedKeywords={searchKeyword}/>
            }
            {
                viewAssetsShow &&
                    <ViewAssets
                        updateFSLightBox={props.updateFSLightBox}
                        fetchedFileData={fetchedFileData}
                        nextUUID={nextUUID}
                        prevUUID={prevUUID}
                        viewAssetsShow={viewAssetsShow}
                        viewAssetBtnDisable={viewAssetBtnDisable}
                        handleViewNext={handleViewNext}
                        handleViewPrev={handleViewPrev}
                        handleViewClose={handleViewClose}
                        fileData={fileData}
                        owners={owners}
                        updateSearchValue={updateSearchValue}/>
            }

            <GenericModal
                modalShow={modalShow}
                handleModalClose={handleModalClose}
                modalType={modalType}
                handleModalDelete={handleModalDelete}
                deleteFilesData={deleteFilesData}
            />
        </>
    )
}

export default SearchView;