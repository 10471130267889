import React, { useState, useEffect, useContext } from 'react';
import { sanitizeInput } from '../../shared/utils';
import Localization from '../../shared/localization';
import CancelIcon from '@mui/icons-material/Cancel';
import Form from 'react-bootstrap/Form';
import CustomIcons from '../../shared/components/custom-icons';
import { ShareContext } from '../../shared/context/share-state';
import { UploadContext } from '../../shared/context/upload-provider';

const UploadTag = (props) => {
    // Share Context
    const { assetTagFiles } = useContext(ShareContext);

    const [tagName, setTagName] = useState('');
    const [tagChar, setTagChar] = useState(0);
    const [textFocus, setTextFocs] = useState(false)
    const [tagLimit, setTagLimit] = useState(false);
    const [mouseCollection, setMouseCollection] = useState(0);

    //Upload Context
    const { updateActiveSetting,
            tagAsset,
            tagMultipleSelection,
            setTagAsset,
            setTagMultipleSelection,
            mergeTagArrays } = useContext(UploadContext);

    const tagCharLimit = 50;

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && tagName) {
            const tagArray = tagAsset;

            for (let i = 0; i < tagArray.length; i++) {
                if (tagArray[i].toLowerCase() === tagName.toLowerCase()) {
                    tagArray.splice(i, 1);
                }
            }

            if(tagMultipleSelection.length > 0) {
                const tagMultipleSelectionArray = tagMultipleSelection;

                props.checkedFiles.forEach((checked) => {
                    const attributeTagArr = checked.attributes.tags ? checked.attributes.tags.split(',') : [];
                    const newCheckedFileArr = [checked];

                    for(let l = 0; l < attributeTagArr.length; l++) {
                        if (attributeTagArr[l].toLowerCase() === tagName.toLowerCase()) {
                            attributeTagArr.splice(l, 1);
                        }
                    }

                    for(let l = 0; l < tagMultipleSelection.length; l++) {
                        if (tagMultipleSelection[l].toLowerCase() === tagName.toLowerCase()) {
                            tagMultipleSelectionArray.splice(l, 1);
                        }
                    }

                    attributeTagArr.unshift(tagName);
                    const attributeTag = { key: 'tags', value: attributeTagArr.toString()};
                    props.updateFileAttributes(attributeTag, newCheckedFileArr);
                });

                const tagging = props.checkedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
                mergeTagArrays(tagging);
                setTagMultipleSelection(tagMultipleSelectionArray);
                setTagName('');
            } else {
                tagArray.unshift(tagName);
                const attributeTag = { key: 'tags', value: tagArray.toString() };
                props.updateFileAttributes(attributeTag, props.checkedFiles);
                setTagAsset(tagArray);
                setTagName('');
            }
            setTagChar(0)
        }
    }

    const tagChange = (char) => {
        const input = char.target;
        const cursorPosition = input.selectionStart;
        const regex = /[.<>'":;[\]{}|\\+=/?]/g;
        const sanitizedValue = sanitizeInput(regex, char.target.value);
        const words = sanitizedValue.split(" ");
        const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        const properCaseValue = formattedWords.join(" ");
        setTagChar(properCaseValue.length);
        setTagName(properCaseValue);

        setTimeout(() => {
            input.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);

        if(properCaseValue.indexOf(',') !== -1 && tagName) {
            const tagArray = tagAsset;

            for (let i = 0; i < tagArray.length; i++) {
                if (tagArray[i].toLowerCase() === tagName.toLowerCase()) {
                    tagArray.splice(i, 1);
                }
            }

            if(tagMultipleSelection.length > 0) {
                props.checkedFiles.forEach((checked) => {
                    const attributeTagArr = checked.attributes.tags ? checked.attributes.tags.split(',') : [];
                    const newCheckedFileArr = [checked];

                    for(let l = 0; l < attributeTagArr.length; l++) {
                        if (attributeTagArr[l].toLowerCase() === tagName.toLowerCase()) {
                            attributeTagArr.splice(l, 1);
                        }
                    }

                    attributeTagArr.unshift(tagName);
                    const attributeTag = { key: 'tags', value: attributeTagArr.toString()};
                    props.updateFileAttributes(attributeTag, newCheckedFileArr);
                });

                const tagging = props.checkedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
                mergeTagArrays(tagging);
                setTagName('');
            } else {
                tagArray.unshift(tagName);
                const attributeTag = { key: 'tags', value: tagArray.toString()};
                props.updateFileAttributes(attributeTag, props.checkedFiles);
                setTagAsset(tagArray);
                setTagName('');
            }
            setTagName("");
        }
    };

    const charLimitsShow = () => setTagLimit(true);

    const tagBlur = () => setTagLimit(false);

    const addTag = (tag) => {
        const tagKey = tag.target.closest('button').dataset.key;
        const process = props.processRef.current;

        const checkTag = props.checkedFiles.filter(file => {
            const fileTags = file.attributes.tags ? file.attributes.tags.split(',') : [];

            if(fileTags) {
                return fileTags.includes(tagKey);
            }

            return [];
        });

        props.checkedFiles.forEach((checked) => {
            const attributeTagArr = checked.attributes.tags ? checked.attributes.tags.split(',') : [];
            const newCheckedFileArr = [checked];

            for(let l = 0; l < attributeTagArr.length; l++) {
                if (attributeTagArr[l].toLowerCase() === tagKey.toLowerCase()) {
                    attributeTagArr.splice(l, 1);
                }
            }

            attributeTagArr.unshift(tagKey);
            const attributeTag = { key: 'tags', value: attributeTagArr.toString()};
            props.updateFileAttributes(attributeTag, newCheckedFileArr);
        });

        if(checkTag) {
            checkTag.forEach(tag => {
                process.querySelector('#' + tag.id).classList.remove('highlight');
            });
        }

        const tagging = props.checkedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
        mergeTagArrays(tagging);
    };
    const addAllTag = (key) => {
        const assetTagFile = assetTagFiles.find(assetFile => assetFile.key === key);
        const tags = assetTagFile.labels.map(label => label.Name).join(',');
        const attributeTag = { key: 'tags', value: tags};
        props.updateFileAttributes(attributeTag, props.checkedFiles);
        const tagging = props.checkedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
        mergeTagArrays(tagging);
    };
    const addAllSomeFileTag = (files) => {
        files.forEach((checkedFile, index) => {
            const propFile = props.checkedFiles.find(file => file.name === checkedFile);
            const tagsArr = propFile.attributes.tags;
            const tags = tagMultipleSelection.map(label => label).join(',');

            const combinedTags = [...tagsArr.split(','), ...tags.split(',')];
            const uniqueTags = [...new Set(combinedTags)];
            const attributeTag = { key: 'tags', value: uniqueTags.join(',')};

            props.updateFileAttributes(attributeTag, [props.checkedFiles[index]]);
            const tagging = props.checkedFiles.map(item => item.attributes.tags ? item.attributes.tags.split(',') : []);
            mergeTagArrays(tagging);
        });
    };

    const removeTag = (tag) => {
        const tagKey = tag.target.closest('button').dataset.key;
        const tagArray = tagAsset.filter(asset => asset !== tag.target.closest('button').dataset.key);

        props.checkedFiles.forEach((checked) => {
            const attributeTagArr = checked.attributes.tags ? checked.attributes.tags.split(',') : [];
            const newCheckedFileArr = [checked];
            const filteredTagArray = attributeTagArr.filter(item => item.toLowerCase() !== tagKey.toLowerCase());
            const attributeTag = { key: 'tags', value: filteredTagArray.toString()};
            props.updateFileAttributes(attributeTag, newCheckedFileArr);
        });

        setTagAsset(tagArray);
    };

    const removeAllTag = () => {
        const attributeTag = { key: 'tags', value: ""};
        props.updateFileAttributes(attributeTag, props.checkedFiles);
        setTagAsset([]);
        setTagMultipleSelection([]);
    };

    const handleMouseTagEnter = (tag) => {
        const process = props.processRef.current;
        const checkTag = props.checkedFiles.filter(file => {
            const fileTags = file.attributes.tags ? file.attributes.tags.split(',') : [];

            if(fileTags) {
                return fileTags.includes(tag);
            }

            return [];
        });
        
        tagMultipleSelection.forEach(tagItem => {
            const button = document.querySelector(`#btnSome-${tagItem.replace(/\s/g, '')}`);
            if(button && tagItem !== tag) {
                button.classList.remove('hover');
            } else {
                button.classList.add('hover');
            }
        });

        if(checkTag) {
            checkTag.forEach(tag => {
                if(process.querySelector('#' + tag.id)) {
                    process.querySelector('#' + tag.id).classList.add('highlight');
                }
            });
            setMouseCollection(checkTag.length);
        }
    };

    const handleMouseTagLeave = (tag) => {
        const process = props.processRef.current;
        const checkTag = props.checkedFiles.filter(file => {
            const fileTags = file.attributes.tags ? file.attributes.tags.split(',') : [];

            if(fileTags) {
                return fileTags.includes(tag);
            }

            return [];
        });
        const button = document.querySelector(`#btnSome-${tag.replace(/\s/g, '')}`);

        if(checkTag.length > 0) {
            checkTag.forEach(tagItem => {
                if(process.querySelector('#' + tagItem.id)) {
                    process.querySelector('#' + tagItem.id).classList.remove('highlight');
                }
            });
            setMouseCollection(0);
        } else {
            props.checkedFiles.forEach(tag => {
                if(process.querySelector('#' + tag.id)) {
                    process.querySelector('#' + tag.id).classList.remove('highlight');
                }
            });
            setMouseCollection(0);
        }
        if(button) {
            button.classList.remove('hover');
        }
    };

    return(
        <>
            <div className='upload-tag-wrapper'>
                <div className='upload-offcanvas-header'>
                    <div>
                        <h3>
                        <button className='icon-button mobile-back-button' onClick={() => updateActiveSetting("")}><CustomIcons variant="arrow" direction="back"/></button> {Localization.Tag.Title}</h3>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={() => updateActiveSetting("")}><CancelIcon /></button>
                        </div>
                    </div>
                    <span className='paragraph-1'>{ props.checkedFiles.length > 0 ? `${props.checkedFiles.length} ${props.checkedFiles.length > 1 ? 'files' : 'file'} selected` : Localization.Edit.NoFilesSelected}</span>
                </div>
                {
                    props.checkedFiles.length > 0
                    ?
                        <>
                            <div className='upload-offcanvas-form'>
                                {/* <p style={{ textAlign: 'left'}} className='upload-tag-AISystem'>{ Localization.Tag.AISystem }</p> */}
                                <div style={{ marginTop: props.isDesktop ? '40px' : '32px'}}>
                                    <Form.Control
                                        type="text"
                                        as="input"
                                        id="tag"
                                        placeholder={props.isDesktop ? Localization.Tag.TagPlaceholder : 'Add tags'}
                                        value={tagName}
                                        maxLength={tagCharLimit}
                                        onChange={(e) => {
                                            tagChange(e);
                                        }}
                                        onBlur={(e) => {
                                            tagBlur(e);
                                            setTextFocs(false);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        onFocus={() => {
                                            charLimitsShow();
                                            setTextFocs(true);
                                        }}
                                    />
                                    <Form.Label style={textFocus ? {color: 'black'} : {}} htmlFor="tag">Tag</Form.Label>
                                    {
                                        tagLimit && (
                                            <div className='upload-char-container'>
                                                {tagChar >= tagCharLimit && (
                                                    <div className='upload-char-limit'>
                                                        Character limit reached
                                                    </div>
                                                )}
                                                <div className='upload-char-limit-right'>
                                                    {`${tagChar}/${tagCharLimit}`}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className='upload-tag-tagging'>
                                {
                                    (tagAsset.length > 0 || tagMultipleSelection.length > 0) &&
                                        <div className='upload-tag-tagging-clear-all'>
                                            <button className='icon-button' onClick={() => removeAllTag()}>
                                                <CustomIcons variant='cancel' /> <span className="clear-all-text" >{ Localization.Tag.ClearAll}</span>
                                            </button>
                                        </div>
                                }
                                <div className='upload-tag-tagging-desc'>
                                    {
                                        props.checkedFiles.length > 1
                                        ?
                                            <span className='paragraph-1'>{ Localization.Tag.TagsAllDesc }</span>
                                        :
                                            <span className='paragraph-1'>{ Localization.Tag.TagsDesc }</span>
                                    }
                                </div>
                                {
                                    tagAsset.length > 0
                                    ?
                                        <div className='upload-tag-tagging-cont'>
                                            {
                                                tagAsset
                                                .sort((a, b) => a.localeCompare(b))
                                                .map(tag => {
                                                    return <button className="chips-primary" key={tag} data-key={tag}><span>{tag}</span><span onClick={(e) => removeTag(e)}><CancelIcon /></span></button>;
                                                })
                                            }
                                        </div>
                                    :
                                        <div className='upload-tag-no-tagging'>
                                            <span className='paragraph-1'>{Localization.Tag.NoTags}<br/>{Localization.Tag.NoTagsDesc}</span>
                                        </div>
                                }
                                {
                                    tagMultipleSelection.length > 0 &&
                                        <div className='upload-tag-tagging-same'>
                                            {
                                                <div className='upload-tag-tagging-clear-all'>
                                                    <button className='icon-button' onClick={() => addAllSomeFileTag(props.checkedFiles.map(checkedFile => checkedFile.name))}>
                                                        <CustomIcons variant='add' /> <span className="clear-all-text" >{ Localization.Tag.AddAll}</span>
                                                    </button>
                                                </div>
                                            }
                                            <div className='upload-tag-tagging-desc'>
                                                <span className='paragraph-1'>{ Localization.Tag.SomeFileTags }</span>
                                            </div>
                                            <div className='upload-tag-tagging-cont'>
                                                {
                                                    tagMultipleSelection
                                                        .filter(tag => typeof tag === 'string' && tag !== "")
                                                        .sort((a, b) => a.localeCompare(b))
                                                        .map(tag => {
                                                        return (
                                                          <button
                                                            className="chips-secondary"
                                                            id={`btnSome-${tag.replace(/\s/g, '')}`}
                                                            key={tag}
                                                            data-key={tag}
                                                            data-tooltip={`${mouseCollection} of ${props.checkedFiles.length} files`}                                                            
                                                            onMouseEnter={() => {
                                                                handleMouseTagEnter(tag);
                                                            }}                                                      
                                                            onMouseLeave={() => {
                                                                handleMouseTagLeave(tag);
                                                            }}
                                                            onClick={(e) =>
                                                              addTag(e)
                                                            }
                                                          >
                                                            <span>{tag}</span>
                                                            <span>
                                                              <CustomIcons variant="add" />
                                                            </span>
                                                          </button>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                            {
                                ((assetTagFiles
                                    .filter(asset => asset.key === props.checkedFiles[0].name)
                                    .map(tag => {
                                        return tag.labels.filter(label => !tagAsset.includes(tag.type === 'video' ? label.Label.Name : label.Name));
                                    })[0] || []).length > 0 && tagMultipleSelection.length === 0 ) &&
                                        <div className='upload-tag-tagging'>
                                            {
                                                <div className='upload-tag-tagging-clear-all'>
                                                    <button className='icon-button' onClick={() => addAllTag(props.checkedFiles[0].name)}>
                                                        <CustomIcons variant='add' /> <span className="clear-all-text" >{ Localization.Tag.AddAll}</span>
                                                    </button>
                                                </div>
                                            }
                                            <div className='upload-tag-tagging-desc'>
                                                {
                                                    <span className='paragraph-1'>{ Localization.Tag.AISmartTagTitle }</span>
                                                }
                                            </div>
                                            {
                                                assetTagFiles.length > 0 &&
                                                    <div className='upload-tag-tagging-cont'>
                                                        {tagMultipleSelection.length > 0 ?
                                                            assetTagFiles
                                                            .filter(asset => props.checkedFiles.some(checkedFile => checkedFile.name === asset.key))
                                                            .map(tag => {
                                                                return tag.labels
                                                                .filter(label => !tagAsset.includes(tag.type === 'video' ? label.Label.Name : label.Name))
                                                                .sort((a, b) => (tag.type === 'video' ? a.Label.Name.localeCompare(b.Label.Name) : a.Name.localeCompare(b.Name)))
                                                                .map(label => (
                                                                <button className="chips-secondary" key={tag.type === 'video' ? label.Label.Name : label.Name} data-key={tag.type === 'video' ? label.Label.Name : label.Name} onClick={(e) => addTag(e)}>
                                                                    <span>{tag.type === 'video' ? label.Label.Name : label.Name}</span>
                                                                    <span>
                                                                    <CustomIcons variant='add' />
                                                                    </span>
                                                                </button>
                                                                ));
                                                            })
                                                            :
                                                            assetTagFiles
                                                            .filter(asset => asset.key === props.checkedFiles[0].name)
                                                            .map(tag => {
                                                                return tag.labels
                                                                .filter(label => !tagAsset.includes(tag.type === 'video' ? label.Label.Name : label.Name))
                                                                .sort((a, b) => (tag.type === 'video' ? a.Label.Name.localeCompare(b.Label.Name) : a.Name.localeCompare(b.Name)))
                                                                .map(label => (
                                                                <button className="chips-secondary" key={tag.type === 'video' ? label.Label.Name : label.Name} data-key={tag.type === 'video' ? label.Label.Name : label.Name} onClick={(e) => addTag(e)}>
                                                                    <span>{tag.type === 'video' ? label.Label.Name : label.Name}</span>
                                                                    <span>
                                                                    <CustomIcons variant='add' />
                                                                    </span>
                                                                </button>
                                                                ));
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </div>
                            }
                        </>
                    :
                        <div className='upload-offcanvas-no-files'>
                            { Localization.Tag.SelectAFile }
                        </div>
                }
            </div>
        </>
    );
}

export default UploadTag;