import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types';

const BackToTop = (props) => {
    const [active, setActive] = useState(props.active || false);

    const BackToTopScroll = () => {
        const top = 500;
        if (document.body.scrollTop > top || document.documentElement.scrollTop > top) {
            setActive(true);
        } else {
            setActive(false);
        }
    };

    const HandlerBackToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    window.onscroll = function() {BackToTopScroll()};

    return(
        <>
            {
                <div className={active ? 'back-to-top active' : 'back-to-top' } onClick={HandlerBackToTop}>
                    <div>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </div>
                    Back to Top
                </div>
            }
        </>
    );
}

export default BackToTop;

BackToTop.propTypes = {
    active: PropTypes.bool
};

BackToTop.defaultProps = {
  active: true
};