import { spanishGray, iconSize } from '../../shared/utils';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

const PeopleIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <PeopleRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default PeopleIcon;