import { useState, useEffect, useContext } from 'react';
import { sanitizeInput } from '../../shared/utils';
import { UploadContext } from '../../shared/context/upload-provider';
import Localization from '../../shared/localization';
import CancelIcon from '@mui/icons-material/Cancel';
import Form from 'react-bootstrap/Form';
import CustomIcons from '../../shared/components/custom-icons';

const UploadEdit = (props) => {
    const [defaultFilesName, setDefaultFilesName] = useState("");
    const [allowComments, setAllowComments] = useState(false);
    const [TextAreaFocus, setTextAreaFocus] = useState(false);
    const [TextAreaFocusDes, setTextAreaFocusDes] = useState(false);
    // const [restrictedUse, setRestrictedUse] = useState(false);
    const [filesName, setFilesName] = useState("");
    const [filesDesc, setFilesDesc] = useState("");
    const [filesDescChar, setFilesDescChar] = useState(0);
    // const [filesOwner, setFilesOwner] = useState("");
    // const [filesGroup, setFilesGroup] = useState("");
    const [filesNameExt, setFilesNameExt] = useState("");
    const [charLimit, setCharLimit] = useState("");
    const [filesNameChar, setFilesNameChar] = useState(0);
    // const [restrictedError, setRestrictedError] = useState(false);
    const [allowCommentsError, setAllowCommentsError] = useState(false);
    // const [ownerError, setOwnerError] = useState(false);

    //Upload Context
    const { updateActiveSetting } = useContext(UploadContext);

    const nameCharLimit = 256;
    const descCharLimit = 300;

    const filesNameCharLimitsChange = (char) => {
        /* const regex = /[.<>'":;[\]{}|\\+=/?,]/g;
        const sanitizedValue = sanitizeInput(regex, char.target.value); */
        setFilesNameChar(char.target.value.length);
        setFilesName(char.target.value);
        props.checkedFiles[0].name = char.target.value + "." + filesNameExt;
        props.updateFileAttributes();
    };

    const filesNameCharLimitsBlur = (char) => {
        setCharLimit("");
        if(char.target.value.length === 0) {
            setFilesName(defaultFilesName);
            setFilesNameChar(defaultFilesName.length);
            props.checkedFiles[0].name = defaultFilesName + "." + filesNameExt;
            props.updateFileAttributes();
        }
    }

    const charLimitsShow = (e, type) => setCharLimit(type);

    const filesDescCharLimitsChange = (char) => {
        setFilesDescChar(char.target.value.length);
        setFilesDesc(char.target.value);

        const attributeDesc = { key: 'description', value: char.target.value};

        props.updateFileAttributes(attributeDesc, props.checkedFiles);
    }

    const filesDescCharLimitsBlur = () => setCharLimit("");

    const allowCommentsChange = (el) => {
        if(el.target.checked) {
            setAllowComments(true);
            setAllowCommentsError(false);
        } else {
            setAllowComments(false);
        }
        const allowCommentsString = el.target.checked.toString();
        const attributeComments = { key: 'comments', value: allowCommentsString};
        props.updateFileAttributes(attributeComments, props.checkedFiles);
    }

    // const restrictedUseChange = (el) => {
    //     if(el.target.checked) {
    //         setRestrictedUse(true);
    //         setRestrictedError(false);
    //     } else {
    //         setRestrictedUse(false);
    //     }
    //     const restrictedString = el.target.checked.toString();
    //     const attributeRestricted = { key: 'restricted', value: restrictedString};
    //     props.updateFileAttributes(attributeRestricted, props.checkedFiles);
    // }

    // const dropdownOwnerSelect = (e) => {
    //     setFilesOwner(formatText(e));
    //     const attributeOwner = { key: 'owner', value: e};
    //     const attributeOwnerName = { key: 'ownerName', value: e};
    //     props.updateFileAttributes(attributeOwner, props.checkedFiles);
    //     props.updateFileAttributes(attributeOwnerName, props.checkedFiles);
    // }

    useEffect(() => {
        // getOwner();
        if(props.checkedFiles.length > 0) {
            if(props.checkedFiles.length > 1) {
                setFilesNameChar(0);
                setFilesNameExt("");
                setFilesName("");

                // const owner = props.checkedFiles.map(item => item.attributes.ownerName);
                // const ownerSame = owner.every(value => value === owner[0]);

                // if(ownerSame) {
                //     setFilesOwner(owner[0]);
                // } else {
                //     setFilesOwner(owner[0]);
                // }

                const description = props.checkedFiles.map(item => item.attributes.description);
                const descriptionSame = description.every(value => value === description[0]);
                setFilesDesc(description[0]);

                if(descriptionSame) {
                    setFilesDesc(description[0]);
                } else {
                    setFilesDesc("");
                }

                // const restricted = props.checkedFiles.map(item => item.attributes.restricted);
                // const restrictedSame = restricted.every(value => value === restricted[0]);

                // if(restrictedSame) {
                //     setRestrictedError(false);
                //     setRestrictedUse(restricted[0] === 'true' ? true : false);
                // } else {
                //     setRestrictedError(true);
                //     setRestrictedUse(false);
                // }

                // const comments = props.checkedFiles.map(item => item.attributes.comments);
                // const commentsSame = comments.every(value => value === comments[0]);

                // if(commentsSame) {
                //     setAllowCommentsError(false);
                //     setAllowComments(comments[0] === 'true' ? true : false);
                // } else {
                //     setAllowCommentsError(true);
                //     setAllowComments(false);
                // }
            } else {
                // Reset Error State when checked files only have 1
                // setRestrictedError(false);
                // setAllowCommentsError(false);

                // Set File when checked files only have 1
                setDefaultFilesName(props.checkedFiles[0].name.replace(/\.[^/.]+$/, ""));
                setFilesNameChar(props.checkedFiles[0].name.replace(/\.[^/.]+$/, "").length);
                setFilesNameExt(/[^.]+$/.exec(props.checkedFiles[0].name));
                setFilesName(props.checkedFiles[0].name.replace(/\.[^/.]+$/, ""));
                // setFilesOwner(props.checkedFiles[0].attributes.ownerName);
                setFilesDesc(props.checkedFiles[0].attributes.description ? props.checkedFiles[0].attributes.description : "");
                // setAllowComments(props.checkedFiles[0].attributes.comments === 'true' ? true : false);
                // setRestrictedUse(props.checkedFiles[0].attributes.restricted === 'true' ? true : false);
            }
        }
    }, [props.checkedFiles])
    const customBorderFilename = {
        border: TextAreaFocus ? '1px solid black' : (props.checkedFiles.length > 1 ? '0' : ''),
        padding: props.checkedFiles.length > 1 ? '0' : '0.625rem 0.313rem 0.625rem 0'
      };
    const customBorderDesc = {
        border: TextAreaFocusDes ? '1px solid black' : '',
        padding: '0.625rem 0.313rem 0.625rem 0'
    };
    return(
        <>
            <div className='upload-edit-wrapper'>
                <div className='upload-offcanvas-header'>
                    <div>
                        <h3 className='mb-0'><button className='icon-button mobile-back-button' onClick={() => updateActiveSetting("")}><CustomIcons variant="arrow" direction="back"/></button> {Localization.Edit.Title}</h3>
                        <div className='upload-offcanvas-close'>
                            <button className='icon-button' onClick={() => updateActiveSetting("")}><CancelIcon /></button>
                        </div>
                    </div>
                    <p className='paragraph-1'>{ props.checkedFiles.length > 0 ? `${props.checkedFiles.length} ${props.checkedFiles.length > 1 ? 'files' : 'file'} selected` : Localization.Edit.NoFilesSelected}</p>
                </div>
                {
                    props.checkedFiles.length > 0
                    ?
                        <div className='upload-offcanvas-form' style={{ marginTop: !props.isDesktop && props.checkedFiles.length === 1 ? '32px' : ''}}>
                            <div className={`form-group ${props.checkedFiles.length > 1 ? "no-editing" : ""}`}>
                                <div className='form-control' style={customBorderFilename}>
                                    <Form.Control
                                        as="textarea"
                                        id="Filename"
                                        disabled={props.checkedFiles.length > 1}
                                        value={props.checkedFiles.length > 1 ? "" : filesName}
                                        aria-describedby="fileNameDesc"
                                        maxLength={nameCharLimit}
                                        onChange={(e) => filesNameCharLimitsChange(e)}
                                        onBlur={(e) => {
                                            filesNameCharLimitsBlur(e, filesName)
                                            setTextAreaFocus(false)
                                        }}
                                        onFocus={(e) => {
                                            charLimitsShow(e, "name");
                                            setTextAreaFocus(true)
                                        }}
                                        placeholder="Type here"
                                    />
                                </div>
                                <Form.Label style={ TextAreaFocus ? {color: 'black'} : {}} htmlFor="Filename">*Filename</Form.Label>
                                {
                                    props.checkedFiles.length > 1
                                    ?
                                        <Form.Text id="fileNameDesc">
                                            {Localization.Alerts.FileNameError}
                                        </Form.Text>
                                    :
                                        charLimit === "name" &&
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="upload-char-limit"> 
                                                <span>
                                                    {filesNameChar >= nameCharLimit && "Character limit reached"}
                                                </span>
                                                <span>
                                                    {`${filesNameChar}/${nameCharLimit}`}
                                                </span>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className='form-group'>
                            <div className='form-control' style={customBorderDesc}>
                                <Form.Control
                                    id="Description"
                                    as="textarea"
                                    aria-describedby="fileDescError"
                                    value={filesDesc}
                                    maxLength={descCharLimit}
                                    placeholder={props.checkedFiles.length === 1 ? 'Type here' : Localization.Edit.FileDescPlaceholder}
                                    onChange={(e) => filesDescCharLimitsChange(e)}
                                    onBlur={(e) => {
                                        filesDescCharLimitsBlur(e);
                                        setTextAreaFocusDes(false)
                                    }}
                                    onFocus={(e) => {
                                        charLimitsShow(e, "desc");
                                        setTextAreaFocusDes(true);
                                        setFilesDescChar(filesDesc?filesDesc.length:0);
                                    }}
                                />
                                </div>
                                <Form.Label style={ TextAreaFocusDes ? {color: 'black'} : {}} htmlFor="Description">Description</Form.Label>
                                {
                                    charLimit === "desc" &&
                                        <>
                                            <div className='upload-char-limit'>
                                                <span>
                                                    {
                                                        descCharLimit === filesDescChar &&
                                                            `${Localization.Upload.Offcanvas.Progress.CharLimitReach}`
                                                    }
                                                </span>
                                                <span>
                                                    {
                                                        `${filesDescChar}/${descCharLimit}`
                                                    }
                                                </span>
                                            </div>
                                        </>
                                }
                            </div>
                            {/* <div>
                                <Dropdown onSelect={(e) => dropdownOwnerSelect(e)}>
                                    <Dropdown.Toggle variant="default" id="dropdown-owner">
                                        {formatText(filesOwner)}<span>{formatText(filesGroup)}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            props.owners.length > 0
                                            ?
                                                props.owners.map((owner) => (
                                                    owner.username !== filesOwner && <Dropdown.Item eventKey={owner.username}><span>{formatText(owner.username)}</span><span>{formatText(owner.group)}</span></Dropdown.Item>
                                                ))
                                            :
                                                <div className='dropdown-menu-loading'>
                                                    <Spinner animation="grow" variant='dark' />
                                                </div>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Label htmlFor="Owner">*Owner</Form.Label>
                            </div> */}
                            {/* <div>
                                <Form.Check
                                    className={restrictedError ? 'form-check-input-mixed' : ''}
                                    type='checkbox'
                                    id="restrictedUse"
                                    aria-describedby="restrictedUseError"
                                    label={Localization.Edit.Restricted}
                                    onChange={(e) => restrictedUseChange(e)}
                                    checked={restrictedUse}
                                />
                                {
                                    restrictedError && <Form.Text id="restrictedUseError">
                                        {Localization.Alerts.MixedSetting}
                                    </Form.Text>
                                }
                            </div> */}
                            {/* <div>
                                <Form.Check
                                    className={allowCommentsError ? 'form-check-input-mixed' : ''}
                                    type='checkbox'
                                    id="Comment"
                                    aria-describedby="commentError"
                                    label={Localization.Edit.Comments}
                                    onChange={(e) => allowCommentsChange(e)}
                                    checked={allowComments}
                                />
                                {
                                    allowCommentsError && <Form.Text id="commentError">
                                        {Localization.Alerts.MixedSetting}
                                    </Form.Text>
                                }
                            </div> */}
                        </div>
                    :
                        <div className='upload-offcanvas-no-files'>
                            { Localization.Edit.SelectAFile }
                        </div>
                }
            </div>
        </>
    );
}

export default UploadEdit;