

import { useContext } from 'react';
import { ShareContext } from '../../../context/share-state';
import Localization from '../../../localization';
import DOMPurify from 'dompurify';

const AssetsStorage = () => {
    const { isStorageMax,
        storageSize,
        remainingSize,
        storageSizePercentage,
        maxStorageSize } = useContext(ShareContext);

    return(<>
        {
            isStorageMax ?
                <div className='settings-plan-details'>
                    <div className='settings-plan-details-title'>
                        <h2>{Localization.Plan.Storage.Title}</h2>
                    </div>
                    <div className='settings-plan-storage'>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.Storage.Desc.replace(/\${storagePercentage}/g, "100%").replace(/\${maxStorageSize}/g, maxStorageSize))}}></div>
                        <div className='settings-plan-storage-bar'>
                            <div style={{width: "100%"}}></div>
                        </div>
                        <div className='settings-plan-storage-bar-size'>
                            <span>{Localization.Plan.Storage.Used}<br/><b>{maxStorageSize}</b></span>
                            <span>{Localization.Plan.Storage.Remaining}<br/><b>0 Bytes</b></span>
                        </div>
                    </div>
                </div>
            :
                <div className='settings-plan-details'>
                    <div className='settings-plan-details-title'>
                        <h2>{Localization.Plan.Storage.Title}</h2>
                    </div>
                    <div className='settings-plan-storage'>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Plan.Storage.Desc.replace(/\${storagePercentage}/g, storageSizePercentage).replace(/\${maxStorageSize}/g, maxStorageSize))}}></div>
                        <div className='settings-plan-storage-bar'>
                            <div style={{width: storageSizePercentage}}></div>
                        </div>
                        <div className='settings-plan-storage-bar-size'>
                            <span>{Localization.Plan.Storage.Used}<br/><b>{storageSize}</b></span>
                            <span>{Localization.Plan.Storage.Remaining}<br/><b>{remainingSize}</b></span>
                        </div>
                    </div>
                </div>
        }

    </>);
};

export default AssetsStorage;
