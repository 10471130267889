import { useState, createContext, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AccountContext } from '../account-provider';
import { SessionContext } from '../session-provider';
import { IsTablet, IsMobile} from '../../utils';
import routePath from '../../routePaths';
import _ from 'lodash';
import AssetService from '../../../../services/api/assetsService';

export const ShareContext = createContext();

export const ShareStateProvider =  ({children, path}) => {
    const defaultBreadcrumb = {
        title: "",
        breadcrumb: [
            {
                name: "Collections",
                url: "#",
                active: false
            }
        ]
    };

    const defaultBookmarkBreadcrumb = {
        title: "",
        breadcrumb: [
            {
                name: "Bookmarks",
                url: "#",
                active: false
            }
        ]
    };

    // State
    const [collectionUuid, setCollectionUuid] = useState("");
    const [bookmarkUuid, setBookmarkUuid] = useState("");
    const [breadcrumb, setBreadcrumb] = useState(defaultBreadcrumb);
    const [bookmarkBreadcrumb, setBookmarkBreadcrumb] = useState(defaultBookmarkBreadcrumb);
    const [menuTabKey, setMenuTabKey] = useState(path);
    const [selectedOption, setSelectedOption] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isImageSearch, setIsImageSearch] = useState(false);
    const [profileImgUrl, setProfileImgUrl] = useState(null);
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const [inputValue, setInputValue] = useState(searchKeyword);
    const [userData, setUserData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [view, setView] = useState('grid');
    const [isImageSearchHeaderModalOpen, setIsImageSearchHeaderModalOpen] = useState(false);
    const [isImageSearchModalOpen, setIsImageSearchModalOpen] = useState(false);
    const [collectionCard, setCollectionCard] = useState([]);

    // Ref
    const prevMenuTabKey = useRef(menuTabKey);

    // Explore
    const [AllAssets, setAllAssets] = useState(null);
    const [displayItems, setDisplayItems] = useState(null);

    //Search
    const [allSearchItems, setAllSearchItems] = useState(null);
    const [displaySearchItems, setDisplaySearchItems] = useState([]);
    const [imageSearchUrl, setImageSearchUrl] = useState("");
    const [imageSearchLabels, setImageSearchLabels] = useState([]);

    // My Assets
    const [displayMyAssetItems, setDisplayMyAssetItems] = useState([]);
    const [checkedMyAssets, setCheckedMyAssets] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [filterSearchData, setFilterSearchData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeType, setActiveType] = useState('all');

    // Collection
    const [allCollectionItems, setAllCollectionItems] = useState(null);
    const [displayCollectionItems, setDisplayCollectionItems] = useState(null);

    // Bookmark
    const [bookmarkTotalPages, setBookmarkTotalPages] = useState(0);
    const [allBookmark, setAllBookmark] = useState([]);
    const [allBookmarkAssets, setAllBookmarkAssets] = useState([]);
    const [displayBookmarkAssets, setDisplayBookmarkAssets] = useState([]);
    const [selectedAssetUuid, setSelectedAssetUuid] = useState(null);
    const [newCreatedBookmark, setNewCreatedBookmark] = useState(null);
    const [selectedBookmarkName, setSelectedBookmarkName] = useState([]);
    const [deselectedBookmarkName, setDeselectedBookmarkName] = useState([]);
    const [editBookmarkError, setEditBookmarkError] = useState('');
    const [nextBookmarkToken, setNextBookmarkToken] = useState(null);
    const [isCreateSelected, setIsCreateSelected] = useState(false);
    const [createBookmarkLoading, setCreateBookmarkLoading] = useState(false);

    // Users
    const [userListExpandedRows, setUserListExpandedRows] = useState([]);
    const [userRequestExpandedRowKeys, setUserRequestExpandedRowKeys] = useState([]);
    const [selectAllUserList, setSelectAllUserList] = useState(false);
    const [selectedUserListKeys, setSelectedUserListKeys] = useState([]);
    const [selectAllUserListCount, setSelectAllUserListCount] = useState(0);
    const [selectAllRequestChecked, setSelectAllRequestChecked] = useState(false);
    const [checkedRequestUser, setCheckedRequestUser] = useState([]);
    const [tabKey, setTabKey] = useState("pending");

    // User Pending Data
    const [dropdownMenuCheckedRole, setDropdownMenuCheckedRole] = useState({});
    const [tablePendingData, setTablePendingData] = useState([]);
    const [tableFilterPendingData, setTableFilterPendingData] = useState([]);

    // Filter
    const [isFilter, setIsFilter] = useState(false);
    const [type, setType] = useState('');
    const [sort, setSortBy] = useState(1);
    const [numFilters, setNumFilters] = useState(0);
    const [orientationVal, setOrientationVal] = useState([]);
    const [filters, setFilters] = useState({
        types: type,
        publishedDates: [null, null],
        mmodifiedDates: [null, null],
        maxFileSize: null,
        minFileSize: null,
        sort: sort,
        orientation: []
    });
    const [displayFilters, setDisplayFilters] = useState(false);

    // Profile
    const [isProfileEditing, setIsProfileEditing] = useState(false);
    const [isRequestFilter, setIsRequestFilter] = useState(false);
    const [openRequestFilter, setOpenRequestFilter] = useState(false);
    const [openUserListFilter, setOpenUserListFilter] = useState(false);
    const [showProfileChangePassword, setShowProfileChangePassword] = useState(true);

    // Audit Trail
    const categoryType = [
        { id: 1, type: "account" },
        { id: 2, type: "asset" },
        { id: 3, type: "collection" },
        { id: 4, type: "plan" },
        { id: 5, type: "user" },
    ];
    const [selectedCategory, setSelectedCategory] = useState(categoryType);
    const [applyCategory, setApplyCategory] = useState(categoryType);
    const [filteredCategoryData, setFilteredCategoryData] = useState();
    const [days, setDays] = useState(30);
    const [auditSelectedOption, setAuditSelectedOption] = useState(1);
    const [auditExpandedRowKeys, setAuditExpandedRowKeys] = useState([]);

    // Loading
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [fetching, setIsFetching] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    // Mobile
    const [sortMobile, setSortByMobile] = useState(1);
    const [prevSortMobile, setPrevSortByMobile] = useState(1);
    const [isMobileModalTypeOpen, setIsMobileModalTypeOpen] = useState(false);
    const [isMobileModalFilterOpen, setIsMobileModalFilterOpen] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [mobileView, setMobileView] = useState(true);
    const [mobileViewApprove, setMobileViewApprove] = useState(false);
    const [showViewApprove, setShowViewApprove] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
    const isMobile = IsMobile();
    //Check isTablet
    const isTablet = IsTablet();

    // Assets Tag File
    const [assetTagFiles, setAssetTagFiles] = useState([]);

    //File Size
    const [minFileSize, setMinFileSize] =  useState(0);
    const [maxFileSize, setMaxFileSize] = useState(0);

    //Publishd Date
    const [pdFrom, pdFromOnChange] = useState(null);
    const [pdTo, pdToOnChange] = useState(null);
    const [pd, setPd] = useState(null);

    //Modified Date
    const [mdFrom, mdFromOnChange] = useState(null);
    const [mdTo, mdToOnChange] = useState(null);
    const [md, setMd] = useState(null);
      
    // Mobile Navigation
    const [mobileNavigationMenu, setMobileNavigationMenu] = useState(false);

    const currentLocation = useLocation();
    const currentPath = currentLocation.pathname;
    const navigate = useNavigate();
    const pathPrefix = "/";
    const tablePageSize = 10;
    const MAX_PAGE_SIZE = 9999;
    const PAGE_SIZE = 12;

    // Account Context
    const { filterCognitoUsers } = useContext(AccountContext);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);

    // Assets Storage
    const [isStorageMax, setIsStorageMax] = useState(false);
    const [remainingSize, setRemainingSize] = useState("0 Bytes");
    const [storageSize, setStorageSize] = useState("0 Bytes");
    const [storageSizePercentage, setStorageSizePercentage] = useState("0%");
    const [maxStorageSize, setMaxStorageSize] = useState("0 Bytes");

    // Reset State Checker
    const [resetState, setResetState] = useState(false);

    // Plan Paid Plan
    const [planPaidPlan, setPlanPaidPlan] = useState(null);
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
    const [attachPaymentMethodError, setAttachPaymentMethodError] = useState(false);

    // Plan Free Trial
    const [planFreeTrial, setPlanFreeTrial] = useState(null);

    // Invoices
    const [invoices, setInvoices] = useState([]);

    //Payment Methods
    const [paymentMethods, setPaymentMethods] = useState(null);

    // Stripe Product Plan
    const [productPlan, setProductPlan] = useState(null);

    // User Request
    const [userRequestPending, setUserRequestPending] = useState([]);
    const [userRequestApproved, setUserRequestApproved] = useState([]);
    const [userRequestRejected, setUserRequestRejected] = useState([]);

    //Notification
    const [showNotification, setShowNotification] = useState(false);
    const [showEmptyNotif, setShowEmptyNotif] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notifToken, setNotifToken] = useState("");
    const [stopGetNotif, setStopGetNotif] = useState(false);
    const [newNotif, setNewNotif] = useState([]);
    const [earlierNotif, setEarlierNotif] = useState([]);
    const [notifToRead, setNotifToRead] = useState({uuids: []});
    const [notifLoading, setNotifLoading] = useState(true);
    
    // Assets Token
    const [assetsToken, setAssetsToken] = useState('');

    const handleMobileModalOpen = () => {
        setIsMobileModalOpen(true);
    }

    const handleMobileModalClose = () => {
        setIsMobileModalOpen(false);
    }

    const resetDefaultState = () => {
        setResetState(!resetState);
        setView("grid");
        setIsFilter(false);
        // setType('');
        // setSortBy(1);
        // setNumFilters(0);
        // setMinFileSize(0);
        // setMaxFileSize(0);
        // pdFromOnChange(null);
        // pdToOnChange(null);
        // setPd(null);
        setLoading(true);
        setIsFetching(false);
        setFilters({
            types: type,
            publishedDates: [null, null],
            mmodifiedDates: [null, null],
            maxFileSize: null,
            minFileSize: null,
            sort: sort,
            orientation: []
        });
        // setDisplayFilters(false);

        if(isTablet) {
            setSortByMobile(1);
            setPrevSortByMobile(1);
            setIsMobileModalTypeOpen(false);
            setIsMobileModalFilterOpen(false);
            setFilterState(false);
            setIsMobileModalOpen(false);
            setMobileView(true);
            document.querySelector("html").style = '';
        }
    };

    const filterAssetsApi = async (filterAssets, uuid) => {
        const response = await AssetService.filterAssets(filterAssets, uuid ? uuid : null);

        return response;
    };

    const filterTenantAssetsApi = async (filterAssets , uuid) => {
        let response;
        let allTenantAssets = [];
        // do {
        response = await AssetService.filterAssets(filterAssets, uuid ? uuid : null);
        allTenantAssets.push(...response.Items); // merge all arriving assets

        if (response.NextToken) {
            setAssetsToken(response.NextToken);
            // filterAssets.token = response.NextToken
        } else {
            setAssetsToken("");
        }

        // } while(response.NextToken);
        return allTenantAssets;
    };

    const clearFilterInformation = () => {
        setMinFileSize(0);
        setMaxFileSize(0);
        pdFromOnChange(null);
        pdToOnChange(null);
        setPd(null);
    };

    const formatCount = (count) => {
        if (count >= 1000000) {
            const millions = Math.floor(count / 1000000);
            const remainder = Math.ceil((count % 1000000) / 100000) * 100;

            if (remainder === 1000) {
                return `${millions}M`;
            } else {
                return `${millions}.${remainder / 100}M`;
            }
        } else if (count >= 1000) {
            const roundedCount = Math.ceil(count / 100) * 100;
            return (roundedCount === 1000) ? '1k++' : `${roundedCount / 1000}k++`;
        }

        return count.toString();
    };

    const getAssetCount = (type, collectionUuid, searchResult, bookmarkUuid) => {
        if (!type) {
            return formatCount(_.filter(collectionUuid ? allCollectionItems : (searchResult || isImageSearch ? allSearchItems : (bookmarkUuid? allBookmarkAssets : AllAssets)), (item) => {
                return item.overallStatus === 'COMPLETED';
            }).length);
        }
        let filteredItems = _.filter(collectionUuid ? allCollectionItems : (searchResult || isImageSearch ? allSearchItems : (bookmarkUuid? allBookmarkAssets : AllAssets)), (item) => {
            return item.type === type && item.overallStatus === 'COMPLETED';
        });
        return formatCount(filteredItems.length);
    };

    const updateStorage =  (size, percentage, remaining) => {
        setRemainingSize(remaining);
        setStorageSize(size);
        setStorageSizePercentage(percentage);
    };

    const listFilterTenantUser = async (searchfullname, rangePickerValueFrom, rangePickerValueTo, userGroupFilterValues, roleFilterValues) => {
        try {
            const data = await filterCognitoUsers(searchfullname, rangePickerValueFrom, rangePickerValueTo, userGroupFilterValues, roleFilterValues);

            const userDataWithKeys = data.users.map((user, index) => {
                return {
                    ...user,
                    key: user.uuid,
                };
            });

            setUserData(userDataWithKeys);
            setTotalUsers(userDataWithKeys.length);

            return;
        } catch(error) {
            throw new Error(`List User Fail: ${error}`);
        }
    };

    const updateMenuTabKey = (update) => {
        setMenuTabKey(update);
        // setType('');
    };

    // Mobile Modal
    const handleMobileModalTypeOpen = () => {
        setIsMobileModalTypeOpen(true);
        document.querySelector("html").style.overflowY = 'hidden'; // Disable scrolling
    };

    const handleMobileModalTypeClose = () => {
        setIsMobileModalTypeOpen(false);
        setDisplayFilters(false);
        setFilterState(false);
        document.querySelector("html").style = ''; // Enable scrolling
    };

    const handleMobileModalFilterOpen = () => {
        setIsMobileModalFilterOpen(true);
        document.querySelector("html").style.overflowY = 'hidden'; // Disable scrolling
    };

    const handleMobileModalFilterClose = () => {
        setIsMobileModalFilterOpen(false);
        setSortByMobile(prevSortMobile);
        onClickFilters();
        document.querySelector("html").style = ''; // Enable scrolling
    };

    const onClickFilters = () => {
        setDisplayFilters(!displayFilters);
        setFilterState(!filterState);
    }

    // Filter Assets Function
    const filterAssetsFunc = async (filterType, collectionUuid, searchResult, bookmarkUuid) => {
        const minFileSizeInBytes = minFileSize != null ? minFileSize * 1048576 : null;
        const maxFileSizeInBytes = maxFileSize != null ? maxFileSize * 1048576 : null;

        const filterAssets = {
            group: tenantUuid,
            pageSize: 1000,
            types: filterType?.changeType ? filterType?.type : type,
            publishedDates: filterType?.clearFilter ? filterType?.publishedDates : [pdFrom, pdTo],
            modifiedDates: filterType?.clearFilter ? filterType?.mmodifiedDates : [null, null],
            minFileSize: filterType?.clearFilter ? filterType?.minFileSize : minFileSizeInBytes,
            maxFileSize: filterType?.clearFilter ? filterType?.maxFileSize : maxFileSizeInBytes,
            sort: isTablet ? filterType?.clearFilter ? 1 : sortMobile : sort,
            orientation: filterType?.clearFilter ? [] : orientationVal
        };

        setFilters(filterAssets);

        try {
            let assets;
            if (searchResult || isImageSearch) {
                assets = allSearchItems;
            } else if (bookmarkUuid) {
                // retrieve the specific bookmark to get collection of assets
                const query = {
                    uuid: bookmarkUuid,
                    type: "bookmark",
                    pageSize: MAX_PAGE_SIZE,
                    userUuid: userUuid
                }
                const specificBookmark = await AssetService.getSetBookmark(query, tenantUuid);
                
                assets = specificBookmark.response[0].assets;
                setAllBookmarkAssets(assets);
            } else {
                assets = await filterTenantAssetsApi(filterAssets, collectionUuid || null);
                if (collectionUuid) {
                    setAllCollectionItems(assets);
                } else {
                    setAllAssets(assets);
                }
            }
            
            const pageData = getData(assets, filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setTotalPages(pageData.totalPages);

            if (searchResult || isImageSearch) {
                setDisplaySearchItems(pageData.data);
            } else if (collectionUuid) {
                setDisplayCollectionItems(pageData.data);
            } else if (bookmarkUuid) {
                setDisplayBookmarkAssets(pageData.data);
            } else {
                setDisplayItems(pageData.data);
            }
        } catch (error) {
            console.error("Error filtering assets:", error);
        } finally {
            setIsFetching(false);
            setLoading(false);
        }
    };

    // Apply Filters
    const applyFilters = (filterType, collectionUuid, searchResult, bookmarkUuid) => { 
        setLoading(true);
        setIsFetching(true);
        if(!filterType?.clearFilter) {
            let numFiltersCount = 0;

            if(pd) {
                numFiltersCount += 1;
            }

            if(minFileSize > 0 || maxFileSize > 0) {
                numFiltersCount += 1;
            }

            if (orientationVal.length > 0) {
                numFiltersCount += 1;
            }

            setNumFilters(numFiltersCount);
        }

        filterAssetsFunc(filterType, collectionUuid, searchResult, bookmarkUuid);

        if(isTablet) {
            setDisplayFilters(false);
            setFilterState(false);
            setIsMobileModalFilterOpen(false);
            setPrevSortByMobile(sortMobile);
            document.querySelector("html").style = '';
        }
    };

    // Clear Filters
    const clearFilters = (collectionUuid, searchResult, bookmarkUuid) => {
        setLoading(true);
        setFilters(null);
        setIsFetching(false);
        setIsFilter(false);
        setNumFilters(0);
        setOrientationVal([]);
        clearFilterInformation();

        if(isTablet) {
            setIsMobileModalFilterOpen(false);
            setDisplayFilters(false);
            setFilterState(false);
            setSortByMobile(1);
            document.querySelector("html").style = '';
        }

        applyFilters({
            clearFilter: true,
            publishedDates: [null, null],
            mmodifiedDates: [null, null],
            maxFileSize: null,
            minFileSize: null,
            sort: isTablet ? 1 : sort,
            orientation: []
        }, collectionUuid, searchResult, bookmarkUuid);
    };

    // Filter Assets Get Data
    const getData = (allItems ,filters, page, pageSize) => {
        let allCompletedItems = _.filter(allItems, item => item.overallStatus === 'COMPLETED');
        let sortedItems = _.orderBy(allCompletedItems, ['timestamp'], [filters.sort === 1 ? 'desc': 'asc']);
        let pg = page || 1,
        pgSize = pageSize || 100,
        offset = (pg - 1) * pgSize,
        filterItem = _.filter(sortedItems, function(item) {
            if (filters.types != null && filters.types !== '' &&
                !_.includes(filters.types.split(','), item.type)) {
                return false;
            }

            let date1 = new Date(filters.publishedDates[0]);
            date1.setHours(0, 0, 1, 0); // Set time to 00:00:01 AM

            let date2 = new Date(filters.publishedDates[1]);
            date2.setHours(23, 59, 59, 0); // Set time to 23:59:59 PM

            if (filters.publishedDates[0] != null &&
                filters.publishedDates[1] != null &&
                !(item.timestamp > date1 &&
                item.timestamp < date2)) {
                return false;
            }

            if (item.overallStatus !== 'COMPLETED') {
                return false;
            }

            if (filters.maxFileSize != null && filters.maxFileSize !== 0 && item.fileSize > filters.maxFileSize) {
                return false;
            }

            if (filters.minFileSize != null && filters.minFileSize !== 0 && item.fileSize < filters.minFileSize) {
                return false;
            }

            if (filters.types === 'image' || filters.types === 'video') {
                const orientation = item.orientation;
                
                // If no specific orientation is selected, show all assets
                if (!filters.orientation || filters.orientation.length === 0) {
                    return true;
                }

                // Ensure filters.orientation is an array before using .includes
                if (Array.isArray(filters.orientation)) {
                    if (filters.orientation.includes(1) && orientation === 'Landscape') return true;
                    if (filters.orientation.includes(2) && orientation === 'Portrait') return true;
                    if (filters.orientation.includes(3) && orientation === 'Square') return true;
                    
                    // Exclude asset if orientation does not match any of the allowed orientations
                    return false;
                }
            }

            return true;
        }),
        pagedItems = _.drop(filterItem, offset).slice(0, pgSize);
        return {
            page: pg,
            pageSize: pgSize,
            total: allCompletedItems,
            totalPages: Math.ceil(allCompletedItems?.length / pgSize),
            data: pagedItems
        };
    };

    const filterTypeFunc = (filters, collectionUuid, searchResult, bookmarkUuid) => {
        let filterAssets = {
            pageSize: PAGE_SIZE,
            types: filters.types,
            publishedDates: filters.publishedDates,
            modifiedDates: filters.modifiedDates,
            maxFileSize: filters.maxFileSize,
            minFileSize: filters.minFileSize,
            sort: filters.sort,
            orientation: filters.orientation
        };

        if(searchResult) {
            setFilters(filterAssets);
            let pageData = getData(allSearchItems, filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setTotalPages(pageData.totalPages);
            setDisplaySearchItems(pageData.data);
            setIsFetching(false);
            setLoading(false);

            return;
        }

        if(collectionUuid) {
            setFilters(filterAssets);
            let pageData = getData(allCollectionItems, filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setTotalPages(pageData.totalPages);
            setDisplayCollectionItems(pageData.data);
            setIsFetching(false);
            setLoading(false);

            return;
        };

        if(bookmarkUuid) {
            setFilters(filterAssets);
            let pageData = getData(allBookmarkAssets, filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setTotalPages(pageData.totalPages);
            setDisplayBookmarkAssets(pageData.data);
            setIsFetching(false);
            setLoading(false);

            return;
        };

        setFilters(filterAssets);
        let pageData = getData(AllAssets, filterAssets, 1, PAGE_SIZE);
        setCurrentPage(1);
        setTotalPages(pageData.totalPages);
        setDisplayItems(pageData.data);
        setIsFetching(false);
        setLoading(false);
    };

    const changeType = (e, isFilterBool = false, type, collectionUuid, searchResult, bookmarkUuid) => {
        e.preventDefault();
        setType(type);

        if(isFilterBool) {
            filterTypeFunc({
                types: type,
                publishedDates: filters.publishedDates,
                mmodifiedDates: filters.mmodifiedDates,
                maxFileSize: filters.maxFileSize,
                minFileSize: filters.minFileSize,
                sort: isTablet ? sortMobile : sort,
                orientation: orientationVal
            }, collectionUuid, searchResult, bookmarkUuid);
        } else {
            applyFilters({
                changeType: true,
                type: type,
                orientation: orientationVal
            }, collectionUuid, searchResult, bookmarkUuid);
        }
    };

    //Compare the storeitem and elements for bookmark
    const updateAssets = (element, storeitem) => {
        const newAssetsIndices = element.reduce((acc, elAsset, index) => {
            if (!storeitem.some(stAsset => JSON.stringify(elAsset) === JSON.stringify(stAsset))) {
                acc.push(index); // Store the index of the new asset
            }
            return acc;
        }, []);
    
        const removedAssetsIndices = storeitem.reduce((acc, stAsset, index) => {
            if (!element.some(elAsset => JSON.stringify(stAsset) === JSON.stringify(elAsset))) {
                acc.push(index); // Store the index of the removed asset
            }
            return acc;
        }, []);
    
        return { newAssetsIndices, removedAssetsIndices };
    };

    useEffect(() => {
        if(menuTabKey !== currentPath.slice(1)) {
            navigate(pathPrefix + menuTabKey, { replace: true });

            if(menuTabKey !== routePath.SEARCH) {
                setSearchKeyword("");
                setInputValue("");
                setShowSearch(false);
                setSearchSubmitted(false);
            }

            if(menuTabKey !== routePath.EXPLORE || menuTabKey !== routePath.SETTINGS  || menuTabKey !== routePath.COLLECTIONS || menuTabKey !== routePath.ASSET) {                    
                setType('');
                setSortBy(1);
                setNumFilters(0);
                setMinFileSize(0);
                setMaxFileSize(0);
                setDisplayFilters(false);
                pdFromOnChange(null);
                pdToOnChange(null);        
                setPd(null);
                setOrientationVal([]);
            }
        }

        if (!menuTabKey.includes(routePath.ASSET)) {
            prevMenuTabKey.current = menuTabKey; // Update with new value if 'asset' is not included
        }
    },[menuTabKey]);
    
    return (
        <ShareContext.Provider value={{
            userRequestPending,
            setUserRequestPending,
            userRequestApproved,
            setUserRequestApproved,
            userRequestRejected,
            setUserRequestRejected,
            productPlan,
            setProductPlan,
            paymentMethods,
            setPaymentMethods,
            invoices,
            setInvoices,
            planFreeTrial,
            setPlanFreeTrial,
            planPaidPlan,
            setPlanPaidPlan,
            assetTagFiles,
            setAssetTagFiles,
            isSearch,
            setIsSearch,
            isImageSearch,
            setIsImageSearch,
            setIsMobileModalFilterOpen,
            handleMobileModalTypeOpen,
            handleMobileModalFilterOpen,
            isMobileModalOpen,
            handleMobileModalOpen,
            handleMobileModalClose,
            filterAssetsApi,
            resetState,
            resetDefaultState,
            MAX_PAGE_SIZE,
            getData,
            onClickFilters,
            handleMobileModalTypeClose,
            handleMobileModalFilterClose,
            mobileView,
            setMobileView,
            filterAssetsFunc,
            changeType,
            getAssetCount,
            displayFilters,
            setDisplayFilters,
            applyFilters,
            clearFilters,
            view,
            setView,
            filterState,
            setFilterState,
            isMobileModalTypeOpen,
            isMobileModalFilterOpen,
            sortMobile,
            setSortByMobile,
            prevSortMobile,
            setPrevSortByMobile,
            filters,
            setFilters,
            currentPage,
            setCurrentPage,
            totalPages,
            setTotalPages,
            loading,
            setLoading,
            fetching,
            setIsFetching,
            mobileNavigationMenu,
            setMobileNavigationMenu,
            allCollectionItems,
            setAllCollectionItems,
            allSearchItems,
            setAllSearchItems,
            numFilters,
            setNumFilters,
            pdFrom,
            pdFromOnChange,
            pdTo,
            pdToOnChange,
            pd,
            setPd,
            minFileSize,
            setMinFileSize,
            maxFileSize,
            setMaxFileSize,
            type,
            setType,
            sort,
            setSortBy,
            isFilter,
            setIsFilter,
            setStorageSize,
            remainingSize,
            updateStorage,
            setIsStorageMax,
            setMaxStorageSize,
            isStorageMax,
            storageSize,
            storageSizePercentage,
            maxStorageSize,
            PAGE_SIZE,
            AllAssets,
            setAllAssets,
            displayItems,
            setDisplayItems,
            displaySearchItems,
            setDisplaySearchItems,
            displayMyAssetItems,
            setDisplayMyAssetItems,
            checkedMyAssets,
            setCheckedMyAssets,
            displayCollectionItems,
            setDisplayCollectionItems,
            updateMenuTabKey,
            inputValue,
            setInputValue,
            searchSubmitted,
            setSearchSubmitted,
            defaultBreadcrumb,
            breadcrumb,
            setBreadcrumb,
            collectionUuid,
            setCollectionUuid,
            bookmarkUuid,
            setBookmarkUuid,
            menuTabKey,
            selectedOption,
            setSelectedOption,
            searchKeyword,
            setSearchKeyword,
            showSearch,
            setShowSearch,
            profileImgUrl,
            setProfileImgUrl,
            userData,
            setUserData,
            totalUsers,
            setTotalUsers,
            tablePageSize,
            listFilterTenantUser,
            userListExpandedRows,
            setUserListExpandedRows,
            userRequestExpandedRowKeys,
            setUserRequestExpandedRowKeys,
            isProfileEditing,
            setIsProfileEditing,
            selectAllUserList,
            setSelectAllUserList,
            selectedUserListKeys,
            setSelectedUserListKeys,
            selectAllRequestChecked,
            setSelectAllRequestChecked,
            checkedRequestUser,
            setCheckedRequestUser,
            selectAllUserListCount,
            setSelectAllUserListCount,
            isRequestFilter,
            setIsRequestFilter,
            openRequestFilter,
            setOpenRequestFilter,
            openUserListFilter,
            setOpenUserListFilter,
            showProfileChangePassword,
            setShowProfileChangePassword,
            isMobile,
            isTablet,
            mobileViewApprove,
            setMobileViewApprove,
            showApprove,
            setShowApprove,
            showViewApprove,
            setShowViewApprove,
            isLoading,
            setIsLoading,
            tabKey,
            setTabKey,
            originalData,
            setOriginalData,
            filterSearchData,
            setFilterSearchData,
            searchQuery,
            setSearchQuery,
            activeType,
            setActiveType,
            tablePendingData,
            setTablePendingData,
            tableFilterPendingData,
            setTableFilterPendingData,
            dropdownMenuCheckedRole,
            setDropdownMenuCheckedRole,
            categoryType,
            selectedCategory,
            setSelectedCategory,
            applyCategory,
            setApplyCategory,
            filteredCategoryData,
            setFilteredCategoryData,
            days,
            setDays,
            auditSelectedOption,
            setAuditSelectedOption,
            auditExpandedRowKeys,
            setAuditExpandedRowKeys,
            imageSearchUrl,
            setImageSearchUrl,
            imageSearchLabels,
            setImageSearchLabels,
            paymentMethodLoading,
            setPaymentMethodLoading,
            attachPaymentMethodError,
            setAttachPaymentMethodError,
            isImageSearchHeaderModalOpen,
            setIsImageSearchHeaderModalOpen,
            isImageSearchModalOpen,
            setIsImageSearchModalOpen,
            showNotification,
            setShowNotification,
            showEmptyNotif,
            setShowEmptyNotif,
            unreadCount,
            setUnreadCount,
            notifToken,
            setNotifToken,
            stopGetNotif,
            setStopGetNotif,
            newNotif,
            setNewNotif,
            earlierNotif,
            setEarlierNotif,
            notifToRead,
            setNotifToRead,
            notifLoading,
            setNotifLoading,
            setResetState,
            orientationVal,
            setOrientationVal,
            prevMenuTabKey,
            assetsToken,
            setAssetsToken,
            allBookmark,
            setAllBookmark,
            selectedBookmarkName,
            setSelectedBookmarkName,
            selectedAssetUuid,
            setSelectedAssetUuid,
            deselectedBookmarkName,
            setDeselectedBookmarkName,
            newCreatedBookmark,
            setNewCreatedBookmark,
            bookmarkBreadcrumb,
            setBookmarkBreadcrumb,
            defaultBookmarkBreadcrumb,
            allBookmarkAssets,
            setAllBookmarkAssets,
            setDisplayBookmarkAssets,
            displayBookmarkAssets,
            editBookmarkError,
            setEditBookmarkError,
            nextBookmarkToken,
            setNextBookmarkToken,
            isCreateSelected,
            setIsCreateSelected,
            bookmarkTotalPages,
            setBookmarkTotalPages,
            createBookmarkLoading,
            setCreateBookmarkLoading,
            updateAssets,
            collectionCard,
            setCollectionCard
        }}>
            {children}
        </ShareContext.Provider>
    );
};