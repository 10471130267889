import { spanishGray, iconSize } from '../../shared/utils';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const WarningIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <WarningRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default WarningIcon;