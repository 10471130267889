import { spanishGray, iconSize } from '../../shared/utils';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import Replay10RoundedIcon from '@mui/icons-material/Replay10Rounded';

const ReportIcon = ({ variant, className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;
    const variantVal = variant || "";

    return (
        <>
            {
                variantVal === "10"
                ?
                    <Replay10RoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <ReplayRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default ReportIcon;