import { useState, useContext, useEffect, useRef } from "react";
import { Nav } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../components/shared/context/account-provider";
import { generateRandomPassword } from '../../components/shared/utils';
import { IsSLG } from "../../components/shared/utils";
import Localization from '../../components/shared/localization';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import path from "../../components/shared/routePaths";
import AlertNotification from "../../components/shared/components/alert-notification";

const ForgotPassword = (props) => {
  const isSLG = IsSLG();
  const { forgotpassword, confirmpassword, changepassword, authenticate,
      logout } = useContext(AccountContext);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [showReenterPassword, setShowReenterPassword] = useState(true);
  const [processState, setProcessState] = useState("1");
  const [username, setUserName] = useState("");
  const [eightCharValid, setEightCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [reenternMessage, setReenterMessage] = useState("");
  const [newpasswordInputDisabled, setNewpasswordInputDisabled] = useState(true);
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [alertNotification, setAlertNotification] = useState({
    show: false,
    variant: "",
    message: ""
  });
  const sendVerificationCodeRef = useRef(null);
  const resetPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const reenterPasswordRef = useRef(null);
  const processChangePasswordRef = useRef(null);
  const navigate = useNavigate();
  const pathPrefix = "/";

  const handleGotoLoginClick = () => {
    localStorage.removeItem('forgotPassword');
  };
  const handleSendVerificationCodeClick = async () => {
    const userInput = document.getElementById("userInput");
    const username = userInput.value;
    setUserName(username);

    try {
      const data = await forgotpassword(username);
      if (data) {
        setAlertNotification({
          show: false
        });
        localStorage.setItem("forgotPassword", true);
        setTemporaryPassword(generateRandomPassword());
        setProcessState("2");
      }
    } catch (err) {
      if (err.name === 'InvalidParameterException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ForgotPasswordRespose.InvalidParameterException
        });
      } else if (err.name === 'UserNotFoundException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ForgotPasswordRespose.UserNotFoundException
        });
      } else if (err.name === 'LimitExceededException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ForgotPasswordRespose.LimitExceededException
        });
      } else {
        setAlertNotification({
          show: true,
          variant: "error",
          message: err.message
        });
      }
    }
  };
  const handleNextProcessClick = async () => {
    setProcessState("3");
    localStorage.setItem("forgotPassword", true);
    setButtonDisabled(true);

  };
  const handleResetPasswordClick = async () => {
    //setProcessState("4");
    //localStorage.setItem("forgotPassword", true);
    const verificationcode = document.getElementById("verificationCode");
    //setVerificationCode(verificationcode.value);
    setButtonDisabled(true);
    setReenterMessage("");
    //setTemporaryPassword(generateRandomPassword());

    try {
      const data = await confirmpassword(username, verificationcode.value, temporaryPassword);
      if (data) {
        setAlertNotification({
          show: false
        });
        localStorage.setItem("forgotPassword", true);
        setProcessState("4");
      }
    } catch (err) {
      if (err.name === 'LimitExceededException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ForgotPasswordRespose.LimitExceededException
        });
      } else {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ForgotPasswordRespose.VerificationCodeInvalidMeassge
        });
      }
    }
  };
  const handleProcessChangePasswordClick = async () => {
    const newpasswordInput = newPasswordRef.current;
    setButtonDisabled(true);
    try {
      const data = await changepassword( username, temporaryPassword, newpasswordInput.value, true);
      if (data) {
        setAlertNotification({
          show: false
        });
        localStorage.setItem("forgotPassword", true);
        setProcessState("5");
        setButtonDisabled(false);
      }
      logout(false);
    } catch (err) {
        if (err.name === 'NotAuthorizedException') {
          setAlertNotification({
            show: true,
            variant: "error",
            message: Localization.Profile.ChangePasswordRespose.NotAuthorizedExceptionForChangePassword
          });
        } else if (err.name === 'LimitExceededException') {
          setAlertNotification({
            show: true,
            variant: "error",
            message: Localization.Profile.ChangePasswordRespose.LimitExceededException
          });
        } else if (err.name === 'InvalidParameterException') {
          setAlertNotification({
            show: true,
            variant: "error",
            message: Localization.Profile.ChangePasswordRespose.InvalidParameterException
          });
        } else {
          setAlertNotification({
            show: true,
            variant: "error",
            message: err.message
          });
        }
    }
  };
  const handleDoneClick = () => {
    localStorage.clear();
    navigate(pathPrefix + path.LOGIN, { replace: true });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length === 0) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };
  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handlNewPasswordChange = (event) => {
    const reenterPasswordInput = reenterPasswordRef.current;
    const inputValue = event.target.value;
    setEightCharValid(inputValue.length > 7);
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    setLowercaseValid(lowercaseRegex.test(inputValue));
    setUppercaseValid(uppercaseRegex.test(inputValue));

    setReenterMessage('');
    setButtonDisabled(true);

    if (reenterPasswordInput.value === inputValue) {
      setButtonDisabled(false);
      setReenterMessage('');
    } else {
      if(reenterPasswordInput.value) {
        setButtonDisabled(true);
        setReenterMessage('Your passwords do not match');
      }
    }
  };

  const handlReenterPasswordChange = (event) => {
    const newpasswordInput = newPasswordRef.current;
    const inputValue = event.target.value;
    if (newpasswordInput.value === inputValue) {
      setButtonDisabled(false);
      setReenterMessage('');
    } else {
      setButtonDisabled(true);
      setReenterMessage('Your passwords do not match');
    }
  };

  const handleSendVerificationCodeKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      sendVerificationCodeRef.current.click();
    }
  };

  const handleResetPasswordKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      resetPasswordRef.current.click();
    }
  };

  const handleNewPasswordKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      reenterPasswordRef.current.focus();
    }
  };

  const handleProcessChangePasswordKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      processChangePasswordRef.current.click();
    }
  };

  const handleResendCodeClick = async (event) => {
    try {
      const data = await forgotpassword(username);
      if (data) {
        setAlertNotification({
          show: true,
          variant: "success",
          message: Localization.ForgotPassword.ResendCodeResponse.Successful
        });
        setProcessState("3");
      }
    } catch (err) {
      if (err.name === 'InvalidParameterException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ConfirmPassword.InvalidParameterException
        });
      } else if (err.name === 'UserNotFoundException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ConfirmPassword.UserNotFoundException
        });
      } else if (err.name === 'LimitExceededException') {
        setAlertNotification({
          show: true,
          variant: "error",
          message: Localization.ForgotPassword.ConfirmPassword.LimitExceededException
        });
      } else {
        setAlertNotification({
          show: true,
          variant: "error",
          message: err.message
        });
      }
    }
  };

  useEffect(() => {
    if (eightCharValid && lowercaseValid && uppercaseValid) {
      setNewpasswordInputDisabled(false);
    } else {
      setNewpasswordInputDisabled(true);
      setReenterMessage('');
    }
  }, [eightCharValid, lowercaseValid, uppercaseValid]);

  return (
    <div className="forgotpassword-wrapper">
      <div className={isSLG ? "forgotpassword-left-cont col-12" : "forgotpassword-left-cont col-5"}>
        <div className="forgotpassword-form">
          <div className="forgotpassword-header d-flex flex-row">
            <a href={"/" + path.LOGIN}  className="d-flex flex-row">
              <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo" />
            </a>
          </div>
          <div className="message-container">
            {
              alertNotification.show &&
                <AlertNotification variant={alertNotification.variant} content={alertNotification.message} />
            }
          </div>
          {processState === "1" ? (
            <>
            <h1 className="title-text">Forgot Password?</h1>
            <p className="content-text forgotpassword-message">{Localization.ForgotPassword.ForgotPasswordMessage}</p>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  id="userInput"
                  className="form-control"
                  //pattern="[a-zA-Z0-9._%+-]+"
                  type="text"
                  placeholder="Enter username"
                  autoFocus="autofocus"
                  onChange={handleInputChange}
                  onKeyDown={handleSendVerificationCodeKeyPress}
                ></input>
              </div>
            </div><div className="button-group">
                <button className="btn btn-primary"
                  onClick={handleSendVerificationCodeClick}
                  disabled={buttonDisabled}
                  ref={sendVerificationCodeRef}
                >Reset Password</button>
              </div><Nav className="forgot-password-cont">
                Return to&nbsp;<Nav.Link className="font-bold" href="/" onClick={handleGotoLoginClick}>Log In</Nav.Link>
              </Nav></>
          ) : ""}
          {processState === "2" ? (
            <>
              <div className="form-container">
              <h1 className="title-text">Check your email</h1><p className="content-text">If you have an approved elko account, an email will be sent to the email address linked to your username. Please check your email for more information. Make sure to check your spam or junk folder as well.</p>
              <p className="mb-0">Emails will not be sent to accounts pending Admin’s approval.</p>
              </div><div className="button-group">
                <button className="btn btn-primary" onClick={handleNextProcessClick}>Next</button>
              </div>
            </>
          ) : ""}
          {processState === "3" ? (
            <>
              <h1 className="title-text">Verification</h1><p className="content-text verification">{Localization.ForgotPassword.VerificationMeassge}</p>
              <div className="form-container">
                <div className="form-group">
                  <Nav className="resend-code-cont d-flex justify-content-end">
                    <Nav.Link className="resendcode-link" onClick={handleResendCodeClick}>Resend code</Nav.Link>
                  </Nav>
                </div>
                <div className="form-group">
                  <label htmlFor="username">Verification code</label>
                  <input
                    id="verificationCode"
                    className="form-control"
                    type="text"
                    placeholder="Enter code"
                    onChange={handleInputChange}
                    onKeyDown={handleResetPasswordKeyPress}
                  ></input>
                </div>
                <div className="button-group PaddingTop48 verification">
                  <button className="btn btn-primary"
                    onClick={handleResetPasswordClick}
                    disabled={buttonDisabled}
                    ref={resetPasswordRef}
                  >Next</button>
                </div>
                <p className="PaddingTop48 verification">Have issues verifying? Contact <a target="_blank" className="link-bold" rel="noreferrer" href="https://hello.elko.digital/contact">elko support</a></p>
              </div>
            </>
          ) : ""}
          {processState === "4" ? (
            <><h1 className="title-text">Reset Password</h1>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="newpassword">New password</label>
                  <input
                    id="newpassword"
                    className="form-control"
                    type={!showPassword ? 'text' : 'password'}
                    placeholder="Enter new password"
                    onChange={handlNewPasswordChange}
                    onCopy={handleCopy}
                    onKeyDown={handleNewPasswordKeyPress}
                    ref={newPasswordRef}
                  ></input>
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-password-icon"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </button>
                </div>
                <div className="form-group new-password-criteria">
                  <p className={`eightchar ${eightCharValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> &nbsp;At least 8 characters</p>
                  <p className={`lowercase ${lowercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> &nbsp;Include at least one lowercase</p>
                  <p className={`uppercase ${uppercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> &nbsp;Include at least one uppercase</p>
                </div>
                <div className="form-group">
                  <label htmlFor="reenterpassword" className={`${reenternMessage ? 'warning-label' : ''}`}>Re-enter new password</label>
                  <input
                    id="reenterpassword"
                    className={`form-control ${reenternMessage ? 'warning-input' : ''}`}
                    type={!showReenterPassword ? 'text' : 'password'}
                    placeholder="Re-enter new password"
                    onChange={handlReenterPasswordChange}
                    onCopy={handleCopy}
                    onKeyDown={handleProcessChangePasswordKeyPress}
                    disabled={newpasswordInputDisabled}
                    ref={reenterPasswordRef}
                  ></input>
                  <button
                    type="button"
                    onClick={() => setShowReenterPassword(!showReenterPassword)}
                    className="show-password-icon"
                  >
                    {showReenterPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </button>
                </div>
                <div className="form-group reenterpassword">
                  {reenternMessage ? (
                    <p className="reenterpassword-warning"><WarningRoundedIcon /> {reenternMessage}</p>
                  ) : null}
                </div>
                <div className="button-group">
                  <button className="btn btn-primary"
                    onClick={handleProcessChangePasswordClick}
                    disabled={buttonDisabled}
                    ref={processChangePasswordRef}
                  >Done</button>
                </div>
                <Nav className="forgot-password-cont">
                  Return to&nbsp;<Nav.Link className="font-bold" href="/" onClick={handleGotoLoginClick}>Log In</Nav.Link>
                </Nav>
              </div>
              </>
          ) : ""}
          {processState === "5" ? (
            <>
              <div className="form-container">
                <h1 className="title-text">Successful Password Reset</h1>
                <p className="successful-content-text">{Localization.ForgotPassword.SuccessfulPasswordChangeMessage}</p>
                <div className="button-group">
                  <button className="btn btn-primary" onClick={handleDoneClick} disabled={buttonDisabled}>Done</button>
                </div>
              </div>
            </>
          ) : ""}
        </div>
      </div>

      {
        !isSLG &&
          <div className="forgotpassword-right-cont col-7">
            <img src="/images/Ring.svg" className="ring-icon" alt="ringicon" />
            <svg width="1094" height="661" viewBox="0 0 1094 661" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="-33.9844" y="-261.523" width="1407.35" height="714.133" transform="rotate(8.86 -33.9844 -261.523)" fill="#F5F5F5" />
            </svg>
          </div>
      }
    </div>
  );

};

export default ForgotPassword;