import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadContext } from '../../shared/context/upload-provider';
import { ShareContext } from '../../shared/context/share-state';
import Localization from '../../shared/localization';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import DOMPurify from 'dompurify'
import path from '../../shared/routePaths';
import { IsMobile, IsTablet, IsDesktop } from '../../shared/utils';

const UploadDropZone = () => {
    const pathPrefix = "/";
    const isDesktop = IsDesktop();

    // Assets Storage
    const { isStorageMax,
        storageSize,
        storageSizePercentage,
        maxStorageSize,
        remainingSize,
        isLoading } = useContext(ShareContext);

    // Upload File
    const { uploadFilesData } = useContext(UploadContext);

    const onDrop = files => {
        uploadFilesData(files, remainingSize);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop,
        noClick: isLoading || remainingSize === '0 Bytes',
    });

    const contentToRender = isDesktop ? Localization.Upload.Dropzone : Localization.Upload.MobileDropzone;

    const sanitizedContent = DOMPurify.sanitize(contentToRender);

    return (
        <>
            {isDesktop && <p className='upload-guide-btn'><a className='text-button' href={pathPrefix + path.UPLOADGUIDE} target='_blank'>{Localization.Upload.UploadGuide}</a></p>}
            {
                isStorageMax ?
                    <div className='upload-dropzone-wrapper' style={{cursor: "default"}}>
                        <div className='upload-dropzone-cont'>
                            <div className="upload-dropzone-content">
                                {/* <UploadRoundedIcon /> */}
                                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.MaxStorage)}}></p>
                                <div className='upload-dropzone-storage-space'>
                                    <div className='upload-dropzone-storage-bar'>
                                        <div style={{width: "100%"}}></div>
                                    </div>
                                    <div className='upload-dropzone-storage-space-content'>
                                        {
                                            `Storage space used: ${maxStorageSize} / ${maxStorageSize}`
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className={isDragActive ? 'upload-dropzone-wrapper upload-dropzone-active' : 'upload-dropzone-wrapper'} style={remainingSize === '0 Bytes' ? {cursor: "not-allowed"} : isLoading ? {cursor: "not-allowed"} : {}}>
                        <div {...getRootProps({ className: "dropzone" })} className='upload-dropzone-cont'>
                            <input className="upload-input" {...getInputProps()} type="file" multiple="multiple" accept=".mov,.gif,.pdf,.mp4,.avi,.jpg,.png,.mp3,.wav,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                            <div className="upload-dropzone-content">
                                <UploadRoundedIcon />
                                <p dangerouslySetInnerHTML={{__html: sanitizedContent}}></p>
                                <div className='upload-dropzone-storage-space'>
                                    <div className='upload-dropzone-storage-bar'>
                                        <div style={{width: storageSizePercentage}}></div>
                                    </div>
                                    <div className='upload-dropzone-storage-space-content'>
                                        {
                                            `Storage space used: ${storageSize} / ${maxStorageSize}`
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default UploadDropZone;