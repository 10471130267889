import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ShareContext } from "../../context/share-state";
import path from "../../routePaths";

const CollectionHooks = () => {
    const {
      defaultBreadcrumb,
      setBreadcrumb,
      setCollectionUuid,
      updateMenuTabKey } = useContext(ShareContext);
    const navigate = useNavigate();
    const pathPrefix = "/";

    const resetState = () => {
        setCollectionUuid("");
        setBreadcrumb(defaultBreadcrumb);
        updateMenuTabKey(path.COLLECTIONS);
    };

    const handleClickBreadcrumb = () => {
        navigate(pathPrefix + path.COLLECTIONS, { replace: true });
        resetState();
    };

    const handleClickCollectionCard = async (name, uuid) => {
        resetState();
        navigate(pathPrefix + path.COLLECTIONS + pathPrefix + uuid, { replace: true });
        setBreadcrumb(prevBreadcrumb => ({
            title: name,
            breadcrumb: [
              ...prevBreadcrumb.breadcrumb,
              {
                name: name,
                url: "#",
                active: true
              }
            ]
        }));
        setCollectionUuid(uuid);
        updateMenuTabKey(path.COLLECTIONS + pathPrefix + uuid);
    };

    const updateCollectionSearch = (name, uuid) => {
      resetState();
      setBreadcrumb(() => ({
          title: name,
          breadcrumb: [
            ...defaultBreadcrumb.breadcrumb,
            {
              name: name,
              url: "#",
              active: true
            }
          ]
      }));
      navigate(pathPrefix + path.COLLECTIONS + pathPrefix + uuid, { replace: true });
      setCollectionUuid(uuid);
      updateMenuTabKey(path.COLLECTIONS + pathPrefix + uuid);
    };

    return { handleClickCollectionCard, handleClickBreadcrumb, updateCollectionSearch, resetState };
};

export default CollectionHooks;