import { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Table, Dropdown, Tag } from "antd";
import { formatDateTime } from "../../../shared/utils";
import CustomIcons from "../../../shared/components/custom-icons";
import Localization from "../../../shared/localization";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SpinnerIcon from "../../../icons/spinner";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MobileModal from "../../../shared/components/mobile-modal";
import ViewMobileModal from "../../../shared/components/view-mobile-modal";
const UserRequestMobileModals = ({
  tabKey,
  isMobileModalOpenAction,
  hasRecords,
  handleMobileModalClose,
  isViewMobileModalOpen,
  isMobileModalOpenEdit,
  dropdownMenuCheckedRole,
  handleDropdownCheckbox,
  handleViewModal,
  isViewMobileModalOpenApproved,
  handleViewModalApproved,
  handleMobileRejectUser,
  handleMobileApproveUsers,
  isLoading,
  isPaidPlan,
}) => {
  const isCheckEmpty =
    dropdownMenuCheckedRole[hasRecords ? hasRecords.key : ""] === undefined
      ? true
      : false;
  return (
    <>
      {isMobileModalOpenAction && (
        <MobileModal
          title={hasRecords.fullname}
          isCapitalize={true}
          onClose={handleMobileModalClose}
        >
          <div className="mobile-modal-more-vertical">
            <Button
              size="sm"
              className="btn-row-action"
              onClick={() => {
                handleViewModal();
              }}
              variant="link"
            >
              <RemoveRedEyeIcon />
              <h3>View</h3>
            </Button>
            <Button
              className={`btn-row-action icon-button ${
                tabKey !== "pending" ? "d-none" : ""
              }`}
              size="sm"
              variant="link"
              onClick={() => {
                handleViewModalApproved();
              }}
            >
              <>
                <CustomIcons variant="tick" />
                <h3>Approve</h3>
              </>
            </Button>
            <Button
              className={`btn-row-action icon-button ${
                tabKey !== "pending" ? "d-none" : ""
              }`}
              size="sm"
              variant="link"
              onClick={handleMobileRejectUser}
            >
              <>
                <CustomIcons variant="cancel" />
                <h3>Reject</h3>
              </>
            </Button>
          </div>
        </MobileModal>
      )}
      {isViewMobileModalOpen && (
        <ViewMobileModal title={"View"} onClose={handleMobileModalClose}>
          <div className="name-role">
            <h1>{hasRecords.fullname}</h1>
            {tabKey === "approved" ? (
              <div className="ant-tag-container">
                <Tag
                  className={
                    hasRecords.groupRoles[0].color === "green"
                      ? "ant-dropdown-select-tag green"
                      : hasRecords.groupRoles[0].color === "blue"
                      ? "ant-dropdown-select-tag blue"
                      : hasRecords.groupRoles[0].color === "pink"
                      ? "ant-dropdown-select-tag pink"
                      : hasRecords.groupRoles[0].color === "purple"
                      ? "ant-dropdown-select-tag purple"
                      : hasRecords.groupRoles[0].color === "orange"
                      ? "ant-dropdown-select-tag orange"
                      : "custom-select-checkbox-tag"
                  }
                >
                  <label>{hasRecords.groupRoles[0].label}</label>
                </Tag>
              </div>
            ) : null}
          </div>
          <div className="email-username">
            <p className="paragraph-1">
              <CustomIcons variant="email" /> <span>{hasRecords.email}</span>
            </p>
            <p className="paragraph-1">
              <AccountCircleRoundedIcon />
              <span>{hasRecords.username}</span>
            </p>
          </div>
          <div className="approve-details">
            <p className="paragraph-1 approved-name">
              {tabKey === "pending"
                ? "Submitted on"
                : tabKey === "approved"
                ? `Approved by ${hasRecords.authorizerName}`
                : tabKey === "rejected"
                ? `Rejected by ${hasRecords.authorizerName}`
                : ""}
            </p>
            <p className="paragraph-1">
              {tabKey === "pending"
                ? formatDateTime(hasRecords.createDate)
                : tabKey === "approved"
                ? formatDateTime(hasRecords.approveDate)
                : tabKey === "rejected"
                ? formatDateTime(hasRecords.rejectedDate)
                : ""}
            </p>
          </div>
        </ViewMobileModal>
      )}
      {isMobileModalOpenEdit && (
        <MobileModal
          title={"Select a role"}
          onClose={handleMobileModalClose}
          withBtn={true}
        >
          <div className="mobile-modal-more-vertical">
            <div className="mobile-modal-sort">
              {hasRecords.groupRoles.map((role, i) => {
                const roleArr = [
                  Localization.Settings.Roles.CustomerAdmin,
                  Localization.Settings.Roles.AssetOwner,
                  Localization.Settings.Roles.EndUser,
                ];
                return (
                  <div className="sort-data" key={role.id}>
                    <Form.Check
                      className="ps-0"
                      type="radio"
                      id={role.id}
                      label={""}
                      disabled={role.disabled}
                      checked={
                        dropdownMenuCheckedRole[hasRecords.key]
                          ? dropdownMenuCheckedRole[hasRecords.key].includes(
                              role.id
                            )
                          : false
                      }
                      onChange={() =>
                        handleDropdownCheckbox(hasRecords, role.id)
                      }
                    />
                    <div
                      className="role-desc-container"
                      onClick={() => document.getElementById(role.id).click()}
                    >
                      <span className="paragraph-1">{role.name}</span>
                      <span className="role-desc">{roleArr[i]}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </MobileModal>
      )}
      {isViewMobileModalOpenApproved && (
        <ViewMobileModal
          title={"Approve"}
          subTitle={isPaidPlan ? "Select a role for the user." : "Select a role for the user:"}
          subTitle1={"Additional users will be charged."}
          onClose={handleMobileModalClose}
          withBtn={true}
          isShow = {!isPaidPlan ? true : false}
        >
          <div className="view-mobile">
            <div className="view-mobile-modal-check">
              {hasRecords.groupRoles.map((role, i) => {
                const roleArr = [
                  Localization.Settings.Roles.CustomerAdmin,
                  Localization.Settings.Roles.AssetOwner,
                  Localization.Settings.Roles.EndUser,
                ];
                return (
                  <div className="check-data position-relative" key={role.id}>
                    <Form.Check
                      className="ps-0"
                      type="radio"
                      id={role.id}
                      label={""}
                      disabled={role.disabled}
                      checked={
                        dropdownMenuCheckedRole[hasRecords.key]
                          ? dropdownMenuCheckedRole[hasRecords.key].includes(
                              role.id
                            )
                          : false
                      }
                      onChange={() =>
                        handleDropdownCheckbox(hasRecords, role.id)
                      }
                    />
                    <div
                      className="role-desc-container"
                      onClick={() => document.getElementById(role.id).click()}
                    >
                      <span className="paragraph-1">{role.name}</span>
                      <span className="role-desc">{roleArr[i]}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {isPaidPlan ? (
            <div className="view-mobile-modal-btn mobile-offcanvas-btn">
              <div className="button-groups-offcanvas">
                <Button
                  variant="primary"
                  onClick={handleMobileApproveUsers}
                  disabled={isCheckEmpty || isLoading}
                >
                  Approve user request
                  {isLoading && <SpinnerIcon />}
                </Button>
                <div className="paragraph-2">
                  {
                    Localization.Settings.UserRequest.Offcanvas
                      .ApproverUserRequest.SmallDesc
                  }
                </div>
              </div>
            </div>
          ) : (
            <div className="view-mobile-modal-btn">
              <div className="button-groups">
                <Button variant="secondary" onClick={handleMobileModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleMobileApproveUsers}
                  disabled={isCheckEmpty || isLoading}
                >
                  Approve
                  {isLoading && <SpinnerIcon />}
                </Button>
              </div>
            </div>
          )}
        </ViewMobileModal>
      )}
    </>
  );
};

export default UserRequestMobileModals;
