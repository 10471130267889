import { spanishGray, iconSize } from '../../shared/utils';
import Spinner from 'react-bootstrap/Spinner';

const SpinnerIcon = ({ className }) => {
    return (
        <Spinner className={className && className} animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    )
};

export default SpinnerIcon;