import { useState, useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IsDesktop, IsTablet, IsMobile } from '../../utils';
import CollectionEdit from "../../../collection/collection-edit";
import CollectionSuccess from "../../../collection/collection-success";


const CollectionOffCanvas = (props) => {
    const isMobile = IsMobile();
    const isTablet = IsTablet();
    const [showEditPage, setShowEditPage] = useState(true);

    const handleContainerSwitch = () => {
        setShowEditPage(!showEditPage);
      };
    return (
        <>
        {
            props.offCanvasType === "edit"
            ? 

                isMobile?
                    <Offcanvas style={{ width: '100%' }} show={props.offcanvasShow} placement="end" backdrop={false} scroll={false}>
                        
                        <Offcanvas.Body style={{ padding: '0' }} >
                        {
                        <div className="collection-offcanvas-wrapper">
                            <div className="collection-offcanvas-cont progress-state">
                                {
                                    showEditPage ?
                                        <CollectionEdit 
                                            offcanvasShow= {props.offcanvasShow}
                                            collectionUuid = {props.collectionUuid}
                                            collectionTitle = {props.collectionTitle}
                                            handleContainerSwitch={handleContainerSwitch}
                                            clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                        />
                                    :
                                        <CollectionSuccess 
                                            offcanvasShow= {props.offcanvasShow}
                                            handleContainerSwitch={handleContainerSwitch}
                                            clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                        />
                                }
                            </div> 
                        </div>  
                        }
                        </Offcanvas.Body>
                    </Offcanvas>
                :
                
                    isTablet?
                        <Offcanvas style={{ maxWidth:'40%', width: '700px' }} show={props.offcanvasShow} placement="end" backdrop={true} scroll={true}>
                            <Offcanvas.Body>
                            {
                            <div className="collection-offcanvas-wrapper">
                                <div className="collection-offcanvas-cont progress-state">
                                    {
                                        showEditPage ?
                                            <CollectionEdit 
                                                offcanvasShow= {props.offcanvasShow}
                                                checkIfTyping = {props.checkIfTyping}
                                                collectionUuid = {props.collectionUuid}
                                                collectionTitle = {props.collectionTitle}
                                                handleContainerSwitch={handleContainerSwitch}
                                                clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                            />
                                        :
                                            <CollectionSuccess 
                                                iconClicked= {props.iconClicked}
                                                handleContainerSwitch={handleContainerSwitch}
                                                clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                            />
                                    }
                                </div> 
                            </div>  
                            }
                            </Offcanvas.Body>
                        </Offcanvas>   
                    ://1024px up
                        <Offcanvas style={{ maxWidth:'34%', width: '700px' }} show={props.offcanvasShow} placement="end" backdrop={true} scroll={true}>
                            <Offcanvas.Body>
                            {
                            <div className="collection-offcanvas-wrapper">
                                <div className="collection-offcanvas-cont progress-state">
                                    {
                                        showEditPage ?
                                            <CollectionEdit 
                                                offcanvasShow= {props.offcanvasShow}
                                                checkIfTyping = {props.checkIfTyping}
                                                collectionUuid = {props.collectionUuid}
                                                collectionTitle = {props.collectionTitle}
                                                handleContainerSwitch={handleContainerSwitch}
                                                clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                            />
                                        :
                                            <CollectionSuccess 
                                                iconClicked= {props.iconClicked}
                                                handleContainerSwitch={handleContainerSwitch}
                                                clickCollectionOffcanvas = {props.clickCollectionOffcanvas}
                                            />
                                    }
                                </div> 
                            </div>  
                            }
                            </Offcanvas.Body>
                        </Offcanvas> 
                    
            
            : props.offCanvasType === "insert new type"
            ?
                isMobile?
                    <Offcanvas style={{ width: '100%' }} show={props.iconClicked} placement="end" backdrop={false} scroll={false}>
                        
                        <Offcanvas.Body style={{ padding: '0' }} >
                        {
                         //insert here new content
                        }
                        </Offcanvas.Body>
                    </Offcanvas>
                :
                    <Offcanvas show={props.iconClicked} placement="end" backdrop={true} scroll={true}>
                        <Offcanvas.Body>
                        {
                        //insert here new content
                        }
                        </Offcanvas.Body>
                    </Offcanvas>  

            : null
        }
        </>
    )
}

export default CollectionOffCanvas

