import { spanishGray, iconSize } from '../../shared/utils';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';

const ImageIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <ImageRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default ImageIcon;