import { DatePicker } from 'antd';
import { Button } from 'react-bootstrap';
import { IsMobile, IsTablet } from '../../../shared/utils';
import MobileModal from '../../../shared/components/mobile-modal';
import CustomSelectMultiple from '../../../shared/components/forms/custom-select-multiple';
import CustomIcons from '../../../shared/components/custom-icons';

const { RangePicker } = DatePicker;

const UserRequestFilter = ({
    filterDateText,
    userGroupOptions,
    RangePickerValue,
    handleFilterUserGroup,
    handleFilterDate,
    tableData,
    handleClearAllFilter,
    handleApplyFilter,
    customSelectMultipleClearAll,
    updateCustomSelectMultipleClearAll,
    handleMobileModalClose,
    isMobileModalOpenFilter,
    handleMobileSortByAsc,
    handleMobileSortByDes,
    sortOrderMobile,
}) => {
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    if(isTablet){
        return(
            <>
            {isMobileModalOpenFilter && (
                <MobileModal title="Filters" onClose={handleMobileModalClose} withBtn={true}>
                    <div className="mobile-modal-sort">
                        <div className="sort-title">
                            <p className="paragraph-1">Sort By</p>
                        </div>
                        <div className="sort-data">
                            <input
                                type="radio"
                                id="full-name-A-Z"
                                name="sort"
                                value={1}
                                checked={sortOrderMobile === 'ascend'}
                                onChange={handleMobileSortByAsc}
                            />
                            <label htmlFor="full-name-A-Z" className="paragraph-1">Full Name: A-Z</label>
                        </div>
                        <div className="sort-data">
                            <input
                                type="radio"
                                id="full-name-Z-A"
                                name="sort"
                                value={2}
                                checked={sortOrderMobile === 'descend'}
                                onChange={handleMobileSortByDes}
                            />
                            <label htmlFor="full-name-Z-A" className="paragraph-1">Full Name: Z-A</label>
                        </div>
                    </div>
                    <div className='mobile-modal-filter'>
            <div className='row asset-filter-container'>
                <div className='col-3'>
                    <div className='rangepicker-container' style={{marginBottom: '2.5rem'}}>
                        <div className='mobile-rangepicker-container'>
                        { filterDateText === 'approved' ? (
                            <>
                                <p className='paragraph-1 font-semi-bold'>Date Approved</p>
                                <label>Date Approved</label>
                                </>
                            ):filterDateText === 'rejected' ? (
                                <>
                                    <p className='paragraph-1 font-semi-bold'>Date Rejected</p>
                                    <label>Date Rejected</label>
                                </>
                            ):
                            <>
                                <p className='paragraph-1 font-semi-bold'>Date Submitted</p>
                                <label>Date submitted</label>
                            </>
                        }
                        <RangePicker
                            value={RangePickerValue}
                            size='8'
                            format="DD-MM-YYYY"
                            placeholder={['DD/MM/YY', 'DD/MM/YY']}
                            className='date-component'
                            onChange={(value) => handleFilterDate(value)}
                            clearIcon={<CustomIcons className='rangepicker-close-icon' variant="cancel" />}
                        />
                        </div>
                    </div>
                </div>
                {/*
                    userGroups && userGroups !== null &&
                        <div className='col-5 col-xxl-3'>
                            <p className='paragraph-1 font-bold'>User Groups</p>
                            <CustomSelectMultiple
                                enableSearch
                                onClearAllComplete={() => updateCustomSelectMultipleClearAll(false)}
                                clearAll={customSelectMultipleClearAll}
                                handleCustomSelectMultipleChange={(value) => handleFilterUserGroup(value)}
                                label="User Groups"
                                options={userGroups}
                                placeholder="Select an option"
                                validated
                            />
                        </div>
                    */}
            </div>
        </div>
                    <div className="mobile-modal-filter">
                        <div className='asset-filter-top'>
                            <Button variant='secondary' onClick={() => handleClearAllFilter(tableData)}>Clear All</Button>
                            <Button variant='primary'  onClick={() => handleApplyFilter(tableData)}>Apply</Button>
                        </div>
                    </div>
                </MobileModal>
        )
    }
    </>
    );
    } else {
        return(
        <>
            <div className='user-request-filter'>
                <div className='user-request-filter-button-group'>
                    <Button className='clear-all-button' variant='secondary' onClick={() => handleClearAllFilter(tableData)}>Clear All</Button>
                    <Button className='apply-filter-button' variant='primary' onClick={() => handleApplyFilter(tableData)}>Apply Filters</Button>
                </div>
                <div className='user-request-filter-options'>
                    <div className='row'>
                        <div className='col-4 filter-options-rangepicker-container'>
                            { filterDateText === 'approved' ? (
                                <>
                                    <p className='paragraph-1 font-semi-bold'>Date Approved</p>
                                    <label>Date Approved</label>
                                </>
                            ):filterDateText === 'rejected' ? (
                                <>
                                    <p className='paragraph-1 font-semi-bold'>Date Rejected</p>
                                    <label>Date Rejected</label>
                                </>
                            ):
                            <>
                                <p className='paragraph-1 font-semi-bold'>Date Submitted</p>
                                <label>Date submitted</label>
                            </>
                            }
                            <RangePicker value={RangePickerValue} size='large' className='date-component'
                                onChange={(value) => handleFilterDate(value)}
                                placeholder={['DD/MM/YY', 'DD/MM/YY']}
                                clearIcon={<CustomIcons className='rangepicker-close-icon' variant="cancel" />}/>
                        </div>
                        {
                            userGroupOptions &&
                                <div className='col-4'>
                                <p className='paragraph-1 font-bold'>User Groups</p>
                                <CustomSelectMultiple
                                    enableSearch
                                    onClearAllComplete={() => updateCustomSelectMultipleClearAll(false)}
                                    clearAll={customSelectMultipleClearAll}
                                    handleCustomSelectMultipleChange={(value) => handleFilterUserGroup(value)}
                                    label="Select Multiple"
                                    options={userGroupOptions}
                                    placeholder="Select an option"
                                    validated
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>);
    }
}

export default UserRequestFilter;