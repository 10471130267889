import React, { useContext, useEffect, useRef, useState } from 'react'
import { SessionContext } from '../../shared/context/session-provider';
import { ApiContext } from '../../shared/context/api-state';
import { Button, Form } from 'react-bootstrap';
import CustomIcons from '../../shared/components/custom-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio } from '@fortawesome/free-solid-svg-icons';
import GridSkeleton from './GridSkeleton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MobileModal from '../../shared/components/mobile-modal';

const RowFIlteredAssets = ({ data, allDataDisplay, handleViewAssetOpen, onCheckboxChange, selectAllChecked, checkedMyAssets, toggleSingleDeleteModal, isTablet }) => {

    const { tenantUuid } = useContext(SessionContext);
    const { getAssetsById } = useContext(ApiContext);

    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [key, setKey] = useState(null);
    const [fileUUID, setFileUUID] = useState(null);
    const [basename, setBasename] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [type, setType] = useState(null);
    const [size, setSize] = useState(null);
    const [status, setStatus] = useState(null);

    // image
    const [imageUrl, setImageUrl] = useState(null);
    const [gifUrl, setGifUrl] = useState(null);

    // document
    const [mime, setMime] = useState(null);

    // audio / video
    const vidRef= useRef();
    const [playVideo, setPlayVideo] = useState(false);
    const [videoContentType, setVideoContentType] = useState(null);
    const [videoBlob, setVideoBlob] = useState(null);

    const audioRef = useRef();
    const [playAudio, setPlayAudio] = useState(false);
    const [audioContentType, setAudioContentType] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);

    const [mute, setMute] = useState(true);
    const [duration, setDuration] = useState(null);
    const [isMobileModalOpenAction, setIsMobileModalOpenAction] = useState(false);

    // checkbox
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setAllData(allDataDisplay);
        setFileUUID(data.uuid);
        setBasename(data.basename);
        setTimestamp(data.timestamp);
        setType(data.type);
        setSize(data.size);
        setLoading(true);

        if(data.overallStatus === 'COMPLETED') {
            getAssetsById(data.uuid, tenantUuid).then(asset => {
                if(asset) {
                    setFileUUID(asset.uuid);
                    setKey(asset.key);
                    setStatus(asset.overallStatus);

                    if (asset.type === 'image') {
                        if (asset.mime === 'image/gif') {
                            const proxyurl = URL.createObjectURL(asset.proxyBlob);
                            const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                            setImageUrl(proxyurl);
                            setGifUrl(ingestUrl);
                            setLoading(false);

                            return;
                        }

                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setLoading(false);
                    }

                    if (asset.type === 'document') {
                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setMime(asset.mime);
                        setLoading(false);
                    }

                    if (asset.type === 'video') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                        const proxyUrl = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(proxyUrl);
                        setVideoBlob(ingestUrl);
                        setVideoContentType(asset.mime);
                        setDuration(asset.duration);
                        setLoading(false);
                    }

                    if (asset.type === 'audio') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);

                        setMime(asset.mime);
                        setAudioContentType(asset.mime);
                        setAudioUrl(ingestUrl);
                        setDuration(asset.duration);
                        setLoading(false);
                    }
                } else {
                    setImageUrl(null);
                    setLoading(false);
                }

            });
        } else {
            setStatus(data.overallStatus);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        selectAllChecked ? setIsChecked(true) : setIsChecked(false);
    }, [selectAllChecked]);

    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -7); // Remove the last 3 characters and the extension
        return truncatedFulltext;
    }

    const truncateEndText = (fulltext) => {
        const extension = fulltext.substr(-4);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    }

    const handleCheckboxChangeTrigger = () => {
        onCheckboxChange(fileUUID);
    }

    function formatBytes(bytes) {
        if (bytes < 1024) {
            return bytes + ' bytes';
        } else if (bytes < 1024 * 1024) {
            return (bytes / 1024).toFixed(2) + ' KB';
        } else {
            return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
        }
    }
    const handleMobileModalClose = () =>{
        setIsMobileModalOpenAction(false);
    }
    return (<>
        {
            loading
            ?
                <GridSkeleton view="row" />
            :
                status !== 'COMPLETED' ?
                    null
                : isTablet ?
                <div className='mobile-row-filter'  key={fileUUID}>
                    <div className="my-assets-card-row-data">
                        <div>
                            <input
                                type="checkbox"
                                checked={checkedMyAssets.includes(fileUUID)}
                                onChange={handleCheckboxChangeTrigger}
                                id={fileUUID}
                            />
                            <label htmlFor={fileUUID}>
                                <CustomIcons variant='check' color="#000000" />
                            </label>
                            <div className="row-data-image-container" onClick={() => handleViewAssetOpen(fileUUID, allData)} >
                                {/* <img src={card.img} alt={card.title} />    */}
                                {type === 'audio' && (
                                    <div className='svg-icon'>
                                        <FontAwesomeIcon className='audio-icon' icon={faFileAudio} />
                                    </div>
                                )}
                                {type === 'video' && (
                                    <img src={ imageUrl } alt={ basename } />
                                )}
                                {type === 'image' && (
                                    <img src={imageUrl} alt={basename} />
                                )}
                                {type === 'document' && (
                                    <img src={imageUrl} alt={basename} />
                                )}
                            </div>
                        </div>
                        <div onClick={() => document.getElementById(fileUUID).click()}>
                            <div className="list-view-title-container">
                                <div className="first-text text-capitalize">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                                <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                            </div>
                            <div className='mobile-type-size'>
                                <span className='paragraph-2'>{data.type}</span>
                                <span className='paragraph-2'>{formatBytes(data.size)}</span>
                            </div>
                        </div>
                    </div>

                    <div className="asset-card-actions">
                        <button className="mobile-more-vertical" onClick={() => setIsMobileModalOpenAction(true)}>
                            <CustomIcons variant="more" />
                        </button>
                    </div>
                </div>
                :
                <div className="my-assets-card-row-data" key={fileUUID}>
                    <div>
                        <input
                            type="checkbox"
                            checked={checkedMyAssets.includes(fileUUID)}
                            onChange={handleCheckboxChangeTrigger}
                            id={fileUUID}
                        />
                        <label htmlFor={fileUUID}>
                            <CustomIcons variant='check' color="#000000" />
                        </label>
                        <div className="row-data-image-container" onClick={() => handleViewAssetOpen(fileUUID, allData)} >
                            {/* <img src={card.img} alt={card.title} />    */}
                            {type === 'audio' && (
                                <div className='svg-icon'>
                                    <FontAwesomeIcon className='audio-icon' icon={faFileAudio} />
                                </div>
                            )}
                            {type === 'video' && (
                                <img src={ imageUrl } alt={ basename } />
                            )}
                            {type === 'image' && (
                                <img src={imageUrl} alt={basename} />
                            )}
                            {type === 'document' && (
                                <img src={imageUrl} alt={basename} />
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="list-view-title-container">
                            <div className="first-text">{truncateFirstText(key.substring(key.lastIndexOf("/") + 1))}</div>
                            <div className="end-text">{truncateEndText(key.substring(key.lastIndexOf("/") + 1))}</div>
                        </div>
                    </div>
                    <div>{data.type}</div>
                    {/* <div>2 MB</div> */}
                    <div>{formatBytes(data.size)}</div>
                    <div className="asset-card-actions">
                     <span onClick={() => handleViewAssetOpen(fileUUID, allData)}><CustomIcons className='my-svg' variant="visibility" visible="false" /></span>
                     <span onClick={() => toggleSingleDeleteModal(fileUUID)}><CustomIcons className='my-svg' variant="trash" visible="false" /></span>
                    </div>
                </div>

        }
          {
                    isMobileModalOpenAction && (
                        <MobileModal isCapitalize={true} titleWithExtension={key} onClose={handleMobileModalClose}>
                            <div className="mobile-modal-more-vertical">
                                <Button size='sm' className="btn-row-action" onClick={() => handleViewAssetOpen(fileUUID, allData)} variant='link'>
                                <RemoveRedEyeIcon />
                                    <h3>View</h3>
                                </Button>
                                <Button className={`btn-row-action icon-button`} onClick={() => {toggleSingleDeleteModal(fileUUID); handleMobileModalClose()}} size='sm' variant='link'>
                                        <>
                                        <CustomIcons variant="trash" className="custom-icon" />
                                            <h3>Delete</h3>
                                        </>
                                </Button>
                            </div>
                        </MobileModal>
                    )
                }

        </>
    )
}

export default RowFIlteredAssets