import Toast from 'react-bootstrap/Toast';
//import CancelIcon from '@mui/icons-material/Cancel';
import CustomIcons from '../custom-icons';
import PropTypes from 'prop-types';

const ToastMessage = (props) => {
    return(
        <Toast className={props.variant ? props.variant === "success" ? `toast-success ${props.className && props.className}` : props.variant === "error" ? `toast-error ${props.className && props.className}` : props.className : props.className} show={props.showToast}  onClose={() => props.hideToastMessage()} delay={5000} autohide>
            <Toast.Header>
                <span>
                    { props.variant === "success" && <CustomIcons variant='tick' /> }
                    { props.variant === "error" && <CustomIcons variant='error' /> }
                    { props.header }
                </span>
                <button className='icon-button' onClick={() => props.hideToastMessage()}>
                    <CustomIcons variant='close' />
                </button>
            </Toast.Header>
            {
                props.body &&
                    <Toast.Body>{props.body}</Toast.Body>
            }
        </Toast>
    );
}

export default ToastMessage;

ToastMessage.propTypes = {
    showToast: PropTypes.bool,
    header: PropTypes.string,
    className: PropTypes.string
};