import Skeleton from 'react-loading-skeleton';
import { IsTablet } from '../../shared/utils';

const AssetCardSkeleton = (props) => {
    const options = {
        baseColor: props.options && props.options.baseColor,
        highlightColor: props.options && props.options.highlightColor
    };

    //Check isTablet?
    const isTablet = IsTablet();

    return(
        <>
            {
                props.view === "row"
                ?
                    <tr className='asset-card-skeleton-list-view'>
                        <td><Skeleton width={80} height='100%' /></td>
                        <td><Skeleton width="100%" height='100%' /></td>
                        <hr />
                    </tr>
                :
                    <div className={isTablet ? 'asset-card-skeleton col-md-4' : 'asset-card-skeleton col-md-3'}>
                        <div className='ratio ratio-4x3'>
                            <Skeleton baseColor={options.baseColor} highlightColor={options.highlightColor} />
                        </div>
                        <Skeleton baseColor={options.baseColor} highlightColor={options.highlightColor} height={58} />
                    </div>
            }
        </>
    );
};

export default AssetCardSkeleton;