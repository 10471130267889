import Localization from "../../components/shared/localization";

const ErrorPage = (props) => {
    return(<>
        <div className="error-wrapper">
            <div className="row">
                <div className="col-12 col-header">
                    <a href="/login" className="d-flex flex-row">
                        <img
                            src="/images/elko-logo.svg"
                            className="dam-logo-img"
                            alt="Dam Logo"
                        />
                    </a>
                </div>
                <div className="col-12">
                    <div className="error-container">
                        <img src="/images/page-not-found.svg" alt="Page Not Found"/>
                        <h2>{Localization.Error.Title}</h2>
                        <p className="paragraph-1">{Localization.Error.Desc}</p>
                        <div className="button-group">
                            <a className="btn btn-primary"
                                href={Localization.Error.Button.PrimaryUrl}
                                >{Localization.Error.Button.Primary}</a>
                            <a className="btn btn-secondary"
                                href={Localization.Error.Button.SecondaryUrl}
                            >{Localization.Error.Button.Secondary}</a>
                        </div>
                        {
                            props.hasSubdomain ?
                                <p className="paragraph-1"><a href={window.location.origin}>{Localization.Error.Elko}</a></p>
                                :
                                <p className="paragraph-1"><a href={"https://hello.elko.digital/"}>{Localization.Error.ElkoHomePage}</a></p>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default ErrorPage;


