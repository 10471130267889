import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AccountContext } from "../../components/shared/context/account-provider";
import { useForm } from "react-hook-form";
import Spinner from 'react-bootstrap/Spinner';
import path from "../../components/shared/routePaths";

const ChangePassword = (props) => {
  const [ password, setPassword ] = useState("");
  const [ newPassword, setNewPassword ] = useState("");
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  const { getSession } = useContext(AccountContext);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const pathPrefix = "/";

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonDisabled(true);

    getSession().then(({ user }) => {
      user.changePassword(password, newPassword, (err, result) => {
        if (err) {
            setButtonDisabled(false);
            console.error('Change Password: ', err);
        } else {
            localStorage.removeItem("newPasswordRequired");
            <Navigate replace to={pathPrefix + path.LOGIN} />;
        }
      });
    });
  };

  if(props.checkAuthenticated) {
    return (
      <div className="login-wrapper">
          <div className="login-cont">
          <div className="login-header">
              <h3>WPH DAM - Change Password</h3>
          </div>
          <div className="login-form">
              <form onSubmit={handleSubmit(onSubmit)} novalidate="true">
                  <img className="mb-4" src="/images/elko-logo.png" alt="elko logo" width="240"/>
                  <div className="form-group">
                      <label htmlFor="CurrentPassword">Current Password</label>
                      <input
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                      />
                      {errors.CurrentPassword?.type === 'required' && <p className="error" role="alert">You have entered an invalid password.</p>}
                  </div>
                  <div className="form-group">
                      <label htmlFor="NewPassword">New Password</label>
                      <input
                      className="form-control"
                      value={newPassword}
                      pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      type="password"
                      placeholder="New Password"
                      {...register("newpassword", { required: true })}
                      aria-invalid={errors.newpassword ? "true" : "false"}
                      onChange={(event) => setNewPassword(event.target.value)}
                      ></input>
                      {errors.newpassword?.type === 'required' && <p className="error" role="alert">You have entered an invalid password.</p>}
                  </div>
                  <div className="button-group">
                      <button className={buttonDisabled ? "btn btn-primary disabled" : "btn btn-primary"} type="submit">Login</button>
                      { buttonDisabled ? <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                      </Spinner> : null }
                  </div>
              </form>
          </div>
          </div>
      </div>
    );
  } else {
    return(
      <Navigate replace to="/"/>
    )
  }

};

export default ChangePassword;
