import { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { ShareContext } from '../../../shared/context/share-state';
import { IsMobile, IsTablet } from '../../../shared/utils';
import CancelIcon from "../../../icons/cancel";
import TickIcon from "../../../icons/tick";
import SearchIcon from '../../../icons/search';
import FilterIcon from '../../../icons/filter-icon';
import ArrowIcon from "../../../icons/arrow";

const UserRequestSettingsBar = ({
    handleSelectAllChange,
    fullNameSearch,
    handleFullNameSearch,
    tableData,
    onClickFilters,
    handleApproveUser,
    handleRejectUser,
    openFilter,
    tabKey,
    handleMobileModalFilters,
    isMobileModalOpenFilter,
    countFilter
}) => {
    const isMobile = IsMobile();
    const isTablet = IsTablet();

    // Share Context
    const { selectAllRequestChecked, checkedRequestUser } = useContext(ShareContext);
    if(isTablet){
        return(
            <>
                        <div className='mobile-setting-filter'>
                            <Button
                                onClick={handleMobileModalFilters}
                                variant="default" className={isMobileModalOpenFilter ? "icon-button icon-button-text icon-button-left selected" : "icon-button icon-button-text icon-button-left"}>
                                <h3 className='filters-h3'>{countFilter ? `Filters (${countFilter})` : 'Filters'}</h3>
                                <span style={{marginLeft: '4px', display: 'inline-block' }}>
                                {isMobileModalOpenFilter ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                            </span>
                            </Button>
                        </div>
                        <div className="form-group form-search">
                        <SearchIcon />
                            <input
                                id="search-full-name-rejected"
                                className="form-control"
                                type="text"
                                placeholder="Search full name"
                                value={fullNameSearch}
                                onChange={(event) => handleFullNameSearch(event, tableData)}
                            ></input>
                        </div>
                        {
                        checkedRequestUser.length > 0 &&
                            <div className='mobile-check-delete'>
                                <div className='mobile-user-check'>
                                <Form.Check
                                    disabled={tabKey !== "pending"}
                                    type='checkbox'
                                    id='select-all'
                                    className={selectAllRequestChecked ? "" : checkedRequestUser.length > 0 ? "form-check-input-mixed" : ""}
                                    label={selectAllRequestChecked ? "All selected" : checkedRequestUser.length > 0 ? `${checkedRequestUser.length} selected` : "Select all"}
                                    onChange={handleSelectAllChange}
                                    checked={selectAllRequestChecked}
                                />
                                </div>
                                <div className='mobile-user-approve-reject'>
                                <Button
                                        disabled={tabKey !== "pending" && checkedRequestUser.length === 0}
                                        onClick={() => handleApproveUser()}
                                        variant="default" className="icon-button icon-button-text icon-button-left">
                                        <TickIcon />
                                    </Button>
                                    <Button
                                        disabled={tabKey !== "pending" && checkedRequestUser.length === 0}
                                        onClick={() => handleRejectUser()}
                                        variant="default" className="icon-button icon-button-text icon-button-left">
                                        <CancelIcon />
                                    </Button>
                                </div>
                            </div>
                    }
                    </>
        )
    }else{
    return(
    <>
        <div className='user-request-settings-bar'>
            <div className='user-request-settings-bar-left'>
                <Form.Check
                    disabled={tabKey !== "pending"}
                    type='checkbox'
                    id='select-all'
                    className={selectAllRequestChecked ? "" : checkedRequestUser.length > 0 ? "form-check-input-mixed" : ""}
                    label={selectAllRequestChecked ? "All selected" : checkedRequestUser.length > 0 ? `${checkedRequestUser.length} selected` : "Select all"}
                    onChange={handleSelectAllChange}
                    checked={selectAllRequestChecked}
                />
            </div>
            <div className='user-request-settings-bar-right'>
                <div className="form-group form-search">
                    <SearchIcon />
                    <input
                        id="search-full-name-rejected"
                        className="form-control"
                        type="text"
                        placeholder="Search full name"
                        value={fullNameSearch}
                        onChange={(event) => handleFullNameSearch(event, tableData)}
                    ></input>
                </div>
                <Button
                    onClick={onClickFilters}
                    variant="default" className={openFilter ? "icon-button icon-button-text icon-button-left selected" : "icon-button icon-button-text icon-button-left"}>
                    {countFilter ? `Filters (${countFilter})` : 'Filters'}
                    <span style={{marginLeft: '4px', display: 'inline-block' }}>
                            {openFilter ? <ArrowIcon direction="up" /> : <ArrowIcon direction="down" />}
                            </span>
                </Button>
                <Button
                    disabled={tabKey !== "pending" && checkedRequestUser.length === 0}
                    onClick={() => handleApproveUser()}
                    variant="default" className="icon-button icon-button-text icon-button-left">
                    <TickIcon />Approve
                </Button>
                <Button
                    disabled={tabKey !== "pending" && checkedRequestUser.length === 0}
                    onClick={() => handleRejectUser()}
                    variant="default" className="icon-button icon-button-text icon-button-left">
                    <CancelIcon />Reject
                </Button>
            </div>
        </div>
    </>);}
}

export default UserRequestSettingsBar;
