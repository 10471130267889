import { useContext, useEffect, useState } from 'react';
import { UploadContext } from '../../shared/context/upload-provider';
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { SessionContext } from '../../shared/context/session-provider';
import { ShareContext } from '../../shared/context/share-state';
import Localization from '../../shared/localization';
import 'react-circular-progressbar/dist/styles.css';
import AssetService from '../../../services/api/assetsService';
import { IsTablet, IsDesktop } from '../../shared/utils';
import CustomIcons from '../../shared/components/custom-icons';
import CancelIcon from '@mui/icons-material/Cancel';

const UploadProgress = (props) => {
    const isDesktop = IsDesktop();
    const isTablet = IsTablet();
    const { handleProgress,
        percentage,
        successPercentage,
        errorPercentage, updateActiveSetting,
        setProgressState, updateUploadError, offcanvasState } = useContext(UploadContext);

    const { tenantUuid } = useContext(SessionContext);

    const { updateStorage,
        setMaxStorageSize,
        setIsStorageMax } = useContext(ShareContext);
    const [hasCalledOnHandleSetting, setHasCalledOnHandleSetting] = useState(false);

    useEffect(() => {
        const getAssetStorage = async () => {
            const getAssetStorage = await AssetService.getAssetStorage(tenantUuid);

            if(getAssetStorage.statusCode === 200) {
                updateStorage(getAssetStorage.response.storageSize, getAssetStorage.response.percentage, getAssetStorage.response.remainingSize);
                setMaxStorageSize(getAssetStorage.response.maxStorageSize);
             
            }

            setIsStorageMax(getAssetStorage.statusCode === 403);
        }


        if(props.uploadedFiles.length + props.errFiles.length === props.totalFiles) {
            getAssetStorage();
            if (!hasCalledOnHandleSetting && isDesktop) {
                if(offcanvasState){
                    setHasCalledOnHandleSetting(true);
                }else{
                    props.onHandleSetting('uploadProgress');
                    setHasCalledOnHandleSetting(true);
                }
            }
        }
    }, [props.uploadedFiles, props.errFiles]);

    return(
        <div className="upload-progress-wrapper">
            {!isDesktop &&
                <div className='upload-offcanvas-header'>
                   <div>
                   <h3 className='mb-0'><button className='icon-button mobile-back-button' onClick={() => updateActiveSetting('')}>
                    <CustomIcons variant="arrow" direction="back"/></button> {'Upload Progress'}</h3>
                   </div>
               </div>
            }
            {isDesktop &&
                  <div className="upload-progress-header">
                    <h3 className='mb-0'>{ Localization.Upload.Offcanvas.Progress.Title}</h3>
                    <div className='upload-offcanvas-close'>
                        <button className='icon-button' onClick={() => updateActiveSetting("")}><CancelIcon /></button>
                    </div>
                  </div>
            }
            <div className="upload-progress-cont">
                <div className="upload-progress-circular">
                    <CircularProgressbarWithChildren
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                            pathColor: 'transparent',
                            trailColor: '#E1E1E1'
                        })}
                        strokeWidth={12}
                        >
                        <CircularProgressbar
                            value={successPercentage}
                            styles={buildStyles({
                                pathColor: '#30BA34',
                                trailColor: 'transparent',
                                transform: 'rotate(-0.25turn)', // Adjust the rotation
                            })}
                            strokeWidth={12}
                        />
                        <CircularProgressbar
                            value={errorPercentage}
                            styles={buildStyles({
                                pathColor: '#D80029',
                                trailColor: 'transparent',
                                transform: 'rotate(-0.25turn)', // Adjust the rotation
                            })}
                            strokeWidth={12}
                        />
                    </CircularProgressbarWithChildren>
                </div>
                <div className="upload-progress-content">
                    <div>
                        <span>{Localization.Upload.Offcanvas.Progress.CircularContent.Total}</span>
                        <span>{Localization.Upload.Offcanvas.Progress.CircularContent.Uploaded}</span>
                        <span>{Localization.Upload.Offcanvas.Progress.CircularContent.Fail}</span>
                    </div>
                    <div className="upload-progress-detail">
                        <span>{props.totalFiles}</span>
                        <span>{props.uploadedFiles ? props.uploadedFiles.length : 0}</span>
                        <span>{props.errFiles ? props.errFiles.length : 0}</span>
                    </div>
                </div>
            </div>
            {
                props.uploadedFiles.length + props.errFiles.length === props.totalFiles
                ?
                    <div className="upload-progress-status">
                        {
                            props.errFiles.length > 0
                            ?
                                <div className="upload-progress-status-error">
                                    <h3 className="font-bold">{Localization.Upload.Offcanvas.Progress.Error}</h3>
                                    <p className="paragraph-1" style={{ marginBottom: 'unset'}}>There was an issue processing your files.</p>
                                    <p className="paragraph-1" style={{ marginBottom: '1.5rem'}}>{Localization.Upload.Offcanvas.Error.Desc2}</p>
                                    <hr/>
                                    <p className="paragraph-1" style={{ textAlign: 'left' }}>Check and ensure your file has not been moved, renamed or changed during upload.</p>
                                    <div className="upload-progress-status-filename-container">
                                        <div className="upload-progress-status-filename">
                                            {
                                                props.errFiles && props.errFiles.map((file) => {
                                                    return(<span key={file.id}>{file.name}</span>);
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="upload-progress-status-success">
                                        <h3 className="font-bold" style={{marginBottom: '8px'}}>{Localization.Upload.Offcanvas.Progress.Success}</h3>
                                        <p className="paragraph-1 store-info">{Localization.Upload.Offcanvas.Progress.SuccessDesc}</p>
                                </div>
                        }
                        {/* {!isTablet &&
                            <button className="btn btn-primary btn-done" onClick={handleProgress}>{Localization.Upload.Offcanvas.Progress.Button}</button>
                        } */}
                    </div>
                : null
            }

        </div>
    );
}

export default UploadProgress;
