import { spanishGray, iconSize } from '../../shared/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAudio } from '@fortawesome/free-solid-svg-icons'

const FileAudioIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;

    // Define the style for width and height
    const iconStyle = {
        width: size || iconSize,
        height: size || iconSize,
    };

    return (
        <FontAwesomeIcon className={className && className} icon={faFileAudio} style={iconStyle} color={colorVal}/>
    )
};

export default FileAudioIcon;