import { useContext } from 'react';
import { UploadContext } from '../../shared/context/upload-provider';
import { IsMobile, IsTablet, IsDesktop } from '../../shared/utils';
import Form from 'react-bootstrap/Form';
import Localization from '../../shared/localization';
import GridViewIcon from '../../icons/grid-view';
import ListViewIcon from '../../icons/list-view';
import CancelIcon from '../../icons/cancel';
import EditIcon from '../../icons/edit';
import TagIcon from '../../icons/tag';
import CollectionIcon from '../../icons/collection';
import Trash from '../../icons/trash';
import CustomIcons from '../../shared/components/custom-icons';
const UploadSettings = (props) => {
    const { activeSettingView,
        selectAllChecked,
        activeSetting, progressState,
        setProgressState, updateUploadError } = useContext(UploadContext);

    const isTablet = IsTablet();
    const isDesktop = IsDesktop();

    return(
        <>
            <div className={props.progressState ? 'upload-settings-wrapper upload-setting-progress-state' : props.stickySetting ? 'upload-settings-wrapper sticky' : 'upload-settings-wrapper'} ref={props.settingRef}>
                <div className='upload-settings-cont'>
                    {
                        props.progressState ? null
                        :
                            <div className='upload-settings-select-all'>
                                {
                                    !activeSetting || !isTablet
                                    ?
                                    <Form.Check
                                        type='checkbox'
                                        className={props.checkedFiles.length > 0 && !selectAllChecked && 'form-check-input-mixed'}
                                        id='select-all'
                                        label={selectAllChecked ? "All selected" : props.checkedFiles.length > 0 ? `${props.checkedFiles.length} selected` : "Select all"}
                                        onChange={props.onSelectAllChange}
                                        checked={selectAllChecked}
                                        disabled={props.progressState}
                                    />
                                    :
                                    !isDesktop && selectAllChecked ? <span className='paragraph-1'>All selected</span>:
                                    <span className='paragraph-1'>{ props.checkedFiles.length > 0 ? `${props.checkedFiles.length} ${isDesktop ? props.checkedFiles.length > 1 ? 'files' : 'file' : ''} selected` : Localization.Edit.NoFilesSelected}</span>
                                }
                            </div>
                    }
                    <div>
                        {
                            props.progressState ?
                                null
                            :
                            <>
                                <div className='upload-item upload-settings-edit'>
                                    <button className={props.isActive === 'edit' ? 'icon-button icon-button-text selected' : props.isEnable.edit ? 'icon-button icon-button-text enable' : 'icon-button icon-button-text'}  onClick={() => props.onHandleSetting('edit')}>
                                        <EditIcon />
                                        <span>Edit</span>
                                    </button>
                                </div>
                                <div className='upload-item upload-settings-tag'>
                                    <button className={props.isActive ==='tag' ? 'icon-button icon-button-text selected' : props.isEnable.tag ? 'icon-button icon-button-text enable' : 'icon-button icon-button-text'}  onClick={() => props.onHandleSetting('tag')}>
                                        <TagIcon />
                                        <span>Tag</span>
                                    </button>
                                </div>
                                <div className='upload-item upload-settings-collection'>
                                    <button className={props.isActive === 'collection' ? 'icon-button icon-button-text selected' : props.isEnable.collection ? 'icon-button icon-button-text enable' : 'icon-button icon-button-text'}  onClick={() => props.onHandleSetting('collection')}>
                                        <CollectionIcon />
                                        <span>Collection</span>
                                    </button>
                                </div>
                                <div className='upload-item upload-settings-delete'>
                                    <button className={props.isEnable.delete ? 'icon-button icon-button-text enable' : 'icon-button icon-button-text'}  onClick={() => props.onHandleSetting('delete')} >
                                        <Trash color="#000" />
                                        <span>Delete</span>
                                    </button>
                                </div>
                            </>
                        }
                        <div className={`upload-settings-view ${!isDesktop ? props.progressState ? 'mobile-progress-upload' : '' :''}`}>
                            {props.progressState &&
                            <button className={`upload-progress-btn ${isDesktop ? props.isActive === 'upload-progress' ? 'icon-button icon-button-text selected' : '' : ''}`} onClick={()=> props.onHandleSetting('uploadProgress')}>
                                <CustomIcons variant="downloading"/><span>Upload Progress</span>
                            </button>
                            }
                            <button className={activeSettingView === 'grid' ? 'grid-btn icon-button active' : 'grid-btn icon-button'} onClick={() => props.onHandleSetting('grid')}>
                                <GridViewIcon />
                            </button>
                            <button className={activeSettingView === 'list' ? 'list-btn icon-button active' : 'list-btn icon-button'} onClick={() => props.onHandleSetting('list')}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadSettings;