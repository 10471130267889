import ENDPOINTS from '../util/endpoints';

class SubDomainService {
    static async checkSubDomain(body) {
        const url = `${ENDPOINTS.SubDomain}`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });
        return fetchResponse;
    }
}

export default SubDomainService;