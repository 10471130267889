import { spanishGray, iconSize } from '../../shared/utils';
import HelpRounded from '@mui/icons-material/HelpRounded';

const HelpIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <HelpRounded className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default HelpIcon;