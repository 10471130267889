import { spanishGray, black, iconSize } from '../../shared/utils';

const CheckIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <svg 
            width="18" height="13" 
            viewBox="0 0 18 13" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg" 
            className={className && className} 
            style={{ color: colorVal }} 
            fontSize={sizeVal}
        >
            <path d="M6.00012 10.2001L2.50012 6.70007C2.11012 6.31007 1.49012 6.31007 1.10012 6.70007C0.710117 7.09007 0.710117 7.71007 1.10012 8.10007L5.29012 12.2901C5.68012 12.6801 6.31012 12.6801 6.70012 12.2901L17.3001 1.70007C17.6901 1.31007 17.6901 0.690068 17.3001 0.300068C16.9101 -0.0899316 16.2901 -0.0899316 15.9001 0.300068L6.00012 10.2001Z" fill={{ colorVal }} />
        </svg>
    )
};

export default CheckIcon;