import { spanishGray, black, iconSize } from '../../shared/utils';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const CancelIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <CancelRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default CancelIcon;