import { useState, useEffect, useRef, useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { ApiContext } from '../../shared/context/api-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { createAuditBody, formatTime } from '../../shared/utils';
import { SessionContext } from '../../shared/context/session-provider';
import { IsTablet } from '../../shared/utils';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MovieIcon from '@mui/icons-material/Movie';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import S3Utils from '../../../services/util/s3utils';
import AssetService from '../../../services/api/assetsService';
import ImageIcon from '../../icons/image';
import DocumentIcon from '../../icons/document';
import AssetCardSkeleton from '../asset-card-skeleton';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import SpinnerIcon from '../../icons/spinner';
import CustomIcons from '../../shared/components/custom-icons';
import MobileModal from '../../shared/components/mobile-modal';
import auditTrailService from '../../../services/api/auditTrailService';

const AssetCard = ({file, handleViewAssetOpen, handleRemoveFileModal, view, displayItems}) => {
    const vidRef= useRef();
    const audioRef = useRef();
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileUUID, setFileUUID] = useState(null);
    const [mime, setMime] = useState(null);
    const [duration, setDuration] = useState(null);
    const [status, setStatus] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

    const [playVideo, setPlayVideo] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const [videoContentType, setVideoContentType] = useState(null);

    const [displayGif, setDisplayGif] = useState(false);
    const [gifUrl, setGifUrl] = useState(null);

    const [audioContentType, setAudioContentType] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [playAudio, setPlayAudio] = useState(false);

    const [mute, setMute] = useState(true);
    const [key, setKey] = useState(null);

    //Check isTablet?
    const isTablet = IsTablet();

    let cardView = view == null? 'card' : view;

    const { getAssetsById } = useContext(ApiContext);

    const { tenantUuid, userUuid } = useContext(SessionContext);

    let formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const renderDocumentInnerBody = (mime, imageUrl) => {
        if (imageUrl) {
            return <img alt={fileName} src={ imageUrl }/>
        }
        if(mime === 'application/pdf') {
            return  <FontAwesomeIcon className="svg-icon" icon={faFilePdf} />;
        }
        return <FontAwesomeIcon className="svg-icon" icon={faFileWord} />;
    }

    const download = () => {
        setDownloading(true);
        AssetService.getAssetById(file.uuid, tenantUuid).then(asset => {
            S3Utils.getObject(asset.bucket, asset.key).then(response => {
                let fileBlob = new Blob([response.Body.buffer], {
                    type: response.ContentType,
                });
                downloadBlob(fileBlob, `${asset.basename}.${asset.key.split('.').pop()}`, true);
                setDownloading(false);
            });
        });
    }

    const downloadBlob = async (blob, name) => {
        if (
          window.navigator &&
          window.navigator.msSaveOrOpenBlob
        ) return window.navigator.msSaveOrOpenBlob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = name;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        if(tenantUuid && userUuid) {
            // Add download asset activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "asset", "download", { files: [name] });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }

    const onVideoMouseEnter = () => {
        if (vidRef.current) {
            setPlayVideo(true);
            vidRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the video
                const currentTime = vidRef.current.currentTime;
                const formattedRemainingTime = formatTime(vidRef.current.duration - currentTime);
                setDuration(formattedRemainingTime);
            });
            vidRef.current.play();
        }
    }

    const onVideoMouseLeave = () => {
        if (vidRef.current) {
            setPlayVideo(false);
            vidRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the video
                const duration = vidRef.current.duration;
                const formattedDuration = formatTime(duration);
                setDuration(formattedDuration);
            });
            vidRef.current.pause();
            vidRef.current.currentTime = 0;
            setMute(true);
        }
    }

    const onAudioMouseEnter = () => {
        if (audioRef.current) {
            setPlayAudio(true);
            audioRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const currentTime = audioRef.current.currentTime;
                const formattedRemainingTime = formatTime(audioRef.current.duration - currentTime);

                setDuration(formattedRemainingTime);
            });
            audioRef.current.play();
        }
    }

    const onAudioMouseLeave = () => {
        if (audioRef.current) {
            setPlayAudio(false);
            audioRef.current.addEventListener("timeupdate", function() {
                // Get the current time of the audio
                const duration = audioRef.current.duration;
                const formattedDuration = formatTime(duration);

                setDuration(formattedDuration);
            });
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setMute(true);
        }
    }

    const onGifMouseEnter = () => {
        if(!gifUrl) {
            return;
        }
        setDisplayGif(true);
    }

    const onGifMouseLeave = () => {
        if(!gifUrl) {
            return;
        }
        setDisplayGif(false);
    }

    const onPressMute = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (file.type == 'video') {
            setMute(!mute);
            vidRef.current.muted = mute;
        }
        if (file.type == 'audio') {
            setMute(!mute);
            audioRef.current.muted = mute;
        }
    }

    useEffect(() => {
        if(file.overallStatus === 'COMPLETED') {
            getAssetsById(file.uuid, tenantUuid).then(asset => {
                if(asset) {
                    setFileUUID(asset.uuid);
                    setKey(asset.key);
                    setStatus(asset.overallStatus);

                    if (asset.type === 'image') {
                        if (asset.mime === 'image/gif') {
                            const proxyurl = URL.createObjectURL(asset.proxyBlob);
                            const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                            setImageUrl(proxyurl);
                            setGifUrl(ingestUrl);
                            setFileName(asset.key);
                            setLoading(false);

                            return;
                        }

                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setFileName(asset.key);
                        setLoading(false);
                    }

                    if (asset.type === 'document') {
                        const url = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(url);
                        setFileName(asset.key);
                        setMime(asset.mime);
                        setLoading(false);
                    }

                    if (asset.type === 'video') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);
                        const proxyUrl = URL.createObjectURL(asset.proxyBlob);
                        setImageUrl(proxyUrl);
                        setVideoBlob(ingestUrl);
                        setVideoContentType(asset.mime);
                        setFileName(asset.key);
                        setDuration('00:00');
                        setLoading(false);
                    }

                    if (asset.type === 'audio') {
                        const ingestUrl = URL.createObjectURL(asset.ingestBlob);

                        setMime(asset.mime);
                        setFileName(asset.key);
                        setAudioContentType(asset.mime);
                        setAudioUrl(ingestUrl);
                        setDuration('00:00');
                        setLoading(false);
                    }
                } else {
                    setImageUrl(null);
                    setLoading(false);
                }

            });
        } else {
            setStatus(file.overallStatus);
            setLoading(false);
        };
    }, [file]);

    // for the first part of the long text
    const truncateFirstText = (fulltext) => {
        const truncatedFulltext = fulltext.slice(0, -7); // Remove the last 3 characters and the extension

        return truncatedFulltext;
    }

    // for the end part of the long text
    const truncateEndText = (fulltext) => {
        const extension = fulltext.substr(-4);
        const lastThreeText = fulltext.substring(fulltext.lastIndexOf('.') - 3, fulltext.lastIndexOf('.'));
        return `${lastThreeText}${extension}`;
    }

    const handleMobileModalClose = () => {
        setIsMobileModalOpen(false);
    }

    const Card = () => {
        return (<>
            {
                loading
                ?
                    <AssetCardSkeleton />
                :
                    status !== 'COMPLETED' ?
                        null
                    :
                        <div className={isTablet ? 'asset-card-wrapper col-md-4' : 'asset-card-wrapper col-md-3'} onMouseEnter={file.type === 'audio' ? onAudioMouseEnter : file.type === 'video' ? onVideoMouseEnter : null} onMouseLeave={file.type === 'audio' ? onAudioMouseLeave : file.type === 'video' ? onVideoMouseLeave : null}>
                            <div className='asset-card-container border'>
                                <div className={`asset-card-header ${file.type === 'image' && 'header-image' } ${file.type === 'video' ||  file.type === 'audio' ? 'header-video' : ''}`}>
                                    {
                                        fileName !== null &&
                                        <div className="grid-view-title-container">
                                            <div className="first-text">{truncateFirstText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                            <div className="end-text">{truncateEndText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                        </div>
                                    }
                                </div>
                                <div
                                    onClick={() => handleViewAssetOpen(fileUUID, displayItems)}
                                    className={loading ? 'asset-card-body asset-card-body-loading' : 'asset-card-body'}>
                                    {
                                        imageUrl &&
                                                file.type === 'image' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                            <div
                                                                onMouseEnter={onGifMouseEnter}
                                                                onMouseLeave={onGifMouseLeave}
                                                                className='ratio ratio-4x3'>
                                                                {
                                                                    fileName && (fileName.endsWith('.jpeg') || fileName.endsWith('.jpg') || fileName.endsWith('.png')) &&
                                                                        <img
                                                                            style={{ display: displayGif? 'none': 'block' }}
                                                                            alt={ fileName }
                                                                            src={ imageUrl }
                                                                        />
                                                                }
                                                                {
                                                                    fileName && fileName.endsWith('.gif') &&
                                                                        <>
                                                                            <img
                                                                                alt={ fileName }
                                                                                src={ gifUrl }
                                                                            />
                                                                            <div className='movie-footer'>
                                                                                {(fileName && fileName.endsWith('.gif')) && <div><CustomIcons className='my-svg' variant='gif' color="white"/></div>}
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    (fileName && fileName.endsWith('.jpg') || fileName && fileName.endsWith('.jpeg')) &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.jpg') || fileName && fileName.endsWith('.jpeg')) && <div><ImageIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                                {
                                                                    fileName && fileName.endsWith('.png') &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.png')) && <div><ImageIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'document' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>

                                                            <div className='ratio ratio-4x3'>
                                                                {renderDocumentInnerBody(mime, imageUrl)}
                                                                {
                                                                    fileName && fileName.endsWith('.pdf') &&
                                                                        <div className='movie-footer'>
                                                                            {(fileName && fileName.endsWith('.pdf')) && <div><DocumentIcon className='my-svg' color='white' /></div>}
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'video' ?
                                                    <div className='w-100 h-100'>
                                                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                            <div
                                                                // onMouseEnter={onVideoMouseEnter}
                                                                // onMouseLeave={onVideoMouseLeave}
                                                                className='ratio ratio-4x3'>
                                                                <video ref={ vidRef } muted={mute} playsInline>
                                                                    <source src={videoBlob} type="video/mp4"/>
                                                                </video>
                                                                <div className='volumn-icon-section' onClick={(e) => onPressMute(e)}
                                                                    style={{ display: playVideo? 'block': 'none' }} >
                                                                    {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                                                </div>
                                                                <div className='movie-footer'>
                                                                    <div>
                                                                        <MovieIcon className='my-svg' color='white' />
                                                                    </div>
                                                                    <p>{duration}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                file.type === 'audio' ?
                                                    <div className='w-100 h-100'>
                                                            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                                                <div
                                                                    // onMouseEnter={onAudioMouseEnter}
                                                                    // onMouseLeave={onAudioMouseLeave}
                                                                    className='ratio ratio-4x3'>
                                                                    <div className='svg-icon'>
                                                                        <FontAwesomeIcon className='svg-icon' icon={faFileAudio} />
                                                                        <audio muted={mute} ref={audioRef}>
                                                                            <source src={audioUrl} type={audioContentType}/>
                                                                        </audio>
                                                                    </div>
                                                                    <div className='volumn-icon-section' onClick={(e) => onPressMute(e)}
                                                                            style={{ display: playAudio ? 'inline': 'none' }} >
                                                                        {mute ? <VolumeOffIcon /> : <VolumeUpIcon />}
                                                                    </div>
                                                                    <div className='movie-footer'>
                                                                        <div>
                                                                            <AudiotrackRoundedIcon className='my-svg' color='white' />
                                                                        </div>
                                                                            <p>{duration}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                    <div>
                                                        {/* <QuestionMarkIcon className='upload-card-icon' /> */}
                                                    </div>
                                    }
                                    </div>
                                    <div className='asset-card-footer'>
                                        <div className="d-grid">
                                            <ButtonGroup className="d-flex">
                                                {downloading ? <CustomIcons variant="spinner" />
                                                    : <Button className='icon-button' variant='link' onClick={() => download()}><CustomIcons variant="download" /></Button>}
                                            </ButtonGroup>
                                        </div>
                                    </div>
                            </div>
                        </div>
            }
        </>);
    }

    const Row = () => {
        return (<>
            {
                loading
                ?
                    <AssetCardSkeleton view="row"/>
                :
                    status !== 'COMPLETED' ?
                        null
                    :
                        <tr>
                            <td onClick={() => handleViewAssetOpen(fileUUID, displayItems)}>
                            {
                                imageUrl &&
                                        file.type === 'image' ?
                                            <div>
                                                <img
                                                    alt={ fileName }
                                                    src={ imageUrl }
                                                />
                                            </div>:
                                        file.type === 'document' ?
                                            <div>
                                                {renderDocumentInnerBody(mime, imageUrl)}
                                            </div> :
                                        file.type === 'video' ?
                                            <div>
                                                {imageUrl !== null && <div>
                                                    <img
                                                    alt={ fileName }
                                                    src={ imageUrl } />
                                                </div>}
                                                {imageUrl === null &&
                                                    <div className='svg-icon'>
                                                        <MovieIcon fontSize='large' />
                                                    </div>}
                                            </div> :
                                        file.type === 'audio' ?
                                        <div className='row-sound-icon-container'>
                                            <FontAwesomeIcon className='svg-icon' icon={faFileAudio} />
                                        </div> :
                                        <div>
                                        </div>
                                }
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    <div className="list-view-title-container">
                                        {/* <div className="first-text">{truncateFirstText(fileName.basename)}</div>
                                        <div className="end-text">{truncateEndText(fileName.basename)}</div> */}
                                        <div className="first-text">{truncateFirstText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                        <div className="end-text">{truncateEndText(fileName.substring(fileName.lastIndexOf("/") + 1))}</div>
                                    </div>
                                    <div className="mobile-type-and-size-container">
                                        <span className="mobile-type">
                                            {file.type[0].toUpperCase() + file.type.slice(1, file.type.length)} {file.mime === 'image/gif' ? '(GIF)' : ''}
                                        </span>
                                        <span className="mobile-size">{formatBytes(file.size)}</span>
                                    </div>
                                </div>
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    {file.type[0].toUpperCase() + file.type.slice(1, file.type.length)} {file.mime === 'image/gif' ? '(GIF)' : ''}
                                </div>
                            </td>
                            <td className='text-left'>
                                <div className="td-assets">
                                    {formatBytes(file.size)}
                                </div>
                            </td>
                            <td className='text-left button-group'>
                                <div className="td-assets">
                                    <div className="mobile-more-vertical" onClick={() => setIsMobileModalOpen(true)} style={{ transform: "rotate(90deg)" }}>
                                        <CustomIcons variant="more" />
                                    </div>
                                    <Button size='sm' className="btn-row-action" onClick={() => handleViewAssetOpen(fileUUID, displayItems)} variant='link'><RemoveRedEyeIcon /></Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => download()} variant='link'>
                                        {downloading && <SpinnerIcon />}
                                        {!downloading && <CustomIcons variant="download" />}
                                    </Button>
                                </div>
                            </td>
                            {
                                isTablet &&
                                    <hr />
                            }
                        </tr>
            }
            {
                isMobileModalOpen && (
                    <tr>
                        <td>
                            <MobileModal titleWithExtension={fileName} onClose={handleMobileModalClose}>
                                <div className="mobile-modal-more-vertical">
                                    <Button size='sm' className="btn-row-action" onClick={() => { handleMobileModalClose(); handleViewAssetOpen(fileUUID, displayItems); }} variant='link'>
                                        <RemoveRedEyeIcon />
                                        <h3>View</h3>
                                    </Button>
                                    <Button className="btn-row-action icon-button" size='sm' onClick={() => download()} variant='link'>
                                        {downloading && <SpinnerIcon />}
                                        {!downloading &&
                                            <>
                                                <CustomIcons variant="download" />
                                                <h3>Download</h3>
                                            </>
                                        }
                                    </Button>
                                </div>
                            </MobileModal>
                        </td>
                    </tr>
                )
            }
        </>);
    }

    return cardView === "row"? Row() : Card();
}

export default AssetCard;