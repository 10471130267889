// breakpoints must be same as SASS variables
export const BREAKPOINTS = {
  SM: 576,
  SM_UP: 577,
  MD: 768,
  MD_UP: 769,
  LG: 1024,
  LG_UP: 1025,
  S_LG: 1200,
  MD_LG: 1440,
  X_LG: 1480,
  XL: 1920
};

export const DEVICE_WIDTH = {
  SM: BREAKPOINTS.SM,
  SM_UP: BREAKPOINTS.SM_UP,
  MD: BREAKPOINTS.MD,
  MD_UP: BREAKPOINTS.MD_UP,
  LG: BREAKPOINTS.LG,
  LG_UP: BREAKPOINTS.LG_UP,
  S_LG: BREAKPOINTS.S_LG,
  MD_LG: BREAKPOINTS.MD_LG,
  X_LG: BREAKPOINTS.X_LG,
  XL: BREAKPOINTS.XL
}

export const HEADER_HEIGHT = {
  DESKTOP: 0
};
