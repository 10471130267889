/* eslint-disable no-undef */
import ServiceUtil from '../util/serviceUtil';
import ENDPOINTS from '../util/endpoints';
import { createAuditBody } from '../../components/shared/utils';
import auditTrailService from './auditTrailService';

class UsersService {
    static async verifyUserBackupCodes(tenantUuid, userUuid, code) {
        const body = {
            userUuid: userUuid,
            tenantUuid: tenantUuid,
            code: code
        };

        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.VerifyUserBackupCodes, {}, body, tenantUuid);
    }

    static async getUserBackupCodes(tenantUuid, userUuid) {
        const query = {
            userUuid: userUuid,
            tenantUuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.UserBackupCodes, query, '', tenantUuid);
    }

    static async generateNewUserBackupCodes(tenantUuid, userUuid) {
        const body = {
            userUuid: userUuid,
            tenantUuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('PUT', ENDPOINTS.UserBackupCodes, {}, body, tenantUuid);
    }

    static async generateUserBackupCodes1stTimeLogin(tenantUuid, userUuid) {
        const body = {
            userUuid: userUuid,
            tenantUuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.UserBackupCodes, {}, body, tenantUuid);
    }

    static async sendReset2faEmail(email, customerAdminName, tenantUuid, subDomain) {
        const body = {
            email: email,
            customerAdminName: customerAdminName,
            subDomainName: subDomain,
            tenantUuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.Reset2FA, {}, body, tenantUuid);
    }

    static async freeTrial(tenantUuid) {
        const query = {
            uuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.FreeTrial, query, '', tenantUuid);
    }

    static async getUsers(tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.Users, {}, '', tenantUuid);
    }

    static async addUsers(users, tenantUuid) {
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.Users, undefined, users, tenantUuid);
    }

    static async deleteUser(user, tenantUuid) {
        return ServiceUtil.authHttpRequest('DELETE', ENDPOINTS.Users, {
            user
        }, '', tenantUuid);
    }

    static async userListing(tenantUuid, filter) {
        const query = {
            tenantUuid: tenantUuid,
            searchFullname: filter.searchFullname,
            startDate: filter.startDate,
            endDate: filter.endDate,
            role: filter.role
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.UserListing, query, "", tenantUuid);
    };

    static async getUserGroup(tenantUuid) {
        const query = {
            uuid: tenantUuid
        }
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.UserGroup, query, '', tenantUuid);
    }

    static async addUserGroup(tenantUuid, params) {
        const body = {
            uuid: tenantUuid,
            newUserGroups: params
        }
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.UserGroup, undefined, body, tenantUuid);
    }

    static async deleteUserGroup(tenantUuid, params) {
        const body = {
            uuid: tenantUuid,
            oldUserGroups: params
        }
        return ServiceUtil.authHttpRequest('DELETE', ENDPOINTS.UserGroup, undefined, body, tenantUuid);
    }

    static async editUserGroup(tenantUuid, oldUserGroups, newUserGroups) {
        const body = {
            tenantUuid,
            oldUserGroups,
            newUserGroups
        }

        return ServiceUtil.authHttpRequest('PUT', ENDPOINTS.UserGroup, undefined, body, tenantUuid);
    }

    static async userApprover(params) {
        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.UserApprover, undefined, params, params.uuid);
        if(response.code === 200) {
            // Add reject user/s activity to audit trail
            const options = {
                users: params.users.map(user => ({
                    name: user.name
                }))
            };
            const auditBody = createAuditBody(params.uuid, params.userUuid, "user", params.approveStatus === true ? "approve" : "reject", options);
            await auditTrailService.addActivity(auditBody, params.uuid);
        }

        return response;
    }

    static async listUserRequest(tenantUuid) {
        const query = {
            uuid: tenantUuid
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.UserApprover, query, '', tenantUuid);
    }

    static async userSettings(params) {
        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.UserSettings, undefined, params, params.uuid);

        if(response.code === 200) {
            // Edit user activity to audit trail
            const {
                uuid,
                users,
                currentUser,
                name,
                oldRoles,
                status
                } = params;
            if(status === "update") {
                const options = {
                    users: [
                        {
                            name: name,
                            role: users ? users[0].roles.join(', ') : "",
                            oldrole: oldRoles
                        }
                    ]
                };
                const auditBody = createAuditBody(uuid, currentUser, "user", "edit", options);
                await auditTrailService.addActivity(auditBody, uuid);
            } else if(status === "delete") {
                const options = {
                    users: users.map(user => ({
                        name: user.name,
                        role: user.roles
                    }))
                };
                const auditBody = createAuditBody(uuid, currentUser, "user", "delete", options);
                await auditTrailService.addActivity(auditBody, uuid);
            }
        }

        return response;
    }

    static async metaData() {
        const url = `${ENDPOINTS.MetaData}`;
        const requestOptions = {
            method: 'GET'
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });
        return fetchResponse;
    };

    static async extentFreeTrial(tenantUuid, userUuid) {
        const body = {
            uuid: tenantUuid,
        };
        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.ExtentFreeTrial, undefined, body, tenantUuid);

        if(response.code === 200) {
            // Add extend freetrial activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "plan", "extend");
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    };

    static async userProfile(body) {
        const requestOptions = {
            userUuid: body.userUuid,
            tenantUuid: body.tenantUuid,
            fullname: body.fullname,
        };
        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.userProfile, undefined, requestOptions, body.tenantUuid);

        if(response.status === 200) {
            // Add account edit activity to audit trail
            const auditBody = createAuditBody(body.tenantUuid, body.userUuid, "account", "edit");
            await auditTrailService.addActivity(auditBody, body.tenantUuid);
        }

        return response;
    }
}

export default UsersService;
