import { black, iconSize } from '../../shared/utils';
import IosShareIcon from '@mui/icons-material/IosShare';

const IosShare = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <IosShareIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default IosShare;