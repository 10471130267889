import ServiceUtil from '../util/serviceUtil.js';
import ENDPOINTS from '../util/endpoints';
import auditTrailService from './auditTrailService.js';
import { createAuditBody } from '../../components/shared/utils.js';

class StripeService {
    static async stripeAdditionalUser(customer_id, tenantUuid, paramsUser, status, userUuid) {
        const body = {
            customer_id: customer_id,
            uuid: tenantUuid,
            users: paramsUser,
            status: status
        };

        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.AdditionalUser, undefined, body, tenantUuid);

        if(response.statusCode === 200) {
            if(status) {
                // Add approve new user/s activity to audit trail
                const options = {
                    users: paramsUser.map(user => ({
                        name: user.name,
                        role: user.roles.join(',')
                    }))
                };
                const auditBody = createAuditBody(tenantUuid, userUuid, "user", "approve", options);
                await auditTrailService.addActivity(auditBody, tenantUuid);
            } else {
                // Add reject user activity to audit trail
                const options = {
                    users: paramsUser.map(user => ({
                        name: user.name,
                        role: user.roles
                    }))
                };
                const auditBody = createAuditBody(tenantUuid, userUuid, "user", "delete", options);
                
                await auditTrailService.addActivity(auditBody, tenantUuid);
            }
        }

        return response;
    };

    static async stripeGetAdditionalUserInvoices(customer_id, tenantUuid, quantity) {
        const query = {
            customer_id: customer_id,
            uuid: tenantUuid,
            quantity: quantity
        };

        const response = await ServiceUtil.authHttpRequest('GET', ENDPOINTS.AdditionalUser, query, '', tenantUuid);

        return response;
    };

    static async stripeListInvoice(customer_id, tenantUuid, limit, startingAfterId) {
        const query = startingAfterId != null ? {
            customer_id: customer_id,
            uuid: tenantUuid,
            limit: limit,
            startingAfterId: startingAfterId
        } : {
            customer_id: customer_id,
            uuid: tenantUuid,
            limit: limit
        }

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.StripeListInvoices, query, '', tenantUuid);
    };

    static async stripeGetId(tenantUuid) {
        const query = {
            uuid: tenantUuid
        }
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.StripeGetId, query, '', tenantUuid);
    }

    static async stripeListProductPlan() {
        const url = `${ENDPOINTS.StripeListProductPlan}`;
        const requestOptions = {
            method: 'GET'
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });
        return fetchResponse;
    }

    static async stripePaidPlan(customer_id, tenantUuid) {
        const query = {
            customer_id: customer_id,
            uuid: tenantUuid
        }
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.StripePaidPlan, query, '', tenantUuid);
    }

    static async stripeCancelSubscription(customer_id, tenantUuid, userUuid) {
        const body = {
            customer_id: customer_id,
            uuid: tenantUuid
        };

        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.StripePaidPlan, undefined, body, tenantUuid);
        if(response.code === 200) {
            // Add cancel plan activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "plan", "cancel");
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async stripeGetPaymentMethods(customer_id, tenantUuid) {
        const query = {
            customer_id: customer_id,
            uuid: tenantUuid
        }
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.StripePaymentMethods, query, '', tenantUuid);
    }

    static async stripeSetDefaultPaymentMethods(customer_id, payment_method_id, last4cardno, tenantUuid, userUuid) {
        const body = {
            customer_id: customer_id,
            payment_method_id: payment_method_id,
            uuid: tenantUuid
        };

        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.StripePaymentMethods, undefined, body, tenantUuid);
        if(response.code === 200) {
            // Add default plan payment activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "plan", "change", { cardno: last4cardno });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async stripeAttachPaymentMethods(customer_id, payment_method_id, tenantUuid, userUuid, last4cardno) {
        const body = {
            customer_id: customer_id,
            payment_method_id: payment_method_id,
            uuid: tenantUuid
        };

        const response = await ServiceUtil.authHttpRequest('PUT', ENDPOINTS.StripePaymentMethods, undefined, body, tenantUuid);
        if(response.code === 200) {
            // Add add plan payment activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "plan", "add", { cardno: last4cardno });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async stripeDeletePaymentMethods(customer_id, payment_method_id, last4cardno, tenantUuid, userUuid) {
        const body = {
            customer_id: customer_id,
            payment_method_id: payment_method_id,
            uuid: tenantUuid
        };

        const response = await ServiceUtil.authHttpRequest('DELETE', ENDPOINTS.StripePaymentMethods, undefined, body, tenantUuid);
        if(response.code === 200) {
            // Add delete plan payment activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "plan", "delete", { cardno: last4cardno });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }
}

export default StripeService;