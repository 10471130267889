import { spanishGray, black, iconSize } from '../../shared/utils';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const ArrowIcon = ({ direction, double, className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;
    const directionVal = direction || "down";
    const doubleVal = typeof double === 'boolean' ? double : false;

    return (
        <>
            {
                    doubleVal
                    ?
                            directionVal === "up"
                        ?
                            <KeyboardDoubleArrowUpRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "down"
                        ?
                            <KeyboardDoubleArrowDownRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "left"
                        ?
                            <KeyboardDoubleArrowLeftRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "right"
                        ?
                            <KeyboardDoubleArrowRightRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            <KeyboardDoubleArrowDownRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                    :
                            directionVal === "up"
                        ?
                            <KeyboardArrowUpRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "down"
                        ?
                            <KeyboardArrowDownRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "left"
                        ?
                            <KeyboardArrowLeftRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "right"
                        ?
                            <KeyboardArrowRightRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "back"
                        ?
                            <ArrowBackRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            directionVal === "expand"
                        ?
                            <ExpandMoreRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                        :
                            <KeyboardArrowDownRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default ArrowIcon;