import auditTrailService from '../../services/api/auditTrailService';
import {
    DEVICE_WIDTH,
    HEADER_HEIGHT,
} from './constants';
import { useMediaQuery } from 'react-responsive'

export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export async function downloadBlob (blobUrl, name, revokeURL, tenantUuid, userUuid) {
    if (
      window.navigator &&
      window.navigator.msSaveOrOpenBlob
    ) return window.navigator.msSaveOrOpenBlob(blobUrl);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    if(tenantUuid && userUuid) {
        // Add download asset activity to audit trail
        const auditBody = createAuditBody(tenantUuid, userUuid, "asset", "download", { files: [name] });
        await auditTrailService.addActivity(auditBody, tenantUuid);
    }

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      if(revokeURL)
        window.URL.revokeObjectURL(blobUrl);
      link.remove();
    }, 100);
}

export function randomHexstring() {
    const rnd = new Uint32Array(1);
    (window.crypto || window.msCrypto).getRandomValues(rnd);
    return rnd[0].toString(16);
}

export function getFileType(fileName) {
    var fileExtension = fileName.split('.').pop().toLowerCase();

    // Define file types based on their extensions
    var imageExtensions = ['jpg', 'jpeg', 'png'];
    var gifExtensions = ['gif'];
    var pdfExtensions = ['pdf'];
    var videoExtensions = ['mp4', 'mov'];
    var audioExtensions = ['wav', 'mp3'];

    // Check if the file extension belongs to any of the defined types
    if (imageExtensions.includes(fileExtension)) {
        return 'image';
    } else if (gifExtensions.includes(fileExtension)) {
        return 'gif';
    } else if (videoExtensions.includes(fileExtension)) {
        return 'video';
    } else if (audioExtensions.includes(fileExtension)) {
        return 'audio';
    } else if (pdfExtensions.includes(fileExtension)) {
        return 'pdf';
    } else {
        return 'unknown'; // If the file type is not recognized
    }
}

export function formatText(name) {
    const words = name.split('.');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    const result = capitalizedWords.join(' ');

    return result
};

export function sanitizeInput(restrictedCharsRegex, input) {
    return input.replace(restrictedCharsRegex, '');
}

export function getElementTop(el) {
    return el.offsetTop + (el.offsetParent && getElementTop(el.offsetParent));
}

export function scrollToElement(el, customOffset = 0) {
    if (el) {

      const elOffset = getElementTop(el);
      const headerHeight = IsDesktop() ? HEADER_HEIGHT.DESKTOP : HEADER_HEIGHT.MOBILE;
      const offsetTop = elOffset - headerHeight - customOffset;

      document.documentElement.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
    }
}

export function convertPdfToImage (pdfData, getDocument, updatepdfLoading, updatepdfImg) {
    getDocument({ data: pdfData }).promise.then(function (pdf) {
        return pdf.getPage(1);
    }).then(function (page) {
        var viewport = page.getViewport({ scale: 1.0 });

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({ canvasContext: context, viewport: viewport }).promise.then(function () {
            var imageSrc = canvas.toDataURL();
            updatepdfImg(imageSrc);
            updatepdfLoading();
        });
    }).catch(function (error) {
        console.log('Error: ', error);
    });
};

export function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export function secondsToTime(durationInMs, hhmmss = true) {
    const HH = Math.floor(durationInMs / 3600000);
    const MM = Math.floor((durationInMs % 3600000) / 60000);
    const SS = Math.floor((durationInMs % 60000) / 1000);
    const mmm = Math.ceil(durationInMs % 1000);
    let duration;

    if(HH) {
        duration = `${HH.toString().padStart(2, '0')}:${MM.toString().padStart(2, '0')}:${SS.toString().padStart(2, '0')}`;
    } else {
        duration = `${MM.toString().padStart(2, '0')}:${SS.toString().padStart(2, '0')}`;
    }
    return (hhmmss)
    ? duration
    : `${duration}.${mmm.toString().padStart(3, '0')}`;
};

export function generateRandomPassword() {
    const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()';

    const allCharacters = lowerCaseLetters + upperCaseLetters + numbers + specialCharacters;

    let password = '';

    password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];

    password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];

    for (let i = 0; i < 6; i++) {
      password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    return password;
};

export function formatDateTime(dateObject) {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(dateObject);
    date.setUTCHours(date.getUTCHours() + 8);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);

    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
};

export function isDateInRange(date, from, to) {
    const dateObject = new Date(date);
    dateObject.setHours(0, 0, 0, 0);

    const fromObject = new Date(from);
    fromObject.setHours(0, 0, 0, 0);

    const toObject = new Date(to);
    toObject.setHours(0, 0, 0, 0);

    return dateObject >= fromObject && dateObject <= toObject;
}

// Color
export const spanishGray = "#989595";
export const candyRed = "#D80029";
export const black = "#000000";

// Icon Default Size
export const iconSize = "medium";

//Responsive
export const IsDesktop = () => {
    return useMediaQuery({
        query: `(min-width: ${DEVICE_WIDTH.LG_UP}px)`
    });
};

export const IsSLG = () => {
    return useMediaQuery({
        query: `(max-width: ${DEVICE_WIDTH.S_LG}px)`
    });
};

export const IsTablet = () => {
    return useMediaQuery({
        query: `(max-width: ${DEVICE_WIDTH.LG}px)`
    });
};

export const IsMobile = () => {
    return useMediaQuery({
        query: `(max-width: ${DEVICE_WIDTH.MD}px)`
    });
};

export const IsXLG_To_MDTablet = () => {
    return useMediaQuery({
        query: `(min-width: ${DEVICE_WIDTH.LG_UP}px) and (max-width: ${DEVICE_WIDTH.MD_LG}px)`
    });
};

export const IsXLG_To_Tablet = () => {
    return useMediaQuery({
        query: `(min-width: ${DEVICE_WIDTH.LG_UP}px) and (max-width: ${DEVICE_WIDTH.X_LG}px)`
    });
};

export const createAuditBody = (tenantUuid, userUuid, category, activitytype, options = {}) => {
    let auditBody = {
        tenantUuid: tenantUuid,
        createdby: userUuid,
        category: category,
        activitytype: activitytype,
    };

    if (category === 'plan') {
        auditBody.cardno = options.cardno;
    } else if (category === 'collection') {
        auditBody.collection = options.collection;
    } else if (category === 'user') {
        auditBody.users = options.users;
    } else if (category === 'asset') {
        auditBody.files = options.files;
    } else if (category === 'account') {
        auditBody.users = options.users;
    }

    return auditBody;
};

export const convertImageUrl = (url) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function() {
        // Convert the image to JPG format
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((jpgBlob) => {
        // Create a new File object from the JPG blob
        const jpgFile = new File([jpgBlob], 'image.jpg', { type: 'image/jpeg' });
        // Resolve the promise with the new JPG File object
        resolve({ isImage: true, file: jpgFile });
        }, 'image/jpeg');
      };
      img.onerror = function() {
        // The image did not load (the URL is not valid or the image is not accessible)
        resolve({ isImage: false, file: null });
        reject(new Error('The image could not be loaded.'));
      };
      img.src = url;
    });
  };

  export const checkProtocol = (urlWithoutProtocol) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve('https');
      img.onerror = () => {
        img.onload = () => resolve('http');
        img.onerror = () => reject(new Error('Neither HTTP nor HTTPS worked'));
        img.src = `http://${urlWithoutProtocol}`;
      };
      img.src = `https://${urlWithoutProtocol}`;
    });
  };

  export const isImageUrl = (url) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = function() { resolve(true); };
      img.onerror = function() { resolve(false); };
      img.src = url;
    });
  }
  