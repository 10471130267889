import { useState, useEffect, useContext } from 'react';
import { throttle } from 'lodash';
import { SessionContext } from '../../shared/context/session-provider';
import { ShareContext } from '../../shared/context/share-state';
import { ApiContext } from '../../shared/context/api-state';
import { IsTablet } from '../../shared/utils';
import _ from 'lodash';
import Table from 'react-bootstrap/Table';
import CustomIcons from '../../shared/components/custom-icons';
import GridViewIcon from '../../icons/grid-view';
import AssetCard from '../asset-card';
import AssetCardSkeleton from '../asset-card-skeleton';
import AssetFilter from '../asset-filter';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SettingNavbar from '../../shared/components/setting-navbar';
import MobileFilter from '../../shared/components/mobile-filter';
import MobileType from '../../shared/components/mobile-type';
import SolutionManifest from '../../../solution-manifest';
import Skeleton from 'react-loading-skeleton';

const SearchResultList = (props) => {
    // Account Context
    const { tenantUuid } = useContext(SessionContext);

    // Placeholder Skeleton Length
    const placeholderSkeleton = 4;

    const [data, setData] = useState([]);

    const { selectedOption, selectedKeywords } = props;

    const { searchAssetsApi }= useContext(ApiContext);

    const { mobileView,
        setMobileView,
        displayFilters,
        setDisplayFilters,
        clearFilters,
        view,
        setView,
        setFilterState,
        isMobileModalTypeOpen,
        isMobileModalFilterOpen,
        sortMobile,
        setSortByMobile,
        filters,
        currentPage,
        setCurrentPage,
        totalPages,
        loading,
        setLoading,
        fetching,
        setIsFetching,
        numFilters,
        type,
        isFilter,
        PAGE_SIZE,
        onClickFilters,
        getData,
        displaySearchItems,
        setDisplaySearchItems,
        minFileSize,
        maxFileSize,
        setAllSearchItems,
        setType,
        MAX_PAGE_SIZE,
        sort,
        setFilters,
        setTotalPages,
        pdFrom,
        pdTo,
        resetDefaultState,
        resetState,
        handleMobileModalTypeOpen,
        handleMobileModalFilterOpen,
        applyFilters,
        setIsSearch,
        imageSearchUrl,
        imageSearchLabels,
        isImageSearch
     } = useContext(ShareContext);

    const [sortFilenameOrder, setSortFilenameOrder] = useState('asc');
    const [sortTypeOrder, setSortTypeOrder] = useState('asc');
    const [sortSizeOrder, setSortSizeOrder] = useState('asc');    
    const [imgSrc, setImgSrc] = useState('');

    const isTablet = IsTablet();

    if(!imageSearchUrl) {
        const openRequest = indexedDB.open("image-search", 1);
    
        openRequest.onupgradeneeded = function(event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("imageFile")) {
                db.createObjectStore("imageFile");
            }
        };

        openRequest.onsuccess = function() {
            const db = openRequest.result;
            const transaction = db.transaction("imageFile", "readonly");
            const imageFile = transaction.objectStore("imageFile");
            const getRequest = imageFile.get("file");
    
            getRequest.onsuccess = function() {
                const base64Image = getRequest.result;
                if (typeof base64Image === 'string') {
                    // Call setImgSrc with the Base64 string
                    setImgSrc(base64Image);
                }
            };
        };
    }

    useEffect(() => {
        // Check if data has at least one item
        if (data && data.length > 0 && loading) {
          setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        setLoading(true);
        setData([]);
        resetDefaultState();

        if (!selectedKeywords && !isImageSearch) {
            setLoading(false);
            return;
        }

        const homeTab = document.getElementById('menu-tabs-id-tab-search');
        if (homeTab) {
            homeTab.className = homeTab.className.replace(' active', '')
        }
          setType(selectedOption === undefined?"":selectedOption);
    }, [selectedOption, selectedKeywords, sort]);

    useEffect(() => {
        searchAssets(filters);
    }, [resetState]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    const searchAssets = async (filterType) => {
        const jsonString = JSON.stringify(imageSearchLabels);
        const encodedString = encodeURIComponent(jsonString);

        const options = {
            query: selectedKeywords === undefined ? "" : selectedKeywords,
            audio: selectedOption === 'audio' || selectedOption === '',
            document: selectedOption === 'document' || selectedOption === '',
            image: selectedOption === 'image' || selectedOption === '',
            video: selectedOption === 'video' || selectedOption === '',
            keyphrase: SolutionManifest.SearchOptions.keyphrase,
            text: SolutionManifest.SearchOptions.text,
            label: SolutionManifest.SearchOptions.label,
            customlabel: SolutionManifest.SearchOptions.customlabel,
            pageSize: MAX_PAGE_SIZE,
            ingest: SolutionManifest.SearchOptions.ingest,
            sort:  isTablet ? filterType?.clearFilter ? 1 : sortMobile : sort,
            tenantUuid: tenantUuid,
            labels: imageSearchLabels.length > 0 ? encodedString : '',
            matchingTagsCount: SolutionManifest.matchingTagsCount
        };

        let minFileSizeInBytes = null;
        let maxFileSizeInBytes = null;

        if (minFileSize != null) {
            minFileSizeInBytes = minFileSize * 1048576;
        }

        if (maxFileSize != null) {
            maxFileSizeInBytes = maxFileSize * 1048576;
        }

        let filterAssets = {
            group: tenantUuid,
            pageSize: PAGE_SIZE,
            types: filterType?.changeType ? filterType?.type : type,
            publishedDates: filterType?.clearFilter ? filterType?.publishedDates : [pdFrom, pdTo],
            minFileSize: filterType?.clearFilter ? filterType?.minFileSize : minFileSizeInBytes,
            maxFileSize: filterType?.clearFilter ? filterType?.maxFileSize : maxFileSizeInBytes,
            sort: isTablet ? filterType?.clearFilter ? 1 : sortMobile : sort
        };

        searchAssetsApi(options).then((assets) => {
            setFilters(filterAssets);
            setAllSearchItems(assets ? assets : []);
            setData(assets ? assets : []);
            let pageData = getData(assets ? assets : [], filterAssets, 1, PAGE_SIZE);
            setCurrentPage(1);
            setTotalPages(pageData ? pageData.totalPages : 0);
            setDisplaySearchItems(pageData ? pageData.data : []);
            setIsFetching(false);
            setIsSearch(true);
            setLoading(false);
        });
    };

    const handleScroll = throttle(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const offset = 300;

        if (scrollTop + clientHeight >= scrollHeight - offset) {
            if (loading || fetching || currentPage >= totalPages) {
                return;
            }
            loadItems();
        }
    }, 200);

    const loadItems = () => {
        if (fetching) {
            return;
        }

        setIsFetching(true);

        let page = currentPage + 1;

        if (page > PAGE_SIZE) {
            setIsFetching(false);
            return;
        }
        if (!selectedKeywords) {
            return;
        }
        let pageData = getData(data ,filters, page, PAGE_SIZE);
        setCurrentPage(page);
        setDisplaySearchItems([...displaySearchItems, ...pageData.data]);
        setIsFetching(false);

        return;
    };

    const changeView = (view) => {
        setView(view);
    };

    const handleSortByMobile = (value) => {
        setSortByMobile(value);
    }

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return <AssetCardSkeleton key={index}/>;
        });
    };

    const sortByFileName = () => {
        setSortFilenameOrder(sortFilenameOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const nameA = a.basename.toLowerCase();
            const nameB = b.basename.toLowerCase();

            return sortFilenameOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setDisplaySearchItems(sortedAsset);
    }

    const sortByType = () => {
        setSortTypeOrder(sortTypeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const typeA = a.type.toLowerCase();
            const typeB = b.type.toLowerCase();

            return sortTypeOrder === 'asc' ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        });

        setDisplaySearchItems(sortedAsset);
    }

    const sortBySize = () => {
        setSortSizeOrder(sortSizeOrder === 'asc' ? 'desc' : 'asc');

        const sortedAsset = [...displaySearchItems].sort((a, b) => {
            const sizeA = a.fileSize;
            const sizeB = b.fileSize;

            return sortSizeOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
        });

        setDisplaySearchItems(sortedAsset);
    }

    const renderPlaceholderSkeletonListView = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
          // Replace the return statement with your desired component or JSX logic
          return(<div className='asset-card-skeleton-list-view col-12'>
                    <div>
                        <div className='skeleton-list-view'>
                            <div>
                                <Skeleton height={60} />
                            </div>
                            <div>
                                <Skeleton height={60} />
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="container search-result-list">
            <section className="pt-5 pb-5">
                <h3 className='search-title'>
                    {imgSrc && selectedKeywords.length === 0 ? (
                        "Search by image"    
                    ) : (
                        "Search Results"
                    )}
                </h3>                
                {!loading && imgSrc && selectedKeywords.length === 0 && (
                <div className='search-image-container'>
                    <img src={imgSrc} alt="My File" />
                </div>
                )}             
                {imageSearchUrl && selectedKeywords.length === 0 && (
                <div className='search-image-container'>
                    <img src={imageSearchUrl} alt="My File" />
                </div>
                )}
                <h2 className="h2-mb-48 search-keyword">{selectedKeywords}</h2>
                <div className="row asset-view-container">
                    <div className="mobile-type-active-container" onClick={() => { handleMobileModalTypeOpen(); setDisplayFilters(false); setFilterState(false); }}>
                        <h3 id="mobile-type-active">
                            { type === '' && 'All Assets' }
                            { type === 'image' && 'Images' }
                            { type === 'video' && 'Videos' }
                            { type === 'document' && 'Documents' }
                            { type === 'audio' && 'Audio' }
                        </h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-filter-container" onClick={() => { handleMobileModalFilterOpen(); onClickFilters(); }}>
                        <h3>Filters {numFilters !== 0 && <span style={{color: "black !important"}}>{`(${numFilters})`}</span>}</h3>
                        <KeyboardArrowDownRoundedIcon />
                    </div>
                    <div className="mobile-view-container">
                        <div className={`mobile-view-icon ${mobileView ? "hide" : "show"}`} onClick={() => { setMobileView(true); changeView('grid'); }}><GridViewIcon /></div>
                        <div className={`mobile-view-icon ${mobileView ? "show" : "hide"}`} onClick={() => { setMobileView(false); changeView('row'); }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                            </svg>
                        </div>
                    </div>
                    <SettingNavbar searchResult={selectedKeywords}/>
                </div>
            </section>
            {
                displayFilters &&
                    <section className={view === 'row' ? 'pb-5' : 'pb-5'}>
                        <AssetFilter clear={clearFilters} applyFilters={applyFilters} show={displayFilters} searchResult={selectedKeywords} imgSrc={imgSrc}/>
                    </section>
            }
            <section>
                { view === 'row'? <div className='assets-container'>
                    {
                        !loading ? displaySearchItems.length > 0 &&
                            <Table className="table-assets">
                                <thead>
                                    <tr className='table-dark'>
                                        <th scope="col">
                                        </th>
                                        <th scope="col" className='paragraph-1' onClick={sortByFileName}>
                                            Filename
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col" onClick={sortByType}>
                                            Type
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col" onClick={sortBySize}>
                                            Size
                                            <CustomIcons variant="faSort" />
                                        </th>
                                        <th className='small-column text-center paragraph-1' scope="col">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !loading ? displaySearchItems?.length > 0 && (displaySearchItems && displaySearchItems?.length > 0) && displaySearchItems.map(file => {
                                            let assetFile = {
                                                uuid: file.uuid,
                                                basename: file.basename,
                                                timestamp: file.timestamp,
                                                type: file.type,
                                                size: file.fileSize,
                                                mime: file.mime,
                                                overallStatus: file.overallStatus
                                            }
                                            return (<AssetCard displayItems={displaySearchItems} view={view} key={assetFile.uuid}
                                                file={assetFile}
                                                handleViewAssetOpen={props.handleViewAssetOpen}
                                                handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                        }) :
                                        renderPlaceholderSkeleton()
                                    }
                                </tbody>
                            </Table> : renderPlaceholderSkeletonListView()
                    }
                    {!loading && (displaySearchItems?.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) &&
                        <tr>
                            <div className='no-results'>
                                <p>No results found for "{selectedKeywords}"</p>
                                <ul>
                                    <li>Check and ensure your spelling is correct</li>
                                    <li>Try using different keywords</li>
                                    <li>Keep your search query simple</li>
                                </ul>
                            </div>
                        </tr>
                    }
                </div> : <></> }
                { view === 'grid'?
                    <div className="assets-container g-3">
                        <div className='row'>
                            {!loading && (displaySearchItems.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) && !isFilter &&
                                <>
                                    <div className='no-results'>
                                        {selectedKeywords ? (
                                            <>
                                            <p>No results found for "{selectedKeywords}"</p>
                                            <ul>
                                                <li>Check and ensure your spelling is correct</li>
                                                <li>Try using different keywords</li>
                                                <li>Keep your search query simple</li>
                                            </ul>
                                            </>
                                        ) : (
                                            <>
                                            <p>No results found</p>
                                            <ul>
                                                <li>Ensure your image is clear</li>
                                                <li>Try searching using a different image</li>
                                            </ul>
                                            </>
                                        )}
                                    </div>
                                </>
                            }
                            {
                                !loading && (displaySearchItems.length === 0 || (displaySearchItems && displaySearchItems?.length === 0)) && isFilter &&
                                    <>
                                        <div className='no-cards-found'>
                                            <p className='font-bold'>No results found</p>
                                            <ul>
                                                <li>Check and ensure your inputs and selections are valid</li>
                                                <li>Try inputting and/or selecting other valid Filters options</li>
                                            </ul>
                                        </div>
                                    </>
                            }
                            {
                                !loading ? displaySearchItems.length > 0 && (displaySearchItems && displaySearchItems?.length > 0) && displaySearchItems.map(file => {
                                    let assetFile = {
                                        uuid: file.uuid,
                                        basename: file.basename,
                                        timestamp: file.timestamp,
                                        type: file.type,
                                        size: file.fileSize,
                                        mime: file.mime,
                                        overallStatus: file.overallStatus
                                    }

                                    return (<AssetCard displayItems={displaySearchItems}
                                        view={view} key={assetFile.uuid} file={assetFile} handleViewAssetOpen={props.handleViewAssetOpen}
                                        handleRemoveFileModal={props.handleRemoveFileModal}/>)
                                }) : renderPlaceholderSkeleton()
                            }
                        </div>

                    </div>
                : <></> }
            </section>

            {
                isMobileModalTypeOpen && (
                    <MobileType searchResult={selectedKeywords}/>
                )
            }
            {
                isMobileModalFilterOpen && (
                    <MobileFilter hasSort={true} searchResult={selectedKeywords}/>
                )
            }
        </div>
    )
}

export default SearchResultList;