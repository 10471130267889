const NotificationBanner = (props) => {
    return(<>
        <div className={`notification-banner ${props.variant === 'error' ? 'error-banner' : ''}`}>
            <div className='notification-banner-content'>
                {props.content}
            </div>
        </div>
    </>);
};

export default NotificationBanner;