
import { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { Select } from 'antd';
import CustomIcons from "../../custom-icons";
import PropTypes from 'prop-types';

const CustomSelectMultiple = ({ label, validated, placeholder, disabled, enableSearch, options, handleCustomSelectMultipleChange, clearAll, onClearAllComplete}) => {
    const [checkboxOptions, setCheckboxOptions] = useState(options);
    const [filteredCheckboxOptions, setFilteredCheckboxOptions] = useState(options);
    const [checkboxSelected, setCheckboxSelected] = useState([]);
    const [onSearch, setOnSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleCustomSelectMultipleSearch = (input) => {
        setSearchValue(input);

        if (input && enableSearch) {
            setOnSearch(true);
            const filteredOptions = checkboxOptions.filter((option) =>
                option.value.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredCheckboxOptions(filteredOptions);
        } else {
            setOnSearch(false);
            setFilteredCheckboxOptions(checkboxOptions);
        }
    };

    const handleCustomSelectMultipleCheckbox = async (event, value) => {
        const isChecked = event.target.checked;

        const updatedOptions = checkboxOptions.map(item =>
            item.value === value ? { ...item, checked: !item.checked } : item
        );

        if (onSearch) {
            const filteredOptions = filteredCheckboxOptions.map((item) =>
                item.value === value ? { ...item, checked: !item.checked } : item
            );
            setFilteredCheckboxOptions(filteredOptions);
        }

        if (isChecked) {
            const checkedOptions = updatedOptions
              .filter(item => item.checked)
              .map(item => item.value);

            handleCustomSelectMultipleChange(checkedOptions);
            setCheckboxSelected(checkedOptions);
        } else {
            setCheckboxSelected(prevCheckedUser => {
                const prevChecked = prevCheckedUser.filter(prevValue => prevValue !== value);
                handleCustomSelectMultipleChange(prevChecked);

                return prevChecked;
            });
        }

        setCheckboxOptions(updatedOptions);
    };

    const handleCustomSelectMultipleClearAll = () => {
        setCheckboxOptions(options);
        setFilteredCheckboxOptions(options);
        setCheckboxSelected([]);
        setOnSearch(false);
        setSearchValue("");
    };

    const handleCustomSelectMultipleDropdownClose = (open) => {
        if(!open) {
            setFilteredCheckboxOptions(checkboxOptions);
            setOnSearch(false);
            setSearchValue("");
        }
    };

    useEffect(() => {
        if(clearAll) {
            handleCustomSelectMultipleClearAll();
            onClearAllComplete();
        }
    }, [clearAll]);

    return(
        <>
            <div className={validated ? "form-group" : "form-group form-error"}>
                <label htmlFor={label}>{label}</label>
                <div className="ant-select-customize-checkbox-tag">
                    {
                        checkboxSelected.length > 0  && !onSearch && `${checkboxSelected.length} Selected`
                    }
                </div>
                <Select
                    mode="multiple"
                    showSearch={enableSearch}
                    className="ant-select-customize"
                    id={label}
                    suffixIcon={<CustomIcons variant='arrow' direction="expand" />}
                    placeholder={checkboxSelected.length > 0 ? "" : placeholder}
                    searchValue={searchValue}
                    onSearch={handleCustomSelectMultipleSearch}
                    onDropdownVisibleChange={(open) => handleCustomSelectMultipleDropdownClose(open)}
                    dropdownRender={() => (
                        <div className="ant-select-customize-dropdown">
                            {
                                onSearch
                                ?
                                    filteredCheckboxOptions.length > 0
                                    ?
                                        <>
                                            <div className="ant-select-customize-checkbox">
                                                {
                                                    filteredCheckboxOptions.map((option) =>(<Form.Check onChange={(event) => handleCustomSelectMultipleCheckbox(event, option.value, searchValue)} type='checkbox' checked={option.checked} value={option.value} id={option.label} label={option.label} disabled={option.disabled} key={option.value}/>))
                                                }
                                            </div>
                                            <div className="ant-select-customize-dropdown-clear-all">
                                                <a onClick={() => handleCustomSelectMultipleClearAll()}>Clear all</a>
                                            </div>
                                        </>
                                    :
                                        <>
                                        No result found
                                        </>
                                :
                                    checkboxOptions.length > 0
                                    ?
                                        <>
                                            <div className="ant-select-customize-checkbox">
                                                {
                                                    checkboxOptions.map((option) =>(<Form.Check onChange={(event) => handleCustomSelectMultipleCheckbox(event, option.value)} type='checkbox' checked={option.checked} value={option.value} id={option.label} label={option.label} disabled={option.disabled} key={option.value}/>))
                                                }
                                            </div>
                                            <div className="ant-select-customize-dropdown-clear-all">
                                                <a onClick={() => handleCustomSelectMultipleClearAll()}>Clear all</a>
                                            </div>
                                        </>
                                    :
                                        <>
                                        No result found
                                        </>
                            }

                        </div>
                    )}
                    aria-label={label}
                    disabled={disabled}
                >
                </Select>
            </div>
        </>
    );
};

export default CustomSelectMultiple;

CustomSelectMultiple.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    validated: PropTypes.bool,
    enableSearch: PropTypes.bool,
    disabled: PropTypes.bool
};

