import { spanishGray, iconSize } from '../../shared/utils';

const TrashIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ fontSize: sizeVal }}
        >
        <g clipPath="url(#clip0_9035_3182)">
        <path d="M8.75312 3.62227C8.94297 3.23906 9.3332 3 9.75859 3H13.9914C14.4168 3 14.807 3.23906 14.9969 3.62227L15.25 4.125H18.625C19.2473 4.125 19.75 4.62773 19.75 5.25C19.75 5.87227 19.2473 6.375 18.625 6.375H5.125C4.50273 6.375 4 5.87227 4 5.25C4 4.62773 4.50273 4.125 5.125 4.125H8.5L8.75312 3.62227ZM5.125 7.5H18.625V18.75C18.625 19.991 17.616 21 16.375 21H7.375C6.13398 21 5.125 19.991 5.125 18.75V7.5ZM8.5 9.75C8.19063 9.75 7.9375 10.0031 7.9375 10.3125V18.1875C7.9375 18.4969 8.19063 18.75 8.5 18.75C8.80937 18.75 9.0625 18.4969 9.0625 18.1875V10.3125C9.0625 10.0031 8.80937 9.75 8.5 9.75ZM11.875 9.75C11.5656 9.75 11.3125 10.0031 11.3125 10.3125V18.1875C11.3125 18.4969 11.5656 18.75 11.875 18.75C12.1844 18.75 12.4375 18.4969 12.4375 18.1875V10.3125C12.4375 10.0031 12.1844 9.75 11.875 9.75ZM15.25 9.75C14.9406 9.75 14.6875 10.0031 14.6875 10.3125V18.1875C14.6875 18.4969 14.9406 18.75 15.25 18.75C15.5594 18.75 15.8125 18.4969 15.8125 18.1875V10.3125C15.8125 10.0031 15.5594 9.75 15.25 9.75Z"
            fill={colorVal}
        />
        </g>
        <defs>
        <clipPath id="clip0_9035_3182">
        <rect width="24" height="24" fill={colorVal}/>
        </clipPath>
        </defs>
        </svg>
    )
};

export default TrashIcon;