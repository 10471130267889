/* eslint-disable no-undef */
import SolutionManifest from '../../solution-manifest';
import ServiceUtil from '../util/serviceUtil';
import Localization from '../../components/shared/localization';
import SparkMD5 from 'spark-md5';
import ENDPOINTS from '../util/endpoints';
import auditTrailService from './auditTrailService';
import { createAuditBody } from '../../components/shared/utils';

const Md5 = {
    ChunkSize: 8 * 1024 * 1024,
};
const Multipart = {
    PartSize: 8 * 1024 * 1024,
    MaxConcurrentUpload: 4,
};

class AssetService {
    constructor() {
        this.$IngestBucket = undefined;
        this.$ProxyBucket = undefined;
        this.$connected = false;
    }

    get IngestBucket() {
        return this.$IngestBucket;
    }

    set IngestBucket(val) {
        this.$IngestBucket = val;
    }

    get ProxyBucket() {
        return this.$ProxyBucket;
    }

    set ProxyBucket(val) {
        this.$ProxyBucket = val;
    }

    static get Constants() {
        return {
          Expiration: 60 * 60 * 2,
        };
    }

    // Upload S3 Assets
    static async uploadS3Assets(key, file, callback, query, tenantUuid) {
        this.IngestBucket = SolutionManifest.Ingest.Bucket;
        this.ProxyBucket = SolutionManifest.Proxy.Bucket;

        const fileReport = {
            fileId: file.id,
            elapsed: 0,
            src: {
              name: file.name,
              bytes: file.object.size,
              type: ServiceUtil.Mime.getMime(file.object),
              lastModified: new Date(file.object.lastModified).getTime(),
            },
            metrics: {
              uuid: {
                startTime: new Date().getTime(),
              },
              checksum: {
                type: 'md5',
                startTime: new Date().getTime(),
              }
            },
        };

        function getChunkSize(filesize) {
            return Math.ceil(filesize / Md5.ChunkSize);
        }
        function computeChunkChecksum(file, chunkIdx, previous) {
            return new Promise((resolve, reject) => {
              const start = chunkIdx * Md5.ChunkSize;
              const end = Math.min(start + Md5.ChunkSize, file.size);
              const spark = new SparkMD5.ArrayBuffer();
              if (previous) {
                spark.setState(previous.state);
              }

              const reader = new FileReader();
              reader.onload = (event) => {
                spark.append(event.target.result);
                const accumlated = spark.getState();
                resolve({
                  state: accumlated,
                  start,
                  end,
                });
              };

              reader.onerror = e =>
                reject(e);

              reader.readAsArrayBuffer(file.slice(start, end));
            });
        }
        function finalizeChecksum(status) {
            const spark = new SparkMD5.ArrayBuffer();
            spark.setState(status.state);
            return spark.end();
        }
        async function computeChecksum(file) {
            const chunks = getChunkSize(file.size);
            let response;
            for (let idx = 0; idx < chunks; idx++) {
              response = await computeChunkChecksum(file, idx, response).catch(e => e);
              /* error: failed to compute checksum */
              if (response instanceof Error) {
                console.log("error: failed to compute checksum")
                return undefined;
              }
            }

            /* now, we can get the MD5 of the entire file */
            const checksum = finalizeChecksum(response);
            return checksum;
        }
        async function createValidUuid() {
            let uuid;
            let record;
            const maxTries = 4;
            let tries = 0;

            do {
              uuid = ServiceUtil.uuid4();
              record = await ServiceUtil.authHttpRequest('GET', `${ENDPOINTS.Asset}/${uuid}`, {}, '', tenantUuid);
            } while (record.uuid && tries++ < maxTries);

            /* error: can't create an unique uuid */
            if (record.uuid) {
                console.log("error: can't create an unique uuid")
                return undefined;
            }
            return uuid;
        }
        function requestMultipartUpload(bucket, key, file, params) {
            const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                computeChecksums: true,
                signatureVersion: 'v4',
                s3DisableBodySigning: false,
                useAccelerateEndpoint: !!((SolutionManifest.S3 || {}).UseAccelerateEndpoint),
                customUserAgent: SolutionManifest.CustomUserAgent,
                ...params,
              });

            return s3.upload({
                Bucket: bucket,
                Key: `${tenantUuid}/${fileReport.uuid}/${key}`,
                ContentType: file.type,
                Metadata: {
                    ...file.attributes,
                    uuid: fileReport.uuid,
                    md5: fileReport.checksum,
                    webupload: new Date().toISOString(),
                },
                Body: file.object,
                ExpectedBucketOwner: SolutionManifest.S3.ExpectedBucketOwner,
            }, {
                partSize: Multipart.partSize,
                queueSize: Multipart.MaxConcurrentUpload,
            });
        }

        /* createValidUuid */
        fileReport.uuid = await createValidUuid();
        fileReport.metrics.uuid.endTime = new Date().getTime();
        fileReport.metrics.uuid.elapsed += fileReport.metrics.uuid.endTime - fileReport.metrics.uuid.startTime;
        if (!fileReport.uuid) {
            console.log(`${Localization.Alerts.CreateUuidError}: ${file.name}`);
            return undefined;
        }

        /* computeChecksum */
        fileReport.checksum = await computeChecksum(file.object);
        fileReport.metrics.checksum.endTime = new Date().getTime();
        fileReport.metrics.checksum.elapsed += fileReport.metrics.checksum.endTime - fileReport.metrics.checksum.startTime;
        if (!fileReport.checksum) {
            console.log(`${Localization.Alerts.ComputeChecksumError}: ${file.name}`);
            return undefined;
        }

        const request = await requestMultipartUpload(this.IngestBucket, key, file);
        // request.on('httpUploadProgress', (data) => {
        //     let percentage = Math.ceil((data.loaded / data.total) * 100);
        // });
        let uploads3 = await request.promise().catch(e => e);

        /* error: failed to uploads3 */
        if (uploads3 instanceof Error) {
            console.log(`${Localization.Alerts.UploadS3Error} file name: ${file.name} Error: ${uploads3}`);
            return undefined;
        } else {
            if(!callback) {
                return;
            }
            callback(fileReport.fileId, fileReport.uuid);
        }

        // Process Assets
        const process = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.Asset, query, {
            input: {
                bucket: this.IngestBucket,
                key: `${tenantUuid}/${fileReport.uuid}/${key}`,
                uuid: fileReport.uuid,
                group: tenantUuid,
                aiOptions: JSON.parse(JSON.stringify(SolutionManifest.AIML)),
                attributes: file.attributes,
                aiSmartTags: file.aiSmartTags,
                destination: {
                    bucket: this.ProxyBucket,
                }
            }
        }, tenantUuid);

        if (process instanceof Error) {
            console.log(`${Localization.Alerts.ProcessError}: ${file.name}`);
            return undefined;
        }

        return process;
    }

    // Upload S3 Assets to ProxyBucket
    static async uploadProxyS3TempAssets(key, file, tenantUuid, uuid) {
        this.ProxyBucket = SolutionManifest.Proxy.Bucket;

        function requestMultipartProfileUpload(bucket, key, file, params) {
            const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                computeChecksums: true,
                signatureVersion: 'v4',
                s3DisableBodySigning: false,
                useAccelerateEndpoint: !!((SolutionManifest.S3 || {}).UseAccelerateEndpoint),
                customUserAgent: SolutionManifest.CustomUserAgent,
                ...params,
              });

            return s3.upload({
                Bucket: bucket,
                Key: `temp/${tenantUuid}/${uuid}/${key}`,
                ContentType: file.type,
                Body: file,
                ExpectedBucketOwner: SolutionManifest.S3.ExpectedBucketOwner,
            }, {
            });
        }

        const request = requestMultipartProfileUpload(this.ProxyBucket, key, file);

        let uploads3 = await request.promise().catch(e => e);

        if (uploads3 instanceof Error) {
            console.log(`${Localization.Alerts.UploadS3Error} file name: ${file.name} Error: ${uploads3}`);
            return undefined;
        }

        return;
    };

    // Delete S3 Assets to ProxyBucket
    static async deleteProxyS3TempAssets(assets, tenantUuid) {
        const body = {
            tenantUuid: tenantUuid,
            bucket: SolutionManifest.Proxy.Bucket,
            assets: assets
        };

        return ServiceUtil.authHttpRequest('POST', `${ENDPOINTS.DeleteProxyAssets}`, undefined, body, tenantUuid);
    };

    // Map Assets
    static async mapAssets(body, query, tenantUuid) {
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.MapAssets, query, body, tenantUuid);
    }

    // Collection
    static async createCollection(body, query, tenantUuid) {
        const response = await ServiceUtil.authHttpRequest('POST', ENDPOINTS.Collections, query, body, tenantUuid);
    
        if(response.name) {
            // Add create collection activity to audit trail
            const { userUuid, name } = body.input;
            const auditBody = createAuditBody(tenantUuid, userUuid, "collection", "create", { collection: { name: name } });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async editCollection(body, query, tenantUuid) {
        const response = await ServiceUtil.authHttpRequest('PUT', ENDPOINTS.Collections, query, body, tenantUuid);
        const { userUuid } = body;
        
        if(response.statusCode === 200) {
            // Add edit collection activity to audit trail
            const auditBody = createAuditBody(tenantUuid, userUuid, "collection", "edit", { collection: { name: body.name, oldname: body.old_name } });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async deleteCollection(body, query, tenantUuid) {
        const response = await ServiceUtil.authHttpRequest('DELETE', ENDPOINTS.Collections, query, body, tenantUuid);
    
        if(response.name) {
            // Add delete collection activity to audit trail
            const { userUuid, name } = body.input;
            const auditBody = createAuditBody(tenantUuid, userUuid, "collection", "delete", { collection: { name: name } });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    static async getCollectionUUID(uuid, tenantUuid) {
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Collections}/${uuid}`;

        return ServiceUtil.authHttpRequest('GET', endpoint, {}, '', tenantUuid);
    }

    static async getCollectionTenantUUID(tenantUuid, type) {
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Collections}/tenant/${tenantUuid}`;

        const query = {
            type: type
        };

        return ServiceUtil.authHttpRequest('GET', endpoint, query, '', tenantUuid);
    }

    // list the filenames of foler in bucket
    static async listFilesInFolder(bucket, folderPath) {
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            computeChecksums: true,
            signatureVersion: 'v4',
            s3DisableBodySigning: false,
            useAccelerateEndpoint: !!((SolutionManifest.S3 || {}).UseAccelerateEndpoint),
            customUserAgent: SolutionManifest.CustomUserAgent,
          });
        try {
            const listObjectsResponse = await s3.listObjectsV2({
              Bucket: bucket,
              Prefix: folderPath
            }).promise();

            const files = listObjectsResponse.Contents;
            //const files = listObjectsResponse.Contents.sort((a, b) => b.LastModified.getTime() - a.LastModified.getTime());
            files.sort((a, b) => b.LastModified - a.LastModified);
            const fileNames = files.map(file => file.Key.replace(folderPath, ''));

            return fileNames;
        } catch (error) {
            //console.error('Error in listObjectsV2:', error);

            return "";
        }

    }

    // Upload S3 dam-userprofile
    static async uploadS3UserProfile(key, file, username, tenantUuid) {
        this.IngestBucket = SolutionManifest.UserProfile.Bucket;

        function requestMultipartProfileUpload(bucket, key, file, params) {
            const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                computeChecksums: true,
                signatureVersion: 'v4',
                s3DisableBodySigning: false,
                useAccelerateEndpoint: !!((SolutionManifest.S3 || {}).UseAccelerateEndpoint),
                customUserAgent: SolutionManifest.CustomUserAgent,
                ...params,
              });

            return s3.upload({
                Bucket: bucket,
                Key: `${SolutionManifest.UserProfile.FolderPath}${tenantUuid}/${username}/${key}`,
                ContentType: file.type,
                Body: file,
                ExpectedBucketOwner: SolutionManifest.S3.ExpectedBucketOwner,
            }, {
            });
        }

        const request = await requestMultipartProfileUpload(this.IngestBucket, key, file);

        let uploads3 = await request.promise().catch(e => e);

        if (uploads3 instanceof Error) {
            console.log(`${Localization.Alerts.UploadS3Error} file name: ${file.name} Error: ${uploads3}`);
            return undefined;
        } else {
        }

        return;
    }

    // delete S3 userprofile
    static async deleteS3UserProfile(key) {
        this.IngestBucket = SolutionManifest.UserProfile.Bucket;

        function requestProfileDelete(bucket, key, params) {
            const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                computeChecksums: true,
                signatureVersion: 'v4',
                s3DisableBodySigning: false,
                useAccelerateEndpoint: !!((SolutionManifest.S3 || {}).UseAccelerateEndpoint),
                customUserAgent: SolutionManifest.CustomUserAgent,
                ...params,
              });

            return s3.deleteObject({
                Bucket: bucket,
                Key: `${key}`,
                ExpectedBucketOwner: SolutionManifest.S3.ExpectedBucketOwner,
            }, {
            });
        }

        const request = await requestProfileDelete(this.IngestBucket, key);

        let uploads3 = await request.promise().catch(e => e);

        if (uploads3 instanceof Error) {
            console.log(`Error: ${uploads3}`);
            return undefined;
        } else {
            console.log("deleted successfully");
        }

        return;
    }

    // Ingest Workflow
    static async startIngestWorkflow(body, query, tenantUuid) {
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.Asset, query, body, tenantUuid);
    }

    //Get all assets by type
    static async getAssets(type, pageSize, token, tenantUuid, callback){
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Assets}`;
        return ServiceUtil.authHttpRequest('GET', endpoint, { type, pageSize, token, tenantUuid: tenantUuid }, '', tenantUuid, callback);
    }

    //Get assets by uuid
    static async getAssetById(uuid, tenantUuid, callback) {
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.Assets}/${uuid}`;
        return ServiceUtil.authHttpRequest('GET', endpoint, {
            tenantUuid: tenantUuid
        }, '', tenantUuid, callback);
    }

    static async filterAssets(filters, collectionUuid) {
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.FilterAssets}`;
        let parmas = collectionUuid ? { pageSize: filters.pageSize,
            types: filters.types, token: filters.token, collectionUuid: collectionUuid, group: filters.group } : { pageSize: filters.pageSize,
                types: filters.types, token: filters.token, group: filters.group };

        return ServiceUtil.authHttpRequest('GET', endpoint, parmas, '', filters.group);
    }

    //Get assets storage
    static async getAssetStorage(uuid) {
        let endpoint = `${SolutionManifest.ApiEndpoint}/${SolutionManifest.ApiOps.AssetsStorage}`;

        return ServiceUtil.authHttpRequest('GET', endpoint, {
            uuid: uuid
        }, '', uuid);
    }

    /* record related methods */
    static async scanRecords(query, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.Asset, query, '', tenantUuid);
      }

    static async getRecord(uuid, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', `${ENDPOINTS.Asset}/${uuid}`, {}, '', tenantUuid);
    }

    //edit asset
    static async updateAsset(body, tenantUuid) {
        const response = await ServiceUtil.authHttpRequest('PUT', `${ENDPOINTS.Asset}`, undefined, body, tenantUuid);
    
        if(response.statusCode === 200) {
            // Add edit asset activity to audit trail
            const { useruuid, filename } = body;
            const auditBody = createAuditBody(tenantUuid, useruuid, "asset", "edit", { files: [filename] });
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    //delete asset
    static async purgeRecord(body, tenantUuid) {
        const response = await ServiceUtil.authHttpRequest('DELETE', `${ENDPOINTS.Asset}`, undefined, body, tenantUuid);
    
        if(response.statusCode === 200) {
            // Add delete asset activity to audit trail
            const { useruuid, assetuuids, assetnames } = body;
            const options = {
                files: assetnames.map(file => (file))
            };
            const auditBody = createAuditBody(tenantUuid, useruuid, "asset", "delete", options);
            await auditTrailService.addActivity(auditBody, tenantUuid);
        }

        return response;
    }

    /* aiml results */
    static async startAnalysis(uuid, body, query, tenantUuid) {
        return ServiceUtil.authHttpRequest('POST', `${ENDPOINTS.Analysis}/${uuid}`, query, body, tenantUuid);
    }

    static async getAnalysisResults(uuid, tenantUuid) {
        return ServiceUtil.authHttpRequest('GET', `${ENDPOINTS.Analysis}/${uuid}`, {}, '', tenantUuid);
    }
}

export default AssetService;