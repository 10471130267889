import { useContext } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { SessionContext } from '../context/session-provider';
import path from '../routePaths';

const routes = [
    { path: path.COLLECTIONS },
    { path: `${path.COLLECTIONS}/:subpath` },
    { path: path.UPLOAD },
    { path: path.UPLOADGUIDE },
    { path: path.EXPLORE },
    { path: path.CHANGEPASSWORD },
    { path: path.FORGETPASSWORD },
    { path: path.LOGIN },
    { path: path.REGISTRATION },
    { path: path.PROFILE },
    { path: path.USERVERIFYEMAIL },
    { path: path.SETTINGS },
    { path: path.WELCOME },
    { path: path.ERROR },
    { path: path.VERIFYTENANTEMAIL },
    { path: path.SEARCH }
];

const currentPathName = window.location.pathname;

const SessionRoute = ({ isAuthenticated, checkAuthenticated }) => {
  const basePath = '/';
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const newPasswordRequired = localStorage.getItem('newPasswordRequired');
  const forgotPassword = localStorage.getItem('forgotPassword');
  const routeResult = useRoutes(routes);
  const routeExists = routeResult !== null;

  const notAuthenticatedPath = new Map([
    [path.WELCOME, true],
    [path.REGISTRATION, true],
    [path.LOGIN, true],
    [path.CHANGEPASSWORD, true],
    [path.FORGOTPASSWORD, true],
    [path.USERVERIFYEMAIL, true],
    [path.VERIFYTENANTEMAIL, true],
    [path.ERROR, true],
  ]);

  const authenticatedPath = new Map([
    [path.SETTINGS, true],
    [path.COLLECTIONS, true],
    [path.UPLOAD, true],
    [path.UPLOADGUIDE, true],
    [path.EXPLORE, true],
    [path.PROFILE, true],
    [path.ERROR, true],
    [path.SEARCH, true],
  ]);

  // Session Context
  const { isCustomerAdmin,
          isUserAdmin,
          isEndUser,
          isAssetOwner,
          userRoles } = useContext(SessionContext);

  if(checkAuthenticated == null) {
    return;
  };

  if (checkAuthenticated) {
    if (isAuthenticated) {
      const isSettingsPath = currentPath.slice(1) === path.SETTINGS;
      const isUploadPath = currentPath.slice(1) === path.UPLOAD;
      const isUploadGuidePath = currentPath.slice(1) === path.UPLOADGUIDE;
      const isExplorePath = currentPath.slice(1) === path.EXPLORE;
      const isCollectionsPath = currentPath.slice(1) === path.COLLECTIONS;

      if ((isExplorePath || isCollectionsPath) && (isUserAdmin && userRoles.length === 1)) {
        return <Navigate replace to={basePath + path.ERROR} />;
      }

      if (isSettingsPath && (!isCustomerAdmin && !isUserAdmin && !isAssetOwner)) {
        return <Navigate replace to={basePath + path.ERROR} />;
      }

      if ((isUploadPath || isUploadGuidePath) && ((isEndUser || isUserAdmin) && !isAssetOwner)) {
        return <Navigate replace to={basePath + path.ERROR} />;
      }

      if(routeExists && (currentPathName === basePath && (isUserAdmin && userRoles.length === 1))) {
        return <Navigate replace to={basePath + path.SETTINGS} />;
      }

      if(routeExists && (currentPath === basePath + path.LOGIN) || (currentPath === basePath)) {
        return <Navigate replace to={basePath + path.EXPLORE} />;
      }

      if (routeExists || (notAuthenticatedPath.has(currentPath.slice(1)) || authenticatedPath.has(currentPath.slice(1)))) {
        return;
      }

      return <Navigate replace to={basePath + path.ERROR} />;
    } else {
      return <Navigate replace to={basePath + path.ERROR} />;
    }
  } else {
    if (notAuthenticatedPath.has(currentPath.slice(1))) {
      return;
    }

    if (newPasswordRequired) {
      return <Navigate replace to={basePath + path.CHANGEPASSWORD} />;
    }

    if (forgotPassword) {
      return <Navigate replace to={basePath + path.FORGOTPASSWORD} />;
    }

    if (currentPathName === basePath) {
      return <Navigate replace to={basePath + path.LOGIN} />;
    }

    return <Navigate replace to={basePath + path.LOGIN} />;
  }
};

export default SessionRoute;
