import PropTypes from 'prop-types';

const Chips = (props) => {
    return(
        <div className='chips-components'>
            <div className={`chips-components-color ${props.colorClass}`}>
                {props.text}
            </div>
        </div>
    );
};

export default Chips;

Chips.propTypes = {
    colorClass: PropTypes.string
};

Chips.defaultProps = {
    colorClass: "green",
};
