import { useState, useEffect, useRef, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { IsMobile, IsTablet, IsDesktop } from "../../shared/utils";
import { Table, Select, Tag } from "antd";
import { ShareContext } from "../../shared/context/share-state";
import { GlobalContext } from "../../shared/context/global-provider";
import { SessionContext } from "../../shared/context/session-provider";
import CustomIcons from "../../shared/components/custom-icons";
import Skeleton from "react-loading-skeleton";
import MobileModal from "../../shared/components/mobile-modal";
import AuditTrailService from "../../../services/api/auditTrailService";
import Localization from "../../shared/localization";
import DOMPurify from 'dompurify';

const AuditTrail = () => {
  const [openDropdownCategory, setOpenDropdownCategory] = useState(false);
  const [mobilePeriodModal, setMobilePeriodModal] = useState(false);
  const dropdownCategoryRef = useRef(null);
  const isMobile = IsMobile();
  const isTablet = IsTablet();
  const isDesktop = IsDesktop();

  const { updateToastMessage } = useContext(GlobalContext);
  const { tenantUuid } = useContext(SessionContext);
  const { categoryType,
    selectedCategory,
    setSelectedCategory,
    applyCategory,
    setApplyCategory,
    filteredCategoryData,
    setFilteredCategoryData,
    days,
    setDays,
    auditSelectedOption,
    setAuditSelectedOption,
    auditExpandedRowKeys,
    setAuditExpandedRowKeys } = useContext(ShareContext);

  const auditTableRef = useRef();
  const [loading, setLoading] = useState(true);
  const [loadingMorePage, setLoadingMorePage] = useState(false);

  const [displayData, setDisplayData] = useState();
  const [originalData, setOriginalData] = useState();
  const [nextToken, setNextToken] = useState();
  const [page, setPage] = useState(10);

  const handleClickOutsideCategory = (event) => {
    if (
      dropdownCategoryRef.current &&
      !dropdownCategoryRef.current.contains(event.target)
    ) {
      setOpenDropdownCategory(false);
      setSelectedCategory(applyCategory);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideCategory);
    return () => {
      document.removeEventListener("click", handleClickOutsideCategory);
    };
  }, [applyCategory]);

  const loadMoreData = () => {
    AuditTrailService.getActivities(
      tenantUuid,
      filteredCategoryData?.length > 0 ? filteredCategoryData : "",
      days,
      page,
      ""
    ).then((results) => {
      if (results.statusCode === 200) {
        setDisplayData(results.Items);
        setOriginalData(results);
        setLoading(false);
        setLoadingMorePage(false);
      }
    });
  };
  useEffect(() => {
    loadMoreData();
  }, [filteredCategoryData, days, page]);

  //Expand Table
  const handleExpand = (record) => {
    if (record.users?.length > 1 || record.files?.length > 1) {
      const newExpandedRowKeys = [...auditExpandedRowKeys];
      const index = newExpandedRowKeys.indexOf(record.uuid);
      if (index >= 0) {
        newExpandedRowKeys.splice(index, 1); // Collapse the row
      } else {
        newExpandedRowKeys.push(record.uuid); // Expand the row
      }
      setAuditExpandedRowKeys(newExpandedRowKeys);
    }
  };

  const expandableRowRender = (record) => {
    if (record.users?.length > 1 || record.files?.length > 1) {
      return (
        <>
          <div className="ant-table-custom-expanded-cell">
            <div className="custom-box"></div>
            <div className="custom-box"></div>
            <div className="custom-box"></div>
            <div className="expand-data">
              <div className="user-activity-container">
                <div className="activity-type">
                  {record.category === "asset" && record.files?.length > 0 ? (

                    record.files.map((item, i) => {
                      const isLast = i === record.files?.length - 1;
                      return (
                        <span key={i}>
                          {item}
                          {!isLast && ","}{" "}
                        </span>

                      );
                    })

                  ) : record.category === "user" && record.users?.length > 0 ? (

                    record.users.map((item, i) => {
                      const isLast = i === record.users?.length - 1;
                      return (
                        <span className="text-capitalize" key={i}>
                          <b>
                            {item.name}
                            {!isLast && !item.role && ","}
                          </b>{" "}
                          {item.role && (
                            <span className="text-capitalize">
                              ({item.role === "customer_admin" ?
                                Localization.AuditTrail.UserRoles.customer_admin
                                : item.role === "asset_owner" ?
                                  Localization.AuditTrail.UserRoles.asset_owner
                                  : item.role === "end_user" ?
                                    Localization.AuditTrail.UserRoles.end_user
                                    : null
                              }){!isLast && ","}
                            </span>
                          )}
                        </span>
                      );
                    })
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  const handleExport = () => {
    if (displayData.length === 0) {
      updateToastMessage(`Nothing to export`, "error");
    } else {
      exportData();
    }
  };

  const exportData = () => {
    AuditTrailService.getActivities(
      tenantUuid,
      filteredCategoryData?.length > 0 ? filteredCategoryData : "",
      days,
      page,
      "",
      true
    ).then((results) => {
      if (results.statusCode === 200) {
        const blob = new Blob([results.body], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Get today's date
        const date = new Date();
        const year = date.getFullYear();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const day = date.getDate().toString().padStart(2, '0');

        // Construct the filename
        link.download = `AuditTrail_${day}${month}${year}.csv`;

        link.click();
        URL.revokeObjectURL(url);
      }
    });
  };

  const AuditTableSkeleton = () => {
    return (
      <div className="audit-table-skeleton">
        {isTablet ? (
          <>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="46px" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`audit-header-skeleton ${isTablet || loadingMorePage ? "d-none" : null
                }`}
            >
              <div>Date</div>
              <div>Category</div>
              <div>User</div>
              <div>Activity</div>
              <div></div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
            </div>
            <div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
              <div>
                <Skeleton width="100%" height="24px" />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const ActivityTypesContainer = (record) => {
    return (
      <div className="user-activity-container">
        {record.category === "plan" ? (
          <div className="activity-type">
            {!isDesktop && <span className="text-capitalize">{record.createdby}</span>}
            <span className="user-activity">
              {record.activitytype === "add"
                ? Localization.AuditTrail.Category.ActivityTypes.plan.add
                : record.activitytype === "delete"
                  ? Localization.AuditTrail.Category.ActivityTypes.plan.delete
                  : record.activitytype === "change"
                    ? Localization.AuditTrail.Category.ActivityTypes.plan.change
                    : record.activitytype === "cancel"
                      ? Localization.AuditTrail.Category.ActivityTypes.plan.cancel
                      : record.activitytype === "extend"
                        ? Localization.AuditTrail.Category.ActivityTypes.plan.extend
                        : null}
            </span>
            <span className="bold-activity">{record.cardno}</span>
          </div>
        ) : record.category === "collection" ? (
          <div className="activity-type">
            {!isDesktop && <span className="text-capitalize">{record.createdby}</span>}
            <span className="user-activity">
              {record.activitytype === "create"
                ? Localization.AuditTrail.Category.ActivityTypes.collection
                  .create
                : record.activitytype === "edit"
                  ? Localization.AuditTrail.Category.ActivityTypes.collection.edit
                  : record.activitytype === "delete"
                    ? Localization.AuditTrail.Category.ActivityTypes.collection
                      .delete
                    : null}
            </span>
            <span>
              {record.collection.oldname && (
                <><b>{record.collection.oldname}</b> to </>
              )}
              <b>{record.collection.name}</b>
            </span>
          </div>
        ) : record.category === "asset" ? (
          <div className="activity-type">
            {!isDesktop && <span className="text-capitalize">{record.createdby}</span>}
            <span className="user-activity">
              {record.activitytype === "upload"
                ? Localization.AuditTrail.Category.ActivityTypes.asset.upload
                : record.activitytype === "edit"
                  ? Localization.AuditTrail.Category.ActivityTypes.asset.edit
                  : record.activitytype === "delete"
                    ? Localization.AuditTrail.Category.ActivityTypes.asset.delete
                    : record.activitytype === "download"
                      ? Localization.AuditTrail.Category.ActivityTypes.asset.download
                      : null}
            </span>
            {record.files?.length > 1 ? (
              <span className="bold-activity">
                {record.files?.length} files
              </span>
            ) : (
              record.files?.map((item, i) => {
                return <span className="bold-activity">{item}</span>;
              })
            )}
          </div>
        ) : record.category === "user" ? (
          <div className="activity-type">
            {!isDesktop && <span className="text-capitalize">{record.createdby}</span>}
            <span className="user-activity" style={{ marginRight: '0' }}>
              {record.activitytype === "approve"
                ? Localization.AuditTrail.Category.ActivityTypes.user.approve
                : record.activitytype === "reject"
                  ? Localization.AuditTrail.Category.ActivityTypes.user.reject
                  : record.activitytype === "edit"
                    ? Localization.AuditTrail.Category.ActivityTypes.user.edit
                    : record.activitytype === "delete"
                      ? Localization.AuditTrail.Category.ActivityTypes.user.delete
                      : null}
            </span>
            {record.users?.length > 1 ? (
              <span className="bold-activity">
                {" " + record.users?.length} users
              </span>
            ) : (
              record.users?.map((item) => {
                return (
                  <>
                    <span className="bold-activity text-capitalize" key={item.name}>
                      {" " + item.name}
                    </span>
                    {item.role && item.oldrole ? (
                      <span>from <b className="text-capitalize">{item.oldrole === "customer_admin" ?
                        Localization.AuditTrail.UserRoles.customer_admin
                        : item.oldrole === "asset_owner" ?
                          Localization.AuditTrail.UserRoles.asset_owner
                          : item.oldrole === "end_user" ?
                            Localization.AuditTrail.UserRoles.end_user
                            : null}</b> to <b className="text-capitalize">{item.role === "customer_admin" ?
                              Localization.AuditTrail.UserRoles.customer_admin
                              : item.role === "asset_owner" ?
                                Localization.AuditTrail.UserRoles.asset_owner
                                : item.role === "end_user" ?
                                  Localization.AuditTrail.UserRoles.end_user
                                  : null}</b></span>
                    ) : item.role ? (
                      <span className="text-capitalize" key={item.role}>
                        ({item.role === "customer_admin" ?
                          Localization.AuditTrail.UserRoles.customer_admin
                          : item.role === "asset_owner" ?
                            Localization.AuditTrail.UserRoles.asset_owner
                            : item.role === "end_user" ?
                              Localization.AuditTrail.UserRoles.end_user
                              : null
                        })
                      </span>
                    ) : null
                    }
                  </>
                );
              })
            )}
          </div>
        ) : record.category === "account" ? (
          <div className="activity-type">
            {!isDesktop && <span className="text-capitalize">{record.createdby}</span>}
            <span className="user-activity" style={{ marginRight: '0' }}>
              {record.activitytype === "login"
                ? Localization.AuditTrail.Category.ActivityTypes.account.login
                : record.activitytype === "logout"
                  ? Localization.AuditTrail.Category.ActivityTypes.account.logout
                  : record.activitytype === "edit"
                    ? Localization.AuditTrail.Category.ActivityTypes.account.edit
                    : record.activitytype === "changepassword"
                      ? Localization.AuditTrail.Category.ActivityTypes.account
                        .changePassword
                      : record.activitytype === "resetpassword"
                        ? Localization.AuditTrail.Category.ActivityTypes.account
                          .resetPassword
                        : record.activitytype === "setup2fa"
                          ? <span dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(Localization.AuditTrail.Category.ActivityTypes.account
                              .setup2fa)
                          }} style={{ marginRight: '0' }}></span>
                          : record.activitytype === "reset2fa"
                            ? <span dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(Localization.AuditTrail.Category.ActivityTypes.account
                                .reset2fa)
                            }} style={{ marginRight: '0' }}></span>
                            : null}
            </span>
            {record.users?.length > 1 ? (
              <span className="bold-activity">{" " + record.users?.length}</span>
            ) : (
              record.users?.map((item) => {
                return (
                  <span key={item.name}>
                    <span className="bold-activity text-capitalize">{" " + item.name}</span>
                  </span>
                );
              })
            )}
          </div>
        ) : null}
      </div>
    );
  };
  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => new Date(a.datecreated) - new Date(b.datecreated),
      className: !isDesktop ? "mobile-col" : "",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        if (isTablet) {
          return (
            <div
              className="ant-table-custom-cell"
              onClick={() => handleExpand(record)}
            >
              <div className="mobile-cell">
                {ActivityTypesContainer(record)}
                <div className="mobile-tag-date">
                  <div className="ant-tag-container text-capitalize">
                    <Tag
                      key={record.uuid}
                      className={
                        record.category === "asset"
                          ? "ant-dropdown-select-tag green"
                          : record.category === "user"
                            ? "ant-dropdown-select-tag blue"
                            : record.category === "account"
                              ? "ant-dropdown-select-tag pink"
                              : record.category === "plan"
                                ? "ant-dropdown-select-tag purple"
                                : record.category === "collection"
                                  ? "ant-dropdown-select-tag orange"
                                  : "custom-select-checkbox-tag"
                      }
                    >
                      {record.category}
                    </Tag>
                  </div>
                  <span className="paragraph-2 audit-date">
                    {record.datecreated}
                  </span>
                </div>
              </div>
              {record.users?.length > 1 || record.files?.length > 1 ? (
                <button onClick={() => handleExpand(record)}>
                  {auditExpandedRowKeys.includes(record.uuid) ? (
                    <CustomIcons variant="arrow" direction={"up"} />
                  ) : (
                    <CustomIcons variant="arrow" direction={"down"} />
                  )}
                </button>
              ) : null}
            </div>
          );
        } else {
          return (
            <div className="ant-table-custom-cell">
              <span>{record.datecreated}</span>
            </div>
          );
        }
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      sortDirections: ["ascend", "descend", "ascend"],
      className: isTablet ? "d-none" : null,
      render: (_, record) => (
        <div>
          <div className="ant-tag-container text-capitalize">
            <Tag
              key={record.uuid}
              className={
                record.category === "asset"
                  ? "ant-dropdown-select-tag green"
                  : record.category === "user"
                    ? "ant-dropdown-select-tag blue"
                    : record.category === "account"
                      ? "ant-dropdown-select-tag pink"
                      : record.category === "plan"
                        ? "ant-dropdown-select-tag purple"
                        : record.category === "collection"
                          ? "ant-dropdown-select-tag orange"
                          : "custom-select-checkbox-tag"
              }
            >
              {record.category}
            </Tag>
          </div>
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "user",
      sortDirections: ["ascend", "descend", "ascend"],
      className: isTablet ? "d-none" : "col-user",
      render: (_, record) => (
        <div className="ant-table-custom-cell">
          <span className="text-truncate w-100 text-capitalize">
            {record.createdby}
          </span>
        </div>
      ),
    },
    {
      title: "Activity",
      dataIndex: "activity",
      className: isTablet ? "d-none" : "col-activity",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => (
        <div className="ant-table-custom-cell">
          {ActivityTypesContainer(record)}
        </div>
      ),
    },
    {
      title: " ",
      key: "actions",
      className: isTablet ? "d-none" : "col-action",
      render: (text, record) => (
        <>
          {record.users?.length > 1 || record.files?.length > 1 ? (
            <button onClick={() => handleExpand(record)}>
              {auditExpandedRowKeys.includes(record.uuid) ? (
                <CustomIcons variant="arrow" direction={"up"} />
              ) : (
                <CustomIcons variant="arrow" direction={"down"} />
              )}
            </button>
          ) : null}
        </>
      ),
    },
  ];
  //Handle Select Category CheckBox
  const selectCategory = (category) => {
    setSelectedCategory((prevSelected) => {
      const isChecked = prevSelected.some(
        (editLabel) => editLabel.type === category.type
      );
      if (isChecked) {
        return prevSelected.filter(
          (editLabel) => editLabel.type !== category.type
        );
      } else {
        return [...prevSelected, category];
      }
    });
  };

  //Handle Applied Category
  const applySelectCategory = () => {
    setPage(10);
    setAuditExpandedRowKeys([]);
    if (selectedCategory.length === 0) {
      setSelectedCategory(categoryType);
      setApplyCategory(categoryType);
      setFilteredCategoryData([]);
    } else {
      const selectedCategoryTypes = new Set(
        selectedCategory.map((category) => category.type)
      );
      const selectedCategoryTypesArray = Array.from(selectedCategoryTypes);

      setFilteredCategoryData(selectedCategoryTypesArray);
      setApplyCategory(selectedCategory);
    }
    setOpenDropdownCategory(false);
  };

  const handleMobileModalClose = () => {
    setOpenDropdownCategory(false);
    setMobilePeriodModal(false);
    setSelectedCategory(applyCategory);
  };
  //Handle Select change date
  const handleSelectChange = (value) => {
    setAuditSelectedOption(value);
    filterAuditDate(value);
    if (!isDesktop) {
      setMobilePeriodModal(false);
    }
  };
  const filterAuditDate = (selectedValue) => {
    setPage(10);
    setAuditExpandedRowKeys([]);
    if (selectedValue === 1) {
      setDays(30);
    } else if (selectedValue === 2) {
      setDays(90);
    } else {
      setDays(180);
    }
  };

  //Scroll load more
  const handleScroll = () => {
    const endOfPage = window.innerHeight + window.scrollY >= document.body.offsetHeight;

    if (loadingMorePage) {
      return;
    }

    if (!endOfPage) {
      return;
    }

    if (originalData && originalData.NextToken && !loadingMorePage) {
      setPage((prevPage) => prevPage + 10);
      setLoadingMorePage(true);
    } else {
      return;
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <>
      <div className="audit-trail">
        <h2 className={`${isTablet ? "d-none" : ""}`}>Audit Trail</h2>
        <div className="audit-trail-settings">
          <div className="category-dropdown" ref={dropdownCategoryRef}>
            {
              <div className="dropdown-button-container">
                <button
                  className="text-truncate"
                  onClick={() => setOpenDropdownCategory(!openDropdownCategory)}
                >
                  <h3>
                    Category (
                    {applyCategory.length === 5 ? "All" : applyCategory.length})
                  </h3>
                  <CustomIcons
                    variant="arrow"
                    direction={"down"}
                    className={openDropdownCategory ? "rotate-arrow" : ""}
                  />
                </button>
              </div>
            }
            {isDesktop ? (
              <div
                className={`category-dropdown-menu ${!openDropdownCategory ? "d-none" : ""
                  }`}
              >
                <ul>
                  {categoryType.map((category, i) => {
                    const isChecked = selectedCategory.some(
                      (editLabel) => editLabel.type === category.type
                    );
                    return (
                      <li data-key={category.id} key={category.id}>
                        <Form.Check
                          className={"category-form-check-input text-capitalize"}
                          type="checkbox"
                          data-name={category.type}
                          id={category.id}
                          checked={isChecked}
                          label={category.type}
                          onChange={() => selectCategory(category)}
                        />
                      </li>
                    );
                  })}
                </ul>
                <hr />
                <div className="button-container">
                  <button onClick={() => applySelectCategory()}>
                    <span>Apply</span>
                  </button>
                </div>
              </div>
            ) : (
              openDropdownCategory && (
                <MobileModal
                  title={"Category"}
                  onClose={handleMobileModalClose}
                  withBtn={true}
                >
                  <div className="mobile-modal-more-vertical mobile-category">
                    <div className={`category-dropdown-menu`}>
                      <ul>
                        {categoryType.map((category, i) => {
                          const isChecked = selectedCategory.some(
                            (editLabel) => editLabel.type === category.type
                          );
                          return (
                            <li data-key={category.id} key={category.id}>
                              <Form.Check
                                className={"category-form-check-input text-capitalize"}
                                type="checkbox"
                                data-name={category.type}
                                id={category.id}
                                checked={isChecked}
                                label={category.type}
                                onChange={() => selectCategory(category)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                      <hr />
                      <div className="button-container">
                        <button onClick={() => applySelectCategory()}>
                          <span>Apply</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </MobileModal>
              )
            )}
          </div>
          <div className="days-export-container">
            {isDesktop ? (
              <Select
                id="select-days"
                className="select-days"
                defaultValue={auditSelectedOption}
                value={auditSelectedOption}
                bordered={false}
                suffixIcon={<CustomIcons variant="arrow" direction="expand" />}
                dropdownStyle={{ fontFamily: "Inter", minWidth: "150px" }}
                options={[
                  { value: 1, label: "Last 30 days" },
                  { value: 2, label: "Last 90 days" },
                  { value: 3, label: "Last 180 days" },
                ]}
                aria-label="sort"
                onChange={handleSelectChange}
              />
            ) : (
              <>
                <div className="button-container">
                  <button
                    className="text-truncate"
                    onClick={() => setMobilePeriodModal(!mobilePeriodModal)}
                  >
                    <h3>{auditSelectedOption === 1 ? "Last 30 days" : auditSelectedOption === 2 ? "Last 90 days" : "Last 180 days"}</h3>
                    <CustomIcons
                      variant="arrow"
                      direction={!mobilePeriodModal ? "down" : "up"}
                    />
                  </button>
                </div>
                {mobilePeriodModal && (
                  <MobileModal
                    title={"Period"}
                    onClose={handleMobileModalClose}
                  >
                    <div className="mobile-modal-more-vertical modal-period">
                      <Button
                        size="sm"
                        className={`btn-row-action ${auditSelectedOption === 1 ? "selected" : null
                          }`}
                        onClick={() => {
                          handleSelectChange(1);
                        }}
                        variant="link"
                      >
                        <h3>Last 30 days</h3>
                      </Button>
                      <Button
                        className={`btn-row-action icon-button ${auditSelectedOption === 2 ? "selected" : null
                          }`}
                        onClick={() => {
                          handleSelectChange(2);
                        }}
                        size="sm"
                        variant="link"
                      >
                        <>
                          <h3>Last 90 days</h3>
                        </>
                      </Button>
                      <Button
                        className={`btn-row-action icon-button ${auditSelectedOption === 3 ? "selected" : null
                          }`}
                        onClick={() => {
                          handleSelectChange(3);
                        }}
                        size="sm"
                        variant="link"
                      >
                        <>
                          <h3>Last 180 days</h3>
                        </>
                      </Button>
                    </div>
                  </MobileModal>
                )}
              </>
            )}
            {/* Export button */}
            <div className="export-container" onClick={() => handleExport()}>
              <CustomIcons variant="iosShare" />
              {isDesktop && <h3>Export</h3>}
            </div>
          </div>
        </div>
        <hr />
        {loading ? (
          <AuditTableSkeleton />
        )
          : displayData.length === 0 ? (
            <div className="audit-empty-container">
              <div>
                <div className="image-container">
                  <img
                    src={"/images/audit_empty_report.svg"}
                    alt="audit_empty_report"
                  />
                </div>
                <h2>No activities yet</h2>
                <p>New activities and actions will appear here</p>
              </div>
            </div>
          )
            :
            <div className="audit-trail-table" ref={auditTableRef}>
              <Table
                className="custom-ant-table"
                expandable={{
                  expandedRowRender: expandableRowRender,
                  expandedRowKeys: auditExpandedRowKeys,
                  onExpand: (expanded, record) => handleExpand(record.uuid),
                }}
                columns={tableColumns}
                dataSource={displayData}
                rowKey={(record) => record.uuid}
                pagination={false}
              />
              {loadingMorePage ? <AuditTableSkeleton /> : null}
            </div>
        }
      </div>
    </>
  );
};
export default AuditTrail;
