import { black, iconSize } from '../../shared/utils';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const DownloadIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <DownloadRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default DownloadIcon;