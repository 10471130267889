import React, { useState, useEffect, useContext, useRef } from "react";
import { Select } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import { AccountContext } from "../../shared/context/account-provider";
import { SessionContext } from "../../shared/context/session-provider";
import Localization from '../../shared/localization';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircleIcon from '@mui/icons-material/Circle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import AssetService from '../../../services/api/assetsService';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CustomIcons from "../../shared/components/custom-icons";
import AlertNotification from "../../shared/components/alert-notification";
import UsersService from "../../../services/api/usersService";
import GenericModal from "../../shared/components/generic-modal";
import { deleteCacheItemsByNameChange } from "../../../store";
import SolutionManifest from "../../../solution-manifest";

const ProfileView = (props) => {
    const [imageSelectKey, setImageSelectKey] = useState(0);
    const [userGroup, setUserGroup] = useState('');
    const [messageInfo, setMessageInfo] = useState("");
    const [profilemessageInfo, setProfileMessageInfo] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReenterPassword, setShowReenterPassword] = useState(false);
    const [newpasswordInputDisabled, setNewpasswordInputDisabled] = useState(true);
    const [currentpasswordInputDisabled, setCurrentpasswordInputDisabled] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [showProfile, setShowProfile] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("");
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [eightCharValid, setEightCharValid] = useState(false);
    const [lowercaseValid, setLowercaseValid] = useState(false);
    const [uppercaseValid, setUppercaseValid] = useState(false);
    const [reenternMessage, setReenterMessage] = useState("");
    const [passwordSuccessful, setPasswordSuccessful] = useState(false);
    const [profileSuccessful, setProfileSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [CurrentPasswordInvalid, setCurrentPasswordInvalid] = useState(false);
    const [profileUserGroup, setProfileUserGroup] = useState('');
    const [profileHover, setProfileHover] = useState(false);
    const [modalShow, SetModalShow] = useState(false);
    const [verifyLoading, SetVerifyLoading] = useState(false);
    const [showBackupCodes, SetShowBackupCodes] = useState(false);
    const [userBackupCodes, SetUserBackupCodes] = useState([]);
    const [userAuth, SetUserAuth] = useState(false);

    const currentPasswordRef = useRef(null);
    const TwoFACurrentPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const reenterPasswordRef = useRef(null);
    const processChangePasswordRef = useRef(null);
    const fileInputRef = useRef(null);

    // Share Context
    const { menuTabKey,
        isProfileEditing,
        setIsProfileEditing,
        showProfileChangePassword,
        setShowProfileChangePassword } = useContext(ShareContext);

    // Account Context
    const { profilechangepassword,
        reAuthenticate } = useContext(AccountContext);

    // Session Context
    const { userRoles,
        profileImgUrl,
        loadProfilePic,
        username,
        email,
        userGroups,
        groupsRoles,
        tenantUuid,
        setFullname,
        getSession,
        userUuid,
        subscriptionPlan } = useContext(SessionContext);

    const [imageUrl, setImageUrl] = useState(profileImgUrl);
    const [profilename, setProfileName] = useState("");

    const initials = username
        .split(' ')
        .map(name => name.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('');

    const handleChangePasswordClick = async () => {
        const currentpasswordInput = currentPasswordRef.current;
        const newpasswordInput = newPasswordRef.current;
        try {
            const data = await profilechangepassword(currentpasswordInput.value, newpasswordInput.value);
            if (data) {
                resetChangePassword();
                setMessageInfo(Localization.Profile.ChangePasswordRespose.Successful);
                setPasswordSuccessful(true);
                setPasswordInvalid(false);
            }
        } catch (err) {
            setMessageInfo("");
            setPasswordSuccessful(false);
            if (err.name === 'NotAuthorizedException') {
                setPasswordInvalid(true);
                //setMessageInfo(Localization.Profile.ChangePasswordRespose.NotAuthorizedExceptionForChangePassword);
            } else if (err.name === 'LimitExceededException') {
                setMessageInfo(Localization.Profile.ChangePasswordRespose.LimitExceededException);
            } else if (err.name === 'InvalidParameterException') {
                setMessageInfo(Localization.Profile.ChangePasswordRespose.InvalidParameterException);
            } else {
                setMessageInfo(err.name);
            }
        }
    };

    const resetChangePassword = () => {
        const currentpasswordInput = currentPasswordRef.current;
        const newpasswordInput = newPasswordRef.current;
        const reenterpasswordInput = reenterPasswordRef.current;
        currentpasswordInput.value = "";
        newpasswordInput.value = "";
        reenterpasswordInput.value = "";
        setPasswordInvalid(false);
        setEightCharValid(false);
        setLowercaseValid(false);
        setUppercaseValid(false);
        setButtonDisabled(true);
        setMessageInfo("");
    };

    const handleCopy = (event) => {
        event.preventDefault();
    };

    const handlNewPasswordChange = (event) => {
        const reenterPasswordInput = reenterPasswordRef.current;
        const inputValue = event.target.value;
        setEightCharValid(inputValue.length > 7);
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        setLowercaseValid(lowercaseRegex.test(inputValue));
        setUppercaseValid(uppercaseRegex.test(inputValue));

        setReenterMessage('');
        setButtonDisabled(true);

        if (inputValue === reenterPasswordInput.value) {
            setButtonDisabled(false);
            setReenterMessage('');
        } else {
            if(reenterPasswordRef.current.value) {
                setButtonDisabled(true);
                setReenterMessage('Your passwords do not match');
            }
        }
    };

    const handlCurrentPasswordChange = (event) => {
      const inputValue = event.target.value;
      if(inputValue){
        setCurrentpasswordInputDisabled(false);
      } else {
        setCurrentpasswordInputDisabled(true);
        const newpasswordInput = document.getElementById("newpassword");
        newpasswordInput.value = "";
        setEightCharValid(false);
        setLowercaseValid(false);
        setUppercaseValid(false);
      }
    };

    const handlReenterPasswordChange = (event) => {
        const newpasswordInput = newPasswordRef.current;
        const inputValue = event.target.value;
        if (newpasswordInput.value === inputValue) {
            setButtonDisabled(false);
            setReenterMessage('');
        } else {
            setButtonDisabled(true);
            setReenterMessage('Your passwords do not match');
        }
    };

    const handleCurrentPasswordKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        newPasswordRef.current.focus();
      }
    };

    const handle2FACurrentPasswordKeyPress = (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
          handleModalVerify();
        }
    };

    const handle2FACurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPasswordKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        reenterPasswordRef.current.focus();
      }
    };

    const handleProcessChangePasswordKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        processChangePasswordRef.current.click();
      }
    };
    const handleMouseEnter = () => {
        setProfileHover(true);
    };
    const handleMouseLeave = () => {
        setProfileHover(false);
    };

    const handleImageSelect = async (event) => {
        const file = event.target.files[0];
        const files = event.target.files;
        //const reader = new FileReader();

        const stateFiles = files;

        const maxSize = 10 * 1024 * 1024; // 10MB Limit

        const allowedExtensions = ["png", "jpg", "jpeg"];
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            setProfileSuccessful(false);
            setProfileMessageInfo("Only PNG and JPG files are allowed");
        } else if (file.size > maxSize) {
            setProfileSuccessful(false);
            setProfileMessageInfo("File size exceeds maximum 10MB limit");
        } else {
            setIsLoading(true);
            await processStateFiles(stateFiles)
                .then(async () => {
                    setIsCheck(true);
                    await loadProfilePic(username, tenantUuid)
                        .then(async () => {
                            setProfileMessageInfo("");
                            setIsLoading(false);
                            setIsCheck(false);
                        })
                        .catch((err) => {
                            console.error('Error load profile image:', err);
                        });
                })
                .catch((err) => {
                    console.error('Error upload profile image:', err);
                });
        }

        setImageSelectKey((prevKey) => prevKey + 1);
    };

    async function processStateFiles(stateFiles) {
        for (let i = 0; i < stateFiles.length; i++) {
            await AssetService.uploadS3UserProfile(stateFiles[i].name, stateFiles[i], username, tenantUuid);
        }
    }

    const handleFileSelect = () => {
        fileInputRef.current.click();
    };

    const handleEditProfileClick = () => {
        setIsProfileEditing(true);
        setProfileUserGroup(userGroups);
        setShowProfileChangePassword(false);
        resetChangePassword();
        setProfileMessageInfo("");
    };

    const handleCancelClick = () => {
        setIsProfileEditing(false);
        setProfileMessageInfo("");
        setShowProfileChangePassword(true);
    };

    const handleSaveProfileClick = async () => {
        try {
            const bodyData = {
                fullname: profilename,
                userUuid: userUuid,
                tenantUuid: tenantUuid,
            };

            setProfileMessageInfo("");

            const userProfileResult = await UsersService.userProfile(bodyData);
            if (userProfileResult.status === 200) {
                setProfileName(profilename);
                setProfileSuccessful(true);
                setProfileMessageInfo("You have successfully saved your changes.");
                deleteCacheItemsByNameChange(userUuid);
            }

        } catch (err) {
            console.error("Failed to Update Attribute", err);
            setProfileSuccessful(false);
            setProfileMessageInfo(err);
        }
    };

    const handleNameChange = (event) => setProfileName(event.target.value);

    const handleProfileTabClick = () => {
        setShowProfile(true);
        setShowChangePassword(false);
    };

    const handlePasswordTabClick = () => {
        setShowProfile(false);
        setShowChangePassword(true);
    };

    const onUserGroupsChange = (value) => {
        setProfileUserGroup(value);
    };

    const onUserGroupsSearch = (value) => {
    };

    const handleModalVerify = async () => {
        try {
            SetVerifyLoading(true);
            SetShowBackupCodes(false);
            SetUserAuth(false);
            setCurrentPasswordInvalid(false);

            const authenticate = await reAuthenticate(username, TwoFACurrentPasswordRef.current.value);

            if(authenticate.statusCode === 200) {
                const getBackupCodes = await UsersService.getUserBackupCodes(tenantUuid, userUuid);

                if(getBackupCodes.statusCode === 200) {
                    SetUserBackupCodes(getBackupCodes.response.userBackupCodes);
                    SetVerifyLoading(false);
                    SetShowBackupCodes(true);
                    SetUserAuth(true);

                    return;
                };

                SetVerifyLoading(false);
                SetShowBackupCodes(true);
                SetUserAuth(true);
            }
        } catch(error) {
            setCurrentPasswordInvalid(true);
            SetVerifyLoading(false);
        }
    };

    const handleModalClose = () => {
        SetVerifyLoading(false);
        SetShowBackupCodes(false);
        SetUserAuth(false);
        setCurrentPasswordInvalid(false);
        setShowCurrentPassword(false);
        setCurrentPassword("");
        SetModalShow(!modalShow);
    };

    const renderGenericModalPassword = () => {
        return(
            <div className="form-container">
                <div className="form-group">
                    <label htmlFor="currentpassword" className={`${CurrentPasswordInvalid ? 'warning-label' : ''}`}>Current password</label>
                    <input
                        id="currentpassword"
                        className={`form-control ${CurrentPasswordInvalid ? 'warning-input' : ''}`}
                        type={showCurrentPassword ? 'text' : 'password'}
                        placeholder="Enter current password"
                        ref={TwoFACurrentPasswordRef}
                        onKeyDown={handle2FACurrentPasswordKeyPress}
                        onChange={handle2FACurrentPasswordChange}
                        value={currentPassword}
                    ></input>
                    <button
                        type="button"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        className="show-password-icon"
                    >
                        {showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </button>
                </div>
                { CurrentPasswordInvalid && <span className="candy-apple--color"><CustomIcons color="#D80029" variant="error"/><p>Invalid password</p></span> }
            </div>
        );
    };

    const handleModalGenerateNewCodes = async () => {
        SetVerifyLoading(true);
        const generateNewUserBackupCodes = await UsersService.generateNewUserBackupCodes(tenantUuid, userUuid);

        if(generateNewUserBackupCodes.statusCode === 200) {
            const getBackupCodes = await UsersService.getUserBackupCodes(tenantUuid, userUuid);

            if(getBackupCodes.statusCode === 200) {
                SetUserBackupCodes(getBackupCodes.response.userBackupCodes);
                SetVerifyLoading(false);
                return;
            }

            SetVerifyLoading(false);
            SetUserBackupCodes([]);
        }
    };

    const renderBackupCodes = () => {
        if(userBackupCodes.length > 0) {
            return(
                <div className="view-backup-code">
                    {
                        userBackupCodes.map(code => {
                            return(<div key={code}>{code}</div>)
                        })
                    }
                </div>
            );
        }

        return(
            <div className="view-backup-code">
                No Backup codes Found
            </div>
        );
    };

    useEffect(() => {
        getSession().then(async session => {
            setProfileName(session.name);
            setFullname(session.name);
        });
    }, []);

    useEffect(() => {
        if(menuTabKey === 'profile') {
            resetChangePassword();
            handleCancelClick();
        }
    }, [menuTabKey]);

    useEffect(() => {
        if (eightCharValid && lowercaseValid && uppercaseValid) {
          setNewpasswordInputDisabled(false);
        } else {
          setNewpasswordInputDisabled(true);
          setReenterMessage('');
        }
    }, [eightCharValid, lowercaseValid, uppercaseValid]);

    useEffect(() => {
        if(profileImgUrl) {
            setProfileHover(false);
            setImageUrl(profileImgUrl);
            setIsProfileEditing(isProfileEditing);
        }
    }, [profileImgUrl]);

    return (
        <>
            <section className="continer-fluid profile-view-wrapper d-flex align-items-center">
                <div className="container">
                    {/* MOBILE TAB */}
                    { !isProfileEditing &&
                        <div className="row">
                            <nav className="profile-mobile-tab">
                                <div className={showProfile? "tab active" : "tab"} onClick={handleProfileTabClick}>Profile</div>
                                <div className={showChangePassword? "tab active" : "tab"} onClick={handlePasswordTabClick}>Change Password</div>
                            </nav>
                        </div>
                    }
                    {/* END MOBILE TAB */}

                    <div className="row">
                        <div className={`col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 border-right ${showProfile ? 'mobile-open': 'mobile-close'} ${showProfileChangePassword ? '' : 'hidden-border'}`.trim()}>
                            <div className="profile-message-container">
                                {profilemessageInfo.length > 0 ? (
                                    <AlertNotification variant={profileSuccessful ? "success" : "error"} content={profilemessageInfo}/>
                                ) : null}
                            </div>
                            <div className={`profile-group ${!profilemessageInfo.length > 0 ? 'top-adjust' : ''}`} >
                                { isProfileEditing && <h2 className="title-text">Edit Profile</h2> }

                                <div className="row profile-icon">
                                    {initials ? (
                                        <div className="header-name-initials col-6">
                                            <div
                                                className={`header-name-initials col-6 ${profileHover && !isLoading && !isCheck ? imageUrl ? `hover-image-bg` : 'hover-bg' : ''}`}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                style={{
                                                        backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
                                                        backgroundSize: 'cover',
                                                    }}
                                                >
                                                    <div className="initials-container">
                                                        {isLoading && !isCheck && (
                                                        <div className="processing-circle"></div>
                                                        )}
                                                        {isCheck && (
                                                            <div className="check-icon-overlay">
                                                                <CircleIcon
                                                                className="bg-icon"
                                                                />
                                                                <CheckCircleRoundedIcon
                                                                className="header-icon"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="initials-container" style={{left: '-50px'}}>
                                                <div className="d-flex justify-content-center align-items-center">
                                                {!imageUrl && !isLoading && !isCheck && (
                                                    // PROFILE IMAGE LETTER INITIALS
                                                    <span className={`profile-initals position-absolute ${profileHover && !isLoading && !isCheck ? 'hover-text-bg' : ''}`}>{initials}</span>
                                                )}
                                                </div>
                                                {!isLoading && !isCheck && (

                                                    // EDIT BUTTON FOR PROFILE IMAGE
                                                    <div className="camera-icon-overlay">
                                                        <EditIcon htmlFor="image-upload"
                                                            className="header-icon no-blur-bg"
                                                            onClick={handleFileSelect}
                                                        />
                                                        <input
                                                        id="image-upload"
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg"
                                                        ref={fileInputRef}
                                                        key={imageSelectKey}
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageSelect}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <CustomIcons variant="user" className='header-icon'/>
                                    )}
                                     {username ? (
                                        <div className="col-6 d-flex flex-column justify-content-center" style={{ paddingLeft: '24px' }}>
                                            <span className="title-caption d-flex">Username </span>
                                            <span className="profile-data-caption d-flex">{username}</span>
                                        </div>
                                        ) : null}
                                </div>
                                {!isProfileEditing ? (
                                    // PROFILE SECTION
                                    <div>
                                        {profilename && !isProfileEditing ? (
                                              <p>
                                                <span className="title-caption">Full name </span><br/>
                                                <span className="data-caption">{profilename}</span>
                                              </p>
                                        ) : null}
                                        {userGroups && userGroups !== 'null' ? (
                                            userGroups
                                            .filter((option) => option.value === userGroup)
                                            .slice(0, 1)
                                            .map((option) => (
                                                <p key={option.value}><span className="title-caption">User Groups</span><br/>
                                                    <span className="data-caption">{option.label}</span></p>
                                            ))
                                        ) : null}
                                        {userRoles ? (
                                        <p>
                                            <span className="title-caption">Role </span>
                                            <br />
                                            <span className="data-caption">
                                                {userRoles.map((group, index) => {
                                                    const filterLabels = groupsRoles
                                                    .filter(item => userRoles.includes(item.value));
                                                    const groupOption = filterLabels.find(option => option.value === group);
                                                    const label = groupOption ? groupOption.label : group;

                                                    if(!groupOption) {
                                                        return;
                                                    }

                                                    return (
                                                        <React.Fragment key={group}>
                                                            {label}
                                                            {index !== userRoles.length - 1 && ', '}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </span>
                                        </p>
                                        ) : null}
                                        {email ? (
                                        <p><span className="title-caption">Email address </span><br/>
                                                <span className="data-caption">{email}</span></p>
                                        ) : null}
                                    </div>
                                ) :
                                // END PROFILE SECTION


                                // EDIT PROFILE SECTION
                                (
                                    <div className="row">
                                        <div className="col-md-6">
                                            {userRoles ? (
                                                <>
                                                <p>
                                                    <span className="title-caption">Role </span><br/>
                                                    <span className="data-caption">
                                                        {userRoles.map((group, index) => {
                                                            const filterLabels = groupsRoles
                                                            .filter(item => userRoles.includes(item.value));
                                                            const groupOption = filterLabels.find(option => option.value === group);
                                                            const label = groupOption ? groupOption.label : group;

                                                            if(!groupOption) {
                                                                return;
                                                            }

                                                            return (
                                                                <React.Fragment key={group}>
                                                                    {label}
                                                                    {index !== userRoles.length - 1 && ', '}
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </span>
                                                </p>
                                                </>
                                            ) : null}
                                        </div>
                                        <div className="col-md-12">
                                            {email ? (
                                                <p>
                                                    <span className="title-caption">Email address </span><br/>
                                                    <span className="data-caption">{email}</span>
                                                </p>
                                            ) : null}
                                        </div>
                                        <div className="form-container">
                                            <div className="form-group">
                                                <label htmlFor="fullname">Full name</label>
                                                <input
                                                    id="fullname"
                                                    className='form-control'
                                                    type='text'
                                                    placeholder="Enter full name"
                                                    onChange={handleNameChange}
                                                    value={profilename}
                                                ></input>
                                            </div>
                                            {
                                                userGroups && userGroups !== null &&
                                                    <div className="form-group">
                                                        <label htmlFor="user-groups">User Groups</label>
                                                        <Select
                                                            id="user-groups"
                                                            className="department-select"
                                                            showSearch
                                                            placeholder="User Groups"
                                                            optionFilterProp="children"
                                                            onChange={onUserGroupsChange}
                                                            onSearch={onUserGroupsSearch}
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={userGroups}
                                                            value={profileUserGroup}
                                                            suffixIcon={<KeyboardArrowUpRoundedIcon className="custom-icon" />}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                // END EDIT PROFILE SECTION
                                )}

                                <div>
                                    {!isProfileEditing ? (
                                    <div className="button-group">
                                        <button className="profile-btn btn btn-sm btn-primary"
                                        onClick={handleEditProfileClick}
                                        >Edit Profile</button>
                                    </div>
                                    ) : (
                                    <>
                                    <div className="button-group">
                                        <button className="profile-btn btn btn-sm btn-primary"
                                            disabled={!profilename}
                                            onClick={handleSaveProfileClick}
                                        >Save</button>
                                        <button className="profile-btn btn btn-sm btn-secondary"
                                        onClick={handleCancelClick}
                                        >Cancel</button>
                                    </div>
                                    </>
                                    )}
                                </div>
                            </div>
                            {
                                !isProfileEditing && subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                                    <div className="profile-view-backup-code-2fa">
                                        <h3>{ Localization.Profile.TwoFA.Title }</h3>
                                        <p className="paragraph-1">{ Localization.Profile.TwoFA.Desc }</p>
                                        <button className="text-button" onClick={() => SetModalShow(true)}>{ Localization.Profile.TwoFA.Button }</button>
                                    </div>
                            }

                        </div>
                        <div className={` col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 ${showProfileChangePassword ? '' : ' hidden'} ${showChangePassword? 'mobile-open': 'mobile-close'}`}>
                            <div className="message-container">
                                {messageInfo.length > 0 ? (
                                    <AlertNotification variant={passwordSuccessful ? "success" : "error"} content={messageInfo}/>
                                ) : null}
                            </div>
                            <div className={`change-password-group ${!messageInfo.length > 0 ? 'top-adjust' : ''}`}>
                                <h2 className="title-text">Change Password</h2>
                                <div className="form-container">
                                    <div className="form-group">
                                        <label htmlFor="currentpassword" className={`${passwordInvalid ? 'warning-label' : ''}`}>Current password</label>
                                        <input
                                            id="currentpassword"
                                            className={`form-control ${passwordInvalid ? 'warning-input' : ''}`}
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Enter current password"
                                            onChange={handlCurrentPasswordChange}
                                            onCopy={handleCopy}
                                            onKeyDown={handleCurrentPasswordKeyPress}
                                            ref={currentPasswordRef}
                                        ></input>
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="show-password-icon"
                                        >
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </button>
                                    </div>
                                    {passwordInvalid ? (
                                    <div className="form-group reenterpassword-group">
                                        <p className="reenterpassword-warning"><WarningRoundedIcon /> Your password is invalid</p>
                                    </div>
                                    ) : null}
                                    <div className="form-group password-validation">
                                        <label htmlFor="newpassword">New password</label>
                                        <input
                                            id="newpassword"
                                            className="form-control"
                                            type={showNewPassword ? 'text' : 'password'}
                                            placeholder="Enter new password"
                                            onChange={handlNewPasswordChange}
                                            onCopy={handleCopy}
                                            onKeyDown={handleNewPasswordKeyPress}
                                            disabled={currentpasswordInputDisabled}
                                            ref={newPasswordRef}
                                        ></input>
                                        <button
                                            type="button"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            className="show-password-icon pt-1"
                                        >
                                            {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <p className={`eightchar ${eightCharValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> At least 8 characters</p>
                                        <p className={`lowercase ${lowercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> Include at least one lowercase</p>
                                        <p className={`uppercase ${uppercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon /> Include at least one uppercase</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="reenterpassword" className={`${reenternMessage ? 'warning-label' : ''}`}>Re-enter new password</label>
                                        <input
                                            id="reenterpassword"
                                            className={`form-control ${reenternMessage ? 'warning-input' : ''}`}
                                            type={showReenterPassword ? 'text' : 'password'}
                                            placeholder="Re-enter new password"
                                            onChange={handlReenterPasswordChange}
                                            onCopy={handleCopy}
                                            onKeyDown={handleProcessChangePasswordKeyPress}
                                            disabled={newpasswordInputDisabled}
                                            ref={reenterPasswordRef}
                                        ></input>
                                        <button
                                            type="button"
                                            onClick={() => setShowReenterPassword(!showReenterPassword)}
                                            className="show-password-icon"
                                        >
                                            {showReenterPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </button>
                                    </div>
                                    {reenternMessage ? (
                                    <div className="form-group reenterpassword-group">
                                        <p className="reenterpassword-warning"><WarningRoundedIcon /> {reenternMessage}</p>
                                    </div>
                                    ) : null}
                                    <div className="button-group">
                                        <button className="profile-btn btn btn-primary"
                                            onClick={handleChangePasswordClick}
                                            disabled={buttonDisabled}
                                            ref={processChangePasswordRef}
                                        >Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                !isProfileEditing && subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                    <GenericModal
                        className={"profile-view-backup-code-2fa"}
                        modalType={"profile2fa"}
                        modalShow={modalShow}
                        userAuth={userAuth}
                        verifyLoading={verifyLoading}
                        showBackupCodes={showBackupCodes}
                        handleModalClose={handleModalClose}
                        handleModalVerify={handleModalVerify}
                        handleModalGenerateNewCodes={handleModalGenerateNewCodes}
                        currentPassword={currentPassword}
                    >
                        { (userAuth && showBackupCodes) ? renderBackupCodes() : renderGenericModalPassword() }
                    </GenericModal>
            }
        </>
    )
}

export default ProfileView;