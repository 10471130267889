import { black, iconSize } from '../../shared/utils';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const TickIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <CheckCircleRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default TickIcon;