import { spanishGray, iconSize } from '../../shared/utils';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

const EmailIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <EmailRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default EmailIcon;