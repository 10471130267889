import { useState, createContext, useRef } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({children}) => {
    const [toastHeader, setToastHeader] = useState("");
    const [toastVariant, setToastVariant] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [showBackToTop, setShowBackToTop] = useState(true);
    const [tempfilesData, setTempFilesData] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [uploadError, setUploadError] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [successPercentage, setSuccessPercentage] = useState(0);
    const [errorPercentage, setErrorPercentage] = useState(0);
    const [offcanvasState, setOffcanvasState] = useState(false);
    const [progressState, SetProgressState] = useState(false);
    const [isUploadSuccess, setUploadSuccess] = useState(false);
    const filesArr = {
        total: 0,
        files: []
    };
    const stickyRef = useRef(null);
    const settingRef = useRef(null);
    const processRef = useRef(null);
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const imageRef = useRef(null);
    const pdfRef = useRef(null);
    const hideToastMessage = () => setShowToast(false);

    const updateToastMessage = (toastMessage, variant) => {
        setToastHeader(toastMessage);
        setToastVariant(variant);
        setShowToast(true);
    };

    const updateBackToTop = (newValue) => {
        setShowBackToTop(newValue)
    }

    return (
        <GlobalContext.Provider value={{
            toastVariant,
            videoRef,
            audioRef,
            imageRef,
            pdfRef,
            stickyRef,
            settingRef,
            processRef,
            toastHeader,
            showToast,
            showBackToTop,
            hideToastMessage,
            updateToastMessage,
            updateBackToTop,
            tempfilesData,
            setTempFilesData,
            filesData,
            setFilesData,
            uploadError,
            setUploadError,
            percentage,
            setPercentage,
            successPercentage,
            setSuccessPercentage,
            errorPercentage,
            setErrorPercentage,
            offcanvasState,
            setOffcanvasState,
            progressState,
            SetProgressState,
            isUploadSuccess,
            setUploadSuccess,
            filesArr
        }}>
            {children}
        </GlobalContext.Provider>
    );
};
