import { useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../components/shared/context/session-provider";
import Button from 'react-bootstrap/Button';
import path from "../../components/shared/routePaths";
import RegistrationService from '../../services/api/registrationService';
import Localization from "../../components/shared/localization";

const TenantVerifyEmail = (props) => {
    const { productUrl } = useContext(SessionContext);

    // Email
    const [emailVerified, setEmailVerified] = useState(false);

    // Countdown
    const [countdown, setCountdown] = useState(5);

    // Api Call Check
    const [apiCallCompleted, setApiCallCompleted] = useState(false);

    // Navigate
    const navigate = useNavigate();
    const pathPrefix = "/";
    const queryString = window.location.search;

    const getQueryStringParameter = (name) => {
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    };

    const handleLogoClick = () => {
        navigate(pathPrefix + path.LOGIN, { replace: true });
    }

    useEffect(() => {
        const tenantVerifyEmailFunc = async () => {
            const uuidParam = getQueryStringParameter('uuid');
            const codeParam = getQueryStringParameter('code');

            if(!uuidParam && !codeParam) {
                navigate(pathPrefix + path.ERROR, { replace: true });
            };

            try {
                const verifyEmailRes = await RegistrationService.verifyTenantEmail({
                    uuid: uuidParam,
                    code: codeParam
                });

                if (verifyEmailRes.code === 200) {
                    setEmailVerified(true);
                } else {
                    navigate(pathPrefix + path.LOGIN, { replace: true });
                }
            } catch (error) {
                // Handle error if verification fails
                console.error("Email verification failed:", error);
                window.location.replace(productUrl);
            } finally {
                // Set the API call completion state to true
                setApiCallCompleted(true);
            };
        };

        tenantVerifyEmailFunc();
    }, []);

  useEffect(() => {
    // Start the countdown only if the API call has completed
    if (apiCallCompleted && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);

      // Clean up the timer when the component unmounts
      return () => clearTimeout(timer);
    } else if (countdown === 0) {
      // Redirect after the countdown reaches 0
      navigate(pathPrefix + path.LOGIN, { replace: true });
    }
  }, [countdown, apiCallCompleted]);

  return (
    <>
    {
      emailVerified &&
        <>
            <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-3 d-flex flex-row">
                        <button className="dam-logo" onClick={handleLogoClick}>
                            <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo"/>
                        </button>
                    </div>
                </div>
            </div>
            </header>
            <div className="email-verification-success">
                <div className="row">
                    <div className="col-12">
                        <img  src="/images/email-verification.svg" alt="Welcome SVG"/>
                        <h2 className="h2">{Localization.TenantVerifyEmail.Title}</h2>
                        <p className="paragraph-1">{Localization.TenantVerifyEmail.Desc}</p>
                        <Button disabled>Redirecting in {countdown}s...</Button>
                        <p>
                            <a href={pathPrefix + path.LOGIN}>{Localization.TenantVerifyEmail.LogIn}</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    }
    </>
  );
};

export default TenantVerifyEmail;