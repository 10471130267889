import { useState, useEffect, Suspense, lazy, useContext } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import { createStore, getStoreItem, setStoreItem } from '../../../store';
import { Select } from 'antd';
import { ShareContext } from '../../shared/context/share-state';
import { ApiContext } from '../../shared/context/api-state';
import { SessionContext } from '../../shared/context/session-provider';
import { IsTablet } from "../../shared/utils";
import Localization from '../../shared/localization';
import _ from 'lodash';
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import S3Utils from '../../../services/util/s3utils';
import SolutionManifest from '../../../solution-manifest';
import CollectionHooks from '../../shared/hooks/collection-hooks';
import CustomIcons from "../../shared/components/custom-icons";
import path from '../../shared/routePaths';
import MobileModal from "../../shared/components/mobile-modal";
import CollectionCardSkeleton from "../collection-skeleton";

const CollectionCard = lazy(() => import('../collection-card'));

const CollectionView = (props) => {
    const { getTenantCollection } = useContext(ApiContext);
    const [offcanvasState, updateOffcanvasState] = useState(false);
    const [allCollection, setAllCollection] = useState([]);
    const [collectionCard, setCollectionCard] = useState([]);
    const [fetchCollection, setFetchCollection] = useState(false);
    const [sort, setSortBy] = useState(1);
    const [loading, setLoading] = useState(true);
    const [fetching, setIsFetching] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const placeholderSkeleton = 3;
    const PAGE_SIZE = 12;

    // Check isTablet?
    const isTablet = IsTablet();

    // Share Context
    const { updateMenuTabKey,
        isMobileModalOpen,
        handleMobileModalOpen,
        handleMobileModalClose } = useContext(ShareContext);
        
    // Session Context
    const { isEndUser,
        isUserAdmin } = useContext(SessionContext);

    const { handleClickCollectionCard } = CollectionHooks();

    const handleSortBy = (value) => {
        switch (value) {
            case 1:
                const sortedDataDesc = _.sortBy(allCollection, (item) => -item.timestamp);
                setCollectionCard(sortedDataDesc);
                break;
            case 2:
                const sortedDataAsc = _.sortBy(allCollection, 'timestamp');
                setCollectionCard(sortedDataAsc);
                break;
            case 3:
                const sortedDataAtoZ = _.orderBy(allCollection, [(item) => item.name.toLowerCase()]);

                setCollectionCard(sortedDataAtoZ);
                break;
            case 4:
                const sortedDataZtoA = _.orderBy(allCollection, [(item) => item.name.toLowerCase()], ['desc']);
                setCollectionCard(sortedDataZtoA);
                break;
            default:
                break;
        }
        setSortBy(value);
    }

    const renderPlaceholderSkeleton = () => {
        return Array.from({ length: placeholderSkeleton }, (_, index) => {
            return(<div className={isTablet ? "col-12" : "col-4"} key={index}><CollectionCardSkeleton /></div>);
        });
    };

    const getData = (allItems, page, pageSize) => {
        let pg = page || 1,
        pgSize = pageSize || 100,
        offset = (pg - 1) * pgSize,
        pagedItems = _.drop(allItems, offset).slice(0, pgSize);
        return {
            page: pg,
            pageSize: pgSize,
            total: allItems,
            totalPages: Math.ceil(allItems?.length / pgSize),
            data: pagedItems
        };
    };

    const loadItems = () => {
        if (fetching) {
            return;
        }
        setIsFetching(true);

        let page = currentPage + 1;
        if (page > PAGE_SIZE) {
            setIsFetching(false);
            return;
        }

        let pageData = getData(allCollection, page, PAGE_SIZE);
        setCurrentPage(page);
        setCollectionCard([...collectionCard, ...pageData.data]);
        setIsFetching(false);

        return;
    };

    const handleScroll = () => {
        const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;

        if (loading) {
            return;
        }

        if (!endOfPage) {
            return;
        }

        loadItems();
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        if(props.menuTabKey === path.COLLECTIONS) {
            const getCollection = async () => {
                setCollectionCard([]);
                setFetchCollection(false);

                try {
                    const tenantCollection = await getTenantCollection("assets");

                    if(tenantCollection && tenantCollection.length > 0) {
                        const filterRes = tenantCollection.filter(collection => collection.assets.length > 0);
                        const sortedFilterRes = _.sortBy(filterRes, 'timestamp').reverse();

                        if(sortedFilterRes.length > 0) {
                            await sortedFilterRes.forEach(async (element, index) => {
                                let imageUrl = [], blob = [], type = [], elArray = element;

                                await getStoreItem(element.uuid, createStore('collections', 'asset-collections')).then(async storeItem => {
                                    if(storeItem && storeItem.uuid) {
                                        for(let i = 0; i < storeItem.assets.length; i++) {
                                            if(storeItem.type[i] === 'image' || storeItem.type[i] === 'video' || storeItem.type[i] === 'document') {
                                                let url = URL.createObjectURL(storeItem.blob[i]);
                                                imageUrl.push(url);
                                            }
                                            if(storeItem.type[i] === 'audio') {
                                                imageUrl.push("");
                                            }

                                            if(i === storeItem.assets.length - 1) {
                                                elArray.imageUrl = imageUrl;
                                                elArray.type = storeItem.type;
                                                elArray.total = storeItem.total;
                                                setFetchCollection(true);
                                                setAllCollection((prevCollection) => [...prevCollection, elArray]);
                                            }
                                        };

                                        return;
                                    }

                                    for(let i = 0; i < element.assets.length; i++) {
                                        if(element.assets[i].type === 'image') {
                                            if (element.assets[i].mime === 'image/gif') {
                                                let images = element.assets[i].proxies?.filter(x => x.type === 'image');

                                                if (images?.length > 0) {
                                                    await S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key).then(response => {
                                                        let imageBlob = new Blob([response.Body.buffer], {
                                                            type: response.ContentType,
                                                        });
                                                        let url = URL.createObjectURL(imageBlob);
                                                        imageUrl.push(url);
                                                        blob.push(imageBlob);
                                                        type.push(element.assets[i].type);
                                                    });
                                                }
                                            } else {
                                                await S3Utils.getObject(element.assets[i].bucket, element.assets[i].key).then(response => {
                                                    let imageBlob = new Blob([response.Body.buffer], {
                                                        type: response.ContentType,
                                                    });

                                                    let url = URL.createObjectURL(imageBlob);
                                                    imageUrl.push(url);
                                                    blob.push(imageBlob);
                                                    type.push(element.assets[i].type);
                                                }).catch((e) => {console.error(`fail to get collection image`)});
                                            }
                                        }

                                        if(element.assets[i].type === 'video' || element.assets[i].type === 'document') {
                                            let images = element.assets[i].proxies?.filter(x => x.type === 'image');

                                            if(!images?.length) {
                                                return;
                                            }

                                            await S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key).then(response => {
                                                let imageBlob = new Blob([response.Body.buffer], {
                                                    type: response.ContentType,
                                                });
                                                let url = URL.createObjectURL(imageBlob);
                                                imageUrl.push(url);
                                                blob.push(imageBlob);
                                                type.push(element.assets[i].type);
                                            });
                                        }

                                        if (element.assets[i].type === 'audio') {
                                            imageUrl.push("");
                                            blob.push("");
                                            type.push(element.assets[i].type);
                                        }

                                        if(i === element.assets.length - 1) {
                                            elArray.imageUrl = imageUrl;
                                            elArray.blob = blob;
                                            elArray.type = type;
                                            elArray.total = element.assets.length;
                                            setFetchCollection(true);
                                            setAllCollection((prevCollection) => [...prevCollection, elArray]);

                                            if(elArray.blob && elArray.imageUrl.length > 0) {
                                                setStoreItem(element.uuid, elArray, createStore('collections', 'asset-collections'));
                                            }
                                        }
                                    }
                                });
                            });

                            setLoading(false);
                            return sortedFilterRes;
                        } else {
                            setFetchCollection(true);
                            setCollectionCard([])
                        }
                    } else {
                        setFetchCollection(true);
                        setCollectionCard([])
                    }
                } catch (error) {
                    console.log("Error fail to get collection: ", error)
                };
            };

            const renderCollectionCard = async () => {
                const allCollection = await getCollection();

                let pageData = getData(allCollection, 1, PAGE_SIZE);
                setCurrentPage(1);
                setCollectionCard(pageData.data);
            }
            renderCollectionCard();
        }
    }, [props.menuTabKey]);

    return(
        <>
            {
                props.menuTabKey === path.COLLECTIONS &&
                    <section>
                        <Container>

                            <div className='collection-view-wrapper'>
                                {
                                    fetchCollection ?
                                        collectionCard.length > 0
                                        ?
                                            <>
                                                <h2>{Localization.Collection.Title}</h2>
                                                <div className="row asset-view-container">
                                                    <div className="col-12">
                                                        <Navbar expand="lg" className="justify-content-between p-0">
                                                            <Nav className="w-100">
                                                                <Nav.Item>
                                                                    <div className="collection-view-show-number">
                                                                        {
                                                                            allCollection.length > 1 ? `${allCollection.length} collections` : `${allCollection.length} collection`
                                                                        }
                                                                    </div>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <div className="collection-view-sort-by">
                                                                        <Select
                                                                            id="sort-collection"
                                                                            className='border-0'
                                                                            defaultValue={1}
                                                                            value={sort}
                                                                            bordered={false}
                                                                            suffixIcon={<CustomIcons variant="arrow" direction="expand"/>}
                                                                            options={[
                                                                                { value: 1, label: 'New to Old' },
                                                                                { value: 2, label: 'Old to New' },
                                                                                { value: 3, label: 'A to Z' },
                                                                                { value: 4, label: 'Z to A' },
                                                                            ]}
                                                                            aria-label="sort"
                                                                            onChange={(value) => handleSortBy(value)}
                                                                        />
                                                                    </div>
                                                                </Nav.Item>
                                                            </Nav>
                                                        </Navbar>
                                                        <div className="mobile-collection-view-container">
                                                            <h3>{allCollection.length > 1 ? `${allCollection.length} collections` : `${allCollection.length} collection`}</h3>
                                                
                                                            <div className="mobile-collection-new-to-old"  onClick={handleMobileModalOpen}>
                                                                <h3>
                                                                    {sort === 1 && 'New to Old'}
                                                                    {sort === 2 && 'Old to New'}
                                                                    {sort === 3 && 'A to Z'}
                                                                    {sort === 4 && 'Z to A'}
                                                                    <CustomIcons variant="arrow" direction="expand"/>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="collection-view-card-container">
                                                    <div className="row">
                                                        {
                                                            collectionCard.map((collection) => {
                                                                return (
                                                                    <Suspense key={collection.uuid} fallback={<div className="col-4"><CollectionCardSkeleton /></div>}>
                                                                        <div className="col-4" key={collection.uuid}>
                                                                            <CollectionCard
                                                                                collection={collection}
                                                                                handleClickCollectionCard={handleClickCollectionCard}/>
                                                                        </div>
                                                                    </Suspense>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        :
                                        <>
                                            <div className="no-collection-view">
                                                <img src="./images/no-assets.svg" alt="No Collection Illustration"/>
                                                <h2>{Localization.Collection.NoCollections}</h2>
                                                {
                                                    (!isEndUser && !isUserAdmin) &&
                                                        <>
                                                            <p>{Localization.Collection.NoCollectionsDesc}</p>
                                                            <button className="btn btn-primary" onClick={() => updateMenuTabKey(path.UPLOAD)}>{Localization.Collection.NoCollectionsBtn}</button>
                                                        </>
                                                }
                                            </div>
                                        </>
                                    :
                                        <div className="row">
                                            <Skeleton className="mb-3" width={200} height={30} />
                                            {renderPlaceholderSkeleton()}
                                        </div>
                                }
                            </div>
                        </Container>

                        {
                            isMobileModalOpen && (
                                <MobileModal title="Sort By" onClose={handleMobileModalClose}>
                                    <div className="mobile-modal-sort-only">
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="new-to-old"
                                                name="sort"
                                                value={1}
                                                checked={sort === 1}
                                                onChange={() => { handleSortBy(1); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="new-to-old">
                                                <h3>New to Old</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="old-to-new"
                                                name="sort"
                                                value={2}
                                                checked={sort === 2}
                                                onChange={() => { handleSortBy(2); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="old-to-new">
                                                <h3>Old to New</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="a-to-z"
                                                name="sort"
                                                value={3}
                                                checked={sort === 3}
                                                onChange={() => { handleSortBy(3); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="a-to-z">
                                                <h3>A to Z</h3>
                                            </label>
                                        </div>
                                        <div className="sort-data">
                                            <input
                                                type="radio"
                                                id="z-to-a"
                                                name="sort"
                                                value={4}
                                                checked={sort === 4}
                                                onChange={() => { handleSortBy(4); handleMobileModalClose(); }}
                                            />
                                            <label htmlFor="z-to-a">
                                                <h3>Z to A</h3>
                                            </label>
                                        </div>
                                    </div>
                                </MobileModal>
                            )
                        }
                    </ section>
            }

        </>
    );
};

export default CollectionView;
