import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import ErrorIcon from '../../../icons/error';
import InfoIcon from '../../../icons/info';
import TickIcon from "../../../icons/tick";

const AlertNotification = (props) => {
    return(
        <>
            <div className={props.variant === 'success' ? `alert-notification alert-notification-success ${props.className ? props.className : ""}` : props.variant === 'error' ? `alert-notification alert-notification-error ${props.className ? props.className : ""}` : `alert-notification ${props.className ? props.className : ""}`}>
                {
                    props.variant === 'success'
                    ?
                        <>
                            <div className='alert-notification-success-group'>
                                <TickIcon color="#30BA34" />
                                { props.content && <div  className='alert-notification-content'>{props.content}</div> }
                                { props.htmlContent && <div  className='alert-notification-content'>{props.info && <InfoIcon className="alert-notification-infoIcon"/>}<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.htmlContent)}}></div></div> }
                            </div>
                        </>
                    :
                    props.variant === 'error'
                    ?
                        <>
                            <div className='alert-notification-error-group'>
                                <ErrorIcon color="#D80029" />
                                { props.content && <div className='alert-notification-content'>{props.content}</div> }
                                { props.htmlContent && <div className='alert-notification-content'>{props.info && <InfoIcon className="alert-notification-infoIcon"/>}<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.htmlContent)}}></div></div> }
                            </div>
                        </>
                    :
                    props.variant === 'default'
                    ?
                        <>
                            { props.header && <div className='alert-notification-header'>{props.info && <InfoIcon />}{props.header}</div> }
                            { props.content && <div className='alert-notification-content'><InfoIcon color="#FF6946" /> {props.content}</div> }
                            { props.htmlContent && <div className='alert-notification-content'>{props.info && <InfoIcon color="#FF6946" />}<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.htmlContent)}}></div></div> }
                        </>
                    :
                        <>
                            { props.header && <div className='alert-notification-header'>{props.info && <InfoIcon />}{props.header}</div> }
                            { props.content && <div className='alert-notification-content'><InfoIcon color="#FF6946" /> {props.content}</div> }
                            { props.htmlContent && <div className='alert-notification-content'><div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.htmlContent)}}></div></div> }
                        </>
                }
            </div>
        </>
    );
};

export default AlertNotification;

AlertNotification.propTypes = {
    variant: PropTypes.string,
    info: PropTypes.bool,
    header: PropTypes.string,
    content: PropTypes.string,
    htmlContent: PropTypes.string
}