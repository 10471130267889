import { createContext, useContext } from 'react';
import { getStoreItem, setStoreItem, createStore } from '../../../../store';
import { secondsToTime } from '../../utils';
import { SessionContext } from '../session-provider';
import AssetService from '../../../../services/api/assetsService';
import SearchService from '../../../../services/api/searchService';
import SolutionManifest from '../../../../solution-manifest';
import S3Utils from '../../../../services/util/s3utils';
import _ from 'lodash';

export const ApiContext = createContext();

export const ApiProvider = ({children}) => {
    // Session Context
    const { tenantUuid } = useContext(SessionContext);

    // Get Analysis Result
    const getAnalysisResults = async (uuid) => {
        try {
            const labelPromise = await AssetService.getAnalysisResults(uuid, tenantUuid);
            const proxyBucket = SolutionManifest.Proxy.Bucket;

            if(labelPromise || labelPromise.length > 0) {
                const output = labelPromise[0]?.['rekog-image'] ?
                                labelPromise[0]['rekog-image'].label.output :
                                labelPromise[0]?.['rekognition'] ?
                                labelPromise[0]['rekognition'].label.output :
                                null;

                if(output) {
                    const response = await S3Utils.getObject(proxyBucket, output)
                    .catch(() => undefined);

                    if (response) {
                        const data = JSON.parse(response.Body);
                        return data;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } catch (err) {
            console.log('err: ', err);
            return null;
        }
    }

    // Search Asset API
    const searchAssetsApi = async (options) => {
        try {
            const assets = await SearchService.Search({ ...options });
            let searchData = [];
            let unqiueSearchData = [];

            searchData = searchData.concat(assets.hits);
                const uniqueUuids = new Set();

                unqiueSearchData = searchData.filter(obj => {
                    if (!uniqueUuids.has(obj.uuid)) {
                        uniqueUuids.add(obj.uuid);
                        return true;
                    }
                    return false;
            });

            return unqiueSearchData;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Get Smart Label
    const getSmartLabelData = (analysisResults) => {
        let analysis;

        if (analysisResults) {
          if (analysisResults.Labels)
            analysis = analysisResults.Labels;
          else {
            if(Object.entries(analysisResults)) {
                analysis = Object.entries(analysisResults).map(([Name, Json]) => ({ Name, Json }));
            }
          }
        }

        return analysis;
    };

    const getDimensionsFromUrl = (url, asset) => {
        return new Promise((resolve, reject) => {
            if(asset.type === 'image') {
                const img = new Image();
                img.onload = () => {
                  const { width, height } = img;
                  resolve({ width, height });
                };
                img.onerror = reject;
                img.src = url;
            } else if(asset.type === 'video') {
                const video = document.createElement('video');
                video.onloadedmetadata = () => {
                    const { videoWidth: width, videoHeight: height } = video;
                    const frameRate = video.webkitDecodedFrameRate || 30;
                    resolve({ width, height, frameRate });
                };
                video.onerror = reject;
                video.src = url;
                video.load();
            }
        });
    };

    const getAssetsById = async (uuid) => {
        const getCacheItem = await getStoreItem(uuid);

        if(getCacheItem) {
            return getCacheItem;
        } else {
            const [asset, collectionResults] = await Promise.all([
                AssetService.getAssetById(uuid, tenantUuid),
                getTenantCollection()
            ]);

            if(asset) {
                if(asset.overallStatus !== 'COMPLETED') {
                    return;
                }

                const date = new Date(asset.timestamp);
                const timeZoneOffset = 8 * 60;
                date.setMinutes(date.getMinutes() + timeZoneOffset);
                const dateOptions = {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZone: 'UTC'
                };

                const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);

                const parts = formattedDate.split(', ');
                const [datePart, yearPart, timePart] = parts;
                const [month, day] = datePart.split(' ');
                let newFormattedDate = `${day} ${month} ${yearPart} ${timePart}`;

                if(newFormattedDate.includes("at")) {
                    newFormattedDate = newFormattedDate.replace("at", "");
                }

                if(newFormattedDate.includes("undefined")) {
                    newFormattedDate = newFormattedDate.replace("undefined", "");
                }

                const mimeType = asset.mime;
                const formatMime = mimeType.split("/")[1].toUpperCase();
                const fileSize = asset.fileSize;
                let formatSize, newData;

                if (fileSize < 1024) {
                    formatSize = fileSize + " B";
                } else if (fileSize < 1024 * 1024) {
                    formatSize = (fileSize / 1024).toFixed(2) + " KB";
                } else {
                    formatSize = (fileSize / (1024 * 1024)).toFixed(2) + " MB";
                }

                const filterCollection = collectionResults
                            .filter((item) => asset.collections ? asset.collections.includes(item.uuid) : false)
                            .map((item) => { return { name: item.name, uuid:item.uuid }; });


                const data = {
                    name: asset.basename,
                    desc: asset.description,
                    date: newFormattedDate,
                    status: asset.status,
                    overallStatus: asset.overallStatus,
                    size: formatSize,
                    format: formatMime,
                    owner: asset.owner,
                    uuid: asset.uuid,
                    userUuid: asset.useruuid,
                    comments: asset.comments,
                    mime: asset.mime,
                    tags: asset.attributes.tags ? asset.attributes.tags.split(',') : "",
                    collections: filterCollection.length > 0 ? filterCollection : "",
                    key: `${asset.basename}.${asset.key.split('.').pop()}`,
                    aiSmartTags: asset?.aiSmartTags ? asset?.aiSmartTags.split(",") : ""
                };

                if (asset.type === 'image') {
                    if (asset.mime === 'image/gif') {
                        let images = await asset.proxies?.filter(x => x.type === 'image');
                        let proxyBlob;

                        if (images?.length > 0) {
                            const S3ProxyObject = await S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key);
                            proxyBlob = new Blob([S3ProxyObject.Body.buffer], {
                                type: S3ProxyObject.ContentType,
                            });
                        }

                        const S3IngestObject = await S3Utils.getObject(asset.bucket, asset.key)
                        const ingestBlob = new Blob([S3IngestObject.Body.buffer], {
                            type: S3IngestObject.ContentType,
                        });
                        const url = URL.createObjectURL(ingestBlob);
                        const dimensions = await getDimensionsFromUrl(url, asset);

                        newData = { ...data, type: asset.type, proxyBlob: proxyBlob, ingestBlob: ingestBlob, dimensions: dimensions !== null && `${dimensions.width} x ${dimensions.height}` };
                        setStoreItem(asset.uuid, newData);

                        return newData;
                    }

                    let images = await asset.proxies?.filter(x => x.type === 'image');

                    if (!images?.length) {
                        return null;
                    }

                    const [S3ProxyObject, S3IngestObject] = await Promise.all([
                        S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key),
                        S3Utils.getObject(asset.bucket, asset.key)
                    ]);
                    const imageIngestBlob = new Blob([S3IngestObject.Body.buffer], {
                        type: S3IngestObject.ContentType,
                    });
                    const imageProxyBlob = new Blob([S3ProxyObject.Body.buffer], {
                        type: S3ProxyObject.ContentType,
                    });
                    const url = URL.createObjectURL(imageProxyBlob);
                    const dimensions = await getDimensionsFromUrl(url, asset);

                    newData = { ...data, type: asset.type, ingestBlob: imageIngestBlob, proxyBlob: imageProxyBlob, dimensions: dimensions !== null && `${dimensions.width} x ${dimensions.height}`};
                    setStoreItem(asset.uuid, newData);
                }

                if (asset.type === 'document') {
                    let images = await asset.proxies?.filter(x => x.type === 'image');

                    if (!images?.length) {
                        return null;
                    }

                    const [S3ProxyObject, S3IngestObject] = await Promise.all([
                        S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key),
                        S3Utils.getObject(asset.bucket, asset.key)
                    ]);
                    const imageIngestBlob = new Blob([S3IngestObject.Body.buffer], {
                        type: S3IngestObject.ContentType,
                    });
                    const imageProxyBlob = new Blob([S3ProxyObject.Body.buffer], {
                        type: S3ProxyObject.ContentType,
                    });
                    newData = { ...data, type: asset.type, ingestBlob: imageIngestBlob, proxyBlob: imageProxyBlob };
                    setStoreItem(asset.uuid, newData);
                }

                if (asset.type === 'video') {
                    let images = await asset.proxies?.filter(x => x.type === 'image');

                    if(!images?.length) {
                        return null;
                    }

                    const [S3ProxyObject, S3IngestObject] = await Promise.all([
                        S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key),
                        S3Utils.getObject(asset.bucket, asset.key)
                    ]);

                    const imageIngestBlob = new Blob([S3IngestObject.Body.buffer], {
                        type: S3IngestObject.ContentType,
                    });

                    const imageProxyBlob = new Blob([S3ProxyObject.Body.buffer], {
                        type: S3ProxyObject.ContentType,
                    });
                    const url = URL.createObjectURL(imageProxyBlob);
                    // const dimensions = getDimensionsFromUrl(url, asset);

                    newData = { ...data, duration: secondsToTime(asset.duration), type: asset.type, proxyBlob: imageProxyBlob, ingestBlob: imageIngestBlob, videoContentType: S3IngestObject.ContentType };
                    setStoreItem(asset.uuid, newData);
                }

                if (asset.type === 'audio') {
                    const S3IngestObject = await S3Utils.getObject(asset.bucket, asset.key);
                    const audioIngestBlob = new Blob([S3IngestObject.Body.buffer], {
                        type: S3IngestObject.ContentType,
                    });

                    newData = { ...data, duration: secondsToTime(asset.duration), audioContentType: S3IngestObject.ContentType, ingestBlob: audioIngestBlob, type: asset.type };
                    setStoreItem(asset.uuid, newData);
                }

                return newData;
            }
        }
    };

    const getTenantCollection = async (type) => {
        const collections = await AssetService.getCollectionTenantUUID(tenantUuid, type);
        return collections;
    };

    //Get Asset-Collections
    const getAssetCollections = async () => {
        try {
            const tenantCollection = await getTenantCollection("assets");

            if (tenantCollection && tenantCollection.length > 0) {
                const filterRes = tenantCollection.filter(collection => collection.assets.length > 0);
                const sortedFilterRes = _.sortBy(filterRes, 'timestamp').reverse();

                if(sortedFilterRes.length > 0) {
                    await sortedFilterRes.forEach(async (element, index) => {
                        let imageUrl = [], blob = [], type = [], elArray = element;

                        await getStoreItem(element.uuid, createStore('collections', 'asset-collections')).then(async storeItem => {
                            if(storeItem && storeItem.uuid) {
                                for(let i = 0; i < storeItem.assets.length; i++) {
                                    if(storeItem.type[i] === 'image' || storeItem.type[i] === 'video' || storeItem.type[i] === 'document') {
                                        let url = URL.createObjectURL(storeItem.blob[i]);
                                        imageUrl.push(url);
                                    }
                                    if(storeItem.type[i] === 'audio') {
                                        imageUrl.push("");
                                    }

                                    if(i === storeItem.assets.length - 1) {
                                        elArray.imageUrl = imageUrl;
                                        elArray.type = storeItem.type;
                                        elArray.total = storeItem.total;
                                    }
                                };

                                return;
                            }

                            for(let i = 0; i < element.assets.length; i++) {
                                if(element.assets[i].type === 'image') {
                                    if (element.assets[i].mime === 'image/gif') {
                                        let images = element.assets[i].proxies?.filter(x => x.type === 'image');

                                        if (images?.length > 0) {
                                            await S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key).then(response => {
                                                let imageBlob = new Blob([response.Body.buffer], {
                                                    type: response.ContentType,
                                                });
                                                let url = URL.createObjectURL(imageBlob);
                                                imageUrl.push(url);
                                                blob.push(imageBlob);
                                                type.push(element.assets[i].type);
                                            });
                                        }
                                    } else {
                                        await S3Utils.getObject(element.assets[i].bucket, element.assets[i].key).then(response => {
                                            let imageBlob = new Blob([response.Body.buffer], {
                                                type: response.ContentType,
                                            });

                                            let url = URL.createObjectURL(imageBlob);
                                            imageUrl.push(url);
                                            blob.push(imageBlob);
                                            type.push(element.assets[i].type);
                                        }).catch((e) => {console.error(`fail to get collection image`)});
                                    }
                                }

                                if(element.assets[i].type === 'video' || element.assets[i].type === 'document') {
                                    let images = element.assets[i].proxies?.filter(x => x.type === 'image');

                                    if(!images?.length) {
                                        return;
                                    }

                                    await S3Utils.getObject(SolutionManifest.Proxy.Bucket, images[0].key).then(response => {
                                        let imageBlob = new Blob([response.Body.buffer], {
                                            type: response.ContentType,
                                        });
                                        let url = URL.createObjectURL(imageBlob);
                                        imageUrl.push(url);
                                        blob.push(imageBlob);
                                        type.push(element.assets[i].type);
                                    });
                                }

                                if (element.assets[i].type === 'audio') {
                                    imageUrl.push("");
                                    blob.push("");
                                    type.push(element.assets[i].type);
                                }

                                if(i === element.assets.length - 1) {
                                    elArray.imageUrl = imageUrl;
                                    elArray.blob = blob;
                                    elArray.type = type;
                                    elArray.total = element.assets.length;

                                    if(elArray.blob && elArray.imageUrl.length > 0) {
                                        setStoreItem(element.uuid, elArray, createStore('collections', 'asset-collections'));
                                    }
                                }
                            }
                        });

                    })
                }else {
                    console.log("sortedFilterRes is empty")
                }
            } else {
                console.log("tenant collection is empty")
            }
        } catch (error) {
            console.log("Error fail to get collection: ", error)
        }
    }

    //Get All-Collections
    const getAllCollections = async () => {
        return await getTenantCollection().then((res) => {
            const collectionData = res.map(item => ({
                ...item,
                checked: false
            }));
            const sortCollection = _.sortBy(collectionData, [function(o) { return o.name; }]);

            setStoreItem('all-collections', sortCollection, createStore('get-collections', 'all-collections'));
        }).catch((e) => {console.error(`fail to get collection`)});
    };

    return (
        <ApiContext.Provider value={{
            searchAssetsApi,
            getTenantCollection,
            getAssetCollections,
            getAllCollections,
            getAnalysisResults,
            getSmartLabelData,
            getAssetsById}}>
            {children}
        </ApiContext.Provider>
    );
};
