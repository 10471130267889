/* eslint-disable no-undef */
import ENDPOINTS from '../util/endpoints';

class RegistrationService {
    static async registrationUser(body) {
        const url = ENDPOINTS.UserRegistration;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-tenant-id': body.uuid
            },
            body: JSON.stringify(body)
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });

        return fetchResponse;
    };

    static async resendUserEmailCode(body) {
        const url = ENDPOINTS.UserResendEmailCode;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-tenant-id': body.tenant_uuid
            },
            body: JSON.stringify(body)
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });

        return fetchResponse;
    };

    static async onGETUserVerifyEmail(query) {
        const uuid = query.uuid;
        const code = query.code;
        const subdomain_uuid = query.subdomain_uuid;
        const url = `${ENDPOINTS.UserVerifyEmail}?uuid=${uuid}&code=${code}&subdomain_uuid=${subdomain_uuid}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'x-tenant-id': subdomain_uuid
            }
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });
        return fetchResponse;
    };

    static async onPOSTUserVerifyEmail(body) {
        const url = ENDPOINTS.UserVerifyEmail;
        const subdomain_uuid = body.subdomain_uuid;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-tenant-id': subdomain_uuid
            },
            body: JSON.stringify(body)
        };

        const fetchResponse = fetch(url, requestOptions)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });

        return fetchResponse;
    };

    static async verifyTenantEmail(query) {
        const uuid = query.uuid;
        const code = query.code;
        const url = `${ENDPOINTS.VerifyTenantEmail}?uuid=${uuid}&code=${code}`;

        const fetchResponse = fetch(url)
            .then(response => response.json())
            .catch(error => {
                console.error('Fetch Error:', error);
            });

        return fetchResponse;
    }
};

export default RegistrationService;
