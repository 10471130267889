import React, { useEffect, useState, useRef } from 'react';
import CustomIcons from '../custom-icons';

const ViewMobileModal = ({ title, subTitle, subTitle1, noHeader, onClose, children, isShow}) => {
    return (
        <div className="view-mobile-modal">
            <div className="view-mobile-modal-content">
                {!noHeader &&
                <div className="view-mobile-modal-header">
                    <div className="mobile-modal-close" onClick={onClose} >
                        <CustomIcons variant="arrow" direction="back"/>
                    </div>
                    {title && (
                        <div className="list-view-title-container">
                            <h3>{title}</h3>
                        </div>
                    )}
                </div>
                }
                <div className="view-mobile-modal-sub-header">
                    {subTitle && (
                        <div className='sub-title'>
                            <span className='paragraph-1'>{subTitle}</span><br/>
                            <span className={`paragraph-1 ${isShow ? 'd-none' : null}`}>{subTitle1}</span>
                        </div>
                    )}
                </div>
                <div className="view-mobile-modal-body">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ViewMobileModal;
