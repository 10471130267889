import { useContext } from "react";
import { ShareContext } from "../../context/share-state";
import path from "../../routePaths";

const SearchHooks = () => {
    const { showSearch,
        searchSubmitted,
        selectedOption,
        setSelectedOption,
        inputValue,
        searchKeyword,
        setSearchKeyword,
        setShowSearch,
        setSearchSubmitted,
        setInputValue,
        updateMenuTabKey } = useContext(ShareContext);

    const updateSearchValue = (searchValue) => {
        setSearchKeyword(searchValue);
        setInputValue(searchValue);
        setShowSearch(true);
        setSearchSubmitted(true);
        updateMenuTabKey(path.SEARCH);
    };

    return { showSearch,
        searchSubmitted,
        inputValue,
        updateSearchValue,
        setInputValue,
        selectedOption,
        setSelectedOption,
        searchKeyword,
        setSearchKeyword,
        setShowSearch,
        setSearchSubmitted };
};

export default SearchHooks;