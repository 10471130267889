import { spanishGray, iconSize } from '../../shared/utils';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const SearchIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <SearchRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default SearchIcon;