import { useState, useContext, useRef, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Localization from '../../shared/localization';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import DOMPurify from 'dompurify'
import { ShareContext } from '../../shared/context/share-state';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import AlertNotification from '../../shared/components/alert-notification';

const UploadGuide = (props) => {
    const { updateMenuTabKey } = useContext(ShareContext);
    const [tagViewer, setTagViewer] = useState('grid');
    const [collectionViewer, setCollectionViewer] = useState('grid');
    const [uploadViewer, setUploadViewer] = useState('grid');
    const [sticky, SetSticky] = useState(false);
    const [active, SetActive] = useState("select-file");
    const stickyRef = useRef(null);
    const intersectionContent = useRef(null);

    const scrollToElement = (element) => {
        if(element.target) {
            element.preventDefault();
        }

        const id = element.target ? element.target.id : element;
        const scrollElement = document.querySelector('[data-id=' + id + ']');
        scrollElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        SetActive(id);
    };

    useEffect(() => {
        const offset = 250;
        let docBody = document.documentElement || document.body.parentNode || document.body,
            hasOffset = window.pageYOffset !== undefined,
            sticky = offset,
            scrollTop;

        // Sticky Function
        const handleSticky = async () => {
            scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;

            if(intersectionContent.current) {
                const targetElements = intersectionContent.current.querySelectorAll('section');

                targetElements.forEach(element => {
                    if(scrollTop >= element.offsetTop) {
                        SetActive(element.dataset.id);
                    }
                });
            }
            if(stickyRef.current) {
                if (scrollTop >= sticky) {
                    SetSticky(true);
                } else {
                    SetSticky(false);
                }
            }
        };

        // Scrolling and sticky element
        window.addEventListener('scroll', handleSticky);

        return () => {
            window.removeEventListener('scroll', handleSticky);
        };
    }, []);

    return(
        <>
            <section>
                <Container>
                    <div className='upload-guide'>
                        <div className='row'>
                            <div className='col-8' ref={intersectionContent}>
                                <h2>{Localization.Upload.Guide.Title}</h2>
                                <p className='font-normal'>{Localization.Upload.Guide.Desc}</p>
                                <section data-id={Localization.Upload.Guide.Steps_1.Id}>
                                    <h3 className='upload-guide-header'>
                                        {Localization.Upload.Guide.Steps_1.Header}
                                    </h3>
                                    <p className='paragraph-1'>On the <span className="font-bold">Upload</span> page, <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_1.Desc)}}></span></p>
                                    <img src={Localization.Upload.Guide.Steps_1.Img.src} alt={Localization.Upload.Guide.Steps_1.Img.alt}/>
                                    <p className='paragraph-2 text-center mb-0 mt-3'>{Localization.Upload.Guide.Steps_1.Img.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_1.FileRequirementsId}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_1.FileRequirementsTitle}</p>
                                    <div className='paragraph-1'>
                                        <ul className='mb-4 px-4'>
                                            {
                                                Localization.Upload.Guide.Steps_1.FileRequirements.map((text) => <li key={text}>{text}</li>)
                                            }
                                        </ul>
                                    </div>
                                    <Table>
                                        <thead>
                                            <tr>
                                                {
                                                    Localization.Upload.Guide.Steps_1.FileRequirementsType.map((text) => <th key={text.name}><p className='paragraph-1 mb-0 font-medium'>{text.name}</p></th>)
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {
                                                    Localization.Upload.Guide.Steps_1.FileRequirementsType.map((text) => {
                                                        return(
                                                            <td key={text.name}>
                                                                <div className='paragraph-1 mb-0'>
                                                                    <ul>
                                                                        {
                                                                            text.type.map((type) => <li key={type}>{type}</li>)
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        );
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </Table>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_1.FileRequirementsId}>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_1.WorkspaceDesc}</p>
                                    <img src={Localization.Upload.Guide.Steps_1.WorkspaceImg.src} alt={Localization.Upload.Guide.Steps_1.WorkspaceImg.alt}/>
                                    <p className='paragraph-2 text-center mb-0 mt-3'>{Localization.Upload.Guide.Steps_1.WorkspaceImg.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_1.CommonErrorId}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_1.CommonError}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_1.CommonErrorDesc}</p>
                                    <Table className='mb-5'>
                                        <thead>
                                            <tr>
                                                {
                                                    Localization.Upload.Guide.Steps_1.CommonErrorTable.map((text) => <th key={text.name}><p className='paragraph-1 mb-0 font-medium'>{text.name}</p></th>)
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Localization.Upload.Guide.Steps_1.CommonErrorTable.map((errorRow, index) => (
                                                <tr key={index}>
                                                    <td><p className='paragraph-1 mb-0'>{errorRow.error[0]}</p></td>
                                                    <td><p className='paragraph-1 mb-0'>{errorRow.error[1]}</p></td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td><p className='paragraph-1 mb-0'>{Localization.Upload.Guide.Steps_1.CommonErrorTable[0].error[2]}</p></td>
                                                <td><p className='paragraph-1 mb-0'>{Localization.Upload.Guide.Steps_1.CommonErrorTable[1].error[2]}</p></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_1.UploadError)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_1.UploadErrorImg.src} alt={Localization.Upload.Guide.Steps_1.UploadErrorImg.alt}/>
                                    <p className='paragraph-2 text-center mb-0 mt-3'>{Localization.Upload.Guide.Steps_1.UploadErrorImg.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.Id}>
                                    <h3 className='upload-guide-header'>
                                        {Localization.Upload.Guide.Steps_2.Header}
                                    </h3>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.Desc}</p>
                                    <p className='paragraph-1'>Skip to <button className="text-button bolded-orange" onClick={() => scrollToElement(`${Localization.Upload.Guide.Steps_3.Id}`)}>Step 3</button> if you do not wish to make any changes to the files.</p>
                                    <AlertNotification className="mt-4" info={true} header={Localization.Upload.Guide.Steps_2.AlertHeader} htmlContent={Localization.Upload.Guide.Steps_2.AlertContent} />
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.SettingId}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.SettingHeader}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.SettingDesc1}</p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.SettingDesc2)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_2.SettingImg.src} alt={Localization.Upload.Guide.Steps_2.SettingImg.alt} />
                                    <p className='paragraph-2 text-center mb-0 mt-3'>{Localization.Upload.Guide.Steps_2.SettingImg.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.PreviewId}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.PreviewHeader}</p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.GridView.header}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.GridView.desc}</p>
                                    <img src={Localization.Upload.Guide.Steps_2.GridView.img.src} alt={Localization.Upload.Guide.Steps_2.GridView.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.GridView.img.desc}</p>
                                    <p className="paragraph-1" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.GridView.thumbnailDesc)}}></p>
                                    <div className='paragraph-1 mb-5'>
                                        <p className='mb-4'>{Localization.Upload.Guide.Steps_2.GridView.thumbnailList.header}</p>
                                        <ul className='mb-4 px-4'>
                                            {
                                                Localization.Upload.Guide.Steps_2.GridView.thumbnailList.list.map((list) => <li key={list}>{list}</li>)
                                            }
                                        </ul>
                                        <img src={Localization.Upload.Guide.Steps_2.GridView.thumbnailList.img.src} alt={Localization.Upload.Guide.Steps_2.GridView.thumbnailList.img.alt} />
                                        <p className='paragraph-2 text-center mb-0 mt-3'>{Localization.Upload.Guide.Steps_2.GridView.thumbnailList.img.desc}</p>
                                    </div>
                                    <p className="paragraph-1">{Localization.Upload.Guide.Steps_2.GridView.thumbnailList.select.desc}</p>
                                    <img src={Localization.Upload.Guide.Steps_2.GridView.thumbnailList.select.img.src} alt={Localization.Upload.Guide.Steps_2.GridView.thumbnailList.select.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.GridView.thumbnailList.select.img.desc}</p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.ListView.header}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.ListView.desc}</p>
                                    <img src={Localization.Upload.Guide.Steps_2.ListView.img.src} alt={Localization.Upload.Guide.Steps_2.ListView.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.ListView.img.desc}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.ListView.sortDesc1}</p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.ListView.sortDesc2)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_2.ListView.sortImg.src} alt={Localization.Upload.Guide.Steps_2.ListView.sortImg.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.ListView.sortImg.desc}</p>
                                    <AlertNotification info={true} header={Localization.Upload.Guide.Steps_2.ListView.alertHeader} htmlContent={Localization.Upload.Guide.Steps_2.ListView.alertContent} />
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.SelectAll.id}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.SelectAll.header}</p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.SelectAll.desc1)}}></p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.SelectAll.desc2)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_2.SelectAll.img.src} alt={Localization.Upload.Guide.Steps_2.ListView.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.SelectAll.img.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.Edit.id}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.Edit.header}</p>
                                    <p className='paragraph-1 paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.Edit.desc1)}}></p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Edit.filenameList.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Edit.filenameList.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Edit.descriptionList.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                        {
                                            Localization.Upload.Guide.Steps_2.Edit.descriptionList.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                        }
                                    </ul>
                                    <img src={Localization.Upload.Guide.Steps_2.Edit.img.src} alt={Localization.Upload.Guide.Steps_2.Edit.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.Edit.img.desc}</p>
                                </section>
                                {/* <section data-id={Localization.Upload.Guide.Steps_2.Tag.id}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.Tag.header}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.Tag.desc}</p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Tag.individual.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Tag.individual.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <AlertNotification info={true} header={Localization.Upload.Guide.Steps_2.Tag.alertHeader} htmlContent={Localization.Upload.Guide.Steps_2.Tag.alertContent} />
                                    <p className='paragraph-1 sub-paragraph mt-5'>{Localization.Upload.Guide.Steps_2.Tag.multiple.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Tag.multiple.list.map((list, index) => {
                                            return(
                                                <div key={list}>
                                                    <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>
                                                    {
                                                        index === Localization.Upload.Guide.Steps_2.Tag.multiple.list.length - 1 &&
                                                            <ul key={Localization.Upload.Guide.Steps_2.Tag.multiple.header}>
                                                                {
                                                                    Localization.Upload.Guide.Steps_2.Tag.multiple.nested.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                                                }
                                                            </ul>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                    </ul>
                                    <img src={tagViewer === 'grid' ? Localization.Upload.Guide.Steps_2.Tag.img.grid.src : Localization.Upload.Guide.Steps_2.Tag.img.list.src} alt={tagViewer === 'grid' ? Localization.Upload.Guide.Steps_2.Tag.img.grid.alt : Localization.Upload.Guide.Steps_2.Tag.img.list.alt} />
                                    <div className='upload-guide-img-viewer'>
                                        <button className={tagViewer === 'grid' ? 'text-button active' : 'text-button'} onClick={() => setTagViewer('grid')}>{Localization.Upload.Guide.Steps_2.Tag.img.grid.header}</button>
                                        <button className={tagViewer === 'list' ? 'text-button active' : 'text-button'} onClick={() => setTagViewer('list')}>{Localization.Upload.Guide.Steps_2.Tag.img.list.header}</button>
                                    </div>
                                    {
                                        tagViewer === 'grid'
                                        ?
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_2.Tag.img.grid.desc}</p>

                                        :
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_2.Tag.img.list.desc}</p>
                                    }
                                </section> */}
                                <section data-id={Localization.Upload.Guide.Steps_2.Collection.id}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.Collection.header}</p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.Collection.desc)}}></p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Collection.individual.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Collection.individual.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <img src={Localization.Upload.Guide.Steps_2.Collection.individual.img.src} alt={Localization.Upload.Guide.Steps_2.Collection.individual.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.Collection.individual.img.desc}</p>
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Collection.multiple.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Collection.multiple.list.map((list, index) => {
                                            return(
                                                <div key={list}>
                                                    <li dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>
                                                    {
                                                        index === Localization.Upload.Guide.Steps_2.Collection.multiple.list.length - 1 &&
                                                            <ul key={Localization.Upload.Guide.Steps_2.Collection.multiple.header}>
                                                                {
                                                                    Localization.Upload.Guide.Steps_2.Collection.multiple.nested.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                                                }
                                                            </ul>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    </ul>
                                    <img src={collectionViewer === 'grid' ? Localization.Upload.Guide.Steps_2.Collection.multiple.img.grid.src : Localization.Upload.Guide.Steps_2.Collection.multiple.img.list.src} alt={tagViewer === 'grid' ? Localization.Upload.Guide.Steps_2.Collection.multiple.img.grid.alt : Localization.Upload.Guide.Steps_2.Collection.multiple.img.list.alt} />
                                    <div className='upload-guide-img-viewer'>
                                        <button className={collectionViewer === 'grid' ? 'text-button active' : 'text-button'} onClick={() => setCollectionViewer('grid')}>{Localization.Upload.Guide.Steps_2.Collection.multiple.img.grid.header}</button>
                                        <button className={collectionViewer === 'list' ? 'text-button active' : 'text-button'} onClick={() => setCollectionViewer('list')}>{Localization.Upload.Guide.Steps_2.Collection.multiple.img.list.header}</button>
                                    </div>
                                    {
                                        collectionViewer === 'grid'
                                        ?
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_2.Collection.multiple.img.grid.desc}</p>
                                        :
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_2.Collection.multiple.img.list.desc}</p>
                                    }
                                    <p className='paragraph-1 sub-paragraph'>{Localization.Upload.Guide.Steps_2.Collection.create.header}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Collection.create.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <img src={Localization.Upload.Guide.Steps_2.Collection.create.img.src} alt={Localization.Upload.Guide.Steps_2.Collection.create.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_2.Collection.create.img.desc}</p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_2.Delete.id}>
                                    <p className='paragraph-1 paragraph-header'>{Localization.Upload.Guide.Steps_2.Delete.header}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_2.Delete.desc1}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_2.Delete.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_2.Delete.desc2)}}></p>
                                </section>
                                <section data-id={Localization.Upload.Guide.Steps_3.Id}>
                                    <h3 className='upload-guide-header'>
                                        {Localization.Upload.Guide.Steps_3.Header}
                                    </h3>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_3.Desc)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_3.Img.src} alt={Localization.Upload.Guide.Steps_3.Img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_3.Img.desc}</p>
                                    <p className='paragraph-1'>{Localization.Upload.Guide.Steps_3.Status.desc1}</p>
                                    <ul className='paragraph-1 mb-4 px-4'>
                                    {
                                        Localization.Upload.Guide.Steps_3.Status.list.map((list) => <li key={list} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(list)}}></li>)
                                    }
                                    </ul>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_3.Status.desc2)}}></p>
                                    <img src={uploadViewer === 'grid' ? Localization.Upload.Guide.Steps_3.Status.img.grid.src : Localization.Upload.Guide.Steps_3.Status.img.list.src} alt={uploadViewer === 'grid' ? Localization.Upload.Guide.Steps_3.Status.img.grid.alt : Localization.Upload.Guide.Steps_3.Status.img.list.alt} />
                                    <div className='upload-guide-img-viewer'>
                                        <button className={uploadViewer === 'grid' ? 'text-button active' : 'text-button'} onClick={() => setUploadViewer('grid')}>{Localization.Upload.Guide.Steps_3.Status.img.grid.header}</button>
                                        <button className={uploadViewer === 'list' ? 'text-button active' : 'text-button'} onClick={() => setUploadViewer('list')}>{Localization.Upload.Guide.Steps_3.Status.img.list.header}</button>
                                    </div>
                                    {
                                        uploadViewer === 'grid'
                                        ?
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_3.Status.img.grid.desc}</p>
                                        :
                                            <p className='paragraph-2 text-center mb-5'>{Localization.Upload.Guide.Steps_3.Status.img.list.desc}</p>
                                    }
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_3.Done.desc)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_3.Done.img.src} alt={Localization.Upload.Guide.Steps_3.Done.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_3.Done.img.desc}</p>
                                    <p className='paragraph-1' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(Localization.Upload.Guide.Steps_3.Error.desc)}}></p>
                                    <img src={Localization.Upload.Guide.Steps_3.Error.img.src} alt={Localization.Upload.Guide.Steps_3.Error.img.alt} />
                                    <p className='paragraph-2 text-center mb-5 mt-3'>{Localization.Upload.Guide.Steps_3.Error.img.desc}</p>
                                </section>
                            </div>
                            <div className='col-4' ref={stickyRef}>
                                <div className={sticky ? 'upload-guide-table-content sticky' : 'upload-guide-table-content'}>
                                    <p className='font-bold'>{Localization.Upload.Guide.TableContent.header}</p>
                                    <div className='upload-guide-table-button'>
                                        {
                                            Localization.Upload.Guide.TableContent.steps_1.map((item) => <button onClick={(el) => scrollToElement(el)} className={item.bold ? active === item.id ? "font-bold text-button active" : "font-bold text-button" : active === item.id ? "text-button active" : "text-button"} key={item.id} id={item.id}>{item.name}</button>)
                                        }
                                        <hr />
                                        {
                                            Localization.Upload.Guide.TableContent.steps_2.map((item) => <button onClick={(el) => scrollToElement(el)} className={item.bold ? active === item.id ? "font-bold text-button active" : "font-bold text-button" : active === item.id ? "text-button active" : "text-button"} key={item.id} id={item.id}>{item.name}</button>)
                                        }
                                        <hr />
                                        <button onClick={(el) => scrollToElement(el)} className={Localization.Upload.Guide.TableContent.steps_3.bold ? active === Localization.Upload.Guide.TableContent.steps_3.id ? "font-bold text-button active" : "font-bold text-button" : active === Localization.Upload.Guide.TableContent.steps_3.id ? "text-button active" : "text-button"} id={Localization.Upload.Guide.TableContent.steps_3.id}>{Localization.Upload.Guide.TableContent.steps_3.name}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default UploadGuide;