import { useState, useRef, useContext, useEffect } from "react";
import { Select } from 'antd';
import { AccountContext } from "../../components/shared/context/account-provider";
import { SessionContext } from "../../components/shared/context/session-provider";
import { useNavigate } from "react-router-dom";
import { IsSLG } from "../../components/shared/utils";
import Localization from "../../components/shared/localization";
import ArrowIcon from "../../components/icons/arrow";
import SpinnerIcon from "../../components/icons/spinner";
import Button from 'react-bootstrap/Button';
import path from "../../components/shared/routePaths";
import ErrorIcon from "../../components/icons/error";
import RegistrationService from '../../services/api/registrationService';
import CustomIcons from "../../components/shared/components/custom-icons";

const Registration = () => {
  const isSLG = IsSLG();
  // Account Context
  const { userGroups } = useContext(AccountContext);

  // Session Context
  const { tenantUuid,
          companyName,
          subdomainName } = useContext(SessionContext);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [fullName, setFullname] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState(false);
  const [emailAddressExistError, setEmailAddressExistError] = useState(false);
  const [usergroup, setUsergroup] = useState(undefined);
  const [userName, setUserName] = useState("");
  const [userNameLimit, setUserNameLimit] = useState(0);
  const [userNameLimitShow, setUserNameLimitShow] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showReenterPassword, setShowReenterPassword] = useState(true);
  const [reenterPasswordError, setReenterPasswordError] = useState(false);
  const [eightCharValid, setEightCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [userNameErrorEmail, setUserNameErrorEmail] = useState(false);
  const [userNameExistError, setUserNameExistError] = useState(false);
  const [userNameErrorSpecialChar, setUserNameErrorSpecialChar] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [registeredSuccess, setRegisteredSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [formValidation, setFormValidation] = useState({
    fullname: true,
    usergroup: true,
    email: true,
    emailexisting: true,
    username: true,
    usernameexisting: true,
    new_password: true,
    reenter_new_password: true
  });
  const registerForm = useRef(null);
  const fullnameRef = useRef(null);
  const emailRef = useRef(null);
  const usernameRef = useRef(null);
  const buttonRef = useRef(null);
  const newPasswordRef = useRef(null);
  const reenterPasswordRef = useRef(null);
  const navigate = useNavigate();
  const pathPrefix = "/";
  const usernameMaxLimit = 20;

  const errorMsg = {
    ErrorInvalid: Localization.Registration.ErrorInvalid,
    ErrorEmail: Localization.Registration.ErrorEmailFormat,
    ExistEmail_UserName: Localization.Registration.ExistEmail_UserName,
    ExistUserName: Localization.Registration.ExistUserName,
    ExistEmail: Localization.Registration.ExistEmail,
    ErrorUserName: "UsernameNotAllowException"
  };

  const handleRegister = async () => {
    setButtonDisabled(true);
    setIsRegistered(true);

    try {
      if(isValidEmail(emailAddress) && userName && fullName && newPassword === reenterPassword) {
        const bodyData = {
          email: emailAddress,
          username: userName,
          usergroup: usergroup,
          fullname: fullName,
          password: reenterPassword,
          uuid: tenantUuid
        };

        const registerResult = await RegistrationService.registrationUser(bodyData);

        if(registerResult.code === errorMsg.ErrorInvalid.name
            || registerResult.code === errorMsg.ExistUserName.name
            || registerResult.code === errorMsg.ErrorEmail.name
            || registerResult.code === errorMsg.ExistEmail.name
            || registerResult.code === errorMsg.ExistEmail_UserName.name
            || registerResult.code === errorMsg.ErrorUserName) {
          switch (registerResult.code) {
            case errorMsg.ErrorUserName:
              setErrorAlert(Localization.Registration.ErrorUserName.specialChar);
              break;
            case errorMsg.ErrorInvalid.name:
              setErrorAlert(errorMsg.ErrorInvalid.error);
              break;
            case errorMsg.ExistUserName.name:
              setUserNameExistError(true);
              setErrorAlert(errorMsg.ExistUserName.error);
              setFormValidation((prevValidation) => ({
                ...prevValidation,
                usernameexisting: false
              }));
              break;
            case errorMsg.ErrorEmail.name:
              setErrorAlert(errorMsg.ErrorEmail.error);
              setFormValidation((prevValidation) => ({
                ...prevValidation,
                email: false
              }));
              break;
            case errorMsg.ExistEmail.name:
              setEmailAddressError(true);
              setEmailAddressExistError(true);
              //setErrorAlert(errorMsg.ExistEmail.error);
              setFormValidation((prevValidation) => ({
                ...prevValidation,
                emailexisting: false
              }));
              break;
            case errorMsg.ExistEmail_UserName.name:
              setUserNameExistError(true);
              setEmailAddressError(true);
              setEmailAddressExistError(true);
              setErrorAlert(errorMsg.ExistEmail_UserName.error);
              setFormValidation((prevValidation) => ({
                ...prevValidation,
                usernameexisting: false,
                emailexisting: false
              }));
              break;
            // Add more cases for other error codes if needed
            default:
              setErrorAlert('An unknown error occurred.');
              break;
          }

          setIsRegistered(false);

          if(registerForm.current) {
            const offset = 80;
            const formPosition = registerForm.current.getBoundingClientRect().top;
            const offsetPosition = formPosition + window.pageYOffset + offset;

            window.scrollTo({
              top: offsetPosition,
              left: 0,
              behavior: 'smooth',
            });
          }
        } else {
          if(registerResult.code === 200) {
            setIsRegistered(false);
            localStorage.setItem('verifyUserUuid', registerResult.response.uuid);
            localStorage.setItem('verifyEmailAddress', registerResult.response.email);
            navigate(pathPrefix + path.USERVERIFYEMAIL);
          }
        }
      } else {
        setIsRegistered(false);

        if(registerForm.current) {
          const offset = 80;
          const formPosition = registerForm.current.getBoundingClientRect().top;
          const offsetPosition = formPosition + window.pageYOffset + offset;

          window.scrollTo({
            top: offsetPosition,
            left: 0,
            behavior: 'smooth',
          });
        }

        if(!isValidEmail(emailAddress)) {
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            email: false
          }));
          setErrorAlert(errorMsg.ErrorEmail);
        }

        if(newPassword !== reenterPassword) {
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            reenter_new_password: false
          }));
          setReenterPasswordError(true);
          setErrorAlert(Localization.Registration.ErrorReenterPassword);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email address format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const buttonCheck = () => {
    if (fullName &&
        emailAddress &&
        userName &&
        newPassword &&
        reenterPassword &&
        eightCharValid &&
        lowercaseValid &&
        uppercaseValid &&
        formValidation.fullname &&
        formValidation.email &&
        formValidation.usergroup &&
        formValidation.username &&
        formValidation.new_password &&
        formValidation.reenter_new_password) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleInputChange = async (event, input) => {
    setUserNameLimitShow(false);
    switch (input) {
      case 'fullname':
        setFullname(event.target.value !== "" ? event.target.value : undefined);
        break;
      case 'email':
        const email = event.target.value;
        setEmailAddressError(false);
        
        setEmailAddress(email !== "" ? email : undefined);
        if(!isValidEmail(email) && email.length > 0) {
          setEmailAddressError(true);
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            email: false,
            emailexisting: true
          }));
          setButtonDisabled(true);
        } else {
          setEmailAddressError(false);
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            email: true,
            emailexisting: true
          }));
        }
        break;
      case 'usergroup':
        setUsergroup(event);
        break;
      case 'username':
        setUserNameLimitShow(true);
        setUserNameLimit(event.target.value.length);
        setUserNameExistError(false);
        setUserName(event.target.value !== "" ? event.target.value : undefined);
        const specialCharRegex = /[<>'":;[\]{}|\\+=/?@, *()#$%^&!~`-]/;
        const containsSpecialChar = specialCharRegex.test(event.target.value);

        if(containsSpecialChar) {
          setUserNameErrorSpecialChar(true);
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            username: false,
            usernameexisting: true
          }));
        } else {
          setUserNameErrorSpecialChar(false);
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            username: true,
            usernameexisting: true
          }));
        }
        break;
      case 'newpassword':
        setNewPassword(event.target.value);
        setReenterPasswordError(false);
        setEightCharValid(event.target.value.length > 7);
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        setLowercaseValid(lowercaseRegex.test(event.target.value));
        setUppercaseValid(uppercaseRegex.test(event.target.value));
        setFormValidation((prevValidation) => ({
          ...prevValidation,
          reenter_new_password: true
        }));

        if(newPasswordRef.current.value !== reenterPasswordRef.current.value) {
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            reenter_new_password: false
          }));
          setReenterPasswordError(true);
        }
        break;
      case 'reeneterpassword':
        setReenterPassword(event.target.value);
        setReenterPasswordError(false);
        setFormValidation((prevValidation) => ({
          ...prevValidation,
          reenter_new_password: true
        }));
        
        const reEnterPassWord = reenterPasswordRef.current.value;
        if(newPasswordRef.current.value !== reEnterPassWord && reEnterPassWord.length > 0) {
          setFormValidation((prevValidation) => ({
            ...prevValidation,
            reenter_new_password: false
          }));
          setReenterPasswordError(true);
        }
        break;
    }
  };

  const handleKeyPress = (event, input) => {
    switch (input) {
      case 'fullname':
        setFullname(event.target.value !== "" ? event.target.value : undefined);
        if (event.keyCode === 13) {
          emailRef.current.focus();
        }
        break;
      case 'email':
        setEmailAddress(event.target.value !== "" ? event.target.value : undefined);
        if (event.keyCode === 13) {
          usernameRef.current.focus();
        }
        break;
      case 'usergroup':
        setUsergroup(event);
        break;
      case 'username':
        setUserName(event.target.value !== "" ? event.target.value : undefined);
        if (event.keyCode === 13) {
          newPasswordRef.current.focus();
        }
        break;
      case 'newpassword':
        if (event.keyCode === 13) {
          reenterPasswordRef.current.focus();
        }
        break;
      case 'reeneterpassword':
        if (event.keyCode === 13) {
          buttonRef.current.click();
        }
        break;
    }
  };

  useEffect(() => {
    buttonCheck();
  }, [fullName, usergroup, emailAddress, userName, newPassword, reenterPassword]);

  return (
    <>
      <div className="registration-wrapper">
        <div className={isSLG ? "registration-left-cont col-12" : "registration-left-cont col-5"} ref={registerForm}>
          <div className="registration-form">
            <a href={"/" + path.LOGIN} className="registration-header d-flex flex-row">
              <img
                src="/images/elko-logo.svg"
                className="dam-logo-img"
                alt="Dam Logo"
              />
            </a>
            {
              registeredSuccess
              ?
                <>
                  <h1>{Localization.Registration.SuccessTitle}</h1>
                  <p className="paragraph-1 font-bold">
                    {Localization.Registration.SuccessDesc}
                  </p>
                  <p className="paragraph-1 font-bold">
                    For any enquiries, please contact the <span className='font-bold purple-blue--color'>System Administrator</span>
                  </p>
                  <div className="button-group">
                    <Button variant="primary"  href={pathPrefix + path.LOGIN}>
                      Done
                    </Button>
                  </div>
                </>
              :
              <>
                <h1>{Localization.Registration.Title}</h1>
                {
                  (companyName && subdomainName)
                  ?
                  <>
                    <h3 className="font-inter PaddingBottom48">an account under <strong>{companyName}</strong></h3>
                  </>
                  :
                  <div className="PaddingBottom48"></div>
                }
                <div className="form-container">
                  <div className={formValidation.fullname ? "form-group" : "form-group form-error"}>
                    <label htmlFor="fullname">Full name</label>
                    <input
                      id="fullname"
                      className="form-control"
                      type="text"
                      placeholder="Enter full name"
                      onChange={(event) => handleInputChange(event, 'fullname')}
                      onFocus={(event) => handleInputChange(event, 'fullname')}
                      value={fullName}
                      ref={fullnameRef}
                      onKeyDown={(event) => handleKeyPress(event, 'fullname')}
                      disabled={isRegistered}
                    ></input>
                  </div>
                </div>
                {
                  userGroups && userGroups.length > 0 &&
                    <div className="form-container">
                      <div className={formValidation.usergroup ? "form-group" : "form-group form-error"}>
                        <label htmlFor="usergroup">User group</label>
                        <Select
                          showSearch
                          className="ant-select-customize"
                          id="usergroup"
                          suffixIcon={<ArrowIcon direction="expand" />}
                          placeholder="Select a user group"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={userGroups}
                          aria-label="sort"
                          onChange={(value) => handleInputChange(value, 'usergroup')}
                          disabled={isRegistered}
                        />
                      </div>
                    </div>
                }

                <div className="form-container">
                  <div className={formValidation.email && formValidation.emailexisting ? "form-group" : "form-group form-error"}>
                    <label htmlFor="email">Email address</label>
                    <input
                      id="email"
                      className="form-control"
                      type="text"
                      placeholder="Enter email address"
                      onChange={(event) => handleInputChange(event, 'email')}
                      onFocus={(event) => handleInputChange(event, 'email')}
                      value={emailAddress}
                      disabled={isRegistered}
                      onKeyDown={(event) => handleKeyPress(event, 'email')}
                      ref={emailRef}
                    ></input>
                    {
                      !formValidation.email &&
                        <div className="error-msg">
                          {
                            emailAddressError &&
                              <>
                                <ErrorIcon />{Localization.Registration.ErrorEmail.error}
                              </>
                          }
                        </div>
                    }
                    {
                      !formValidation.emailexisting &&
                        <div className="error-msg">
                          {
                            emailAddressError &&
                              <>
                                <ErrorIcon />{Localization.Registration.ExistEmail.error}
                              </>
                          }
                        </div>
                    }
                  </div>
                </div>
                <div className="form-container">
                  <div className={formValidation.username && formValidation.usernameexisting ? "form-group" : "form-group form-error"}>
                    <label htmlFor="username">Username</label>
                    <input
                      id="username"
                      className="form-control"
                      type="text"
                      placeholder="Enter username"
                      onChange={(event) => handleInputChange(event, 'username')}
                      onFocus={(event) => handleInputChange(event, 'username')}
                      value={userName}
                      onKeyDown={(event) => handleKeyPress(event, 'username')}
                      disabled={isRegistered}
                      ref={usernameRef}
                      maxLength={usernameMaxLimit}
                    ></input>
                   <div className="d-flex justify-content-between align-items-center">
                    {
                      (!formValidation.username || !formValidation.usernameexisting) && (
                        <div className="error-msg">
                          {
                            userNameErrorEmail
                            ?
                              <>
                                <ErrorIcon/>{Localization.Registration.ErrorUserName.email}
                              </>
                            :
                              userNameErrorSpecialChar
                              ?
                                <>
                                  <ErrorIcon/>{Localization.Registration.ErrorUserName.specialChar}
                                </>
                              :
                              userNameExistError
                              ?
                                <>
                                  <ErrorIcon/>{Localization.Registration.ExistUserName.error}
                                </>
                              : null
                          }
                        </div>
                      )
                    }
                    {
                      userNameLimitShow &&
                        <div className={`username-char-limit ${userNameLimit === usernameMaxLimit ? userNameErrorSpecialChar ? 'special-char-limit': 'max-limit' : userNameErrorSpecialChar ? '' :'username-limit'}`}>
                          {
                            (userNameLimit === usernameMaxLimit || userNameErrorSpecialChar) && !userNameErrorSpecialChar &&
                              <span>{Localization.Registration.ErrorUsernameLimit}</span>
                          }
                          <span>{`${userNameLimit}/${usernameMaxLimit}`}</span>
                        </div>
                    }
                    </div>
                  </div>
                </div>
                <div className="form-container">
                  <div className={formValidation.new_password ? "form-group" : "form-group form-error"}>
                    <label htmlFor="newpassword">New password</label>
                    <input
                        id="newpassword"
                        className="form-control"
                        type={!showNewPassword ? 'text' : 'password'}
                        placeholder="Enter new password"
                        onChange={(event) => handleInputChange(event, 'newpassword')}
                        onFocus={(event) => handleInputChange(event, 'newpassword')}
                        onKeyDown={(event) => handleKeyPress(event, 'newpassword')}
                        disabled={isRegistered}
                        ref={newPasswordRef}
                        value={newPassword}
                    ></input>
                    <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="show-password-icon"
                    >
                        {showNewPassword ? <CustomIcons variant="visibility" visible /> : <CustomIcons variant="visibility" />}
                    </button>
                  </div>
                  <div className="form-group password-container">
                    <div className="row">
                      <p className={`col-12 ${eightCharValid ? 'valid-password' : ''}`}>{ eightCharValid ? <CustomIcons color="#30BA34" variant="tick"/> : <CustomIcons color="#989595" variant="tick"/> } At least 8 characters</p>
                      <p className={`col-12 ${lowercaseValid ? 'valid-password' : ''}`}>{ lowercaseValid ? <CustomIcons color="#30BA34" variant="tick"/> : <CustomIcons color="#989595" variant="tick"/> } Include at least one lowercase</p>
                      <p className={`col-12 ${uppercaseValid ? 'valid-password' : ''}`}>{ uppercaseValid ? <CustomIcons color="#30BA34" variant="tick"/> : <CustomIcons color="#989595" variant="tick"/> } Include at least one uppercase</p>
                    </div>
                  </div>
                </div>
                <div className="form-container">
                  <div className={formValidation.reenter_new_password ? "form-group" : "form-group form-error"}>
                      <label htmlFor="reenterpassword">Re-enter new password</label>
                      <input
                          id="reeneterpassword"
                          className="form-control"
                          type={!showReenterPassword ? 'text' : 'password'}
                          placeholder="Re-enter new password"
                          onChange={(event) => handleInputChange(event, 'reeneterpassword')}
                          onFocus={(event) => handleInputChange(event, 'reeneterpassword')}
                          onKeyDown={(event) => handleKeyPress(event, 'reeneterpassword')}
                          disabled={isRegistered}
                          ref={reenterPasswordRef}
                          value={reenterPassword}
                      ></input>
                      <button
                          type="button"
                          onClick={() => setShowReenterPassword(!showReenterPassword)}
                          className="show-password-icon reenter-btn"
                      >
                          {showReenterPassword ? <CustomIcons variant="visibility" visible /> : <CustomIcons variant="visibility" />}
                      </button>
                      {
                        !formValidation.reenter_new_password &&
                          <div className="error-msg">
                            {
                              reenterPasswordError &&
                                <>
                                  <ErrorIcon />{Localization.Registration.ErrorReenterPassword}
                                </>
                            }
                          </div>
                      }
                    </div>
                </div>
                <div className="form-privacy-policy">
                  <p>By registering an account, I agree to the <a href="https://hello.elko.digital/terms-and-conditions" target="_blank">Terms and Conditions of Use</a>, and have read and understood elko's <a href="https://hello.elko.digital/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                </div>
                <div className="button-group">
                  <Button variant="primary" ref={buttonRef}
                    onClick={handleRegister}
                    disabled={buttonDisabled}>
                    {
                      isRegistered &&
                        <SpinnerIcon />
                    }
                    Register
                  </Button>
                </div>
              </>
            }
          </div>
        </div>
        {
          !isSLG &&
            <div className="registration-right-cont col-7">
              <svg width="1094" height="661" viewBox="0 0 1094 661" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-33.9844" y="-261.523" width="1407.35" height="714.133" transform="rotate(8.86 -33.9844 -261.523)" fill="white" />
              </svg>
              <img src="/images/Ring.svg" className="ring-icon" alt="ringicon" />
            </div>
        }
      </div>
    </>
  );
};

export default Registration;
