import { AccountContext } from "../../components/shared/context/account-provider";
import CustomIcons from "../../components/shared/components/custom-icons";
import Localization from '../../components/shared/localization';
import { useState, useContext, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner';

const MFAVerificationComponent = (props) => {
    const [isBackUpCode, setIsBackUpCode] = useState(false);
    const [inputMFACode, setInputMFACode] = useState('');
    const [inputBackUpCode, setInputBackUpCode] = useState('');

    const [mfaCount, setMfaCount] = useState(0);
    const [backUpCount, setBackUpCount] = useState(0);
    const inputVerificationCodeRef = useRef(null);
    const inputBackUpCodeRef = useRef(null);

    // Account Context
    const { sendMFACode,
            setMFAVerification,
            isMfaCodeVerificationMatch,
            isBackupCodeMatch,
            setIsBackupCodeMatch,
            isBackUpVerifying,
            setIsBackUpVerifying,
            customAuthenticate,
            isMfaVerifying,
            setIsMfaVerifying,
            cognitoUser } = useContext(AccountContext);

    const mfaCounter = (event) => {
        const newValue = event.target.value;
        setInputMFACode(newValue);
        if (newValue.length <= 6) {
            setMfaCount(newValue.length);
        } else {
            setMfaCount(6);
            setInputMFACode(newValue.slice(0,6));
        }
    }
    const backUpCounter = (event) => {
        const newValue = event.target.value;
        setInputBackUpCode(newValue);
        if (newValue.length <=8) {
            setBackUpCount(newValue.length);
        } else {
            setBackUpCount(8);
            setInputBackUpCode(newValue.slice(0,8));
        }
    }

    const handleVerificationCode = async () => {
        setIsMfaVerifying(true);
        const inputVerificationCode = inputVerificationCodeRef.current.value;
        const validatedSentCode = await sendMFACode(cognitoUser, inputVerificationCode);

        if(validatedSentCode.success === true) {
            setIsMfaVerifying(false);
            setMFAVerification(false);
        } else {
            setIsMfaVerifying(false);
        }
    };
    
    const handleKeyDown = (event) => {
        const input = event.target;
        const isNavKey = event.key === 'ArrowLeft' || event.key === 'ArrowRight';
        const isTextSelected = input.selectionStart === 0 && input.selectionEnd === input.value.length;

        if (mfaCount >= 6 && event.key !== 'Backspace' && !isTextSelected && !isNavKey) {
            event.preventDefault();

            // Allow left and right arrow keys and backspace
            if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                event.preventDefault();
            }
        }

        if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
            event.preventDefault();
            event.target.select();
        }

        if (event.key === 'Enter') {
            handleVerificationCode();  
        }
    };

    const handleKeyDownForBackUpCode = (event) => {
        const input = event.target;
        const isNavKey = event.key === 'ArrowLeft' || event.key === 'ArrowRight';
        const isTextSelected = input.selectionStart === 0 && input.selectionEnd === input.value.length;

        if (backUpCount >= 8 && event.key !== 'Backspace' && !isTextSelected && !isNavKey) {
            event.preventDefault();

            // Allow left and right arrow keys and backspace
            if (event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                event.preventDefault();
            }
        }

        if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
            event.preventDefault();
            event.target.select();
        } 

        if (event.key === 'Enter') {
            handleVerifyBackupCode();  
        }
    };

    const handleVerifyBackupCode = async () => {
        try {
            setIsBackUpVerifying(true);
            const backupCode = inputBackUpCodeRef.current.value;
            await customAuthenticate(cognitoUser, backupCode);
        } catch (error) {
            throw(error.message);
        }
    }

    return (
        <>
        {(
            isBackUpCode ?
                <>
                    <h1>{Localization.Login.Guide2fa.Verification2fa.backup_code.Title}</h1>
                    <p>{Localization.Login.Guide2fa.Verification2fa.backup_code.Desc1}</p>
                    <div className="input form-group">
                        <label htmlFor="verificationMFACode" className={`label ${isBackupCodeMatch ? '':'error'}`}>Backup code</label>
                        <input
                            id="verificationMFACode"
                            className={`form-control ${isBackupCodeMatch ? '':'error'}`}
                            type="text"
                            placeholder="Enter backup code"
                            onChange={backUpCounter}
                            ref={inputBackUpCodeRef}
                            onKeyDown={handleKeyDownForBackUpCode}
                            value={inputBackUpCode}
                            //onChange={handleVerificationMFACode}
                            //value={verifyBackUpCode}
                        ></input>
                        <div>
                            <p className={`error-label-backup ${isBackupCodeMatch ? 'hidden':''}`}>
                                <CustomIcons variant="error" className="icon-error"/>
                                <span>Invalid backup code</span>
                            </p>
                        </div>
                    </div>
                    <div className="mfa-btn button-group">
                        <button
                            type="submit"
                            className={`btn btn-primary`}
                            disabled={backUpCount === 0 || isBackUpVerifying}
                            onClick={handleVerifyBackupCode}
                        >
                            {Localization.Login.Guide2fa.Verification2fa.backup_code.Button1} {isBackUpVerifying && <Spinner variant='gray' />}
                        </button>
                    </div>
                    <p>Lost your code? Contact your administrator(s) for assistance</p>
                </>
            :
                <>
                    <h1>{Localization.Login.Guide2fa.Verification2fa.verify_code.Title}</h1>
                    <p>{Localization.Login.Guide2fa.Verification2fa.verify_code.Desc1}</p>
                    <div className="input form-group">
                        <label htmlFor="verificationMFACode" className={`label ${isMfaCodeVerificationMatch ? '':'error'}`}>Verification code</label>
                        <input
                            id="verificationMFACode"
                            className={`form-control ${isMfaCodeVerificationMatch ? '':'error'}`}
                            type="text"
                            placeholder="Enter verification code"
                            onChange={mfaCounter}
                            onKeyDown={handleKeyDown}
                            ref={inputVerificationCodeRef}
                            value={inputMFACode}
                        ></input>
                        <div>
                            <p className={`error-label ${isMfaCodeVerificationMatch ? 'hidden':''}`}>
                                <CustomIcons variant="error" className="icon-error"/>
                                <span>Invalid verification code</span>
                            </p>
                        </div>
                    </div>
                    <div className="mfa-btn button-group">
                        <button
                            type="submit"
                            className={`btn btn-primary`}
                            disabled={mfaCount === 0 || isMfaVerifying}
                            onClick={handleVerificationCode}
                        >
                            {Localization.Login.Guide2fa.Verification2fa.verify_code.Button1} {isMfaVerifying && <Spinner variant='gray' />}
                        </button>
                    </div>
                    <p>Issues verifying? <span onClick={() => {setIsBackUpCode(true); setIsBackupCodeMatch(true);}}>Enter a backup code</span> or contact your administrator(s) for assistance</p>
                </>
        )}
        </>
    );
}

export default MFAVerificationComponent;