import { useState, useContext, useRef, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AccountContext } from "../../components/shared/context/account-provider";
import { SessionContext } from "../../components/shared/context/session-provider";
import { Nav } from 'react-bootstrap';
import { IsSLG } from "../../components/shared/utils";
import Localization from '../../components/shared/localization';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DownloadRoundedIcon from '@mui/icons-material/Download';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import VisibilityIcon from "../../components/icons/visibility";
import ImageIcon from '../../components/icons/image';
import MFASetupComponent from "./mfaSetup";
import MFAVerificationComponent  from "./mfaVerification";

const Login = (props) => {
  const isSLG = IsSLG();
  const [changePassword, setchangePassword] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonVerifyDisabled, setButtonVerifyDisabled] = useState(true);
  const [authenticated, setAuthenticated] = useState(null);
  const [showPassword, setShowPassword] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [eightCharValid, setEightCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [showNewPasswordText, setShowNewPasswordText] = useState(false);
  const [showReenterPassword, setShowReenterPassword] = useState(false);
  const [reenternMessage, setReenterMessage] = useState("");
  const [newpasswordInputDisabled, setNewpasswordInputDisabled] = useState(true);
  const [newPasswordInputText, setNewPasswordInputText] = useState('');
  const enterpasswordInputRef = useRef(null);
  const loginButtonRef = useRef(null);
  const processChangePasswordRef = useRef(null);
  const reenterPasswordRef = useRef(null);

  // Account Context
  const { MFAVerification,
          MFASetup,
          authenticate,
          confirmnewpassword,
          logout } = useContext(AccountContext);

  // Session Context
  const { companyName,
          subdomainName,
          HandlerBackToTop } = useContext(SessionContext);

  const handleButtonClick = async () => {
    setButtonDisabled(true);
    try {
      const username = document.getElementById("username").value.trim();
      const password = document.getElementById("password").value.trim();
      const data = await authenticate(username, password);

      setShowNewPassword(false);
      if (data) {
        if (
          localStorage.getItem("newPasswordRequired")
        ) {
          setUserName(username);
          setUserPassword(password);
          setShowNewPassword(true);
          setEightCharValid(false);
          setLowercaseValid(false);
          setUppercaseValid(false);
        } else if (data) {
          setchangePassword(false);
          setAuthenticated(true);
          // Redirect to elko
          props.updateAuthenticatedState(true);
        }
      }
    } catch (err) {
      if (err === 'NotAuthorizedException: Password attempts exceeded') {
        setLoginMessage(Localization.Login.LoginResponse.PasswordAttemptsExceeded);
      } else {
        setLoginMessage(Localization.Login.LoginResponse.NotAuthorizedException);
      }
      HandlerBackToTop();
      setButtonDisabled(false);
      setAuthenticated(false);
      props.updateAuthenticatedState(false);
    }
  };

  const handleVerifyButtonClick = async () => {
    try {
      const newpassword = document.getElementById("newpassword").value.trim();

      const data = await confirmnewpassword(userName, userPassword, newpassword);
      if (data) {
        setShowNewPassword(false);
        logout();
        setUserPassword("");
        localStorage.removeItem('accessToken')
        localStorage.removeItem('idToken')
        window.location.reload();
      }

    } catch (err) {
      console.log('error', err)
    }
  };

  const handleInputChange = (event) => {
    const username = document.getElementById("username").value.trim();
    const password = document.getElementById("password").value.trim();
    //setUserNameInputText(event.target.value);
    setUserName(event.target.value);

    if (username && password) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handlePassInputChange = (event) => {
    const username = document.getElementById("username").value.trim();
    const password = document.getElementById("password").value.trim();
    setUserPassword(event.target.value);

    if (username && password) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };
  const handleCopy = (event) => {
    event.preventDefault();
  };
  const handleForgotPasswordClick = () => {
    localStorage.setItem("forgotPassword", true);
  };
  const handleRegistrationClick = () => {
    localStorage.setItem("registration", true);
  };
  const handleUsernameKeyPress = (event) => {
    if (event.keyCode === 13) {
      enterpasswordInputRef.current.focus();
    }
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      loginButtonRef.current.click();
    }
  };

  const handlNewPasswordChange = (event) => {
    setNewPasswordInputText(event.target.value);
    const inputValue = event.target.value;
    setEightCharValid(inputValue.length > 7);
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    setLowercaseValid(lowercaseRegex.test(inputValue));
    setUppercaseValid(uppercaseRegex.test(inputValue));

    setReenterMessage('');
    setButtonDisabled(true);
    const reenterpasswordInput = document.getElementById("reenternewpassword");
    reenterpasswordInput.value = "";
  };

  const handleNewPasswordKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      reenterPasswordRef.current.focus();
    }
  };

  const handlReenterPasswordChange = (event) => {
    const newpasswordInput = document.getElementById("newpassword");
    const inputValue = event.target.value;
    if (newpasswordInput.value === inputValue) {
      setButtonVerifyDisabled(false);
      setReenterMessage('');
    } else {
      setButtonVerifyDisabled(true);
      setReenterMessage('Your passwords do not match');
      HandlerBackToTop();
    }
  };

  const handleProcessChangePasswordKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      processChangePasswordRef.current.click();
    }
  };

  useEffect(() => {
    if (eightCharValid && lowercaseValid && uppercaseValid) {
      setNewpasswordInputDisabled(false);
    } else {
      setNewpasswordInputDisabled(true);
      setReenterMessage('');
    }
  }, [eightCharValid, lowercaseValid, uppercaseValid]);

  if (changePassword) {
    return <Navigate replace to="/change-password" />;
  } else {
    return (
      <div className="login-wrapper">
        <div className={isSLG ? "login-left-cont col-12" : "login-left-cont col-5"}>
          <div className="login-form">
            <a href="/" className="login-header d-flex flex-row">
              <img src="/images/elko-logo.svg" className="dam-logo-img" alt="Dam Logo" />
            </a>
            {
              MFASetup
              ?
                <>
                  <div className="mfa-setup">
                    <MFASetupComponent />
                  </div>
                </>
              :
                <>
                  {
                    MFAVerification
                    ?
                      <>
                        <div className="mfa-verification">
                          <MFAVerificationComponent username={userName} password={userPassword}/>
                        </div>
                      </>
                    :
                      <>
                        <div className="message-container">
                          {authenticated != null && !authenticated ? (
                              <div className="message-group">
                                <div><WarningRoundedIcon className="warning-icon" /></div>
                                <div>{loginMessage}</div>
                              </div>
                          ) : null}
                        </div>
                          {showNewPassword ? (
                            <>
                            <h1>Reset Password</h1>
                            <div className="form-container">
                              <div className="form-group">
                                <label htmlFor="newpassword">New password</label>
                                <input
                                  id="newpassword"
                                  className="form-control"
                                  type={showNewPasswordText ? 'text' : 'password'}
                                  placeholder="Enter new password"
                                  onChange={handlNewPasswordChange}
                                  onCopy={handleCopy}
                                  onKeyDown={handleNewPasswordKeyPress}
                                  value={newPasswordInputText}
                                ></input>
                                <button
                                  type="button"
                                  onClick={() => setShowNewPasswordText(!showNewPasswordText)}
                                  className="show-password-icon"
                                >
                                  {showNewPasswordText ? <VisibilityIcon visible={true} /> : <VisibilityIcon visible={false} />}
                                </button>
                              </div>
                              <div className="form-group">
                                  <p className={`eightchar ${eightCharValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon />&nbsp; At least 8 characters</p>
                                  <p className={`lowercase ${lowercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon />&nbsp; Include at least one lowercase</p>
                                  <p className={`uppercase ${uppercaseValid ? 'newpassvalid' : ''}`}><CheckCircleRoundedIcon />&nbsp; Include at least one uppercase</p>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="reenternewpassword" className={`${reenternMessage ? 'warning-label' : ''}`}>Re-enter new password</label>
                                  <input
                                      id="reenternewpassword"
                                      className={`form-control ${reenternMessage ? 'warning-input' : ''}`}
                                      type={showReenterPassword ? 'text' : 'password'}
                                      placeholder="Re-enter new password"
                                      onChange={handlReenterPasswordChange}
                                      onCopy={handleCopy}
                                      onKeyDown={handleProcessChangePasswordKeyPress}
                                      disabled={newpasswordInputDisabled}
                                      ref={reenterPasswordRef}
                                  ></input>
                                  <button
                                      type="button"
                                      onClick={() => setShowReenterPassword(!showReenterPassword)}
                                      className="show-password-icon"
                                  >
                                      {showReenterPassword ? <VisibilityIcon visible={true} /> : <VisibilityIcon visible={false} />}
                                  </button>
                              </div>
                              {reenternMessage ? (
                              <div className="form-group reenterpassword-group">
                                  <p className="reenterpassword-warning"><WarningRoundedIcon /> {reenternMessage}</p>
                              </div>
                            ) : null}
                              <div className="button-group verify-button-group">
                                <button className="btn btn-primary"
                                  onClick={handleVerifyButtonClick}
                                  disabled={buttonVerifyDisabled}
                                  ref={processChangePasswordRef}
                                  >Reset</button>
                              </div>
                            </div>
                          </>
                          ) : (
                            <>
                            <h1>Welcome</h1>
                            {
                              (companyName && subdomainName)
                              ?
                                <>
                                  <h3 className="font-inter PaddingBottom48">Log In to <strong>{companyName}</strong></h3>
                                </>
                              :
                                <div className="PaddingBottom48"></div>
                            }
                            <div className="form-container">
                              <div className="form-group">
                                <label htmlFor="username" style={{ color: 'black' }}>Username</label>
                                <input
                                  id="username"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter username"
                                  onChange={handleInputChange}
                                  onKeyDown={handleUsernameKeyPress}
                                  value={userName}
                                ></input>
                              </div>
                            </div>
                            <Nav className="forgot-password-cont justify-content-end">
                              <Nav.Link href="/forgot-password" onClick={handleForgotPasswordClick}>Forgot Password?</Nav.Link>
                            </Nav>
                            <div className="form-container">
                              <div className="form-group">
                                <label htmlFor="password" style={{ color: 'black' }}>Password</label>
                                <input
                                  id="password"
                                  className="form-control"
                                  type={!showPassword ? 'text' : 'password'}
                                  placeholder="Enter password"
                                  onChange={handlePassInputChange}
                                  onCopy={handleCopy}
                                  onKeyDown={handleKeyPress}
                                  ref={enterpasswordInputRef}
                                  value={userPassword}
                                ></input>
                                <button
                                  type="button"
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="show-password-icon"
                                >
                                  {showPassword ? <VisibilityIcon visible={true} /> : <VisibilityIcon visible={false} />}
                                </button>

                              </div>
                            </div>
                            <div className="button-group">
                              <button className={buttonDisabled ? "btn btn-primary disabled" : "btn btn-primary"}
                                onClick={handleButtonClick}
                                disabled={buttonDisabled}
                                ref={loginButtonRef}>Log In</button>
                            </div>
                            <div className="register-now">
                              New user? <a href="/registration" onClick={handleRegistrationClick}>Register now</a>
                            </div>
                            </>
                          )}
                      </>
                  }

                </>
            }

          </div>
        </div>

        {
          !isSLG &&
          <div className="login-right-cont col-7">
          {
            MFASetup || MFAVerification ?
              <>
                <div className="2fa visual-wrapper">
                      <div className="2fa image-container">
                        <img src="./images/two_factor_authentication.svg" alt="Girl in a jacket" />
                        <div className="content-desc1">
                          <h2>2FA enhances security of your account and reduces risk of unauthorised access</h2>
                        </div>
                      </div>
                </div>
              </>
            :
              <>
                <div className="content-desc1">
                  <span>Manage<br/>your digital assets<br/>with</span> <span className="font-bold">ease & convenience.</span>
                </div>
                <div className="login-image">
                  <div className="card-header">
                    <ImageIcon color="white" />
                  </div>
                  <div className="card-body col-12">
                    <img src="./images/login-image.png" alt="" />
                  </div>
                  <div className="card-footer col-12">
                    <DownloadRoundedIcon/>
                  </div>
                </div>
                <div className="calendar-display">
                  <div className="calendar">
                    <div className="calendar-box">
                      <p className="paragraph"><KeyboardDoubleArrowLeftIcon /><KeyboardArrowLeftIcon/> Mar 2023</p>
                      <div className="week">
                        <div className="day">Sun</div>
                        <div className="day">Mon</div>
                        <div className="day">Tue</div>
                        <div className="day">Wed</div>
                        <div className="day">Thu</div>
                        <div className="day">Fri</div>
                        <div className="day">Sat</div>
                      </div>
                      <div className="week">
                        <div className="day gray-color">26</div>
                        <div className="day gray-color">27</div>
                        <div className="day gray-color">28</div>
                        <div className="day">01</div>
                        <div className="day">02</div>
                        <div className="day">03</div>
                        <div className="day">04</div>
                      </div>
                      <div className="week">
                        <div className="day">05</div>
                        <div className="day">06</div>
                        <div className="day">07</div>
                        <div className="day">08</div>
                        <div className="day">09</div>
                        <div className="day">10</div>
                        <div className="day">11</div>
                      </div>
                      <div className="week">
                        <div className="day">12</div>
                        <div className="day">13</div>
                        <div className="day">14</div>
                        <div className="day">15</div>
                        <div className="day">16</div>
                        <div className="day">17</div>
                        <div className="day">18</div>
                      </div>
                      <div className="week">
                        <div className="day">19</div>
                        <div className="day">20</div>
                        <div className="day">21</div>
                        <div className="day">22</div>
                        <div className="day">23</div>
                        <div className="day">24</div>
                        <div className="day">25</div>
                      </div>
                      <div className="week">
                        <div className="day">26</div>
                        <div className="day">27</div>
                        <div className="day">28</div>
                        <div className="day">29</div>
                        <div className="day">30</div>
                        <div className="day">31</div>
                        <div className="day gray-color">01</div>
                      </div>
                      <div className="week">
                        <div className="day gray-color">02</div>
                        <div className="day gray-color">03</div>
                        <div className="day gray-color">04</div>
                        <div className="day gray-color">05</div>
                        <div className="day gray-color">06</div>
                        <div className="day gray-color">07</div>
                        <div className="day gray-color">08</div>
                      </div>
                    </div>
                    <div className="calendar-box">
                    <p className="paragraph"><KeyboardDoubleArrowLeftIcon /><KeyboardArrowLeftIcon/> Apr 2023</p>

                      <div className="week">
                        <div className="day">Sun</div>
                        <div className="day">Mon</div>
                        <div className="day">Tue</div>
                        <div className="day">Wed</div>
                        <div className="day">Thu</div>
                        <div className="day">Fri</div>
                        <div className="day">Sat</div>
                      </div>
                      <div className="week">
                        <div className="day gray-color">26</div>
                        <div className="day gray-color">27</div>
                        <div className="day gray-color">28</div>
                        <div className="day">01</div>
                        <div className="day">02</div>
                        <div className="day">03</div>
                        <div className="day">04</div>
                      </div>
                      <div className="week">
                        <div className="day">05</div>
                        <div className="day">06</div>
                        <div className="day">07</div>
                        <div className="day">08</div>
                        <div className="day">09</div>
                        <div className="day">10</div>
                        <div className="day">11</div>
                      </div>
                      <div className="week">
                        <div className="day">12</div>
                        <div className="day">13</div>
                        <div className="day">14</div>
                        <div className="day">15</div>
                        <div className="day">16</div>
                        <div className="day">17</div>
                        <div className="day">18</div>
                      </div>
                      <div className="week">
                        <div className="day">19</div>
                        <div className="day">20</div>
                        <div className="day">21</div>
                        <div className="day">22</div>
                        <div className="day">23</div>
                        <div className="day">24</div>
                        <div className="day">25</div>
                      </div>
                      <div className="week">
                        <div className="day">26</div>
                        <div className="day">27</div>
                        <div className="day">28</div>
                        <div className="day">29</div>
                        <div className="day">30</div>
                        <div className="day">31</div>
                        <div className="day gray-color">01</div>
                      </div>
                      <div className="week">
                        <div className="day gray-color">02</div>
                        <div className="day gray-color">03</div>
                        <div className="day gray-color">04</div>
                        <div className="day gray-color">05</div>
                        <div className="day gray-color">06</div>
                        <div className="day gray-color">07</div>
                        <div className="day gray-color">08</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-desc2">
                  Store, organise and track assets with greater productivity
                </div>
              </>
          }
          </div>
        }
      </div>
    );
  }
};

export default Login;