import React from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({ type, label, placeholder, validated, disabled, handleCustomInputChange}) => {
    return(
        <>
            <div className={validated ? "form-group" : "form-group form-error"}>
                <label htmlFor={label}>{label}</label>
                <input
                    id={label}
                    className="form-control"
                    type={type}
                    placeholder={placeholder}
                    onChange={(event) => handleCustomInputChange(event) }
                    disabled={disabled}
                ></input>
            </div>
        </>
    );
};

export default CustomInput;

CustomInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password']),
    validated: PropTypes.bool,
    disabled: PropTypes.bool
};

