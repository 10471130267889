/* eslint-disable no-undef */
import ServiceUtil from '../util/serviceUtil';
import ENDPOINTS from '../util/endpoints';

class NotificationService {
    static async getNotifications(tenantUuid, userUuid, token = "") {
        const query = {
            tenantUuid: tenantUuid,
            userUuid: userUuid,
        };

        if (token !== "") {
            query.token = token;
        }

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.Notification, query, {}, tenantUuid);
    }

    static async updateRead(idbody, tenantUuid) {
        const body = {
            uuids: idbody.uuids,
            tenantUuid: tenantUuid
        }
        return ServiceUtil.authHttpRequest('PUT', ENDPOINTS.Notification, {}, body, tenantUuid);
    }

    static async createNotification(tenantUuid, userUuid, notificationType, editor = "", oldRole = "", newRole = "") {
        const body = {
            tenantUuid: tenantUuid,
            userUuid: userUuid,
            notificationType: notificationType,
            editor: editor,
            oldRole: oldRole,
            newRole: newRole
        }
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.Notification, {}, body, tenantUuid);
    }
}

export default NotificationService;

