import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ButtonDropDown = ({ children, listItems, handleItemClick }) => {
    const [showActionDropBox, setShowActionDropBox] = useState(false);
    
    const [position, setPosition] = useState({ top: -200, left: -200 });
    const targetRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (targetRef.current && dropdownRef.current) {
            const targetRect = targetRef.current.getBoundingClientRect();
            const divRect = dropdownRef.current.getBoundingClientRect();
            setPosition({
                top: targetRect.bottom + window.scrollY + 8, // Add 8px below the target
                left: targetRect.left + window.scrollX + (targetRect.width - divRect.width) / 2, // Center horizontally
            });
        }
    }, [showActionDropBox]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowActionDropBox(false);
        }
    };

    useEffect(() => {
        if (showActionDropBox) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showActionDropBox]);

    return (
        <>
        <div ref={targetRef} className='btn-dropdown'>
            <button
                className='icon-button'
                onClick={() => setShowActionDropBox(true)}
            >
                {children}
            </button>
        </div>
        {showActionDropBox && (
            ReactDOM.createPortal(
            <div className='dropdown-box' ref={dropdownRef} style={{top: position.top, left: position.left}}>
                <ul>
                    {listItems.map((item, index) => <li key={item} onClick={() => handleItemClick(item)}>{item}</li>)}
                </ul>
            </div>,
            document.body)
        )}
        </>
    );
};

export default ButtonDropDown;
