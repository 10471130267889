
import { useState, useMemo, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IsMobile } from '../../../utils';
import { useStripe,
        useElements,
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement } from '@stripe/react-stripe-js';
import { SessionContext } from '../../../context/session-provider';
import { GlobalContext } from '../../../context/global-provider';
import Localization from '../../../localization';
import Chips from '../../chips';
import Skeleton from 'react-loading-skeleton';
import CustomIcons from '../../custom-icons';
import StripeService from '../../../../../services/api/stripeService';
import SolutionManifest from '../../../../../solution-manifest';
import SpinnerIcon from '../../../../icons/spinner';
import GenericModal from '../../generic-modal';
import MobileModal from '../../mobile-modal';
import { ShareContext } from '../../../context/share-state';

const stripePromise = loadStripe(SolutionManifest.Stripe.PublishableKey);

const StripePaymentForm = (props) => {
    const [paymentFormLoading, setPaymentFormLoading] = useState(true);
    const [validationError, setValidationError] = useState(null);
    const [validationErrorCard, setValidationErrorCard] = useState(null);
    const [validationErrorDate, setValidationErrorDate] = useState(null);

    //Share Context
    const { paymentMethodLoading,
        setPaymentMethodLoading,
        attachPaymentMethodError,
        setAttachPaymentMethodError } = useContext(ShareContext);

    // Session Context
    const { tenantUuid, userUuid } = useContext(SessionContext);
    const isMobile = IsMobile();
    const { updateToastMessage } = useContext(GlobalContext);

    //Stripe Elements Options
    const useOptions = () => {
        const fontSize = "16px";
        const options = useMemo(
            () => ({
            style: {
                base: {
                fontSize,
                color: "#000000",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
                },
                invalid: {
                color: "#000000",
                borderColor: "#D80029"
                }
            }
            }),
            [fontSize],
        );

        return options;
    };
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return;
      }

      setPaymentMethodLoading(true);

      // Create PaymentMethod without processing a payment
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        setPaymentMethodLoading(false);
        handleStripeError(error);
      } else {
        setPaymentMethodLoading(true);
        setAttachPaymentMethodError(false);
        // Send the paymentMethod.id to your server to associate it with a customer
        await handleServerSidePaymentMethod(paymentMethod.id, paymentMethod.card.last4);
      }
    };

    const handleStripeError = (error) => {
        if (error.type === 'validation_error') {
          // Handle validation errors
          // For example, update UI to display error messages
          setValidationError(error.message); // Update state or handle the error in your preferred way
        } else {
          // Handle other types of errors
          setValidationError(error.message); // Update state or handle the error in your preferred way
          console.error('Stripe Error:', error);
        }
    };

    const handleFormChange = (event) => {
        if (event.error) {
          // Handle validation error
          setValidationErrorCard(event.error.message);
        } else {
          // Clear any previous validation error
          setValidationErrorCard("");
        }
    };
    const handleFormChangeDate = (event) => {
        if (event.error) {
          // Handle validation error
          setValidationErrorDate(event.error.message);
        } else {
          // Clear any previous validation error
          setValidationErrorDate("");
        }
    };

    const handleServerSidePaymentMethod = async (paymentMethodId, last4cardno) => {
        const stripeAttachPaymentMethods = await StripeService.stripeAttachPaymentMethods(props.customerId, paymentMethodId, tenantUuid, userUuid, last4cardno);

        if(stripeAttachPaymentMethods.code === 200) {
            props.updatePaymentMethods(null);
            props.updateAddPaymentMethods(false);
            const listPaymentMethods = await props.listPaymentMethods(props.customerId);
            if(listPaymentMethods.code === 200) {
                updateToastMessage(Localization.Plan.PaidPlan.NewPaymentMethod, "success");
                setPaymentMethodLoading(false);
            }
        } else {
            setAttachPaymentMethodError(true);
            setPaymentMethodLoading(false);
            props.updateAddPaymentMethods(true);
        }
    };

    const StripePaymentSkeleton = () => {
        return(
            <div className='stripe-payment-skeleton'>
                <Skeleton width='100%' height='68.8px'/>
                <div className='stripe-payment-skeleton-group'>
                    <Skeleton width='100%' height='68.8px'/>
                    <Skeleton width='100%' height='68.8px'/>
                </div>
            </div>
        );
    }

    return (
        <form className={paymentFormLoading ? "stripe-payment-form loading" : "stripe-payment-form"} onSubmit={handleSubmit}>
            {
                paymentFormLoading &&
                    <StripePaymentSkeleton />
            }
            <div className="form-group">
                <label style={{color: validationErrorCard ? "#D80029" : null}}>{Localization.Plan.PaidPlan.CardNumber}</label>
                <CardNumberElement
                    className='card-number'
                    options={options}
                    onReady={() => setPaymentFormLoading(false)}
                    onChange={handleFormChange}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
            </div>
            <div className="form-group">
                <label style={{color: validationErrorDate ? "#D80029" : null}}>{Localization.Plan.PaidPlan.ExpirationDate}</label>
                <CardExpiryElement
                    options={options}
                    onReady={() => setPaymentFormLoading(false)}
                    onChange={handleFormChangeDate}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
            </div>
            <div className="form-group">
                <label>{Localization.Plan.PaidPlan.CVC}</label>
                <CardCvcElement
                    options={options}
                    onReady={() => setPaymentFormLoading(false)}
                    onChange={handleFormChange}
                    onBlur={() => {
                        console.log("CardNumberElement [blur]");
                    }}
                    onFocus={() => {
                        console.log("CardNumberElement [focus]");
                    }}
                />
            </div>
            <div className="form-error">
                <div className="error-msg">
                    { validationErrorCard ?
                        <>
                            <CustomIcons variant="error" color="#D80029"/> {validationErrorCard}
                        </>
                        : validationErrorDate ?
                        <>
                            <CustomIcons variant="error" color="#D80029"/> {validationErrorDate}
                        </>
                        : validationError ?
                        <>
                            <CustomIcons variant="error" color="#D80029"/> {validationError}
                        </>
                        :null
                    }
                    {
                        attachPaymentMethodError && !paymentFormLoading &&
                            <>
                                <CustomIcons variant="error" color="#D80029"/> {Localization.Plan.PaidPlan.PaymentMethodError}
                            </>
                    }
                </div>
            </div>
            <div className='stripe-payment-form-btn-group'>
                <Button variant="secondary" className="btn-sm" onClick={() => props.updateAddPaymentMethods(false)}>
                    { Localization.Plan.PaidPlan.Cancel }
                </Button>
                <Button variant='primary' className="btn-sm" type="submit" disabled={paymentMethodLoading}>
                { isMobile ? 'Add' : Localization.Plan.PaidPlan.AddPaymentMethod }
                {
                    paymentMethodLoading &&
                        <SpinnerIcon />
                }
                </Button>
            </div>
        </form>
    );
};

const PaymentMethods = (props) => {
    // Tenant Uuid
    const { tenantUuid, userUuid } = useContext(SessionContext);

    //Share Context
    const { paymentMethodLoading, setAttachPaymentMethodError } = useContext(ShareContext);

    // Payment methods
    const [defaultPopup, setDefaultPopup] = useState(null);
    const [addPaymentMethods, setAddPaymentMethods] = useState(false);

    // Modal
    const [modalData, setModalData] = useState({
        show: false,
        payment_method_id: null,
        last4cardno: null
    });
    const [modalLoading, setModalLoading] = useState(false);
    const isMobile = IsMobile();

    const updateAddPaymentMethods = (newAddPaymentMethods) => {
        setAddPaymentMethods(newAddPaymentMethods)
    };
    const { updateToastMessage } = useContext(GlobalContext);

    const setDefaultPaymentMethod = async (customer_id, payment_method_id, last4cardno) => {
        setDefaultPopup(null);
        props.updatePaymentMethods(null);
        const setDefaultPaymentMethods = await StripeService.stripeSetDefaultPaymentMethods(customer_id, payment_method_id, last4cardno, tenantUuid, userUuid);

        if(setDefaultPaymentMethods) {
            const listPaymentMethods = await props.listPaymentMethods(props.customerId);

            if(listPaymentMethods.code === 200) {
                updateToastMessage(Localization.Plan.PaidPlan.DefaultPaymentMethod, "success");
            }
        }
    };

    const changeDefaultPaymentMethod = (customer_id, payment_method_id, last4cardno) => {
        setDefaultPaymentMethod(customer_id, payment_method_id, last4cardno);
    };

    const handleDeletePaymethod = async () => {
        setModalLoading(true);
        const stripeDeletePaymentMethods = await StripeService.stripeDeletePaymentMethods(modalData.customer_id, modalData.payment_method_id, modalData.last4cardno, tenantUuid, userUuid);

        if(stripeDeletePaymentMethods.code === 200) {
            props.updatePaymentMethods(null);

            setModalLoading(false);
            setModalData({
                show: false,
                payment_method_id: null,
                last4cardno: null
            });

            const listPaymentMethods = await props.listPaymentMethods(props.customerId);

            if(listPaymentMethods.code === 200) {
                updateToastMessage(Localization.Plan.PaidPlan.PaymentMethodDeleted, "success");
            }
        }
    };

    const handleModalClose = () => setModalData({
        show: false,
        payment_method_id: null,
        last4cardno: null
    });

    const handleMobileModalClose = () => {
        setDefaultPopup(null);
    }
    return(<>
        <div className='settings-plan-payment-method'>
            <div className='settings-plan-details-title'>
                <h2>{Localization.Plan.PaidPlan.PaymentMethod}</h2>
            </div>
            {
                !addPaymentMethods
                ?
                    <>
                        <div className='settings-plan-details-payment-method'>
                        {
                            props.paymentMethods && !paymentMethodLoading
                            ?
                                <>
                                {
                                    props.paymentMethods.paymentMethods.map((paymentMethod) => <div key={paymentMethod.id} id={paymentMethod.id}>
                                        <span>
                                            {
                                                paymentMethod.card.brand === 'visa'
                                                ?
                                                    <img src="./images/cards/visa.svg"/>
                                                :
                                                paymentMethod.card.brand === 'mastercard'
                                                ?
                                                    <img src="./images/cards/mastercard.svg"/>
                                                :
                                                paymentMethod.card.brand === 'amex'
                                                ?
                                                    <img src="./images/cards/american-express.svg"/>
                                                :
                                                paymentMethod.card.brand === 'discover'
                                                ?
                                                    <img src="./images/cards/discover.svg"/>
                                                :
                                                paymentMethod.card.brand === 'diners'
                                                ?
                                                    <img src="./images/cards/dinersclub.svg"/>
                                                : null
                                            }
                                            {`Ending in ${paymentMethod.card.last4}`}
                                            {!isMobile && paymentMethod.id === props.paymentMethods.defaultPaymentMethodId && <Chips text="Default"/>}
                                        </span>
                                        {isMobile && paymentMethod.id === props.paymentMethods.defaultPaymentMethodId && <Chips text="Default"/>}
                                        {
                                            paymentMethod.id !== props.paymentMethods.defaultPaymentMethodId &&
                                                <span>
                                                    <div>
                                                    {(defaultPopup === paymentMethod.id &&  isMobile) &&

                                                            <MobileModal title={'Payment method'} onClose={handleMobileModalClose}>
                                                                <div className="mobile-modal-more-vertical">
                                                                    <Button size='sm' className="btn-row-action" onClick={() => changeDefaultPaymentMethod(props.customerId, paymentMethod.id)} variant='link'>
                                                                        <h3>Set as default</h3>
                                                                    </Button>
                                                                </div>
                                                            </MobileModal>
                                                    }
                                                    {(defaultPopup === paymentMethod.id && !isMobile) &&
                                                        <div className={`set-default-payment ${isMobile ? 'd-none' : null }`}><button className='text-button' onClick={() => changeDefaultPaymentMethod(props.customerId, paymentMethod.id, paymentMethod.card.last4) }>{Localization.Plan.PaidPlan.SetAsDefault}</button></div>
                                                    }
                                                        <button className='icon-button' onClick={() => defaultPopup === null ? setDefaultPopup(paymentMethod.id) : setDefaultPopup(null) }><CustomIcons variant="more"/></button>
                                                    </div>
                                                    <div>
                                                    <button className='icon-button' onClick={() => setModalData({show: true, customer_id: props.customerId, payment_method_id: paymentMethod.id, last4cardno: paymentMethod.card.last4})}><CustomIcons variant="trash"/></button>
                                                    </div>
                                                </span>
                                        }
                                    </div>).reverse()
                                }
                                </>
                            :
                                <>
                                    <Skeleton width="250px" height="32px" style={{ marginBottom: "28px"}}/>
                                    <Skeleton width="250px" height="32px" style={{ marginBottom: "28px"}}/>
                                    <Skeleton width="250px" height="32px"/>
                                </>
                        }
                        </div>
                        {
                            props.paymentMethods && !paymentMethodLoading && <Button variant='primary' className="btn-sm" onClick={() => {setAddPaymentMethods(true); setAttachPaymentMethodError(false);} }>{Localization.Plan.PaidPlan.AddPaymentMethod}</Button>
                        }
                    </>
                :
                    <>
                        <Elements stripe={stripePromise}>
                            <StripePaymentForm
                                updatePaymentMethods={props.updatePaymentMethods}
                                customerId={props.customerId}
                                listPaymentMethods={props.listPaymentMethods}
                                updateAddPaymentMethods={updateAddPaymentMethods}
                                updateToastMessage={props.updateToastMessage} />
                        </Elements>
                    </>

            }
        </div>
        {
            modalData &&
                <GenericModal
                    modalType="deletePaymentMethod"
                    modalShow={modalData.show}
                    handleModalClose={handleModalClose}
                    handleDeletePaymethod={handleDeletePaymethod}
                    modalLoading={modalLoading}/>
        }
    </>);
};

export default PaymentMethods;
