/* eslint-disable no-undef */
import { useState, useContext } from "react";
import { createContext } from "react";
import { SessionContext } from "../session-provider";
import SolutionManifest from '../../../../solution-manifest';
import IotSubscriber from '../../../../services/api/IotSubscriber';
import UsersService from "../../../../services/api/usersService";
import auditTrailService from "../../../../services/api/auditTrailService";
import { createAuditBody } from "../../utils";
import AWS from 'aws-sdk';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import NotificationService from "../../../../services/api/notificationService";

const AccountContext = createContext();

const Account = (props) => {
  const [MFASetup, setMFASetup] = useState(null);
  const [MFAVerification, setMFAVerification] = useState(null);
  const [associateSecretCode, setAssociateSecretCode] = useState(null);
  const [userInChangePassword, setUserInChangePassword] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [inputUserName, setInputUserName] = useState(null);
  const [isMfaCodeMatch, setIsMfaCodeMatch] = useState(true);
  const [isMfaCodeVerificationMatch, setIsMfaCodeVerificationMatch] = useState(true);
  const [isBackupCodeMatch, setIsBackupCodeMatch] = useState(true);
  const [isMfaVerifying, setIsMfaVerifying] = useState(null);
  const [isBackUpVerifying, setIsBackUpVerifying] = useState(null);
  const [customAuthDetail, setCustomAuthDetail] = useState(null);
  const [sessionForVerifyMfa, setSessionForVerifyMfa] = useState(null);
  // Session Context
  const { getSession,
          tenantUuid,
          userPoolID,
          userPool,
          subdomainName,
          setAwsConfiguration,
          setUserUuid,
          userUuid,
          setupProfileInformation,
          HandlerBackToTop,
          updateLocalStorageWithSessionTokens } = useContext(SessionContext);

  const verifySoftwareToken = (cognitoUser, code) => {
    return new Promise(async (resolve, reject) => {
      cognitoUser.verifySoftwareToken(code, 'My TOTP device', {
        onSuccess: async (data) => {
            await setAwsConfiguration(cognitoUser); // configure to make setting of mfapreference to work
            const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
              apiVersion: '2016-04-18',
            });

            // Enable software token MFA
            const usernamePrefix = '-';
            const params = {
              UserPoolId: userPoolID,
              Username: inputUserName + usernamePrefix + subdomainName,
              SoftwareTokenMfaSettings: {
                Enabled: true,
                PreferredMfa: true
              }
            };

            await cognitoIdentityServiceProvider.adminSetUserMFAPreference(params, async (err, data) => {
              if (err) {
                reject({ success: false, data: err });
              } else {
                const session = await getSession(cognitoUser);
                setSessionForVerifyMfa(session);
                
                // Add Setup2fa activity to audit trail
                if(tenantUuid && session['custom:uuid']) {
                  const auditBody = createAuditBody(tenantUuid, session['custom:uuid'], "account", "setup2fa");
                  await auditTrailService.addActivity(auditBody, tenantUuid);
                }

                // Store the session tokens in browser storage
                updateLocalStorageWithSessionTokens(session, cognitoUser.signInUserSession);
                localStorage.removeItem("newPasswordRequired");
                resolve({ success: true, data: data });
              }
            });
        },
        onFailure: (err) => {
          setIsMfaCodeMatch(false);
          reject({success: false, data: err});
        },
      });
    });
  };

  const getUserBackupCodes1stTimeLogin = async () => {
    try {
      // Add attribute to user
      const usernamePrefix = '-';
      const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
        apiVersion: '2016-04-18',
      });

      const attributeParams = {
        UserPoolId: userPoolID,
        Username: inputUserName + usernamePrefix + subdomainName,
        UserAttributes: [
          {
            Name: "custom:MFAVerify",
            Value: "verify",
          }
        ]
      };

      const setUserVerified = await cognitoIdentityServiceProvider.adminUpdateUserAttributes(attributeParams).promise();
      return {success: true, data: setUserVerified};
    } catch (err) {
      return {success:false, data: err};
    }
  };

  const sendMFACode = async (cognitoUser, code) => {
    return new Promise((resolve, reject) => {
      cognitoUser.sendMFACode(code, {
        onSuccess: async (data) => {
          try {
            setIsMfaCodeVerificationMatch(true);

            const session = await getSession(cognitoUser);

            //Set up profile information
            await setupProfileInformation(session);

            // Add login activity to audit trail
            if(tenantUuid && session['custom:uuid']) {
              const auditBody = createAuditBody(tenantUuid, session['custom:uuid'], "account", "login");
              await auditTrailService.addActivity(auditBody, tenantUuid);
            }

            // Store the session tokens in browser storage
            updateLocalStorageWithSessionTokens(data);
            resolve({ success: true, data: data });
          } catch (error) {
            setIsMfaVerifying(false);
            reject({ success: false, data: error });
          }
        },
        onFailure: (err) => {
          setIsMfaCodeVerificationMatch(false);
          setIsMfaVerifying(false);
          reject({ success: false, data: err })
        },
      }, 'SOFTWARE_TOKEN_MFA');
    });
  };

  const resetMFAForUser = async (userName, userPoolID, name, targetUuid) => {
    const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
      apiVersion: '2016-04-18',
    });

    const params = {
      UserPoolId: userPoolID,
      Username: userName,
      SoftwareTokenMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      }
    };

    try {
      const response = await cognitoIdentityServiceProvider.adminSetUserMFAPreference(params, async (err, data) => {
          if (err) {
            reject(err);
          } else {
            // Add Reset2fa activity to audit trail
            if(tenantUuid && userUuid) {
              const options = {
                users: [{ name: name }],
              };
              const auditBody = createAuditBody(tenantUuid, userUuid, "account", "reset2fa", options);
              await auditTrailService.addActivity(auditBody, tenantUuid);
            }
            // notification creation
            await NotificationService.createNotification(tenantUuid, targetUuid, "reset 2fa", userUuid,)
          }
        });

        return response;
    } catch (error) {
        console.error('Error resetting MFA', error);
    }
  };

  const authenticate = async (Username, Password) => {
    const usernamePrefix = '-';
    const usernameWPrefix = Username + usernamePrefix + subdomainName;
    const userData = {
      Username: usernameWPrefix,
      Pool: userPool
    };

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser(userData);
      const authDetails = new AuthenticationDetails({
        Username: usernameWPrefix,
        Password: Password
      });

      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          await user.getUserAttributes(async (err, attributes) => {
            if (err) {
                console.error('Error fetching user attributes:', err);
                reject(err);
                return;
            }

            // Check the tenant_uuid attribute
            const tenantUUIDAttribute = attributes.find(attr => attr.getName() === 'custom:tenantUuid');
            if (!tenantUUIDAttribute) {
                console.error('Tenant UUID not found in user attributes');
                reject(new Error('Invalid user attributes'));
                return;
            }

            if(tenantUuid !== tenantUUIDAttribute.getValue()) {
              reject(new Error('Invalid username or password'));
              return;
            }

            // Check sub domain free trial disable mfa
            const subscriptionPlanAttribute = attributes.find(attr => attr.getName() === 'custom:subscriptionPlan');
            if(subscriptionPlanAttribute.Value === SolutionManifest.SubscriptionPlan.FreeTrialId) {
                const session = await getSession(user);

                //Set up profile information
                await setupProfileInformation(session);

                // Add login activity to audit trail
                if(tenantUuid && session['custom:uuid']) {
                  const auditBody = createAuditBody(tenantUuid, session['custom:uuid'], "account", "login");
                  await auditTrailService.addActivity(auditBody, tenantUuid);
                }

                // Store the session tokens in browser storage
                updateLocalStorageWithSessionTokens(data);

                return;
            }

            if(user.challengeName === undefined) {
              await user.associateSoftwareToken({
                onFailure: (err) => {
                  console.error('onFailure for associate token:', err);
                },
                associateSecretCode: async (code) => {
                  setMFASetup(true);
                  setCognitoUser(user);
                  setInputUserName(Username);
                  setAssociateSecretCode(code);
                }
              });
            }

            if(user.challengeName === undefined) {
              await user.associateSoftwareToken({
                onFailure: (err) => {
                  console.error('onFailure for associate token:', err);
                },
                associateSecretCode: async (code) => {
                  setMFASetup(true);
                  setCognitoUser(user);
                  setInputUserName(Username);
                  setAssociateSecretCode(code);

                  //make sure uuid is present
                  const session = await getSession(user);
                  setUserUuid(session['custom:uuid']);
                }
              });
            } else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
              const session = await getSession(user);

              //Set up profile information
              await setupProfileInformation(session);

              // Add login activity to audit trail
              if(tenantUuid && session['custom:uuid']) {
                const auditBody = createAuditBody(tenantUuid, session['custom:uuid'], "account", "login");
                await auditTrailService.addActivity(auditBody, tenantUuid);
              }

              // Store the session tokens in browser storage
              updateLocalStorageWithSessionTokens(data);
            }
          });
        },
        totpRequired: (challengeName) => {
          if(challengeName === 'SOFTWARE_TOKEN_MFA') {
            setInputUserName(Username);
            setMFAVerification(true);
            setCognitoUser(user);
            setCustomAuthDetail(authDetails);
          }
        },
        onFailure: (err) => {
          localStorage.removeItem("user");
          localStorage.removeItem("newPasswordRequired");
          HandlerBackToTop();
          console.error("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          localStorage.setItem("newPasswordRequired", true);
          resolve(data);
        },
      });
    });
  };

  const customAuthenticate = async (cognitoUser, backUpCode) => {
    const usernamePrefix = '-';
    const usernameWPrefix = inputUserName + usernamePrefix + subdomainName;

    return await new Promise( async (resolve, reject) => {
      // initiate custom authentication
      // proceed to check authorization of user [need for lamba trigger]
      try {
        cognitoUser.initiateAuth(customAuthDetail, {
          onSuccess: function (result) {
            resolve(result);
          },
          customChallenge: function (challengeParameters) {
            const customChallengeAnswer = backUpCode;
            const clientMetaData = {
              username : usernameWPrefix,
              method : 'customAuthenticate'
            }
            
            cognitoUser.sendCustomChallengeAnswer(customChallengeAnswer, {
              onSuccess: async function (result) {
                  const session = await getSession(cognitoUser);

                  //Set up profile information
                  await setupProfileInformation(session);

                  // Add login activity to audit trail
                  if(tenantUuid && session['custom:uuid']) {
                    const auditBody = createAuditBody(tenantUuid, session['custom:uuid'], "account", "login");
                    await auditTrailService.addActivity(auditBody, tenantUuid);
                  }

                  // Store the session tokens in browser storage
                  updateLocalStorageWithSessionTokens(result);

                  setIsBackupCodeMatch(true);
                  setIsBackUpVerifying(false);
                  setMFAVerification(() => {
                    const newState = false;
                    return newState;
                  });
                  resolve({ success: true, data: result });
              },
              onFailure: function (err) {
                  setIsBackupCodeMatch(false);
                  setIsBackUpVerifying(false);
                  reject({success:false, data:err});
              }
            }, clientMetaData);
          },
          onFailure: function (err) {
            setIsBackupCodeMatch(false);
            setIsBackUpVerifying(false);
            reject({success:false, data:err});
          }
        })
      } catch (error) {
        setIsBackupCodeMatch(false);
        setIsBackUpVerifying(false);
        reject({success:false, data:error});
      }
    });
  }

  const reAuthenticate = async (Username, Password) => {
    const usernamePrefix = '-';
    const usernameWPrefix = Username + usernamePrefix + subdomainName;
    const userData = {
      Username: usernameWPrefix,
      Pool: userPool
    };

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser(userData);

      const authDetails = new AuthenticationDetails({
        Username: usernameWPrefix,
        Password: Password
      });

      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {
          await user.getUserAttributes(async (err, attributes) => {
            if (err) {
                console.error('Error fetching user attributes:', err);
                reject(err);
                return;
            }
            // Check the tenant_uuid attribute
            const tenantUUIDAttribute = attributes.find(attr => attr.getName() === 'custom:tenantUuid');
            if (!tenantUUIDAttribute) {
                console.error('Tenant UUID not found in user attributes');
                reject(new Error('Invalid user attributes'));
                return;
            }

            if(tenantUuid !== tenantUUIDAttribute.getValue()) {
              reject(new Error('Invalid username or password'));
              return;
            }

            resolve({
              statusCode: 200,
              data: data
            });
          });
        },
        totpRequired: (challengeName) => {
          if(challengeName === 'SOFTWARE_TOKEN_MFA') {
              resolve({
                statusCode: 200
              });
          }
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  const logout = async (logActivity = true) => {
    const user = userPool?.getCurrentUser();

    if (user) {
      if (logActivity) {
        // Add logout activity to audit trail
        const auditBody = createAuditBody(tenantUuid, userUuid, "account", "logout");
        await auditTrailService.addActivity(auditBody, tenantUuid);
      }

      user.signOut();
      localStorage.clear();
    } 
  };

  const profilechangepassword = async (oldPassword, newPassword) => {
    const response = await new Promise((resolve, reject) => {
      const user = userPool?.getCurrentUser();

      if (user != null) {
        user.getSession((err, session) => {
          if (err) {
            console.error(err);
            return;
          }

          user.changePassword( oldPassword, newPassword, (err, result) => {
            if (err) {
              reject(err);
              return;
            }

            resolve(result);
          });
        });
      }
    });

    if(response === "SUCCESS" && userUuid) {
      // Add change/resetpassword password activity to audit trail
      const auditBody = createAuditBody(
        tenantUuid,
        userUuid,
        "account",
        "changepassword"
      );
      await auditTrailService.addActivity(auditBody, tenantUuid);
    }
    return response;
  };

  const changepassword = async (username, oldPassword, newPassword, resetpassword = false) => {
    const user = userInChangePassword;
    const usernamePrefix = '-';

    const authDetails = new AuthenticationDetails({
      Username: username + usernamePrefix + subdomainName,
      Password: oldPassword
    });
    
    // Initiate user. Use token for input in changePassword method
    const response = await new Promise((resolve, reject) => {
      try {
        user.initiateAuth(authDetails, {
          customChallenge: (challengeParameters) => {
            const customChallengeAnswer = 'true';
            const clientMetaData = {
              method : 'changePassword' 
            }
  
            user.sendCustomChallengeAnswer(customChallengeAnswer, {
              onSuccess: async function (result) {
                  if(user !== null) {
                    user.getSession((err, session) => {
                      if (err) {
                        reject(new Error("Invalid session."));
                        return;
                      }
                      
                      if(!session.isValid()) {
                        reject(new Error("Invalid session."));
                        return;
                      }
  
                      user.changePassword(oldPassword, newPassword, (err, result) => {
                        if(err) {
                          reject(err);
                          return;
                        }
  
                        resolve(result);
                      })
                    })
                  }
              },
              onFailure: function (err) {
                reject(err);
              }
            }, clientMetaData);
          },
          onFailure: (err) => {
            reject(Error("No current user found."));
          }
        })
      } catch (error) {
        console.error('error in reset password',error)
      }
    });

    // When initiation is successful, add change/resetpassword password activity to audit trail
    if(response === "SUCCESS" && userUuid) {
      const auditBody = createAuditBody(
        tenantUuid,
        userUuid,
        "account",
        resetpassword === true? "resetpassword" : "changepassword"
      );
      await auditTrailService.addActivity(auditBody, tenantUuid);
    }
    return response;
  };

  const forgotpassword = async (Username) => {
    const usernamePrefix = '-';
    const usernameWPrefix = Username + usernamePrefix + subdomainName;
    const userData = {
      Username: usernameWPrefix,
      Pool: userPool
    };

    return await new Promise((resolve, reject) => {

      const user = new CognitoUser(userData);

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (data) => {
          reject(data);
        },
        inputVerificationCode: (data) => {
          resolve(data);
        },
      })
    });
  };

  const confirmpassword = async (Username, verificationCode, newPassword) => {
    const usernamePrefix = '-';
    const usernameWPrefix = Username + usernamePrefix + subdomainName;
    const userData = {
      Username: usernameWPrefix,
      Pool: userPool
    };

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser(userData);

      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: (success) => {
          setUserInChangePassword(user);
          resolve(success);
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  };

  const confirmnewpassword = async (Username, UserPassword, NewPassword) => {
    const usernamePrefix = '-';
    const usernameWPrefix = Username + usernamePrefix + subdomainName;
    const userData = {
      Username: usernameWPrefix,
      Pool: userPool
    };

    return await new Promise((resolve, reject) => {
      const user = new CognitoUser(userData);

      const authenticationDetails = new AuthenticationDetails({
        Username: usernameWPrefix,
        Password: UserPassword,
      });

      user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      user.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          user.completeNewPasswordChallenge(NewPassword, {}, {
            onSuccess: (success) => {
              resolve(success);
            },
            onFailure: (error) => {
              reject(error);
            },
          });
        },
        onSuccess: async () => {
          // Handle the success case if needed
          await user.getUserAttributes(async (err, attributes) => {
            if (err) {
                console.error('Error fetching user attributes:', err);
                reject(err);
                return;
            }

            // Check the tenant_uuid attribute
            const tenantUUIDAttribute = attributes.find(attr => attr.getName() === 'custom:tenantUuid');
            if (!tenantUUIDAttribute) {
                console.error('Tenant UUID not found in user attributes');
                reject(new Error('Invalid user attributes'));
                return;
            }

            if(tenantUuid !== tenantUUIDAttribute.getValue()) {
              reject(new Error('Invalid username or password'));
              return;
            }

            await setAwsConfiguration(user).then(async () => {
              // Store the session tokens in browser storage
              localStorage.setItem('accessToken', data.getAccessToken().getJwtToken());
              localStorage.setItem('idToken', data.getIdToken().getJwtToken());
              localStorage.setItem('refreshToken', data.getRefreshToken().getToken());
              localStorage.removeItem("newPasswordRequired");
              resolve(data);
            });
            await IotSubscriber.attachIot();
            await IotSubscriber.getSingleton().connect(user, tenantUuid);
          });
        },
        onFailure: (err) => {
        },
      });
    });
  }

  const updateUserAttributes = async (attributeList) => {
    return await new Promise((resolve, reject) => {
      const user = userPool?.getCurrentUser();

      if (user != null) {
        user.getSession((err, session) => {
          if (err) {
            console.error(err);
            return;
          }

          user.updateAttributes(attributeList, (err, result) => {
            if (err) {
              reject(err);
              return;
            }

            resolve(result);
          });
        });
      }
    });
  };

  const filterCognitoUsers = async (searchFullname = "", startDate = "", endDate = "", userGroups = [], roles = []) => {
    const filter = {
      searchFullname: searchFullname,
      startDate: startDate,
      endDate: endDate,
      role: roles.join(','),
      userGroup: userGroups.join(",")
    };

    const userListing = await UsersService.userListing(tenantUuid, filter);

    if(userListing.statusCode === 200) {
      return { users: userListing.response.users, totalUsers: userListing.response.totalUsers }
    }
  };

  const listCognitoGroups = async () => {
    return await new Promise((resolve, reject) => {
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

      let params = {
          UserPoolId: userPoolID,
      };

      const groups = cognitoidentityserviceprovider.listGroups(params);
      resolve(groups);
    });
  };

  return (
    <AccountContext.Provider value={{
      sendMFACode,
      resetMFAForUser,
      MFAVerification,
      sessionForVerifyMfa,
      cognitoUser,
      verifySoftwareToken,
      MFASetup,
      setMFASetup,
      setMFAVerification,
      inputUserName,
      associateSecretCode,
      authenticate,
      reAuthenticate,
      changepassword,
      profilechangepassword,
      forgotpassword,
      confirmpassword,
      confirmnewpassword,
      logout,
      updateUserAttributes,
      filterCognitoUsers,
      isMfaCodeMatch,
      isMfaCodeVerificationMatch,
      isBackupCodeMatch,
      setIsBackupCodeMatch,
      isBackUpVerifying,
      setIsBackUpVerifying,
      customAuthenticate,
      isMfaVerifying,
      setIsMfaVerifying,
      setIsMfaCodeMatch,
      getUserBackupCodes1stTimeLogin,
      listCognitoGroups }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
