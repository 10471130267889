import { useState, useEffect, useContext } from 'react';
import Localization from '../../shared/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import { UploadContext } from '../../shared/context/upload-provider';
import { IsMobile, IsTablet, IsDesktop } from '../../shared/utils';

const UploadError = (props) => {
    const isDesktop = IsDesktop();

    const { updateActiveSetting } = useContext(UploadContext);

    const errorIssuesList = props.errFiles.reduce((acc, item) => {
        if (!acc.includes(item.error)) {
            acc.push(item.error);
        }
        updateActiveSetting('upload-error');
        return acc;
    }, []);
  
    return(
        <>
            <div className='upload-error-wrapper'>
                <div className='upload-error-icon'>
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                </div>
                <div className='upload-error-content'>
                    <h3>{Localization.Upload.Offcanvas.Error.Title}</h3>
                    <p className="text-center paragraph-1">{!isDesktop ? Localization.Upload.Offcanvas.Error.Desc3 : Localization.Upload.Offcanvas.Error.Desc}</p>
                    {isDesktop &&
                      <div className='upload-error-button'>
                        <Button variant='primary' onClick={props.handlerErrorProceeds}>{Localization.Upload.Offcanvas.Error.Button}</Button>
                      </div>
                    }
                    <hr></hr>
                    <div className='upload-error-status'>
                        <div className="upload-error-status-error">
                            <p>{Localization.Upload.Offcanvas.Error.Issues.Title}</p>
                            <ul>
                                {
                                    errorIssuesList && errorIssuesList.map((list) => {
                                        if(list === 'same') {
                                            return(<li key={list}>{Localization.Upload.Offcanvas.Error.Issues.Same}</li>);
                                        } else if(list === 'extension') {
                                            return(<li key={list}>{Localization.Upload.Offcanvas.Error.Issues.Extension}</li>);
                                        } else if(list === 'size') {
                                            return(<li key={list}>{Localization.Upload.Offcanvas.Error.Issues.Size}</li>);
                                        } else if(list === 'name') {
                                            return(<li key={list}>{Localization.Upload.Offcanvas.Error.Issues.Name}</li>);
                                        } else {
                                            return(null);
                                        }
                                    })
                                }
                            </ul>
                        </div>
                        <div className="upload-error-status-filename-cont">
                            <div style={props.errFiles.length < 5 ? {overflow: 'hidden'} : {}} className="upload-error-status-filename">
                                {
                                    props.errFiles && props.errFiles.map((file) => {
                                        return(<span key={file.name}>{file.name}</span>);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {!isDesktop &&
                    <div className="upload-view-sticky-bottom upload-settings-mobile">
                        <div>
                            <Button variant='primary' onClick={props.handlerErrorProceeds}>{Localization.Upload.Offcanvas.Error.Button}</Button>
                        </div>
                    </div>
                }
                
            </div>
        </>
    );
}

export default UploadError;
