import { spanishGray, iconSize } from '../../shared/utils';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';

const AudioIcon = ({ muted, className, color, size}) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;
    const mutedVal = typeof muted === 'boolean' ? muted : false;

    return (
        <>
            {
                mutedVal
                ?
                    <VolumeOffRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <VolumeUpRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default AudioIcon;