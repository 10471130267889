import { useState, createContext } from 'react';

export const AdditionalUserOffcanvasContext = createContext();

export const AdditionalUserOffcanvasProvider = ({children}) => {
    const [OffcanvasData, setOffcanvasData] = useState({
        userRequestLoading: false,
        userRequestData: [],
        userRequestParams: {},
        userRequestInvoices: {},
        state: 0,
        customer_id: ""
    });

    const [OffcanvasShow, setOffcanvasShow] = useState(false);

    return (
        <AdditionalUserOffcanvasContext.Provider value={{
            OffcanvasData,
            setOffcanvasData,
            OffcanvasShow,
            setOffcanvasShow
        }}>
            {children}
        </AdditionalUserOffcanvasContext.Provider>
    );
};