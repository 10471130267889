import { IsMobile } from '../../utils';
import CustomIcons from '../custom-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Localization from '../../localization';

const GenericModal = (props) => {
    const isMobile = IsMobile();

    return(
        <Modal className={props.className} show={props.modalShow} onHide={props.handleModalClose}>
            {
                    props.modalType === "delete"
                    ? (
                    <>
                        <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false }>
                            <Modal.Title className='h3'>{Localization.Modal.Delete.Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {`
                            ${Localization.Modal.Delete.Desc} ${props.deleteFilesData.length > 0
                            ?
                                `${props.deleteFilesData.length === 1 ? `this file (${props.deleteFilesData[0].name})?` : `${props.deleteFilesData.length} files?`}`
                            :
                                `this file (${props.deleteFilesData.name})?`}`}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={props.handleModalClose}>{Localization.Modal.Delete.CancelButton}</Button>
                            <Button variant='primary' style={{ marginLeft: !isMobile ? '16px' : '0'}} onClick={props.handleModalDelete}>{Localization.Modal.Delete.ConfirmButton}</Button>
                        </Modal.Footer>
                    </>
                    ) : props.modalType === "logout"
                    ? (
                    <>
                        <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false }>
                            <Modal.Title>{Localization.Modal.Logout.Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {`
                            ${Localization.Modal.Logout.Desc}
                            `}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={props.handleModalClose}>{Localization.Modal.Logout.CancelButton}</Button>
                            <Button variant='primary' onClick={props.handleModalAccept}>{Localization.Modal.Logout.ConfirmButton}</Button>
                        </Modal.Footer>
                    </>
                    ) : props.modalType === 'info' ? (
                        <>
                            <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                                <Modal.Title>Info</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {`
                                ${props.infoMessage}
                                `}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={props.handleModalClose}>
                                Ok
                                </Button>
                            </Modal.Footer>
                        </>
                    ) : props.modalType === 'userlistdelete' ? (
                        <>
                        <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                            <Modal.Title>Delete User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p dangerouslySetInnerHTML={{ __html: props.displayMessage }} />
                            <div className='modal-user-list-name'>
                                <ul dangerouslySetInnerHTML={{ __html: props.infoMessage }}></ul>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={props.handleModalClose}>Cancel</Button>
                            <Button variant='primary' disabled={props.modalLoading ? props.modalLoading : false} onClick={props.handleModalAccept}>Delete
                                {
                                    props.modalLoading &&
                                        <CustomIcons variant="spinner" />
                                }
                            </Button>
                        </Modal.Footer>
                        </>
                    ) : props.modalType === 'Reset2fa' ? (
                        <>
                            <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                                <Modal.Title>{(props.userAuth) ? Localization.Modal.Reset2fa.Title : Localization.Modal.VerifyIdentity.Title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    (props.userAuth)
                                    ?
                                        <>
                                            <p className="no-margin" dangerouslySetInnerHTML={{ __html: props.displayMessage }} />
                                        </>
                                    :
                                        <>
                                            <p>{Localization.Modal.VerifyIdentity.Desc1}</p>
                                            { props.children }
                                        </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                {
                                     (props.userAuth)
                                     ?
                                        <>
                                            <Button variant='secondary' disabled={props.reset2faLoading} onClick={() => {props.handleModalClose(); props.resetModal();}}>Cancel</Button>
                                            <Button variant='primary' disabled={props.reset2faLoading} onClick={() => props.handleModalReset2FA()}>
                                                Reset 2FA
                                            </Button>
                                        </>
                                     :
                                     <>
                                        <Button variant='secondary' onClick={() => {props.handleModalClose(); props.resetModal(); }}>Cancel</Button>
                                        <Button variant='primary' disabled={props.reset2faLoading} onClick={() => props.handleModalVerifyPassword()}>
                                            Verify
                                        </Button>
                                     </>
                                }

                            </Modal.Footer>
                        </>
                    ) :props.modalType === 'userRequest' ?
                        <>
                            <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                                <Modal.Title>{props.userRequestType ? 'Approve user request' : 'Reject user request'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {
                                    props.userRequestType
                                    ?
                                        <>
                                            {
                                                props.userRequestData.length > 1
                                                ?
                                                isMobile ?
                                                    <p className='paragraph-1'>{`Are you sure you want to approve ${props.userRequestData.length} user requests? The action cannot be undone.`}</p>
                                                :
                                                    <p className='paragraph-1'>{`Are you sure you want to approve the ${props.userRequestData.length} user requests listed below? The action cannot be undone.`}</p>
                                                :
                                                isMobile ?
                                                    <p className='paragraph-1'>Are you sure you want to approve <b>{props.userRequestData.map((data) => data.name)}</b> ({props.userRequestData.map((data) => data.roles[0])})? The action cannot be undone.</p>
                                                :
                                                    <p className='paragraph-1'>Are you sure you want to approve the user request listed below? The action cannot be undone.</p>
                                            }
                                        </>
                                    :
                                        <>
                                            {
                                                props.userRequestData.length > 1
                                                ?
                                                isMobile ?
                                                    <p className='paragraph-1'>{`Are you sure you want to reject ${props.userRequestData.length} user requests? The action cannot be undone. `}</p>
                                                :
                                                    <p className='paragraph-1'>{`Are you sure you want to reject the ${props.userRequestData.length} user requests listed below? The action cannot be undone. `}</p>
                                                :
                                                isMobile ?
                                                    <p className='paragraph-1'>Are you sure you want to reject <b>{props.userRequestData.map((data) => data.name)}</b>? The action cannot be undone.</p>
                                                :
                                                    <p className='paragraph-1'>Are you sure you want to reject the user request listed below? The action cannot be undone.</p>
                                            }
                                        </>
                                }
                                <div className='modal-user-request-user'>
                                    <ul>
                                    {
                                        props.userRequestData.map((data) => {
                                            return (<li key={data.name}><b>{data.name}</b> {props.userRequestType && `(${data.roles.join(', ')})`}</li>);
                                        })
                                    }
                                    </ul>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={props.handleModalClose} disabled={props.userRequestLoading}>Cancel</Button>
                                <Button variant='primary' disabled={props.userRequestLoading} style={{marginLeft: !isMobile ? '16px' : '0'}} onClick={() => (props.handleUserRequest(props.userRequestParams))}>{props.userRequestType ? 'Approve' : 'Reject'}{props.userRequestLoading && <CustomIcons variant="spinner" />}</Button>
                            </Modal.Footer>
                        </>
                    : props.modalType === 'deletePaymentMethod' ?
                        (<>
                            <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false }>
                                <Modal.Title className='h3'>{Localization.Modal.Delete.Title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{Localization.Modal.Delete.DescPaymentMethod}</Modal.Body>
                            <Modal.Footer>
                                <Button variant='secondary' onClick={props.handleModalClose}>{Localization.Modal.Delete.CancelButton}</Button>
                                <Button disabled={props.modalLoading ? props.modalLoading : false} variant='primary' style={{ marginLeft: !isMobile ? '16px' : '0'}} onClick={props.handleDeletePaymethod}>
                                    {Localization.Modal.Delete.ConfirmButton}
                                    {
                                        props.modalLoading &&
                                            <CustomIcons variant="spinner" />
                                    }
                                </Button>
                            </Modal.Footer>
                        </>)
                    : props.modalType === 'mobileResponsive' ?
                        <>
                            <Modal.Header>
                                <button className='icon-button' onClick={props.handleModalClose}>
                                    <CustomIcons variant="close" color="#000000"/>
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <img src='../images/mobile-responsive.svg'/>
                                Your best experience is on Desktop view.
                            </Modal.Body>
                        </>
                    : props.modalType === 'deleteCollection' ?
                    <>
                        <Modal.Header closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                            <Modal.Title>{Localization.Modal.DeleteCollection.Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='text-break'>{Localization.Modal.DeleteCollection.Desc1} <b>{props.collectionTitle}</b>? {Localization.Modal.DeleteCollection.Desc2}</p>
                            <p>{`${Localization.Modal.DeleteCollection.Desc3}`}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={props.handleModalClose}>{Localization.Modal.Delete.CancelButton}</Button>
                            <Button variant='primary' style={{ marginLeft: !isMobile ? '16px' : '0'}} onClick={props.handleModalDelete}>{Localization.Modal.Delete.ConfirmButton}{props.collectionDeleting && <Spinner variant='gray' />}</Button>
                        </Modal.Footer>
                    </>
                : props.modalType === 'profile2fa' ?
                    <>
                        <Modal.Header>
                            <Modal.Title>{(props.showBackupCodes && props.userAuth) ? Localization.Profile.TwoFA.Modal.BackupCodes.Title : Localization.Profile.TwoFA.Modal.Title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                (props.showBackupCodes && props.userAuth) ?
                                    <>
                                        <p className='paragraph-1'>{Localization.Profile.TwoFA.Modal.BackupCodes.Desc}</p>
                                        { props.children }
                                    </>
                                :
                                    <>
                                        <p className='paragraph-1'>{Localization.Profile.TwoFA.Modal.Desc}</p>
                                        { props.children }
                                    </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                (props.showBackupCodes && props.userAuth)
                                ?
                                    <>
                                        <Button variant='secondary' disabled={props.verifyLoading} onClick={props.handleModalGenerateNewCodes}>{Localization.Profile.TwoFA.Modal.BackupCodes.GenerateNewCodesButton}{props.verifyLoading && <Spinner variant='gray' />}</Button>
                                        <Button variant='primary' disabled={props.verifyLoading} onClick={props.handleModalClose}>{Localization.Profile.TwoFA.Modal.BackupCodes.CloseButton}</Button>
                                    </>
                                :
                                    <>
                                        <Button variant='secondary' onClick={props.handleModalClose}>{Localization.Profile.TwoFA.Modal.CancelButton}</Button>
                                        <Button variant='primary' disabled={props.verifyLoading || !props.currentPassword} onClick={props.handleModalVerify}>{Localization.Profile.TwoFA.Modal.VerifyButton}{props.verifyLoading && <Spinner variant='gray' />}</Button>
                                    </>
                            }
                        </Modal.Footer>
                    </>
                : props.modalType === 'imageSearchLoading' ?
                    <>
                        <Modal.Header className='modal-image-search-header' closeButton={props.modalCloseButton ? props.modalCloseButton : false}>
                        </Modal.Header>
                        <Modal.Body> 
                            <div className="image-search-upload-loader-container">
                                <div className="loader"></div>
                                <div>Uploading...</div>
                            </div>
                        </Modal.Body>
                    </>
                : null
            }
        </Modal>
    );
}

export default GenericModal;