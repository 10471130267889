import { spanishGray, iconSize } from '../../shared/utils';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';

const BuildingIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <BusinessRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default BuildingIcon;