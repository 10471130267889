function promisifyRequest(request) {
    return new Promise((resolve, reject) => {
      request.oncomplete = request.onsuccess = () => resolve(request.result);
      request.onabort = request.onerror = () => reject(request.error);
    });
}

export function createStore(dbName, storeName) {
    const request = indexedDB.open(dbName);
    request.onupgradeneeded = () => request.result.createObjectStore(storeName);

    const dbp = promisifyRequest(request);

    return (txMode, callback) =>
      dbp.then((db) =>
        callback(db.transaction(storeName, txMode).objectStore(storeName)),
      );
  }

let defaultGetBlobStoreFunc;

export function defaultGetBlobStore() {
    if (!defaultGetBlobStoreFunc) {
        defaultGetBlobStoreFunc = createStore('asset', 'asset-asset');
    }
    return defaultGetBlobStoreFunc;
}

export function setStoreItem(key, value, customStore = defaultGetBlobStore()) {
    return customStore('readwrite', (store) => {
        store.put(value, key);
        return promisifyRequest(store.transaction);
    });
}
export function removeStoreItem(key, customStore = defaultGetBlobStore()) {
    return customStore('readwrite', (store) => {
        store.delete(key);
        return promisifyRequest(store.transaction);
    });
}
export function getStoreItem(key, customStore = defaultGetBlobStore()) {
    return customStore('readonly', (store) => promisifyRequest(store.get(key)));
}

export function getAllStoreItems(customStore = defaultGetBlobStore()) {
    return new Promise((resolve, reject) => {
        customStore('readonly', (store) => {
            const items = [];
            const request = store.openCursor();

            request.onsuccess = function(event) {
                const cursor = event.target.result;
                if (cursor) {
                    items.push(cursor.value);
                    cursor.continue();
                } else {
                    // No more items
                    resolve(items);
                }
            };

            request.onerror = function(event) {
                reject(event.target.error);
            };
        });
    });
}

export function deleteCacheItemsByNameChange(valueToMatch, customStore = defaultGetBlobStore()) {
    return customStore('readwrite', (store) => {
        const request = store.openCursor();

        return new Promise((resolve, reject) => {
            request.onsuccess = function(event) {
                const cursor = event.target.result;
                if (cursor) {
                    const value = cursor.value;
                    if (value.userUuid === valueToMatch) {
                        cursor.delete();                        
                    }
                    cursor.continue();
                } else {
                    // No more items
                    resolve();
                }
            };

            request.onerror = function(event) {
                reject(event.target.error);
            };
        });
    });
}