import React, {useState, useEffect, useRef, useContext} from 'react'
import { IsMobile, IsTablet, IsDesktop } from '../../utils';
import CustomIcons from '../../components/custom-icons';
import NotificationItem from './notification-item';
import NotificationService from '../../../../services/api/notificationService';
import { ShareContext } from '../../context/share-state';
import { SessionContext } from '../../context/session-provider';
import ServiceUtil from '../../../../services/util/serviceUtil';


const Notification = () => {

  const { tenantUuid, userUuid, checkAndRefreshToken } = useContext(SessionContext)

  const {
    showNotification,
    setShowNotification,
    showEmptyNotif,
    setShowEmptyNotif,
    unreadCount,
    setUnreadCount,
    notifToken,
    setNotifToken,
    stopGetNotif,
    setStopGetNotif,
    newNotif,
    setNewNotif,
    earlierNotif,
    setEarlierNotif,
    notifToRead,
    setNotifToRead,
    notifLoading,
    setNotifLoading
  } = useContext(ShareContext);

  const notificationBox = useRef();
  const notificationBtn = useRef();
  const notificationScrollSection = useRef(null);
  const isMobile = IsMobile();

  const currentDate = new Date();
  const today = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds()
  );
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  let displayNewNames = []
  let displayNames = []
  let newNotifRead = true;
  let earlierNotifRead = true;
  let newGroup = [];
  let earlierGroup = [];


  const handleClickOutside = (event) => {
    if (notificationBox.current && !notificationBox.current.contains(event.target)&& !notificationBtn.current.contains(event.target) ) {
      handleCloseNotification();
    }
  };

  useEffect(() => {
      if (showNotification) {
          document.addEventListener('mousedown', handleClickOutside);
          if (isMobile) {
            document.body.style.overflow = 'hidden';
          }
      } else {
          document.removeEventListener('mousedown', handleClickOutside);
          if (isMobile) {
            document.body.style.overflow = '';
          }
      }

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [showNotification]);

  const handleNotificationClick = async () => {
    setShowNotification(!showNotification);
  }

  const handleCloseNotification = async () => {
    setShowNotification(false);
    const response = await NotificationService.updateRead(notifToRead, tenantUuid);
    if (response.statusCode === 200){
      selAllReadTrue();
      addUnreadCount(-unreadCount);
    }
  }

  const selAllReadTrue = () => {
    const newNotifCopy = [...newNotif];
    const updatedNewNotif = newNotifCopy.map((item) => {
      return {...item, isRead: true};
    });
    setNewNotif(updatedNewNotif);
    const earlierNotifCopy = [...earlierNotif];
    const updatedEarlierNotif = earlierNotifCopy.map((item) => {
      return {...item, isRead: true};
    });
    setEarlierNotif(updatedEarlierNotif);
  }


  const addNewNotif = (item, prepend, subtract) => {
    setNewNotif((prevNewNotif) => {
      if (prevNewNotif.some(notif => notif.uuid === item.uuid)){
        return prevNewNotif;
      }
      if (item.isRead !== true){
        addNotifToRead(item);
        addUnreadCount(1-subtract);
      }
      if (prepend){
        return [item, ...prevNewNotif];
      }
      return [...prevNewNotif, item];
    });
  }

  const addEarlierNotif = (item, prepend, subtract) => {
    setEarlierNotif((prevEarlierNotif) => {
      if (prevEarlierNotif.some(notif => notif.uuid === item.uuid)){
        return prevEarlierNotif;
      }
      if (item.isRead !== true){
          addNotifToRead(item);
          addUnreadCount(1 - subtract);
      }
      if (prepend){
        return [item, ...prevEarlierNotif];
      }
      return [...prevEarlierNotif, item];
    });
  }

  //this creates an array of Partition Key and Sort Key to be pass to api to update the read status of the notification
  const addNotifToRead = (item) => {
    setNotifToRead((prevNotifToRead) => {
      if (prevNotifToRead.uuids.some(notif => notif.PK === item.uuid)){
        return prevNotifToRead;
      }
      return {uuids: [...prevNotifToRead.uuids, {PK: item.uuid, SK: tenantUuid}]};
    });
  }

  const addUnreadCount = (number) => {
    setUnreadCount((prevUnreadCount) => {
      return prevUnreadCount + number;
    });
  }

  let lastToken = notifToken;
  let stopGetingNotif = stopGetNotif;

  const getNotifications = async() => {
    let response = await NotificationService.getNotifications(tenantUuid, userUuid, lastToken);
    if (response.statusCode !== 200) return;

    if (!response.NextToken) {
      stopGetingNotif = true;
    } else {
      lastToken = response.NextToken;
    }

    let lastNotificationType = null;
    let subtract = 0;

    for (let Item of response.Items) {
      const itemDate = new Date(Item.datecreated);
      const isSameTypeAsPrevious = Item.notificationType === lastNotificationType;
      if (isSameTypeAsPrevious && Item.notificationType === 'join request') {
        if (Item.isRead === false) {
          subtract++;
        }
      }
      if (itemDate >= threeDaysAgo) {
        addNewNotif(Item, false, subtract);
      } else {
        addEarlierNotif(Item, false, subtract);
      }
      lastNotificationType = Item.notificationType;
      subtract = 0;
    }

    if (showEmptyNotif && (newNotif.length > 0 || earlierNotif.length > 0)){
      setShowEmptyNotif(false);
    }
    if (!response.NextToken) {
      return 11;
    }
    if (response.Items.length === 0) {
      return 0;
    } 
    return response.Items.length;
  } 

  const getNotificationsUpdate = async () => {
    let response = await NotificationService.getNotifications(tenantUuid, userUuid, "");
    if (response.statusCode !== 200) return;
    if (response.Items.length === 0) return;

    let lastNotificationType = null;
    let subtract = 0;

    for (let Item of response.Items.reverse()) {
      const itemDate = new Date(Item.datecreated);
      if (itemDate >= threeDaysAgo) {
        const isSameTypeAsPrevious = Item.notificationType === lastNotificationType;
        if (isSameTypeAsPrevious && Item.notificationType === 'join request') {
          if (Item.isRead === false) {
            subtract++;
          }
        }
        addNewNotif(Item, true, subtract);
        lastNotificationType = Item.notificationType;
        subtract = 0;
      }
    }
  }

  //initial notification fetch
  useEffect(() => {
    if (newNotif.length > 0 || earlierNotif.length > 0) {
      setShowEmptyNotif(false);
      setNotifLoading(false);
    }

    if (stopGetNotif) {
      setNotifLoading(false);
      return;
    }


    if ((newNotif || earlierNotif) && ((newNotif.length + earlierNotif.length) <=20)) {
      getNotifications();
    }
    setNotifToken(lastToken)
  }, [earlierNotif, newNotif, notifToken])

  //notification short polling
  useEffect(() => {
    setStopGetNotif(true);
    setNotifLoading(true);
    getNotificationsUpdate();    
    setNotifLoading(false);
  
    //fires every 30 seconds
    const notificationsInterval = setInterval(getNotificationsUpdate, 30000);
    //fires every 30 minutes
    const awsCredentialsInterval = setInterval(() => ServiceUtil.refreshAWSCredentials(), 30 * 60 * 1000);

    const CognitoIdentityCredentialsInterval = setInterval(() => checkAndRefreshToken, 30 * 60 * 1000);
  
    return () => {
      clearInterval(notificationsInterval);
      clearInterval(awsCredentialsInterval);
      clearInterval(CognitoIdentityCredentialsInterval);
    };
  }, []);

  //lazy loading
  useEffect(() => {
    if (!notificationScrollSection.current) return;
    if ((newNotif || earlierNotif) && ((newNotif.length + earlierNotif.length) >=100)) return;
    const container = notificationScrollSection.current;
    handleScroll();
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [notificationScrollSection, showNotification])

  const handleScroll = async () => {
    setStopGetNotif(stopGetingNotif);
    if (stopGetingNotif) return;

    if (newNotif.length > 0 || earlierNotif.length > 0) {
      setShowEmptyNotif(false);
    }
    
    const {scrollTop, scrollHeight, clientHeight} = notificationScrollSection.current;

    const threshold = 20;

    // Check if user is near the bottom
    if (scrollTop + clientHeight >= scrollHeight - threshold) {
    let results = 0;
    while (results < 10){
        const resultNumber = await getNotifications();
        if (resultNumber === 11) {
          setStopGetNotif(true);
          break;
        }
        results += resultNumber;
        setNotifToken(lastToken)
      }
    }
  }
  // end lazy loading

  const getFormattedAge = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInMilliseconds = now - date;

    // Convert the difference from milliseconds to other units
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24 * 7));

    if (diffInMinutes < 1) {
        return 'Just now';
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        const diffInHoursRounded = Math.floor(diffInMinutes / 60);
        return `${diffInHoursRounded} hour${diffInHoursRounded > 1 ? 's' : ''} ago`;
    } else if (diffInDays < 7) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    } else {
        return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`;
    }
  };

  return (
    <div className='notification-btn'>
      <button  ref={notificationBtn} className='icon-button' onClick={handleNotificationClick}>
          <CustomIcons variant='notification' className='header-icon' />
          { unreadCount >= 1 && <div className='counter'>{unreadCount >= 10? "9+" : unreadCount}</div> }
      </button>
      {showNotification && 
        <div ref={notificationBox} className='notificationBox'>
          <svg className='pointer' width="100" height="100" viewBox="0 70 200 100">
            <path
              d="M100,80
                    Q65,150 
                    10,150 
                    L10,160 
                    L190,160 
                    L190,150 
                    Q135,150 
                    100,80"
              fill="white"
              stroke="none"
            />
            <path
              d="M100,80
                    Q65,150 
                    10,150"
              fill="none"
              stroke="black"
              strokeWidth="4"
            />
            <path
              d="M100,80
                    Q135,150 
                    190,150"
              fill="none"
              stroke="black"
              strokeWidth="4"
            />
          </svg>
          <header>
            <button className='back-btn' onClick={() => handleCloseNotification()}>
              <CustomIcons variant='arrow' double={true} direction='back'/>
            </button>
            <h1>Notifications</h1>
            <button className='close-btn' onClick={() => handleCloseNotification()}>
              <CustomIcons variant='close' />
            </button>
          </header>
          <main ref={notificationScrollSection}>
            { notifLoading ?
              <section className="notification-period">
                <NotificationItem variant="skeleton"></NotificationItem>
                <NotificationItem variant="skeleton"></NotificationItem>
                <NotificationItem variant="skeleton"></NotificationItem>
                <NotificationItem variant="skeleton"></NotificationItem>
                <NotificationItem variant="skeleton"></NotificationItem>
              </section>
              :
              (showEmptyNotif ?
                <section className="empty-notif">
                  <img src="../../../../images/mailbox.svg"/>
                  <h1>No notifications yet</h1>
                  <p>Check back for new notifications</p>
                </section>
              :
              <>
                { newNotif.length > 0 && 
                  (<section className='notification-period'>
                    <header><h2>New</h2></header>
                    {newNotif.map((item, index) => {
                      if (item.notificationType === 'join request') {
                        // Check if the next notification is also a join request
                        const nextItem = newNotif[index + 1];
                        displayNewNames = [...displayNewNames, item.userName];
                        newGroup = [...newGroup, item]

                        if (item.isRead === false) {
                          newNotifRead = false;
                        }

                        if (nextItem && nextItem.notificationType === 'join request') {
                          return null; // Skip rendering individual join requests
                        }
                        // Otherwise, render the join request
                        return (
                          <NotificationItem 
                          key = {newGroup[0].uuid}
                          variant={newGroup[0].notificationType} 
                          name={newGroup[0].userName}
                          seen = {newNotifRead} 
                          names = {displayNewNames} 
                          {...(newGroup[0].editor && {editor : newGroup[0].editor})}
                          {...(newGroup[0].oldRole && {oldRole : newGroup[0].oldRole})}
                          {...(newGroup[0].newRole && {newRole : newGroup[0].newRole})}
                          age={getFormattedAge(newGroup[0].datecreated)}
                          handleCloseNotification={handleCloseNotification}/>
                          );
                      }

                      newNotifRead = true;
                      displayNewNames = [];
                      newGroup = [];

                      return <NotificationItem 
                        key = {item.uuid}
                        variant={item.notificationType} 
                        name={item.userName} 
                        seen = {item.isRead} 
                        {...(item.editor && {editor : item.editor})}
                        {...(item.oldRole && {oldRole : item.oldRole})}
                        {...(item.newRole && {newRole : item.newRole})}
                        age={getFormattedAge(item.datecreated)}
                        handleCloseNotification={handleCloseNotification}/>
                    })}
                  </section>)
                }
                { earlierNotif.length > 0 && 
                  (<section className='notification-period'>
                    <header><h2>Earlier</h2></header>
                    {earlierNotif.map((item, index) => {
                      if (item.notificationType === 'join request') {
                        // Check if the next notification is also a join request
                        const nextItem = earlierNotif[index + 1];
                        displayNames = [...displayNames, item.userName];
                        earlierGroup = [...earlierGroup, item]

                        if (item.isRead === false) {
                          earlierNotifRead = false;
                        }

                        if (nextItem && nextItem.notificationType === 'join request') {
                          return null; // Skip rendering individual join requests
                        }
                        // Otherwise, render the join request
                        
                        return (
                          <NotificationItem 
                          key = {earlierGroup[0].uuid}
                          variant={earlierGroup[0].notificationType} 
                          name={earlierGroup[0].userName}
                          seen = {earlierNotifRead} 
                          names = {displayNames} 
                          {...(earlierGroup[0].editor && {editor : earlierGroup[0].editor})}
                          {...(earlierGroup[0].oldRole && {oldRole : earlierGroup[0].oldRole})}
                          {...(earlierGroup[0].newRole && {newRole : earlierGroup[0].newRole})}
                          age={getFormattedAge(earlierGroup[0].datecreated)}
                          handleCloseNotification={handleCloseNotification}
                          />
                          );
                      }

                      earlierNotifRead = true;
                      displayNames = [];
                      earlierGroup = [];

                      return <NotificationItem 
                        key = {item.uuid}
                        variant={item.notificationType} 
                        name={item.userName}
                        seen = {item.isRead}
                        {...(item.editor && {editor : item.editor})}
                        {...(item.oldRole && {oldRole : item.oldRole})}
                        {...(item.newRole && {newRole : item.newRole})}
                        age={getFormattedAge(item.datecreated)}
                        handleCloseNotification={handleCloseNotification}/>
                    })}
                  </section>)
                }
                { !stopGetNotif && 
                  (
                    <section className="notification-period">
                      <NotificationItem variant="skeleton"></NotificationItem>
                    </section>
                  )
                }
                {/* DONT Delete this is for future expansion of the icons for future reference*/}
                {/* <section>
                  <img src='../images/icons/all_assets.svg'></img>
                  <img src='../images/icons/documents.svg'></img>
                  <CustomIcons variant='collection' />
                </section> */}
              </>
              )
            }
          </main>
        </div>
      }
    </div>
  )
}

export default Notification;
