// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable no-undef */
import { randomHexstring } from '../../components/shared/utils';
import SolutionManifest from '../../solution-manifest';
import ServiceUtil from '../util/serviceUtil';
import ENDPOINTS from '../util/endpoints';

const ID_APP = '#root';
const ON_RECEIVE_MESSAGE = 'iot:message:received';
const ID_IOTSUBSCRIBE = `iotsubscriber-${randomHexstring()}`;

export default class IotSubscriber {
  constructor() {
    const iotsubscriber = document.createElement('div');
    const APP = document.querySelector(ID_APP);
    iotsubscriber.setAttribute('id', ID_IOTSUBSCRIBE);
    this.$mqtt = undefined;
    this.$clientId = undefined;
    this.$connected = false;
    this.$eventSource = iotsubscriber;
    APP.appendChild(this.$eventSource);
  }

  static getSingleton() {
    if(!window.AWSomeNamespace) {
      window.AWSomeNamespace = {}
    }
    if (!window.AWSomeNamespace.IotSubscriberSingleton) {
      window.AWSomeNamespace.IotSubscriberSingleton = new IotSubscriber();
    }
    return window.AWSomeNamespace.IotSubscriberSingleton;
  }

  static get Event() {
    return {
      Message: {
        Received: ON_RECEIVE_MESSAGE,
      },
    };
  }

  get mqtt() {
    return this.$mqtt;
  }

  set mqtt(val) {
    this.$mqtt = val;
  }

  get clientId() {
    return this.$clientId;
  }

  set clientId(val) {
    this.$clientId = val;
  }

  get eventSource() {
    return this.$eventSource;
  }

  get cognito() {
    return this.$cognito;
  }

  get connected() {
    return this.$connected;
  }

  set connected(val) {
    this.$connected = !!val;
  }

  /* iot */
  static async attachIot() {
    return ServiceUtil.authHttpRequest('POST', ENDPOINTS.AttachIot, {}, '');
  }

  /**
   * @function connect
   * @description connecto Iot message broker
   */
  async connect(user) {
    try {
      if (this.connected) {
        return;
      }
      const username = user.username || 'anonymous';
      this.clientId = `${username}-${randomHexstring()}`;
      this.mqtt = AWSIoTData.device({
        region: SolutionManifest.Region,
        host: SolutionManifest.IotHost,
        clientId: this.clientId,
        protocol: 'wss',
        maximumReconnectTimeMs: 8000,
        debug: true,
        accessKeyId:  AWS.config.credentials.accessKeyId,
        secretKey:  AWS.config.credentials.secretAccessKey,
        sessionToken: AWS.config.credentials.sessionToken,
      });
    } catch (e) {
      e.message = `IotSubscriber.connect: ${encodeURIComponent(e.message)}`;
      console.error(e.message);
      return;
    }

    this.mqtt.on('connect', () => {
      console.log(`${SolutionManifest.IotTopic} connected to IoT`);
      this.mqtt.subscribe(SolutionManifest.IotTopic);
      this.connected = true;
    });

    this.mqtt.on('reconnect', () => {
      console.log(`reconnecting ${this.clientId}...`);
      this.reconnect();
    });

    this.mqtt.on('error', (e) => {
      console.log(`error: iot.error: ${e}`);
    });

    this.mqtt.on('message', (topic, payload) => {
      const event = new CustomEvent(IotSubscriber.Event.Message.Received, { detail: [JSON.parse(payload.toString())]});
      this.eventSource.dispatchEvent(event);
      // Display IoT Message
      //console.log('Iot Message: ', JSON.parse(payload.toString()));
    });
  }

  /**
   * @function reconnect
   * @description reconnect to Iot message broker
   */
  async reconnect(credentials) {
    try {
      const {
        accessKeyId = '',
        secretAccessKey = '',
        sessionToken = '',
      } = credentials || AWS.config.credentials;
      await this.mqtt.updateWebSocketCredentials(accessKeyId, secretAccessKey, sessionToken);
    } catch (e) {
      e.message = `error: iot.reconnect: ${encodeURIComponent(e.message)}`;
      console.error(e.message);
    }
  }

  unsubscribe() {
    if (this.mqtt) {
      this.mqtt.unsubscribe(this.tenantUuid, () => {});
    }
  }
}
