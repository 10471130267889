import { spanishGray, iconSize } from '../../shared/utils';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';

const RestoreIcon = ({ circle, className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <RestoreRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default RestoreIcon;