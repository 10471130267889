import { black, iconSize } from '../../shared/utils';
import FilterCenterFocusRounded  from '@mui/icons-material/FilterCenterFocusRounded';

const FilterCenterFocusIcon = ({ className, color, size }) => {
    const colorVal = color || black;
    const sizeVal = size || iconSize;

    return (
        <FilterCenterFocusRounded className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
    )
};

export default FilterCenterFocusIcon;