import { spanishGray, iconSize } from '../../shared/utils';

const MovieIcon = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fontSize: sizeVal }}
        >
            <g clipPath="url(#clip0_8927_20539)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9997 2.66675L13.213 5.09341C13.225 5.11863 13.2305 5.14644 13.229 5.17432C13.2276 5.2022 13.2192 5.22927 13.2046 5.2531C13.19 5.27692 13.1698 5.29674 13.1456 5.31076C13.1215 5.32478 13.0942 5.33257 13.0663 5.33341H11.7463C11.6229 5.33483 11.5015 5.30112 11.3965 5.2362C11.2914 5.17128 11.207 5.07783 11.153 4.96675L9.99967 2.66675H8.66634L9.87967 5.09341C9.89165 5.11863 9.89716 5.14644 9.89569 5.17432C9.89422 5.2022 9.88583 5.22927 9.87127 5.2531C9.85672 5.27692 9.83645 5.29674 9.81231 5.31076C9.78817 5.32478 9.76091 5.33257 9.73301 5.33341H8.41301C8.28952 5.33483 8.16818 5.30112 8.06312 5.2362C7.95807 5.17128 7.87364 5.07783 7.81967 4.96675L6.66634 2.66675H5.33301L6.54634 5.09341C6.55832 5.11863 6.56382 5.14644 6.56236 5.17432C6.56089 5.2022 6.5525 5.22927 6.53794 5.2531C6.52338 5.27692 6.50312 5.29674 6.47898 5.31076C6.45484 5.32478 6.42758 5.33257 6.39967 5.33341H5.07967C4.95543 5.33428 4.83342 5.30041 4.7274 5.23562C4.62138 5.17083 4.53558 5.0777 4.47967 4.96675L3.33301 2.66675H2.66634C2.31304 2.6678 1.97452 2.80862 1.7247 3.05844C1.47488 3.30826 1.33406 3.64678 1.33301 4.00008V12.0001C1.33406 12.3534 1.47488 12.6919 1.7247 12.9417C1.97452 13.1915 2.31304 13.3324 2.66634 13.3334H13.333C13.6863 13.3324 14.0248 13.1915 14.2747 12.9417C14.5245 12.6919 14.6653 12.3534 14.6663 12.0001V3.33341C14.6663 3.1566 14.5961 2.98703 14.4711 2.86201C14.3461 2.73699 14.1765 2.66675 13.9997 2.66675H11.9997Z"
                fill={colorVal}
            />
            </g>
            <defs>
            <clipPath id="clip0_8927_20539">
                <rect width="16" height="16" fill={colorVal} />
            </clipPath>
            </defs>
        </svg>
    )
};

export default MovieIcon;