import { useState, useContext } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { ShareContext } from '../../context/share-state';
import { Select } from 'antd';
import { IsXLG_To_Tablet } from '../../utils';
import _ from 'lodash';
import Badge from 'react-bootstrap/Badge';
import CustomIcons from '../custom-icons';

const SettingNavbar = (props) => {
    //Share Context
    const { displayItems,
        setDisplayItems,
        displayCollectionItems,
        setDisplayCollectionItems,
        type,
        sort,
        setSortBy,
        numFilters,
        setDisplayFilters,
        filterState,
        setFilterState,
        displayFilters,
        view,
        setView,
        getAssetCount,
        changeType,
        displaySearchItems,
        setDisplaySearchItems,
        isImageSearch,
        imageSearchLabels
    } = useContext(ShareContext);

    //Check isXLG to Tablet?
    const isXLG_To_Tablet = IsXLG_To_Tablet();

    const changeView = (view) => {
        setView(view);
    };

    const onClickFilters = () => {
        setDisplayFilters(!displayFilters);
        setFilterState(!filterState);
    };

    const handleSortBy = (value) => {
        setSortBy(value);

        if(props.searchResult) {
            const sortedSearchItems = _.orderBy(displaySearchItems, ['timestamp'], [value === 1? 'desc': 'asc']);

            setDisplaySearchItems(sortedSearchItems);

        } else if(props.collectionUuid) {
            const sortedDisplayItems = _.orderBy(displayCollectionItems, ['timestamp'], [value === 1? 'desc': 'asc']);

            setDisplayCollectionItems(sortedDisplayItems);
        } else {
            const sortedDisplayItems = _.orderBy(displayItems, ['timestamp'], [value === 1? 'desc': 'asc']);

            setDisplayItems(sortedDisplayItems);
        }
    };

    return(
        <Navbar expand="lg" className="justify-content-between p-0 setting-navbar">
            <Container fluid className='g-0'>
                <Nav>
                    <Nav.Item className={type === ''? 'selected': ''}>
                        <Nav.Link onClick={(e) => changeType(e, '', props.collectionUuid, props.searchResult)}>
                            <img src="../images/icons/all_assets.svg" alt="all assets icon" />
                            { !isXLG_To_Tablet && 'All Assets' }
                            <Badge>{getAssetCount('', props.collectionUuid, isImageSearch ? imageSearchLabels : props.searchResult)}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={type === 'image'? 'selected': ''}>
                        <Nav.Link onClick={(e) => changeType(e, 'image', props.collectionUuid, props.searchResult)}>
                            <img src="../images/icons/images.svg" alt="images icon" />
                            { !isXLG_To_Tablet && 'Images' }
                            <Badge>{getAssetCount('image', props.collectionUuid, isImageSearch ? imageSearchLabels : props.searchResult)}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={type === 'video'? 'selected': ''}>
                        <Nav.Link onClick={(e) => changeType(e, 'video', props.collectionUuid, props.searchResult)}>
                            <img src="../images/icons/videos.svg" alt="videos icon" />
                            { !isXLG_To_Tablet && 'Videos' }
                            <Badge>{getAssetCount('video', props.collectionUuid, isImageSearch ? imageSearchLabels : props.searchResult)}</Badge>
                        </Nav.Link>
                    </Nav.Item>
                    {!isImageSearch && (
                        <>
                            <Nav.Item className={type === 'document'? 'selected': ''}>
                                <Nav.Link onClick={(e) => changeType(e, 'document', props.collectionUuid, props.searchResult)}>
                                    <img src="../images/icons/documents.svg" alt="documents icon" />
                                    { !isXLG_To_Tablet && 'Documents' }
                                    <Badge>{getAssetCount('document', props.collectionUuid, props.searchResult)}</Badge>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={type === 'audio'? 'selected': ''}>
                                <Nav.Link onClick={(e) => changeType(e, 'audio', props.collectionUuid, props.searchResult)}>
                                    <img src="../images/icons/audio.svg" alt="audio icon" />
                                    { !isXLG_To_Tablet && 'Audio' }
                                    <Badge>{getAssetCount('audio', props.collectionUuid, props.searchResult)}</Badge>
                                </Nav.Link>
                            </Nav.Item>
                        </>
                    )}
                </Nav>
                <Nav>
                    <Nav.Item>
                        <div className='row g-0 align-items-center'>
                            <div className='col'>
                                <div className='filter-button-wrapper'>
                                    <a onClick={() => onClickFilters()}>
                                        Filters {numFilters !== 0 && <span>{`(${numFilters})`}</span>}
                                        <span>
                                            {displayFilters ? <CustomIcons color="black" variant="arrow" direction="up" /> : <CustomIcons color="black" variant="arrow" direction="down" />}
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className='col-auto'>
                            <Select
                                id="sort-assets"
                                className='border-0 new-old'
                                defaultValue={1}
                                value={sort}
                                bordered={false}
                                suffixIcon={<CustomIcons variant="arrow" direction="expand" />}
                                dropdownStyle={{ fontFamily: 'Inter', minWidth: '150px'}}
                                options={[
                                    { value: 1, label: 'New to Old' },
                                    { value: 2, label: 'Old to New' },
                                ]}
                                aria-label="sort"
                                onChange={(value) => handleSortBy(value)}
                            />
                            </div>
                            <div className='col-auto col-separator'>
                                <div onClick={() => changeView('grid')}
                                    className={ view == 'grid'? 'icon-button selected': 'icon-button' }>
                                    <svg id="grid_view_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_598" data-name="Group 598">
                                            <rect id="Rectangle_451" data-name="Rectangle 451" width="24" height="24" fill="none"/>
                                        </g>
                                        <g id="Group_600" data-name="Group 600">
                                            <g id="Group_599" data-name="Group 599">
                                            <path id="Path_91086" data-name="Path 91086" d="M5,11H9a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,9,3H5A2.006,2.006,0,0,0,3,5V9A2.006,2.006,0,0,0,5,11Z"/>
                                            <path id="Path_91087" data-name="Path 91087" d="M5,21H9a2.006,2.006,0,0,0,2-2V15a2.006,2.006,0,0,0-2-2H5a2.006,2.006,0,0,0-2,2v4A2.006,2.006,0,0,0,5,21Z"/>
                                            <path id="Path_91088" data-name="Path 91088" d="M13,5V9a2.006,2.006,0,0,0,2,2h4a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H15A2.006,2.006,0,0,0,13,5Z"/>
                                            <path id="Path_91089" data-name="Path 91089" d="M15,21h4a2.006,2.006,0,0,0,2-2V15a2.006,2.006,0,0,0-2-2H15a2.006,2.006,0,0,0-2,2v4A2.006,2.006,0,0,0,15,21Z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                {/* <GridViewIcon
                                    /> */}
                            </div>
                            <div className='col-auto'>
                                <div onClick={() => changeView('row')}
                                    className={ view == 'row'? 'icon-button ms-2 selected': 'icon-button ms-2' }>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.22222 14.5714H4.66667C5.33889 14.5714 5.88889 13.9929 5.88889 13.2857V10.7143C5.88889 10.0071 5.33889 9.42857 4.66667 9.42857H2.22222C1.55 9.42857 1 10.0071 1 10.7143V13.2857C1 13.9929 1.55 14.5714 2.22222 14.5714ZM2.22222 21H4.66667C5.33889 21 5.88889 20.4214 5.88889 19.7143V17.1429C5.88889 16.4357 5.33889 15.8571 4.66667 15.8571H2.22222C1.55 15.8571 1 16.4357 1 17.1429V19.7143C1 20.4214 1.55 21 2.22222 21ZM2.22222 8.14286H4.66667C5.33889 8.14286 5.88889 7.56429 5.88889 6.85714V4.28571C5.88889 3.57857 5.33889 3 4.66667 3H2.22222C1.55 3 1 3.57857 1 4.28571V6.85714C1 7.56429 1.55 8.14286 2.22222 8.14286ZM8.33333 14.5714H21.7778C22.45 14.5714 23 13.9929 23 13.2857V10.7143C23 10.0071 22.45 9.42857 21.7778 9.42857H8.33333C7.66111 9.42857 7.11111 10.0071 7.11111 10.7143V13.2857C7.11111 13.9929 7.66111 14.5714 8.33333 14.5714ZM8.33333 21H21.7778C22.45 21 23 20.4214 23 19.7143V17.1429C23 16.4357 22.45 15.8571 21.7778 15.8571H8.33333C7.66111 15.8571 7.11111 16.4357 7.11111 17.1429V19.7143C7.11111 20.4214 7.66111 21 8.33333 21ZM7.11111 4.28571V6.85714C7.11111 7.56429 7.66111 8.14286 8.33333 8.14286H21.7778C22.45 8.14286 23 7.56429 23 6.85714V4.28571C23 3.57857 22.45 3 21.7778 3H8.33333C7.66111 3 7.11111 3.57857 7.11111 4.28571Z" fill="#989595"/>
                                    </svg>
                                </div>
                                {/* <FormatListBulletedIcon
                                    /> */}
                            </div>
                        </div>
                    </Nav.Item>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default SettingNavbar;
