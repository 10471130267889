import { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Account } from "./components/shared/context/account-provider";
import { SessionContext } from "./components/shared/context/session-provider";
import Login from './layouts/login';
import Landing from './layouts/landing';
import ChangePassword from './layouts/changePassword';
import ForgotPassword from './layouts/forgotPassword';
import SessionRoute from "./components/shared/session-route";
import path from "./components/shared/routePaths";
import Registration from "./layouts/registration";
import UserVerifyEmail from "./layouts/userVerifyEmail";
import Welcome from "./layouts/welcome";
import TenantVerifyEmail from "./layouts/tenantVerifyEmail";
import ErrorPage from './layouts/errorPage';
import "react-loading-skeleton/dist/skeleton.css";

const AuthenticatedRoutes = (props) => {
  const landingProps = {
      isAuthenticated: props.isAuthenticated,
      updateAuthenticatedState: props.updateAuthenticatedState
  };

  return (
    <>
        {
          (props.isCustomerAdmin) &&
              <Route path={path.SETTINGS} element={<Landing {...landingProps} path={path.SETTINGS} />} />
        }
        {
          (props.isUserAdmin) &&
              <Route path={path.SETTINGS} element={<Landing {...landingProps} path={path.SETTINGS} />} />
        }
        {
          (props.isAssetOwner) &&
              <Route path={path.SETTINGS} element={<Landing {...landingProps} path={path.SETTINGS} />} />
        }
        <Route path={path.EXPLORE} element={<Landing {...landingProps} path={path.EXPLORE} />} />
        <Route path={path.SEARCH} element={<Landing {...landingProps} path={path.SEARCH} />} />
        {
          (props.isAssetOwner || (!props.isEndUser && !props.isUserAdmin)) &&
            <>
              <Route path={path.UPLOAD} element={<Landing {...landingProps} path={path.UPLOAD} />} />
              <Route path={path.UPLOADGUIDE} element={<Landing {...landingProps} path={path.UPLOADGUIDE} />} />
            </>
        }

        <Route path={path.COLLECTIONS} element={<Landing {...landingProps} path={path.COLLECTIONS} />} />
        <Route path={`${path.COLLECTIONS}/:subpath`} element={<Landing {...landingProps} path={path.COLLECTIONS} />} />
        <Route path={path.PROFILE} element={<Landing {...landingProps} path={path.PROFILE} />} />
    </>
  );
};

function App() {
  // Session Context
  const { isCustomerAdmin,
    isUserAdmin,
    isEndUser,
    isAssetOwner,
    isAuthenticated,
    checkAuthenticated,
    setIsAuthenticated,
    setCheckAuthenticated,
    hasSubdomain } = useContext(SessionContext);

  const updateAuthenticatedState = (newValue) => {
      setIsAuthenticated(newValue);
      setCheckAuthenticated(newValue);
  };

  const renderRoutes = () => {
    return (
      <>
          {
            isAuthenticated &&
              AuthenticatedRoutes({ updateAuthenticatedState, isAuthenticated, isCustomerAdmin, isUserAdmin, isEndUser, isAssetOwner })
          }
          <Route path={path.LOGIN} element={<Login updateAuthenticatedState={updateAuthenticatedState} />} />
          <Route path={path.REGISTRATION} element={<Registration />} />
          <Route path={path.USERVERIFYEMAIL} element={<UserVerifyEmail />} />
          <Route path={path.CHANGEPASSWORD} element={<ChangePassword isAuthenticated={isAuthenticated} />} />
          <Route path={path.FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={path.WELCOME} element={<Welcome />} />
          <Route path={path.VERIFYTENANTEMAIL} element={<TenantVerifyEmail />}></Route>
          <Route path={path.ERROR} element={<ErrorPage hasSubdomain={hasSubdomain} />} />
      </>
    );
  };

  const renderApp = () => {
    if(hasSubdomain === null) {
      return;
    }

    if(!hasSubdomain) {
      return <ErrorPage />;
    };

    return(
      <>
        <Account>
          <BrowserRouter>
            <SessionRoute isAuthenticated={isAuthenticated} checkAuthenticated={checkAuthenticated} />
            <Routes>
              {renderRoutes()}
            </Routes>
          </BrowserRouter>
        </Account>
      </>
    );
  };

  useEffect(() => {
    // Check if the current hostname starts with "www.dev"
    if (window.location.hostname.startsWith("www.*")) {
      // Extract subdomain without "www" and redirect
      const subdomain = window.location.hostname.replace("www.", "");
      window.location.href = `${window.location.protocol}//${subdomain}${window.location.pathname}`;
    }
  }, []);

  return(
    <>
      {renderApp()}
    </>
  );
}

export default App;
