/* eslint-disable no-undef */
import ServiceUtil from '../util/serviceUtil';
import ENDPOINTS from '../util/endpoints';

class auditTrailService {
    static async getActivities(tenantUuid, category, lastdays, pagesize, token, isexport) {
        const query = {
            tenantUuid: tenantUuid,
            category: category,
            lastdays: lastdays,
            pagesize: pagesize,
            token: token,
            isexport: isexport
        };

        return ServiceUtil.authHttpRequest('GET', ENDPOINTS.AuditTrail, query, '', tenantUuid);
    }

    static async addActivity(body, tenantUuid) {
        return ServiceUtil.authHttpRequest('POST', ENDPOINTS.AuditTrail, undefined, body, tenantUuid);
    }
}

export default auditTrailService;
