
import { useContext } from 'react';
import { SessionContext } from '../../shared/context/session-provider';
import { IsMobile, IsTablet } from '../../shared/utils';
import Localization from '../../shared/localization';
import Skeleton from 'react-loading-skeleton';
import CustomIcons from '../../shared/components/custom-icons';
import SolutionManifest from '../../../solution-manifest';
import PaymentMethods from '../../shared/components/plan-page-components/payment-methods';
import DisplayPlan from '../../shared/components/plan-page-components/displaying-plan';
import AssetsStorage from '../../shared/components/plan-page-components/assets-storage';

const Plan = (props) => {
    // Tenant Uuid
    const { productUrl } = useContext(SessionContext);

    const isMobile = IsMobile();
    const isTablet = IsTablet();

    const FeatureList = ({ features }) => {
        return (
          <ul>
            {features.map((feature, index) => (
              <li key={index}><CustomIcons variant="tick" color="#30BA34" />{feature.name}</li>
            ))}
          </ul>
        );
    };

    const PricingSkeleton = () => {
        return(
            <div className="pricing-skeleton">
                <Skeleton width="20%" height="24px"/>
                <Skeleton width="50%" height="40px" style={{marginBottom: '3rem'}}/>
                <Skeleton width="100%" height="50px" style={{marginBottom: '3rem'}}/>
                <Skeleton width="45%" height="24px"/>
                <Skeleton width="45%" height="24px"/>
                <Skeleton width="45%" height="24px"/>
                <Skeleton width="45%" height="24px"/>
            </div>
        );
    };

    return(<>
        <div className='settings-plan'>
            <h2 className={`${isTablet ? 'd-none' : null}`}>Plan</h2>
            <div className='settings-plan-container'>
                <DisplayPlan
                    updateFreeTrialPlan={props.updateFreeTrialPlan}
                    updatePaidPlan={props.updatePaidPlan}
                    customerId={props.customerId}
                    subscriptionPlan={props.subscriptionPlan}
                    planFreeTrial={props.planFreeTrial}
                    planPaidPlan={props.planPaidPlan}
                />
                <AssetsStorage />
            </div>

            {
                props.subscriptionPlan === SolutionManifest.SubscriptionPlan.FreeTrialId
                ?
                    <>
                        <div className='settings-plan-pricing-details'>
                            <h2>{Localization.Plan.FreeTrial.PricingTitle}</h2>
                        </div>
                        {
                            props.productPlan
                            ?
                                <div className='settings-plan-details-pricing'>
                                    <h3 className="product-name">{props.productPlan.productName}</h3>
                                    <div className="product-price">{props.productPlan.amount}</div>
                                    <div className="product-price-label">{props.productPlan.priceLabel}</div>
                                    <a className="btn btn-primary" href={`${productUrl}/plan`} target='_blank'>
                                        {Localization.Plan.FreeTrial.ProductPriceBtn}
                                    </a>
                                    <div className='product-features'>
                                        <FeatureList features={props.productPlan.features} />
                                    </div>
                                </div>
                            :
                                <PricingSkeleton />
                        }
                    </>
                :
                    props.subscriptionPlan === SolutionManifest.SubscriptionPlan.PaidPlanId &&
                        <PaymentMethods
                            customerId={props.customerId}
                            paymentMethods={props.paymentMethods}
                            listPaymentMethods={props.listPaymentMethods}
                            updatePaymentMethods={props.updatePaymentMethods}
                        />
            }
        </div>
    </>);
};

export default Plan;