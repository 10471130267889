import { spanishGray, iconSize } from '../../shared/utils';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';

const PlayIcon = ({ circle, className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;
    const circleVal = circle || false;

    return (
        <>
            {
                circleVal
                ?
                    <PlayCircleRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
                :
                    <PlayArrowRoundedIcon className={className && className} style={{ color: colorVal }} fontSize={sizeVal} />
            }
        </>
    )
};

export default PlayIcon;