import React, { useContext, useState, useEffect, useRef } from 'react';
import { UploadContext } from '../../shared/context/upload-provider';
import UploadSettings from '../upload-settings';
import UploadCard from "../upload-card";
import CustomIcons from '../../shared/components/custom-icons';

const UploadProcess = (props) => {
    const files = props.filesData.files ? props.filesData.files : [];
    const [sortName, setSortName] = useState(true);
    const [sortType, setSortType] = useState(true);
    const [sortTermsofuse, setSortTermsofuse] = useState(true);
    const scrollToCardRef = useRef(null);
    const { updateFileData,
        activeSettingView, updateOffcanvasState,
        uploadError} = useContext(UploadContext);

    const RenderUploadCards =
        files
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((file) => (
        <UploadCard
            key={file.id}
            onClickRemove={props.handleDelete}
            file={file}
            isSelected={props.checkedFiles.some((checkedFile) => checkedFile.id === file.id)}
            onSelectedChange={(event) => props.handleSelectedChange(event, file)}
            onSelectedClick={(el) => {props.handleSelectedClick(el, file)}}
            disableDelete={props.disableDelete}
            FSLightbox={props.FSLightbox}
            updateFSLightBox={props.updateFSLightBox}
            cardsTotal={files.length}
            progressState={props.progressState}
        />
    ));

    // List View Sort Files
    const sortFile = (type, ascending) => {
        switch (type) {
            case 'name':
                const fileName = files.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // convert names to uppercase for case-insensitive sorting
                    const nameB = b.name.toUpperCase();

                    let comparison = 0;

                    if (nameA > nameB) {
                        comparison = 1;
                    } else if (nameA < nameB) {
                        comparison = -1;
                    }

                    return ascending ? comparison : -comparison;
                });

                setSortName(sortName ? false : true);
                updateFileData(fileName);
                break;
            case 'type':
                const fileType = files.sort((a, b) => {
                    const nameA = a.type.toUpperCase(); // convert names to uppercase for case-insensitive sorting
                    const nameB = b.type.toUpperCase();

                    let comparison = 0;

                    if (nameA > nameB) {
                        comparison = 1;
                    } else if (nameA < nameB) {
                        comparison = -1;
                    }

                    return ascending ? comparison : -comparison;
                });

                setSortType(sortType ? false : true);
                updateFileData(fileType);
                break;
            case 'termsofuse':
                const fileTermsofuse = files.sort((a, b) => {
                    const nameA = a.attributes.termsofuse.toUpperCase(); // convert names to uppercase for case-insensitive sorting
                    const nameB = b.attributes.termsofuse.toUpperCase();

                    let comparison = 0;

                    if (nameA > nameB) {
                        comparison = 1;
                    } else if (nameA < nameB) {
                        comparison = -1;
                    }

                    return ascending ? comparison : -comparison;
                });

                setSortTermsofuse(sortTermsofuse ? false : true);
                updateFileData(fileTermsofuse);
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        if (scrollToCardRef.current) {
            window.scrollTo({
                top: scrollToCardRef.current.offsetTop,
                behavior: 'smooth'
              });
          }
    },[files]);

    return(
        <>
            {files.length > 0 ?
                <div className={`upload-process ${props.progressState ? props.isDesktop ? 'upload-progress-process progress-desktop' : 'upload-progress-process' : ''}`} ref={props.processRef}>
                    <div className="upload-process-wrapper" ref={scrollToCardRef}>
                        {!props.isDesktop &&
                            <UploadSettings
                            onSelectAllChange={props.handleSelectAllChange}
                            onHandleSetting={props.handleSetting}
                            isActive={props.activeSetting}
                            isEnable={props.enableSetting}
                            isDelete={props.disableDelete}
                            progressState={props.progressState}
                            checkedFiles={props.checkedFiles}
                            stickySetting={props.stickySetting}
                            settingRef={props.settingRef}
                            />
                        }
                        <div className={activeSettingView === 'grid' ? "upload-process-grid-view" : props.disableDelete ? "upload-process-list-view disableDelete" : "upload-process-list-view"}>
                            {   activeSettingView === 'list'
                                ?
                                    <div className='upload-process-list-sorting'>
                                        <div></div>
                                        <div className="flex-1" onClick={() => sortFile("name", sortName)}>Filename <CustomIcons variant="faSort" color="white" /></div>
                                        <div></div>
                                        <div onClick={() => sortFile("type", sortType)}>Type <CustomIcons variant="faSort" color="white" /></div>
                                        {/* <div onClick={() => sortFile("termsofuse", sortTermsofuse)}>Terms of Use <FontAwesomeIcon icon={faSort} /></div> */}
                                        <div style={{fontWeight:400}}>{props.progressState ? 'Status' : 'Actions'}</div>
                                    </div>
                                : null
                            }
                            { RenderUploadCards }
                        </div>
                    </div>
                </div>
            : null}
        </>
    );
}

export default UploadProcess;