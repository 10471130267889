import { AccountContext } from "../../components/shared/context/account-provider";
import { SessionContext } from "../../components/shared/context/session-provider";
import CustomIcons from "../../components/shared/components/custom-icons";
import Localization from '../../components/shared/localization';
import { useState, useContext, useRef } from "react";
import { Navigate } from "react-router-dom";
import UsersService from "../../services/api/usersService";
import Spinner from 'react-bootstrap/Spinner';
import QRCode from 'qrcode.react';
import { createAuditBody } from "../../components/shared/utils";
import auditTrailService from "../../services/api/auditTrailService";

const MFASetupComponent = () => {
    const [step, setStep] = useState(0);
    const softwareTokenRef = useRef(null);
    const [isTokenVerifying, setIsTokenVerifying] = useState(null);
    const [verificationSoftwareToken, setVerificationSoftwareToken] = useState('');
    const [tokenCount, setTokenCount] = useState(0);
    const [backupCode,setGetBackupCode] = useState({});

    // Account Context
    const { inputUserName,
            cognitoUser,
            setMFASetup,
            verifySoftwareToken,
            isMfaCodeMatch,
            setIsMfaCodeMatch,
            getUserBackupCodes1stTimeLogin,
            associateSecretCode } = useContext(AccountContext);

    // Session Context
    const { tenantUuid,
            userUuid,
            getSession,
            setupProfileInformation } = useContext(SessionContext);

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        if (step === 3) {
            setVerificationSoftwareToken('');  // Clear the input field
            setTokenCount(0);  // Reset token count
            setIsMfaCodeMatch(true);
            setIsTokenVerifying(false);
        }
        setStep(step - 1);
    };

    const handleVerificationSoftwareToken = async () => {
        let validatedToken;
        try {
            setIsTokenVerifying(true);
            const inputSoftwareToken = softwareTokenRef.current.value;
            validatedToken = await verifySoftwareToken(cognitoUser, inputSoftwareToken);
        } catch (error) {
            setIsTokenVerifying(false);
            return;
        }

        if(validatedToken.success === true) {
            //set user as verified
            const isVerified = await getUserBackupCodes1stTimeLogin();
            try {
            //generate backup code
                if(isVerified.success === true) {
                    const setGetBackUpCodes = await UsersService.generateUserBackupCodes1stTimeLogin(tenantUuid, userUuid);

                    setGetBackupCode((prevState) => {
                        const newState = setGetBackUpCodes;
                        if (newState) {
                          nextStep();
                        }
                        return newState;
                    });

                    setIsTokenVerifying(false);
                }
            } catch (error) {
                setIsTokenVerifying(false);
            }
        }
    };

    const handleRefreshPage = async () => {
        //Set up profile information
        const session = await getSession(cognitoUser);

        await setupProfileInformation(session).then(async () => {            
            // Add login activity to audit trail
            if(tenantUuid && session['custom:uuid']) {
                const auditBody = createAuditBody(tenantUuid, userUuid, "account", "login");
                await auditTrailService.addActivity(auditBody, tenantUuid);
            }

            <Navigate replace to="/explore" />;
             return setMFASetup(false);
            
        })
    }
    const tokenCounter = (event) => {
        const newValue = event.target.value;
        setVerificationSoftwareToken(newValue);
        if (newValue.length <= 6) {
            setTokenCount(newValue.length);
        } else {
            setTokenCount(6);
            setVerificationSoftwareToken(newValue.slice(0,6));
        }
    }

    const handleKeyDown = (event) => {
        const input = event.target;
        const isNavKey = event.key === 'ArrowLeft' || event.key === 'ArrowRight';
        const isTextSelected = input.selectionStart === 0 && input.selectionEnd === input.value.length;

        if (tokenCount >= 6 && event.key !== 'Backspace' && !isTextSelected && !isNavKey) {
          event.preventDefault();
        }

        if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
            event.preventDefault();
            event.target.select();
        }

        if (event.key === 'Enter') {
            handleVerificationSoftwareToken();
        }
    };

    return (
        <>
            {step === 0 && (
                <>
                    <div className="general-wrapper">
                        <h1>{Localization.Login.Guide2fa.Setup2fa.step_0.Title}</h1>
                        <p>{Localization.Login.Guide2fa.Setup2fa.step_0.Desc1}</p>
                        <div className="mfa form-group">
                            <div className='btn-wrapper'>
                                <div className="mfa-btn button-group">
                                    <button
                                        type="submit"
                                        className={`btn btn-primary`}
                                        onClick={nextStep}
                                    >
                                        {Localization.Login.Guide2fa.Setup2fa.step_0.Button1}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {step === 1 && (
                <>
                    <div className="general-wrapper">
                        <div>
                            <div className="step-container">
                                <p className="step-indicator-tag">{`STEP ${step} OF 3`}</p>
                            </div>
                            <h1>{Localization.Login.Guide2fa.Setup2fa.step_1.Title}</h1>
                        </div>
                        <p>{Localization.Login.Guide2fa.Setup2fa.step_1.Desc1}</p>
                        <div className="mfa form-group">
                            <div className='btn-wrapper'>
                                <div className="mfa-btn button-group">
                                    <button
                                        type="submit"
                                        className={`btn btn-primary`}
                                        onClick={nextStep}
                                    >
                                        {Localization.Login.Guide2fa.Setup2fa.step_1.Button1}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {step === 2 && (
                <>
                    <div className="general-wrapper">
                        <div>
                            <div className="step-container">
                                <i onClick={prevStep}><CustomIcons variant="arrow" direction="back" className="icon"/></i>
                                <p className="step-indicator-tag">{`STEP ${step} OF 3`}</p>
                            </div>
                            <h1>{Localization.Login.Guide2fa.Setup2fa.step_2.Title}</h1>
                        </div>

                        <div className="qr-wrapper">
                            <QRCode value={`otpauth://totp/Elko:${encodeURIComponent(inputUserName)}?secret=${associateSecretCode}&issuer=Elko`} />
                        </div>

                        <p className="qr-code-info">
                            {Localization.Login.Guide2fa.Setup2fa.step_2.Desc1}<br/>
                            <span>Code: {associateSecretCode}</span>
                        </p>

                        <div className='btn-wrapper'>
                            <div className="mfa-btn button-group">
                                <button
                                    type="submit"
                                    className={`btn btn-primary`}
                                    onClick={nextStep}
                                >
                                    {Localization.Login.Guide2fa.Setup2fa.step_2.Button1}
                                </button>
                            </div>
                        </div>
                    </div>
                </>

            )}
            {step === 3 && (
                <>
                    <div className="general-wrapper">
                        <div>
                            <div className="step-container">
                                <i onClick={prevStep}><CustomIcons variant="arrow" direction="back" className="icon"/></i>
                                <p className="step-indicator-tag">{`STEP ${step} OF 3`}</p>
                            </div>
                            <h1>{Localization.Login.Guide2fa.Setup2fa.step_3.Title}</h1>
                        </div>
                        <div className="input form-group">
                            <label htmlFor="verificationSoftwareToken" className={`label ${isMfaCodeMatch ? '':'error'}`}>Verification code</label>
                            <input
                                id="verificationSoftwareToken"
                                className={`form-control ${isMfaCodeMatch ? '':'error'}`}
                                type="text"
                                placeholder="Enter verification code"
                                ref={softwareTokenRef}
                                onChange={tokenCounter}
                                onKeyDown={handleKeyDown}
                                value={verificationSoftwareToken}
                            ></input>
                            <div>
                                <p className={`error-label ${isMfaCodeMatch ? 'hidden':''}`}>
                                    <CustomIcons variant="error" className="icon-error"/>
                                    <span>Invalid verification code</span>
                                </p>
                            </div>
                        </div>
                        <div className="mfa-btn button-group">
                            <button
                                type="submit"
                                className={`btn btn-primary`}
                                onClick={handleVerificationSoftwareToken}
                                disabled={tokenCount === 0 || isTokenVerifying}
                            >
                                {Localization.Login.Guide2fa.Setup2fa.step_3.Button1} {isTokenVerifying && <Spinner variant='gray' />}
                            </button>
                        </div>
                    </div>
                </>
            )}
            {step === 4 && (
                <>
                    <div className="general-wrapper">
                        <h1>{Localization.Login.Guide2fa.Setup2fa.step_4.Title}</h1>
                        <p>{Localization.Login.Guide2fa.Setup2fa.step_4.Desc1}</p>
                        <div className="mfa form-group">
                            <div className="back-up-code-wrapper">
                                <p className="mfa-desc-1"><b>{Localization.Login.Guide2fa.Setup2fa.step_4.Desc2}</b></p>
                                <p className="mfa-desc-2">{Localization.Login.Guide2fa.Setup2fa.step_4.Desc3}</p>
                                <div className="codes-tag">
                                    {
                                       backupCode.statusCode === 200?
                                            <>
                                                <div className="code-line 1">
                                                    <p>{backupCode.response.userBackupCodes[0]}</p>
                                                    <p>{backupCode.response.userBackupCodes[1]}</p>
                                                </div>

                                                <div className="code-line 2">
                                                    <p>{backupCode.response.userBackupCodes[2]}</p>
                                                    <p>{backupCode.response.userBackupCodes[3]}</p>
                                                </div>

                                                <div className="code-line 3">
                                                    <p>{backupCode.response.userBackupCodes[4]}</p>
                                                    <p>{backupCode.response.userBackupCodes[5]}</p>
                                                </div>

                                                <div className="code-line 4">
                                                    <p>{backupCode.response.userBackupCodes[6]}</p>
                                                    <p>{backupCode.response.userBackupCodes[7]}</p>
                                                </div>
                                                <div className="code-line 5">
                                                    <p>{backupCode.response.userBackupCodes[8]}</p>
                                                    <p>{backupCode.response.userBackupCodes[9]}</p>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div>
                                                    {null}
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='btn-wrapper'>
                            <div className="mfa-btn button-group">
                                <button
                                    type="submit"
                                    className={`btn btn-primary`}
                                    onClick={handleRefreshPage}
                                >
                                    {Localization.Login.Guide2fa.Setup2fa.step_4.Button1}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default MFASetupComponent;
