import { useState, useContext } from "react";
import { createStore, getStoreItem } from '../../../../store';
import { formatText } from '../../utils';
import { ApiContext } from "../../context/api-state";
import { SessionContext } from "../../context/session-provider";

const ViewAssetsHooks = (props) => {
    const [fileData, setFileData] = useState({});
    const [fetchedFileData, setFetchedFileData] = useState(false);
    const [currentUUID, setCurrentUUID] = useState(null);
    const [nextUUID, setNextUUID] = useState(null);
    const [prevUUID, setPrevUUID] = useState(null);
    const [viewAssetsShow, setViewAssetsShow] = useState(false);
    const [viewAssetBtnDisable, setViewAssetBtnDisable] = useState(false);
    const [displayItems, setDisplayItems] = useState(null);
    const { getAssetsById } = useContext(ApiContext);
    const { tenantUuid } = useContext(SessionContext);

    const getSmartLabelData = (analysisResults) => {
        let analysis;

        if (analysisResults) {
          if (analysisResults.Labels)
            analysis = analysisResults.Labels;
          else {
            analysis = Object.entries(analysisResults).map(([Name, Json]) => ({ Name, Json }));
          }
        }

        return analysis;
    };

    const getDimensionsFromUrl = (url, asset) => {
        return new Promise((resolve, reject) => {
            if(formatText(asset.type) === 'Image') {
                const img = new Image();
                img.onload = () => {
                  const { width, height } = img;
                  resolve({ width, height });
                };
                img.onerror = reject;
                img.src = url;
            } else if(formatText(asset.type) === 'Video') {
                const video = document.createElement('video');
                video.onloadedmetadata = () => {
                    const { videoWidth: width, videoHeight: height } = video;
                    const frameRate = video.webkitDecodedFrameRate || 30;
                    resolve({ width, height, frameRate });
                };
                video.onerror = reject;
                video.src = url;
                video.load();
            }
        });
    };

    const getFileData = async (fileUUID) => {
        try {
            const storeItem = await getStoreItem(fileUUID, createStore('asset', 'asset-asset'));

            if(storeItem) {
                const url = URL.createObjectURL(storeItem.ingestBlob);
                const data = { ...storeItem, url };
                setFileData(data);
                setViewAssetBtnDisable(false);
                setViewAssetsShow(true);
                setFetchedFileData(true);

                return;
            }

            getAssetsById(fileUUID, tenantUuid).then((asset) => {
                const url = URL.createObjectURL(asset.ingestBlob);
                const data = { ...asset, url };
                setFileData(data);
                setViewAssetBtnDisable(false);
                setViewAssetsShow(true);
                setFetchedFileData(true);
            });

            return;
        } catch (error) {
            // Handle error
            console.error('getFileData error:', error);
            return false;
        }
    };

    const handleViewAssetOpen = async (fileUUID, displayItems) => {
        setViewAssetsShow(true);
        setFileData({});
        setFetchedFileData(false);

        await getFileData(fileUUID);

        if(displayItems && displayItems.length > 0) {
            setDisplayItems(displayItems);
            setCurrentUUID(fileUUID);
            displayItems.map((file, index, elements) => {
                if(file.uuid === fileUUID) {
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    return elements;
                }
                return elements;
            });
        }

    };

    const handleViewPrev = async () => {
        if(prevUUID) {
            setFileData({});
            setFetchedFileData(false);
            setViewAssetBtnDisable(true);
            await getFileData(prevUUID);
            setCurrentUUID(prevUUID);
            displayItems.map((file, index, elements) => {
                if(file.uuid === prevUUID) {
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    return elements;
                }
                return elements;
            });
        }
    };

    const handleViewNext = async () => {
        if(nextUUID) {
            setFileData({});
            setFetchedFileData(false);
            setViewAssetBtnDisable(true);
            await getFileData(nextUUID);
            setCurrentUUID(nextUUID);
            displayItems.map((file, index, elements) => {
                if(file.uuid === nextUUID) {
                    const nextItem = elements[index + 1];
                    const prevItem = elements[index - 1];
                    setNextUUID(nextItem && nextItem.uuid);
                    setPrevUUID(prevItem && prevItem.uuid);
                    return elements;
                }
                return elements;
            });
        }
    };

    const handleViewClose = () => {
        setFileData({});
        setFetchedFileData(false);
        setViewAssetsShow(false);
    };

    return {
        handleViewPrev,
        handleViewNext,
        handleViewClose,
        handleViewAssetOpen,
        fileData,
        fetchedFileData,
        currentUUID,
        nextUUID,
        prevUUID,
        viewAssetsShow,
        viewAssetBtnDisable
    };
};

export default ViewAssetsHooks;