import { spanishGray, iconSize } from '../../shared/utils';

const LockReset = ({ className, color, size }) => {
    const colorVal = color || spanishGray;
    const sizeVal = size || iconSize;

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  className={className} style={{ fontSize: sizeVal }} enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={colorVal}>
            <rect fill="none" height="24" width="24"/>
            <path d="M13,3c-4.97,0-9,4.03-9,9c0,0.06,0.01,0.12,0.01,0.19l-1.84-1.84l-1.41,1.41L5,16l4.24-4.24l-1.41-1.41l-1.82,1.82 C6.01,12.11,6,12.06,6,12c0-3.86,3.14-7,7-7s7,3.14,7,7s-3.14,7-7,7c-1.9,0-3.62-0.76-4.88-1.99L6.7,18.42 C8.32,20.01,10.55,21,13,21c4.97,0,9-4.03,9-9S17.97,3,13,3z M15,11v-1c0-1.1-0.9-2-2-2s-2,0.9-2,2v1c-0.55,0-1,0.45-1,1v3 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-3C16,11.45,15.55,11,15,11z M14,11h-2v-1c0-0.55,0.45-1,1-1s1,0.45,1,1V11z"/>
        </svg>
    )
};

export default LockReset;








